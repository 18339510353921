import { Box, useMediaQuery } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Suspense } from "react";
import { FormProvider } from "react-hook-form";

import { AnimatedColorBar, Loader } from "@components/common";
import { Modal } from "@components/layout";
import { CallForHelpFooter } from "@components/layout/Footer/CallForHelpFooter.tsx";
import { CreditAuthorizationFooter } from "@components/layout/Footer/CreditAuthorizationFooter.tsx";
import { DebtReviewFooter } from "@components/layout/Footer/DebtReviewFooter.tsx";
import { SelectedPlanFooter } from "@components/layout/Footer/SelectedPlanFooter.tsx";
import { SelectPlanFooter } from "@components/layout/Footer/SelectPlanFooter.tsx";
import { useStyles } from "@components/layout/Form/form.styles.ts";
import { useStore } from "@stores";
import { theme } from "@styles";
import { useFormActions } from "@utils";

import { BudgetToolFooter } from "../Footer/BudgetToolFooter";

export const ModalForm = observer(() => {
  const {
    modalStore: { module, modalLoading },
  } = useStore();

  const actions = useFormActions(module.name);
  const onSubmit = actions.submitHandler;
  const name = module.name ?? "";
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const { remoteConfigStore } = useStore();

  const classes = useStyles({ name });

  let footer: JSX.Element = <></>;

  switch (name) {
    case "CreditModule":
      footer = <CreditAuthorizationFooter name={name} />;
      break;
    case "BudgetTool":
      if (isDesktop) {
        footer = <BudgetToolFooter />;
      }
      break;
    case "DebtReview":
      footer = <DebtReviewFooter />;
      break;
    case "SelectPlan":
      footer = <SelectPlanFooter methods={actions.methods} onSubmit={onSubmit} name={name} />;
      break;
    case "PlanSelected":
      footer = <SelectedPlanFooter name={name} />;
      break;
    case "CallForHelp":
      footer = <CallForHelpFooter name={name} showDoneToggle />;
      break;
  }

  return (
    <Modal>
      {actions.methods && (
        <FormProvider {...actions.methods}>
          <Box className={classes.container} id="form-container" data-cy="Form.container">
            {name === "CreditModule" ? (
              <AnimatedColorBar
                nextUserCtaAfterCreditPull={actions.nextUserCtaAfterCreditPull}
                initialAnimationRunTime={remoteConfigStore.creditPullAnimationRunTime || "15"}
              >
                <>
                  <Box className={classes.content}>{module.component}</Box>
                  {footer}
                </>
              </AnimatedColorBar>
            ) : (
              <Suspense fallback={<Loader data-cy="modal-loader-spinner" />}>
                {modalLoading ? (
                  <Loader data-cy="modal-loader-spinner" />
                ) : (
                  <>
                    <Box className={classes.content} data-cy="Form.content">
                      {module.component}
                    </Box>
                    {footer}
                  </>
                )}
              </Suspense>
            )}
          </Box>
        </FormProvider>
      )}
    </Modal>
  );
});
