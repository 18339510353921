import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { Text } from "@components/common";
import { useStore } from "@stores";
import { formatNumberToCurrencyString } from "@utils";

export const AvailableCash = observer(() => {
  const { t } = useTranslation("budget_tool");
  const {
    budgetStore: { availableCash },
  } = useStore();

  return (
    <Box
      data-cy="AvailableCash-Box-container"
      display="flex"
      alignItems="center"
      gap={1}
      sx={{ whiteSpace: "nowrap" }}
      justifyContent="center"
    >
      <Text
        data-cy="AvailableCash-availableCash"
        sx={{ fontSize: "clamp(1rem, calc(0.5rem + 0.5vw), 1.4rem)" }}
        fontWeight="semiBold"
        noWrap
      >
        {t("availableCash")}
      </Text>
      {availableCash < 0 && <WarningAmberOutlinedIcon color="error" />}
      <Text
        data-cy="AvailableCash-formantToCurrency"
        sx={{ fontSize: "clamp(1rem, calc(1rem + 0.7vw), 1.4rem)" }}
        fontWeight="bold"
      >
        ${formatNumberToCurrencyString(availableCash)}/{t("frequencyLabel.Abb.monthly")}.
      </Text>
    </Box>
  );
});
