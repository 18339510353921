import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { memo, useEffect, useRef } from "react";

import { useStyles } from "../TermsAndConditions.styles";

export const TermsEnVersion = memo(
  ({
    hideModal,
    scrollToPrivacyPolicyRef,
    scrollToEsignRef,
  }: {
    hideModal: () => void;
    scrollToPrivacyPolicyRef?: boolean;
    scrollToEsignRef?: boolean;
  }) => {
    const privatePolicyTargetRef = useRef<HTMLDivElement>(null);
    const disputeRef = useRef<HTMLDivElement>(null);
    const esignTargetRef = useRef<HTMLDivElement>(null);
    const classes = useStyles();

    useEffect(() => {
      if (scrollToPrivacyPolicyRef) {
        setTimeout(() => {
          privatePolicyTargetRef.current?.scrollIntoView({
            behavior: "instant",
            block: "start",
          });
        }, 100);
      }
      if (scrollToEsignRef) {
        setTimeout(() => {
          esignTargetRef.current?.scrollIntoView({
            behavior: "instant",
            block: "start",
          });
        }, 100);
      }
    }, [scrollToPrivacyPolicyRef, scrollToEsignRef]);

    const scrollIntoView = (element: React.RefObject<HTMLElement>) => {
      setTimeout(() => {
        element.current?.scrollIntoView({
          behavior: "instant",
          block: "start",
        });
      }, 100);
    };

    return (
      <div className={classes.container}>
        <style>
          {`
          a {
            color: blue;
            transition: color 0.3s ease;
          }
          a:active {
            color: lightblue;
          }
          a:hover {
            cursor: pointer;
          }
        `}
        </style>
        <div className={classes.header}>
          <IconButton aria-label="terms-of-use" onClick={() => hideModal()}>
            <Close />
          </IconButton>
        </div>
        <div className={classes.content}>
          <div style={{ whiteSpace: "pre-line" }}>
            <h2 style={{ margin: 0 }}>Debt.com's Terms and Conditions</h2>
          </div>
          <p>
            <b>Last updated: January 30, 2025. </b>
          </p>
          <h2>
            <span></span>
            AGREEMENT BETWEEN USER AND DEBT.COM
            <span></span>
          </h2>
          <p>
            These Terms and Conditions of Use (“Agreement”) are a legal agreement between you and Debt.com, LLC
            (hereinafter referred to as “Website Owner”, “We”, “US”, “Our”, “Debt.com”), the owner and developer of
            Debt.com (the “Site”). By using, registering, or otherwise interacting with the Site in any way whatsoever,
            including through any service provided through Debt.com, you agree to be bound by all the terms and
            conditions (the “Terms”) set forth in this Agreement. You understand that you are not able to modify these
            terms and that you must accept all terms “AS-IS.” Should you choose not to accept all Terms herein, simply
            do not register your information, make use of, or interact with this Site.
          </p>
          <p style={{ fontWeight: "bold" }}>
            This Agreement contains a binding arbitration agreement in the{" "}
            <span style={{ textDecoration: "underline" }}>DISPUTE RESOLUTION BY BINDING ARBITRATION</span> Section of
            this Agreement, which provides that you and Website Owner, and any of Website Owner’s principals, employees,
            affiliates, members, or officers as well as all independent service and technology providers who provide
            services to or on the Site, agree to resolve all disputes arising out of or relating to your registration,
            use, and/or interaction with the Site in any way whatsoever through binding individual arbitration and not
            in any class or mass arbitration setting, and that you and we both give up any right to have any dispute
            between the parties be decided in a court by a judge or a jury. You have the right to opt out of our
            agreement to arbitrate.{" "}
            <a onClick={() => scrollIntoView(disputeRef)} target="_blank" rel="noopener noreferrer">
              See the Dispute Resolution by Binding Arbitration
            </a>{" "}
            section of this Agreement. YOU AGREE THAT YOU HAVE READ, UNDERSTAND AND AGREE TO BE BOUND BY THESE TERMS.
          </p>
          <p>
            The Site is a financial education and referral website that provides financial education materials to
            consumers about how to properly tackle their finances and debt and connects consumers with independent
            third-party financial service providers that provide debt relief financial services and help consumers to
            live healthier financial lives. You understand that when you call the number on our Site, you may be
            connected with one of our representatives or you may be directly connected with one of our partners, or a
            third-party to assist you. Independent third-party financial service providers may charge fees for their
            services and have their own terms of service.{" "}
            <span style={{ fontWeight: "bold" }}>
              Debt.com is not responsible and does not guarantee any outcomes from these independent third-party
              financial service providers. Debt.com does not guaranty that Independent third-party financial service
              providers provide service in your state.
            </span>
          </p>
          <p>
            Please be advised that Debt.com’s{" "}
            <a onClick={() => scrollIntoView(privatePolicyTargetRef)} target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>{" "}
            is hereby incorporated by reference into this Agreement. The Privacy Policy explains how we collect,
            protect, share and use your information as a part of our Site, and all of our product and services
          </p>
          <h3>MODIFICATION OF THESE TERMS OF USE</h3>
          <p>
            DEBT.COM RESERVES THE RIGHT, WITH OR WITHOUT NOTICE, TO MAKE CHANGES TO THE TERMS AT ITSDISCRETION. YOU
            SHOULD REVIEW THIS AGREEMENT PERIODICALLY TO DETERMINE IF ANY CHANGES HAVE BEEN MADE. THE MOST CURRENT
            VERSION OF THESE TERMS, WHICH WILL BE NOTED BY THE “LAST UPDATED” DATE HEREIN, SUPERSEDES ALL PREVIOUS
            VERSIONS. REGARDLESS OF WHETHER OR NOT YOU REVIEWED THE CHANGES TO THESE TERMS, YOU EXPRESSLY ACKNOWLEDGE
            THAT YOUR CONTINUED USE OF ANY PART OF THIS SITE OR ANY FEATURE ON OUR SITE CONSTITUTES YOUR ACCEPTANCE TO
            ALL TERM CHANGES TO THIS AGREEMENT.
          </p>
          <h3>LINKS TO THIRD PARTY SITES</h3>
          <p>
            This Site may contain links to external websites (“Linked Sites”). The Linked Sites are not under the
            control or management of Debt.com, and Debt.com is not responsible for the contents of any and all Linked
            Site, including without limitation any links contained in a Linked Site or any changes or updates to a
            Linked Site. Debt.com is not responsible for webcasting or any other form of transmission received from any
            Linked Site. Debt.com is providing these links to you only as a convenience, and the inclusion of any links
            to Linked Sites does not imply endorsement by Debt.com of the Linked Site or any association with its
            operators.
          </p>
          <h3>NO UNLAWFUL OR PROHIBITED USE</h3>
          <p>
            As a condition of your use of the Site, you warrant to Debt.com that you will not use the Site for any
            purpose that is unlawful, prohibited by law and/or regulation, or is prohibited by these Terms. You may not
            use the Site in any manner which could damage, disable, overburden, negatively impact or impair the Site or
            interfere with any other party’s use and enjoyment of the Site. You may not obtain or attempt to obtain any
            materials or information from the Site through any means not intentionally made available or provided for
            through Debt.com on this Site.
          </p>
          <h3>YOU ARE RESPONSIBLE FOR YOUR FINANCIAL DECISIONS</h3>
          <p>
            Debt.com, through our Site and affiliates sites, may provide you with a venue through which you can obtain
            educational financial information as well as learn about various{" "}
            <span style={{ fontWeight: "bold" }}>independent third-party service providers</span>, such as financial
            institutions, credit card providers, debt management or debt settlement companies, credit repair providers,
            lenders and other financial professionals (“
            <span style={{ fontWeight: "bold" }}>Service Providers</span>”). You understand and acknowledge that
            Debt.com may be compensated by Service Providers for referrals made through this Site. Debt.com does not
            guarantee the accuracy or completeness of any of the information provided on the Site or with regards to the
            Service Providers and are not responsible for any loss resulting from your reliance on such information.
          </p>
          <p>
            You agree that you are to examine the information provided and conduct your own research of whether the
            Service Providers marketed on this Site fit your financial situation. We do not endorse or recommend the
            products or services of any Service Provider, and we are not an agent or advisor to you or any Service
            Provider. We do not validate or investigate the licensing, certification or other requirements and
            qualifications of Service Providers. It is your responsibility to investigate Service Providers you are
            considering engaging with. You acknowledge and agree that Service Providers are solely responsible for any
            services that they may provide you, and that Debt.com is not liable for any losses, costs, damages or claims
            in connection with, arising from, or related to, your use of any Service Provider’s products or services. We
            urge you to obtain the advice of qualified professionals (such as tax advisor, accountant, lawyer, etc.) who
            are fully aware of your individual circumstances before you make any financial decisions. By interacting
            with any Service Providers, you acknowledge and agree that you solely rely on your own judgment and that of
            such advisors in selecting any products or services offered by Service Providers.
          </p>
          <h3>NO GUARANTEE OF QUOTES, FEES, TERMS, RATES, COVERAGE OR SERVICES BY SERVICE PROVIDERS</h3>
          <p>
            We do not make any warranties or representations regarding the quotes, fees, terms, rates, coverage or
            services offered or made available by Service Providers to whom you may be referred to from our Site. We do
            not guarantee that quotes, fees, terms, rates, coverage or services offered by Service Providers are the
            best available in the marketplace. You may be able to find better fitting offers elsewhere.
          </p>
          <h3>USE OF COMMUNICATION SERVICES</h3>
          <p>
            The Site may contain bulletin board services, chat areas, news groups, forums, communities, personal web
            pages, calendars, and/or other message or communication facilities designed to enable you to communicate
            with the public at large or with a group (collectively, “Communication Services”). You agree to use the
            Communication Services only to post, send, and receive messages and material that are related to the
            contents of this Site, the education materials in this Site, or the services promoted or marketed by this
            Site. By way of example, and not as a limitation, you agree that when using a Communication Service, you
            will not under any circumstances:
          </p>
          <ul>
            <li>
              Defame, abuse, harass, stalk, threaten or otherwise violate the legal rights (such as rights of privacy
              and publicity) of others.
            </li>
            <li>
              Publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing,
              obscene, indecent or unlawful topic, name, material or information.
            </li>
            <li>
              Upload files that contain software or other material protected by intellectual property laws (or by rights
              of privacy of publicity) unless you own or control the rights thereto or have received all necessary
              consents.
            </li>
            <li>
              Upload files that contain viruses, corrupted files, or any other similar software or programs that may
              damage the operation of another’s computer.
            </li>
            <li>
              Advertise or offer to sell or buy any goods or services for any business purpose, unless such
              Communication Service specifically allows such messages.
            </li>
            <li>Conduct or forward surveys, contests, pyramid schemes or chain letters.</li>
            <li>
              Download any file posted by another user of a Communication Service that you reasonably should know or
              reasonably believe, cannot be legally distributed in such manner.
            </li>
            <li>
              Falsify or delete any author attributions, legal or other proper notices or proprietary designations or
              labels of the origin or source of software or other material contained in a file that is uploaded.
            </li>
            <li>Restrict or inhibit any other user from using and enjoying the Communication Services.</li>
            <li>
              Violate any code of conduct or other guidelines which may be applicable for any particular Communication
              Service.
            </li>
            <li>
              Harvest or otherwise collect information about others, including e-mail addresses, without their consent.
            </li>
            <li>Violate the Terms under this Agreement and/or the terms of this Site’s Privacy Policy.</li>
            <li>Violate any applicable laws or regulations.</li>
          </ul>
          <p>
            Debt.com has no obligation to monitor the Communication Services. However, Debt.com reserves the right to
            review materials posted to a Communication Service and to remove any materials in its sole discretion.
            Debt.com reserves the right to terminate your access to any or all the Communication Services at any time
            without notice for any reason whatsoever. Debt.com reserves the right at all times to disclose any
            information as necessary to satisfy any applicable law, regulation, legal process or governmental request,
            or refuse to post or to remove any information or materials, in whole or in part, is Debt.com’s sole
            discretion.
          </p>
          <p>
            You are advised to always use caution when giving out any personally identifying information (“PII”) about
            yourself, your spouse, your children, or your household generally in any Communication Service even if
            offered on this Site. Debt.com does not control or endorse the content, messages or information found in any
            Communication Service and, therefore, Debt.com specifically disclaims all warranties and liability
            associated with, or in regards to, the Communication Services, including but not limited to any actions
            resulting from your participation in any Communication Service. Managers and hosts are not authorized
            Debt.com spokespersons, and their views do not necessarily reflect those of Debt.com. Materials uploaded to
            a Communication Service may be subject to size posting limitations, limitations on usage, reproduction
            and/or dissemination. You are responsible for adhering to such limitations if you download the materials.
          </p>
          <h3>MATERIALS PROVIDED TO DEBT.COM OR POSTED AT ANY DEBT.COM’S SITE</h3>
          <p>
            Debt.com does not claim ownership of the materials you provide to Debt.com (including feedback and
            suggestions) or post, upload, input or submit to any Debt.com Site or its associated services (collectively
            “Submissions”). However, by posting, uploading, inputting, submitting, or providing your Submission you are
            granting Debt.com, its affiliated companies and necessary affiliate of Debt.com a non-exclusive, royalty
            free, perpetual license to use your Submission and/or its contents in connection with the operation of the
            Site or business including, without limitation, the rights to: copy, distribute, transmit, publicly display,
            publicly perform, reproduce, edit, translate and reformat your Submission; and to publish your name in
            connection with your Submission.
          </p>
          <p>
            No compensation will be paid with respect to the use of your Submission, as provided herein. Debt.com is
            under no obligation to post or use any Submission you may provide and may remove any Submission at any time
            at Debt.com’s sole discretion.
          </p>
          <p>
            By posting, uploading, inputting, providing or submitting your Submission you warrant and represent that you
            own or otherwise control all of the rights to your Submission as described in this section including,
            without limitation, all the rights necessary for you to provide, post, upload, input or submit the
            Submissions.
          </p>
          <h3>ELECTRONIC COMMUNICATIONS USE</h3>
          <p style={{ fontWeight: "bold" }}>
            <b>
              All website interactions and phone calls are recorded for marketing, compliance and quality assurance
              purposes.
            </b>
          </p>
          <ol type="a">
            <li>
              <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
                Telephone Consumer Protection Act Compliance
              </span>{" "}
              <p>
                Debt.com takes the Telephone Consumer Protection Act (“TCPA”) compliance with outmost seriousness.
                Therefore, any TCPA procedures are pursuant to a strict TCPA compliance policy of Company. This
                provision, outlines our general consent requirements and acknowledgements which you will be subject to
                in the event you submit a TCPA consent form or contact form seeking a communication from us. By
                providing us with your name, email, phone number on the submission form containing the TCPA disclosure,
                and signing using your E-Sign signature, you expressly authorize Debt.com, LLC to contact you (including
                using autodialers, automated text and artificial or pre-recorded messages) via your telephone,
                cellphone, mobile device (including via Short Message Service (“SMS”) or wireless internet (“WAP
                Service”)) and bot chats or via email, even if your telephone number is currently listed on any state or
                federal Do Not Call list. In the event that you have previously requested to be on the Company’s Do Not
                Call List, you understand that even if you submit a request to be called again, the Company policy is
                not to contact anyone who requested to be on the Company’s Do Not Call List. You understand that
                standard phone and data charges may apply. Your TCPA consent is not required and you are not required to
                complete a TCPA Consent form as a condition of receiving our services. You also consent to the recording
                and monitoring of all calls to and from us. You represent you are the owner or authorized user of the
                mobile device and the phone number associated with that mobile device to which we will be making phone
                calls and on which messages will be received, and that you are authorized to approve all applicable
                charges associated with the receipt of message from us. You may opt out of receiving calls and/or
                messages to your telephone or mobile device at any time by any and all reasonable methods, including
                responding to a text message with any of the following words "stop," "quit," "end," "revoke," "opt out,"
                "cancel," or "unsubscribe," or notifying us at{" "}
                <a href="mailto:admin@Debt.com">
                  <b>admin@Debt.com</b>
                </a>
                , or via our interactive or automated voice or key press revocation method if on the call with us, and
                you may opt out of receiving emails by clicking the “Opt-out” link on the bottom of the email. You
                acknowledge and agree that only United States residents may use the SMS or WAP Service to communicate
                with us.
              </p>
            </li>
            <li>
              <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
                Data Security of SMS and WAP Service Communications
              </span>{" "}
              <p>
                SMS or WAP Services are provided via wireless systems which use radio frequencies (and other means) to
                transmit communications over complex networks. Debt.com does not guarantee that your use of the SMS or
                WAP Services will be private or secure, and Debt.com will not be liable to you under any circumstance
                for any lack of privacy or security breach caused to you or that you may experience while sending or
                receiving SMS or WAP Services using your mobile device. You are fully responsible for taking all
                precautions and ensuring security measures are taken by you when using the SMS or WAP Service. You
                acknowledge and agree Debt.com may access the content of your account and the wireless account with your
                carrier for the purpose of identifying and resolving technical problems and service-related complaints.
                Please review our
                <a onClick={() => scrollIntoView(privatePolicyTargetRef)}> Privacy Policy</a> for additional information
                governing privacy of your personal information.
              </p>
            </li>
            <li>
              <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Instant Debt Advisor℠</span>{" "}
              <p>
                The Site includes newly developed SaaS application known as Instant Debt AdvisorSM by All Clear
                Decisioning, LLC (“All Clear”), a leading fintech SaaS platforms and tools development company. The
                Instant Debt AdvisorSM (“Debt Advisor”) asks you simple questions and obtains from you certain personal
                identifiable information, including but not limited to:
                <span style={{ fontWeight: "bold", fontStyle: "italic" }}>
                  {" "}
                  your full legal name, address, phone number, social security number, approval to do a soft pull to
                  your credit, current finances, and other relevant information regarding your debt and financial
                  situation
                </span>
                . Then, with the information you provided while using proprietary automated decision making technology,
                the Debt Advisor analyzes your finances and you overall financial situation, analyzes potential programs
                or products that are likely to be beneficial for you from vetted service providers, and provides you
                with choices of products or solutions to allow you to take control of your finances and improve your
                financial situation.
              </p>
              <p>
                You understand and agrees that All Clear, as the developer of the Debt Advisor platform, may have access
                to the information you enter or provide the Debt Advisor. In addition to Debt.com’s privacy policy, the
                information shall be also subject to All Clear’s privacy policy. For more information regarding All
                Clear’s Privacy Policy, please visit
                <a href="https://allclear.ai/legal-notices/" target="_blank" rel="noopener noreferrer">
                  {" "}
                  www.allclear.ai
                </a>
                .
              </p>
              <p>
                The Debt Advisor automated decision making is heavily dependent on your responses to questions and
                accuracy of the information imputed into the Debt Advisor. You understand and agree that you, and only
                you, are responsible to for the information inserted and answers given to the Debt Advisor inquiries.
                Failure to respond accurately or completely to the inquiries of the Debt Advisor will impact your
                results.
              </p>
              <p>
                You agree that the information you will provide in the Debt Advisor belongs to you, or you are an
                authorized person who has authority to provide the information on another’s behalf. You agree that you
                will use the Debt Advisor for personal use only, and under no circumstance use it for commercial,
                financial, or any other activity in which you may gain a financial benefit from. You are prohibited from
                providing another person’s information without the authority of that person, and in the event, it has
                been determined that you have violated this prohibition, you understand that Debt.com and/or All Clear
                may report your actions to the authorities.
              </p>
              <p>
                You also understand and agree that should you purposefully provide false or inaccurate information, or
                personal information which does not belong to you, you will be required to indemnify and hold harmless
                Debt.com, All Clear, and any third-party provider from all damages, claims, demands, or suits arising
                out of, or relating to your false or inaccurate information, or unauthorized impersonation of another
                consumer, including but not limited to any and all attorney fees (including inhouse counsel fee) and
                costs spent as a result of your conduct.
              </p>
              <p>
                Moreover, you understand and agree that neither Debt.com nor All Clear are your service providers or
                will be responsible to provide you with services. You are asked to, and you should, conduct an
                independent review of each of the options provided by the Debt Advisor, and independently examine the
                best service provider which fits your situation. You understand that Debt.com and/or All Clear are
                unable to influence the results of any product or servicer you may choose, and that you may choose to
                first consult with a professional advisor before signing up or making use of any service provider or
                product you are recommended by the automated Debt Advisor. Under no circumstance shall Debt.com and/or
                All Clear be liable to you for issues rendered by a service provider, and you understand that the Debt
                Advisor is merely a referral tool based on information provided by you and processed on your behalf.
              </p>
              <p>
                The Debt Advisor SaaS platform is provided AS-IS with all faults, errors to code, design issues,
                software issues, or other issues that may be impacted by your browser or our Site. Neither Debt.com nor
                All Clear will be liable for any latency issues, error in results, issues in submission or otherwise
                arising our of or relating to your use of the Debt Advisor.
              </p>
              <p>
                In the event that you choose to use the Debt Advisor on Debt.com’s Site in connection with an
                application or request by you, depending on where you reside, you may request that we disclose to you
                the following: (i) an explanation of the decision making process; and/or (ii) how you may obtain a
                different result. For more information regarding this disclosure requirement please see our{" "}
                <a href="https://allclear.ai/legal-notices/" target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </a>
                .
              </p>
            </li>
          </ol>
          <h3>LIMITATION OF LIABILITY AND WARRANTY DISCLAIMER</h3>
          <p>
            THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE
            INACCURACIES, OLD INFORMATION, OR TYPOGRAPHICAL ERRORS, AND IN NO WAY SHOULD BE INTERPERTED AS ERROR FREE.
            DEBT.COM MAKES PERIODICAL CHANGES TO THE INFORMATION AND MATERIALS PROVIDED ON THE SITE, AND MAY UPDATE OR
            CORRECT ANY INACCURACIES, OLD INFORMATION, OR OTHER TYPOGRAPHICAL ERRORS ON THE WEBSITE. IN ADDITION,
            DEBT.COM AND/OR ITS SITE ADMINISTRATOR MAY MAKE IMPROVEMENTS AND/OR CHANGES TO SITE AT ANY TIME.
          </p>
          <p>
            DEBT.COM AND/OR ITS SITE ADMINISTRATOR MAKE NO REPRESENTATIONS ABOUT AND DISCLAIM ANY AND ALL WARRANTIES,
            WHETHER EXPRESS OR IMPLIED WITH RESPECT TO THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND
            ACCURACY OF THE INFORMATION, SOFTWARE (WHETHER ITS OWN OR LICENSED FROM THIRD PARTIES), DEBT ADVISOR
            PRODUCT, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON OR AVAILABLE THROUGH THE SITE FOR ANY PURPOSE.
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE (WHETHER ITS OWN OR
            LICENSED FROM THIRD PARTIES), DEBT ADVISOR PRODUCT, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED “AS
            IS” WITHOUT WARRANTY OR CONDITION OF ANY KIND, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON- INFRINGEMENT.
          </p>
          <p>
            FURTHERMORE, ALL OPINIONS, GENERAL ADVICE, STATEMENTS OR OTHER COMMENTS ON THE SITE MAY BE THAT OF THE
            WRITER AND ARE NOT ASSUMED OR ADOPTED BY DEBT.COM, AND SHOULD NOT NECESSARILY BE RELIED UPON BY YOU. YOU
            UNDERSTAND AND AGREE THAT ALL OPINIONS, GENERAL ADVICE, STATEMENTS OR COMMENTS ARE NOT TO BE CONSTRUED AS
            PROFESSIONAL ADVICE FROM DEBT.COM, ITS EMPLOYEES, OR ITS WEBSITE OWNER AND SHOULD NOT BE RELIED UPON FOR
            PERSONAL, MEDICAL, LEGAL, OR FINANCIAL DECISIONS. YOU SHOULD MAKE YOUR OWN INDEPENDENT RESEARCH AND ANALYSIS
            REGARDING THE OPINIONS, STATEMENTS, INFORMATION, AND GENERAL ADVICE RECEIVED VIA THE SITE, AND YOU SHOULD
            CONSULT AN APPROPRIATE PROFESSIONAL FOR SPECIFIC ADVICE TAILORED TO YOUR SITUATION.
          </p>
          <p>
            DEBT.COM MAY PROVIDE YOU WITH VARIOUS TOOLS AND SOFTWARE THROUGHOUT THE SITE TO ASSIST YOU IN VARIOUS TASKS
            OR ALLOW YOU TO SUBMIT AND EXAMINE YOUR ELIGIBILITY FOR VARIOUS SERVICES PROVIDED BY THIRD PARTY PROVIDERS.
            SOME TOOLS AND SOFTWARE MAY REQUIRE YOU TO PROVIDE CERTAIN PERSONAL INFORMATION AND/OR WILL BE DEPENDENT ON
            INFORMATION INPUTED BY YOU IN ORDER TO PROVIDE YOU WITH ACCURATE RESULTS BASED ON AUTOMATED DECISIONS. YOU
            UNDERSTAND AND AGREE THAT NEITHER DEBT.COM NOR ITS TECHNOLOGY PARTNER ALL CLEAR DECISIONSING, LLC (WHICH
            PROVIDES PART OF AUTOMATED DECISIONING SOFTWARE ON THE SITE), ARE RESPONSIBLE OR LIABLE FOR THE AUTOMATED
            DECISIONING SYSTEM’S RESULTS WHICH WILL DEPEND ON YOUR INFORMATION INPUT, AND ANY INACCURACY (WHETHER ON
            PURPOSE OR NOT) MAY EFFECT THE ACCURACY OF THE RESULTS.
          </p>
          <p>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL DEBT.COM AND/OR ITS WEBSITE OWNER,
            OFFICERS, EMPLOYEES, MEMBERS, OR REPRESENTATIVES BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL,
            SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF
            USE, DATA OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH: THE USE OR PERFORMANCE OF THE DEBT.COM
            SITE; WITH THE DELAY OR INABILITY TO USE THE DEBT.COM SITE OR RELATED OR OFFERED SERVICES; THE PROVISION OF
            OR FAILURE TO PROVIDE SERVICES BY DEBT.COM OR ANY THIRD-PARTY WHICH MAY MARKET ITS SERVICES ON DEBT.COM; OR
            ARISING OUT OF OR RELATING TO ANY INFORMATION, SOFTWARE (WHETHER OWNED BY DEBT.COM OR LICENSED TO DEBT.COM
            BY A THIRD PARTY), DEBT ADVISOR PRODUCT, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE
            DEBT.COM SITE; OR OTHERWISE ARISING OUT OF OR RELATING TO THE USE OF THE DEBT.COM SITE, WHETHER BASED ON
            CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF DEBT.COM OR ANY OF ITS SUPPLIERS,
            PARTNERS, THIRD PARTY VENDORS, OR TECHNOLOGY PROVIDERS, HAVE BEEN ADVISED OF THE POSSIBILITY OF A CLAIM,
            DISPUTE, DAMAGES. BECAUSE SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY
            FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU IF YOU LIVE IN A
            STATE/JURISDICTION THAT EXPRESSLY PROHIBITS THE USE OF LIMITATION OF LIABILITY. IF YOU ARE DISSATISFIED WITH
            ANY PORTION OF THE DEBT.COM SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO
            DISCONTINUE USING THE DEBT.COM SITE.
          </p>
          <h3>TERMINATION/ACCESS RESTRICTION</h3>
          <p>
            Debt.com may establish areas on the Site which may require obtaining access credentials. Should you obtain
            such access to restricted areas on the Site, Debt.com reserves the right, in its sole discretion, to
            terminate your access to the Debt.com Site and the related services or any portion thereof at any time,
            without notice. In the event that you misuse our Site, the tools and software on our Site, or otherwise
            breach the terms of these Terms and Conditions or the Privacy Policy, you understand that Debt.com reserves
            the right, in its sole discretion, terminate your access and/or use of the Site, or any tool or software on
            the Site.
          </p>
          <h3>SITE NOT INTENDED FOR USE BY MINORS UNDER THE AGE OF 18</h3>
          <p>
            The Site is not designed or directed to individuals under the age of eighteen (18), and we request that
            these individuals do not provide Personally Identifiable Information (PII) through the Site. Information of
            minors that is considered PII under the Children Online Privacy Protection Act (COPPA) includes: (i) First
            and last name; (ii) Physical address that includes street and town or city name; (iii) E-mail address; (iv)
            Online identifier that permits an individual to be contacted directly (e.g. an IM name, video or audio chat
            username, or other form of screen name); (v) Telephone number; (vi) Social security number; (vii) Image,
            video or audio containing an individual's image or voice; (viii) Information sufficient to identify the home
            or other physical address of an individual; (ix) A cookie number, IP address, unique device number or other
            persistent identifier that can be used to track and recognize an individual over time and across different
            websites or apps; and/or (x) Hobbies, interests, information collected through the use of cookies, and any
            other information collected from a child that is either about that child or the parents/guardians of that
            child that, when combined with any of the above, can be used to identify the child.
          </p>
          <p>
            Debt.com does not knowingly or willfully collect PII from minors under 18 years of age without parental
            consent. If you believe your minor child provided his information on our site in disregard of these Terms
            and Conditions, we request that you contact us by email at{" "}
            <a href="mailto:admin@Debt.com">admin@Debt.com</a> and inform us so we can delete any PII provided by your
            child. For more information regarding this policy please visit our{" "}
            <a onClick={() => scrollIntoView(privatePolicyTargetRef)}>Privacy Policy</a>.
          </p>
          <h3>COPYRIGHT AND TRADEMARK NOTICES:</h3>
          <p>
            Debt.com, and the Debt.com logo are registered trademarks, trade names or service marks of Debt.com, LLC or
            its related companies. All other trademarks and service marks presented on the Site are the property of
            their respective owners, and Debt.com has obtained permission to use them in association with the
            educational information and referral services provided. You are not permitted to use any trademark or
            service mark displayed on this Site without the prior written consent of Debt.com or the owner of such
            trademark or service mark. You acknowledge and understand that you have no rights to any trademarks, service
            marks, copyrights. Debt.com reserves all rights to its Intellectual Property on the Site.
          </p>
          <h3>COPYRIGHT INFRINGEMENT POLICY</h3>
          <p>
            In accordance with the requirements set forth in the Digital Millennium Copyright Act, Title 17 United
            States Code Section 512(c)(2)(“DMCA”), Debt.com will investigate notices of copyright infringement and take
            appropriate remedial action. If you believe that any Content on the Site has been used or copied in a manner
            that infringes your work, please provide a written notification of claimed copyright infringement to the
            Designated Agent for the Site containing the following elements as set forth in the DMCA:
          </p>
          <ul>
            <li>
              a physical or electronic signature of the owner of the copyright interest that is alleged to have been
              infringed or the person authorized to act on behalf of the owner;
            </li>
            <li>identification of the copyrighted work(s) claimed to have been infringed, including copyright date;</li>
            <li>
              identification of the Content you claim to be infringing and which you request be removed from the Site or
              access to which is to be disabled along with a description of where the infringing Content is located;
            </li>
            <li>
              information reasonably sufficient to allow us to contact you, such as a physical address, telephone number
              and an email address;
            </li>
            <li>
              a statement by you that you have a good faith belief that the use of the Content identified in your
              written notification in the manner complained of is not authorized by you or the copyright owner, its
              agent or the law; and
            </li>
            <li>
              a statement by you that the information in your written notification is accurate and that, under penalty
              of perjury, you are the copyright owner or authorized to act on behalf of the copyright owner.
            </li>
          </ul>
          <p>
            Debt.com’s designated agent for the written notification of claims of copyright infringement can be
            contacted at the following address:
          </p>
          <p>
            Designated Agent – Copyright Infringement Claims <br /> Debt.com LLC
            <br /> 8220 W. State Road 84, <br /> Fort Lauderdale, FL 33324 <br /> Email:{" "}
            <a href="mailto:admin@Debt.com">
              <strong style={{ fontWeight: "bold" }}>admin@Debt.com</strong>
            </a>
          </p>
          <h3>YOUR INDEMNIFICATION REQUIREMENTS</h3>
          <p>
            You agree to defend, indemnify and hold Debt.com and its officers, directors, shareholders, employees,
            independent contractors, agents, representatives, technology partners (All Clear), and affiliates harmless
            from any and against all claims and expenses, including, but not limited to, attorneys’ fees, arising out
            of, or related to: (i) any breach or violation of this Agreement by you; (ii) your failure to provide
            accurate, complete, and current personally identifiable information (“PII”) in response to any request form
            on our Site, through a tool or software on our Site, or specifically on the Debt Advisor on our Site; (iii)
            your access or use of Services through our Site; (iv) access to our Site or use of our Services under any
            password that may be issued to you; (v) your transmissions, submissions or postings (i.e., your own User
            Generated Content); (vi) the Services you receive from any of the Service Providers to which you were
            referred by us; and/or (vii) any personal injury or property damage caused by you.
          </p>
          <h3>GENERAL / MISCELLANEOUS TERMS</h3>
          <p>
            <b style={{ fontWeight: "bold" }}>Governing Law, Jurisdiction, Venue; Waiver of Jury Trial</b>. This
            Agreement is governed by the laws of the State of Florida, without regards to any choice of law provisions
            or principles. Should you properly and timely opt out of Arbitration, you hereby consent to the exclusive
            jurisdiction and venue of state or federal courts sitting in Broward County, Florida, in all disputes
            arising out of or relating to the use of the Debt.com’s Site. If you did not opt out of Arbitration, please
            see the Arbitration provision below for the applicable law and jurisdiction governing your arbitration. You
            and we agree to submit to the personal jurisdiction of the courts located within Broward County, Florida for
            the purpose of litigating all such claims or disputes.{" "}
            <span style={{ fontWeight: "bold" }}>
              SHOULD YOU PROPERLY AND TIMELY OPT OUT OF ARBITRATION, YOU, NONETHELESS, UNDERSTAND THAT EACH PARTY
              ACKNOWLEDGES AND AGREES THAT ANY CONTROVERSY THAT MAY ARISE UNDER THIS AGREEMENT IS LIKELY TO INVOLVE
              COMPLICATED AND DIFFICULT ISSUES AND, THEREFORE, EACH PARTY HEREBY IRREVOCABLY AND UNCONDITIONALLY WAIVES
              ANY RIGHT SUCH PARTY MAY HAVE TO A TRIAL BY JURY IN RESPECT OF ANY PROCEEDING DIRECTLY OR INDIRECTLY
              ARISING OUT OF OR RELATING TO THIS AGREEMENT OR THE TRANSACTIONS CONTEMPLATED BY THIS AGREEMENT.
            </span>
          </p>
          <p>
            <b style={{ fontWeight: "bold" }}>Relationship of the Parties. </b>
            YYou agree that no joint venture, independent contractor, partnership, employment, or agency relationship
            exists between you and Debt.com as a result of this Agreement or use of the Debt.com Site.
          </p>
          <p>
            <b style={{ fontWeight: "bold" }}>Applicability of Laws. </b>
            Debt.com’s performance of this Agreement is subject to existing laws and legal process, and nothing
            contained in this agreement is in derogation of Debt.com’s right to comply with governmental, court and law
            enforcement requests or requirements relating to your use of the Debt.com Site or any information provided
            to or gathered by Debt.com with respect to such use. Use of the Debt.com Site is unauthorized in any
            jurisdiction that does not give effect to all provisions of these terms and conditions, including without
            limitation this paragraph.
          </p>
          <p>
            <b style={{ fontWeight: "bold" }}>Severability. </b>If any part of this Agreement is determined to be
            invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers
            and liability limitations set forth above, then the invalid or unenforceable provision will be deemed
            superseded by a valid, enforceable provision that most closely matches the intent of the original provision
            and the remainder of the agreement shall continue in effect.
          </p>
          <p>
            <b style={{ fontWeight: "bold" }}>Entire Agreement. </b>Unless otherwise specified herein, this Agreement,
            along with our Privacy Policy, any Notices and Disclosures on our Site, which are incorporated herein by
            reference, together constitute the entire agreement between the You and Debt.com with respect to the Site
            and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral or
            written, between the user and Debt.com with respect to the Debt.com Site. A printed version of this
            Agreement and of any notice given in electronic form shall be admissible in judicial or administrative
            proceedings based upon or relating to this Agreement to the same extent and subject to the same conditions
            as other business documents and records originally generated and maintained in printed form. It is the
            express wish to the parties that this Agreement and all related documents be drawn up in English despite
            having these terms in translated to other languages.
          </p>
          <h3>AMENDMENTS TO THIS AGREEMENT</h3>
          <p>
            We reserve the right to update, amend and/or change this Agreement at any time in our sole discretion and
            without notice. Updates to this Agreement will be posted here. Amendments will take effect immediately upon
            us posting the updated Agreement on our Services. You are encouraged to revisit this Agreement from time to
            time in order to review any changes that have been made. The date on which this Agreement was last updated
            will be noted immediately above this Agreement. Your continued access and use of our Services following the
            posting of any such changes shall automatically be deemed your acceptance of all changes.
          </p>
          <h3 style={{ textDecoration: "underline" }} ref={disputeRef}>
            DISPUTE RESOLUTION BY BINDING ARBITRATION – IMPORTANT NOTICE WAIVING YOUR RIGHT TO BRING A CLAIM BEFORE A
            JUDGE OR JURY IN COURT.
          </h3>
          <p>
            In the event of any controversy between you and Debt.com, including but not limited to any demand, claim,
            suit cross claim, counterclaim, or third-party complaint or dispute, whether contractual, statutory, in tort
            or otherwise (including but not limited to our technology providers, designated servicers, and any
            fulfillment provider working on your behalf) arising out of, or in any way relating, to our relationship,
            including but not limited to this Agreement, our Privacy Policy, Site Disclaimers, use of the Debt.com Site,
            the information on Debt.com’s Site, any referral services provided by Debt.com, Debt.com’s TCPA consent form
            or alleged TCPA violation, or the breach, termination, enforcement, interpretation, or validity of the
            Agreement or its performance, including any determination of the scope, enforcement or applicability of this
            provision to arbitrate, shall be resolved by binding arbitration in the nearest metropolitan area to the
            county in which you reside, or at such other location upon which the parties may agree upon. This agreement
            to arbitrate shall be governed exclusively by the Federal Arbitration Act (“FAA”), 9 U.S.C. §1 et sec.,
            which shall govern the interpretation and enforcement of our mutual agreement to arbitrate, and not by any
            state rule or statute governing arbitration and without regards to any state arbitration law. This agreement
            to arbitrate shall survive termination of the Agreement. The Parties agree to the following Arbitration
            Terms:
          </p>
          <ol type="A">
            <li>
              The Arbitration shall be administered by the American Arbitration Association (“AAA”) (
              <a href="http://www.adr.org/" target="_blank" rel="noopener noreferrer">
                www.adr.org
              </a>
              ), the Judicial Arbitration Mediation Services (“JAMS”), or another nationally known consumer arbitration
              service agreed by the parties in the event AAA and JAMS no longer conduct consumer arbitrations. The Party
              who intends to seek arbitration must first send to the other Party a written Notice of Dispute before
              filing it with the arbitration service provider. The Notice must describe both the nature and basis of the
              dispute; and the specific relief sought. Any notice shall be deemed to have been given and received for
              all purposes when delivered by hand, a day after receipt through a nationally recognized overnight
              courier, or a day after being received through certified or registered mail, postage and charges prepaid,
              return receipt requested, to us at: Debt.com, LLC, Attn: Legal Department, 8220 W. State Road 84, Fort
              Lauderdale, FL 33324.
            </li>
            <li>
              <b style={{ fontWeight: "bold" }}>Commencement and Rules of Arbitration. </b>
              If we do not resolve a claim within 30 days after receipt of the Notice, the party sending the Notice may
              commence an arbitration proceeding with the AAA, JAMS, or another national arbitration provision if AAA or
              JAMS are unavailable, by filing a Demand of Arbitration in effect at the time the action is commenced. If
              you choose to file with the AAA, you may download or copy the form to initiate arbitration from the
              following website: http://www.adr.org/consumer. The AAA shall provide the parties a list of 5 arbitrators
              in which the parties may agree on an arbitrator. If the parties cannot mutually agree on an arbitrator,
              the parties shall rank the arbitrators and the AAA shall appoint a single arbitrator who shall administer
              the arbitration proceeding pursuant to its Commercial Dispute Resolution Procedures and the Supplementary
              Procedures for Consumer-Related Disputes (“AAA Rules”), with the exception of Mass or Class Arbitration
              rules. The AAA Rules are available at www.adr.org or by calling the AAA at 1-800-778-7879. If you choose
              to file with JAMS, you may download or copy the form to initiate arbitration from the following website:{" "}
              <a href="https://www.jamsadr.com/adr-forms" target="_blank" rel="noopener noreferrer">
                https://www.jamsadr.com/adr-forms
              </a>
              . JAMS shall provide the parties a list of 5 arbitrators in which the parties may agree on an arbitrator.
              If the parties cannot mutually agree on an arbitrator, the parties shall rank the arbitrators and the JAMS
              shall appoint a single arbitrator who shall administer the arbitration proceeding pursuant to its
              Commercial Dispute Resolution Procedures and the Consumer Arbitration Minimum Standards (“JAMS Rules”),
              with the exception of Mass or Class Arbitration rules. In the event another arbitration service is
              provided, the parties agree to follow the rules and principles of that arbitration service, except as to
              any rights to Mass or Class Arbitration rules which the Parties expressly agree will not apply.
            </li>
            <li>
              <b style={{ fontWeight: "bold" }}>The Arbitrator. </b>The selected arbitrator shall be either a retired
              judge or an experienced attorney, shall be neutral and independent, and shall comply with the selected
              arbitration service’s code of ethics. The Arbitrator shall conduct the arbitration in a civil manner and
              ensure civility of the parties while making their arguments. Additionally, in conducting the arbitration
              hearing, in addition to the selected arbitration service rules, the arbitrator shall be guided by the
              Federal Rules of Civil Procedure, the Federal Rules of Evidence, and Florida substantive law, and in case
              of a federal law based claim the applicable federal law at issue.
            </li>
            <li>
              <b style={{ fontWeight: "bold" }}>The Arbitration Award. </b>The Arbitrator shall issue a written reasoned
              award in which the arbitrator shall address the claims, defenses, evidence presented, and legal reasoning
              in making his decision. If the Arbitrator determines that reasonable attorney fees and costs may be
              awarded under applicable law, the parties agree that the arbitrator will also determine the amount of
              reasonable attorneys’ fees to be awarded. Any award rendered by the arbitrator shall be final and binding
              and shall not be subject to vacation or modification, except as expressly permitted by the Federal
              Arbitration Act. Judgment on the Arbitration Award may be entered in the jurisdiction in which the
              arbitration was commenced or in any court having jurisdiction over the Party against whom judgment is to
              be entered.
            </li>
            <li>
              <b style={{ fontWeight: "bold" }}>
                Waiver of Right to Bring Mass or Class Actions. The parties agree that either party may bring claims
                against the other only in his/her or its individual capacity and not as a plaintiff or class member in
                any purported mass, class, or other representative proceeding. Further, the parties agree that the
                arbitrator may not consolidate proceedings of more than one person’s claims and may not otherwise
                preside over any form of representative or class proceeding.
              </b>
            </li>
            <li>
              <b style={{ fontWeight: "bold" }}>Limitation on Injunctive Relief: </b>
              The arbitrator may award injunctive relief only in favor of an individual party seeking relief and only to
              the extent necessary to provide relief warranted by that party’s individual claim.
            </li>
            <li>
              <b style={{ fontWeight: "bold" }}>Costs of Arbitration: </b>Payment of all filing, administration and
              arbitrator fees will be governed by the selected arbitration service rules. Each party shall bear its own
              attorneys’ fees, if any, unless such fees are expressly provided for by applicable law. In the event that
              a party fails to proceed with Arbitration, unsuccessfully challenges the Arbitrator’s award, or fails to
              comply with the Arbitrator’s award, the other party shall be entitled to costs of suit, including
              reasonable attorneys’ fees for having to compel Arbitration or defend or enforce the award.
            </li>
            <li>
              <b style={{ fontWeight: "bold" }}>Arbitration Opt-Out. </b>YOU MAY OPT-OUT OF THE TERMS OF THIS
              ARBITRATION PROVISION BY PROVIDING DEBT.COM WITH NOTICE IN WRITING WITHIN THIRTY (30) DAYS OF FIRST USING
              OUR SITE. If you do so, neither you nor we can require the other to participate in an arbitration
              proceeding. To opt out, you must notify us in writing either by U.S. mail delivered to: Attn: Legal
              Department, Debt.com, LLC, 8220 W. State Road 84, Fort Lauderdale, FL 33324 or by email delivered to
              admin@Debt.com. You must include: (1) your name and residence address; (2) the email address and/or mobile
              telephone number associated with your account; and (3) a clear statement that you want to opt out of this
              agreement to arbitrate.
            </li>
            <li>
              <b style={{ fontWeight: "bold" }}>
                What does arbitration mean? By agreeing to arbitration, we both acknowledge and agree that we are each:
                (i) waiving the right to pursue any other available dispute resolution processes, including, but not
                limited to, a court action or administrative proceeding, (ii) waving the right to participate in any
                class or mass action, (iii) waving the right to a trial by either a judge or jury, and (iv) giving up
                any right to appeal the arbitrator’s decision, except for a limited range of appealable issues expressly
                provided in the FAA. This section is considered by the parties to be an integral and non-voidable
                requirement and part of this Agreement, and the parties agree that prior to signing this Agreement
                including agreeing to this section they: (1) have had the opportunity to review such carefully,
                including with their own legal counsel if needed, (2) the parties understand the limitations on legal
                rights contained in this section including, but not limited to, the waiver of jury trial, the waiver of
                court proceeding and class actions, and (3) the parties consent and agree that this section is fair,
                reasonable, represents the intentions of each party at the time of entry into this Agreement, and should
                not be set aside later for any reason by any court. This section and the arbitration requirement shall
                survive termination.
              </b>
            </li>
          </ol>
          <p style={{ fontWeight: "bold" }}>
            Notwithstanding any provision in the Agreement to the contrary, you and we agree that if we make a change to
            this agreement to arbitrate in the future, that change shall not apply to a claim that was filed in a legal
            proceeding between you and us prior to the effective date of the change.
          </p>
          <h3>CONTACT US</h3>
          <p>
            Should you have questions regarding these terms or need additional information regarding our services, you
            may reach us via mail, email, or phone by contacting us at:
          </p>
          <p>
            Debt.com LLC <br />
            8220 W. State Road 84, <br />
            Fort Lauderdale, FL 33324 <br />
            Email: <a href="mailto:admin@Debt.com">admin@Debt.com</a> <br />
            Phone: <span style={{ fontWeight: "bold" }}>1.800.810.0989.</span>
          </p>
          <h2 ref={privatePolicyTargetRef}>DEBT.COM PRIVACY POLICY</h2>
          <p>
            <strong style={{ fontWeight: "bold" }}>Last updated: January 30, 2025.</strong>
          </p>
          <p>
            Debt.com, LLC (“Debt.com”) is the owner of this web site. This Online Privacy Policy (hereinafter sometimes
            referred to as the “Notice”) applies to this <strong style={{ fontWeight: "bold" }}>Debt.com</strong> online
            interface (i.e., website or mobile application (“App(s)”)) (the “Site”). The term{" "}
            <strong style={{ fontWeight: "bold" }}>Debt.com</strong> or “we” or “us” or “our” in this Notice refers to
            Debt.com, and any of Debt.com’s affiliates or subsidiaries that may adopt this Notice. This Notice describes
            how this Site, or any Debt.com landing pages linked to this Site (Debt.com’s landing pages and this Site
            shall collectively refer to as “Sites”), may collect, use, store, and share information from or about you,
            and explains how information collected from or about you may be, used for advertising purposes or shared
            with third parties. Debt.com may provide other online interfaces not covered by this Notice, which shall be
            subject to their own Privacy notices. If you visit or access your services from one of these sites, please
            review the online privacy practices of that site to understand how your online information may be collected,
            used and shared.
          </p>
          <p>
            <strong style={{ fontWeight: "bold" }}>Agreement to this Policy:</strong>
            &nbsp;By using, interacting with, entering information, or viewing information on this Site, you expressly
            acknowledge and consent to this Online Privacy Policy in it’s entirety, including providing you your consent
            to our use and disclosure of information collected from, or about, you in the manner described herein. You
            agree that Debt.com will rely on your use and interaction with this Site as your express consent to the
            terms of this Online Privacy Notice Notice.
          </p>
          <h3>Outline</h3>
          <ol type="I" style={{ fontWeight: "bold" }}>
            <li>INFORMATION WE MAY COLLECT</li>
            <li>HOW WE MAY COLLECT YOUR INFORMATION</li>
            <li>HOW DO WE USE YOUR PERSONAL INFORMATION</li>
            <li>HOW YOUR INFORMATION MAY BE SHARED</li>
            <li>YOUR DATA RIGHTS</li>
            <li>DATA SECURITY</li>
            <li>MISCELLANEOUS</li>
          </ol>
          <ol type="I">
            <li>
              <h3>INFORMATION WE MAY COLLECT</h3>
              <p>
                Debt.com may collect certain information, including Personally Identifiable Information (also known as
                “PII”), in accordance with the law and to provide you with exemplary services. In particular, Debt.com
                may collect the following categories of PII:
              </p>
              <ol type="a">
                <li>
                  <strong style={{ fontWeight: "bold" }}>General Identifying Information:</strong> General Identifying
                  Information may include, but not limited to, you full name, postal address, e-mail address, telephone
                  number, social media account ID, profile photo, other data made publicly available, or data made
                  available by linking your social media or other similar identifiers.
                </li>
                <li>
                  <strong style={{ fontWeight: "bold" }}>Demographic Information and Important Dates:</strong>{" "}
                  Demographic Information and Important Dates may include, but not limited to, you gender, language
                  preferences, nationality, and/or date of birth.
                </li>
                <li>
                  <strong style={{ fontWeight: "bold" }}>Financial Information:</strong> Financial Information may
                  include, but not limited to, your credit, debit, or other payment data, bank account information,
                  personal financial statements, or other financial personal information about you or your household.
                </li>
                <li>
                  <strong style={{ fontWeight: "bold" }}>Credit Information:</strong> Credit Information may include,
                  but not limited to, your credit score, credit accounts status, and other creditworthiness information
                  about you or your household.
                </li>
                <li>
                  <strong style={{ fontWeight: "bold" }}>Internet or Other Electronic Information:</strong> Internet or
                  other Electronic Information may include, but not limited to, your web history, search history,
                  interactions with a website, email, applications, or advertisements, IP address, IP Host location,
                  enabled cookies, or any other information that may be automatically obtained from you while making use
                  of the Sites.
                </li>
                <li>
                  <strong style={{ fontWeight: "bold" }}>Geolocation information:</strong> Geolocation information may
                  include, but not limited to, your real-time location or GPS coordinates data provided by your computer
                  or mobile device or web browser, data derived from your IP address, general location based on nearby
                  Wi-Fi networks or cell towers, location history records, geotags in content uploaded to the Site, or
                  other user location information entered by you.
                </li>
                <li>
                  <strong style={{ fontWeight: "bold" }}>
                    Inferences drawn from the above information about your predicted characteristics
                  </strong>
                </li>
                <li>
                  <strong style={{ fontWeight: "bold" }}>
                    Categories that may be considered Sensitive Personal information:
                  </strong>{" "}
                  Such information may include Government Identifiers, Financial Information, Credit Information, or
                  other sensitive information as defined by applicable law.
                </li>
              </ol>
              <h3 style={{ textDecoration: "underline" }}>Notice Regarding Sensitive Personal Information</h3>
              <p>
                "<span style={{ textDecoration: "underline" }}>Sensitive Personal Information</span>" means any
                information that could be used to identify you individually and that relates to your finances or
                financial accounts with us. This includes, but is not limited to:
              </p>
              <ul>
                <li>Social Security Number</li>
                <li>Account numbers</li>
                <li>Transaction History</li>
                <li>Credit score and credit reports</li>
                <li>Investment information</li>
                <li>Personal financial statements</li>
                <li>Any other information which is used or associated with a financial account belonging to you</li>
              </ul>
              <p style={{ textDecoration: "underline" }}>Purposes of Collection:</p>
              <p>We collect Sensitive Information for the following purposes:</p>
              <ul>
                <li>
                  Providing and maintaining financial services and products (e.g., processing transactions, managing
                  accounts, recommending financial relief services)
                </li>
                <li>Verifying your identity and protecting against fraud</li>
                <li>Complying with legal and regulatory requirements</li>
                <li>Improving our services and offerings</li>
                <li>
                  Marketing products and services by us or third-parties service providers (subject to your opt-out
                  choices)
                </li>
              </ul>

              <p>
                <span style={{ textDecoration: "underline" }}>
                  Third Parties with Whom Personal Identifiable Information or Sensitive Personal Information May Be
                  Shared.
                </span>{" "}
                We may share your PII and/or Sensitive Information with the following types of third parties:
              </p>
              <ul>
                <li>Affiliates: Companies within our corporate family for the purposes outlined in this policy.</li>
                <li>
                  Our Service Providers: Companies that perform services on our behalf, such as data processing,
                  marketing, and customer support. We contractually require these providers to protect the
                  confidentiality and security of your Sensitive Information.
                </li>
                <li>
                  Third Party Financial Service Providers: Companies that preform financial services for consumers to
                  whom consumers who use our Site may be referred to.
                </li>
                <li>
                  Other Financial Institutions: Other financial institutions with whom we partner to offer specific
                  products or services.
                </li>
                <li>Government Agencies: As required by law or legal process.</li>
              </ul>
              <p style={{ textDecoration: "underline" }}>Consent and Opt-Out Options:</p>
              <p>
                We may share your PII and/or Sensitive Personal Information for marketing purposes with our affiliates
                and third-party partners. You have the right to opt out of this sharing at any time. To opt out, please
                visit our{" "}
                <a href="https://www.debt.com/data-request-page/" target="_blank" rel="noopener noreferrer">
                  Data Rights Request page
                </a>
                .
              </p>
              <p>
                We may share your PII and/or Sensitive Personal Information with third party service providers and
                financial institutions as necessary to provide you with financial services and products. You may not opt
                out of this sharing, as it is essential for us to fulfill our contractual obligations to you.
              </p>
              <p style={{ textDecoration: "underline" }}>Other Information We Collect Online</p>
              <p>
                Other Information is any information, other than Personal information, PII, or Sensitive Personal
                Information, which does not reveal your specific identity, such as browser information, information
                collected through cookies, pixel tags, web beacons and other technologies, demographic information, and
                aggregated and de-identified data. Other Information may also include geographic information, referral
                information and URL information. Although such information may not by itself reveal your specific
                identity, it could be used by third parties that have independent relationships with you to identify
                you, your web activities and your interests.
              </p>
              <p>
                We may also install cookies, web beacons or other technologies, including technologies that identify the
                dialing, routing, addressing and signaling information generated by Sites users, that provide access to
                third parties to your information, including but not limited to your IP address and browsing activity on
                pages of our Sites where these devices are placed. You understand that we do not control any information
                gathered by such devices or its use by such third parties. These third parties are required to handle
                your personal information in accordance with their own privacy policies and applicable data protection
                laws and regulations.
              </p>
              <p>
                You agree that any such PII, Sensitive Personal Information, or Other Information collected through
                cookies, pixel tags, web beacons and other technologies, including any technologies that identify the
                dialing, routing, addressing and signaling information generated by website users may be used and shared
                by Debt.com as provided in this Notice.
              </p>
              <p>In general, we may use or use these different types of cookies:</p>
              <ul>
                <li>
                  <strong style={{ fontWeight: "bold" }}>Strictly Necessary Cookies:</strong> These cookies are
                  necessary to enable the basic features of our Sites and Apps, such as providing a secure log-in or
                  booking a stay.
                </li>
                <li>
                  <strong style={{ fontWeight: "bold" }}>Functional Cookies:</strong> These cookies allow our Sites to
                  remember your site preferences and choices you make on the site. We also use functional cookies to
                  facilitate navigation, to display content more effectively, and/or to personalize your experience.
                </li>
                <li>
                  <strong style={{ fontWeight: "bold" }}>Advertising Cookies:</strong> Advertising cookies allow us to
                  select which advertisements or offers are most likely to appeal to you. We also use them to track
                  responses to online advertisements and marketing, and we may use them to better understand your
                  interests so we can present you with relevant messages and offers. These cookies may also allow you to
                  share certain pages with social networks.
                </li>
                <li>
                  <strong style={{ fontWeight: "bold" }}>Analytics Cookies:</strong> Analytics cookies help us improve
                  our Sites and Apps by collecting and reporting information on how you use it.
                </li>
                <li>
                  <strong style={{ fontWeight: "bold" }}>
                    Instant Debt Advisor’s Essentials and Non-Essentials Cookies
                  </strong>
                  <ul>
                    <li>
                      <strong style={{ fontWeight: "bold" }}>Essential Cookies:</strong> Cookies necessary for the core
                      functionality of the application and cannot be disabled without impacting the user experience. In
                      our application essential cookies include:
                      <ul>
                        <li>
                          <strong style={{ fontWeight: "bold" }}>Authentication Cookies:</strong> Used to manage user
                          sessions and ensure secure access to the application.
                        </li>
                        <li>
                          <strong style={{ fontWeight: "bold" }}>Application Performance Cookies: </strong>
                          Necessary for maintaining service reliability, such as load balancing and ensuring smooth
                          operation of the app.
                        </li>
                        <li>
                          <strong style={{ fontWeight: "bold" }}>Compliance Cookies:</strong> Used for functionality
                          like screen recording to capture user actions for TCPA compliance and acceptance certificates.
                        </li>
                        <li>
                          <strong style={{ fontWeight: "bold" }}>Load Balancing Cookies:</strong> Ensure efficient
                          distribution of network traffic across servers.
                        </li>
                        <li>
                          <strong style={{ fontWeight: "bold" }}>Security Cookies:</strong> Protect the website and user
                          from malicious activities.
                        </li>
                      </ul>
                    </li>
                    <li>
                      <strong style={{ fontWeight: "bold" }}>Non-Essential Cookies:</strong> Cookies that enhance the
                      user experience but are not required for the application to function. Users can accept or reject
                      these cookies without impacting the application's core functionality. Examples include:
                      <ul>
                        <li>
                          <strong style={{ fontWeight: "bold" }}>Analytics Cookies:</strong> Google Analytics for
                          tracking user behavior, understanding usage patterns, and improving the application.
                        </li>
                        <li>
                          <strong style={{ fontWeight: "bold" }}>Marketing or Personalization Cookies:</strong> (If
                          applicable) Used for targeted advertising or customizing user experience based on preferences.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
                You can learn more about cookies at:{" "}
                <a
                  href="http://www.allaboutcookies.org/manage-cookies/index.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://www.allaboutcookies.org/manage-cookies/index.html↗
                </a>
                .
              </p>
              <p>
                <strong style={{ fontWeight: "bold", textDecoration: "underline" }}>Cookie Choices.</strong> There are
                several ways to manage cookies:
              </p>
              <ol type="1">
                <li>
                  <strong style={{ fontWeight: "bold" }}>Browser settings:</strong> You can opt out of cookies on your
                  browser. Every browser is different, please check the instructions provided by your browser. Note
                  should you choose to opt out of cookies on your browser or disable cookies, such action may impact
                  your use of our Site
                </li>
                <li>
                  <strong style={{ fontWeight: "bold" }}>Cookies Preferences:</strong> Through our “
                  <span style={{ color: "blue", textDecoration: "underline" }}>Cookie Preferences</span>” tool.
                </li>
                <li>
                  Google and Adobe Analytics: We collect data through Google Analytics and Adobe Analytics, which use
                  cookies and technologies to collect and analyze data about the use of Online Services. These services
                  also collect data regarding the use of other websites, apps, and online resources. You can learn about
                  Google’s practices by going to{" "}
                  <a href="http://www.google.com/policies/privacy/partners/" target="_blank" rel="noopener noreferrer">
                    www.google.com/policies/privacy/partners/↗
                  </a>{" "}
                  and opt out by downloading the Google Analytics opt out browser add-on, available at{" "}
                  <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">
                    https://tools.google.com/dlpage/gaoptout↗
                  </a>
                  .
                </li>
              </ol>

              <h3 style={{ fontWeight: "bold", textDecoration: "underline" }}>Advertising Choices</h3>
              <p>
                We may use third-party advertising companies to serve advertisements on our Site. To serve such
                advertisements, these companies place or recognize a unique cookie on your browser (including through
                use of pixel tags). They also use these technologies, along with information they collect about your
                online use, to recognize you across the devices you use, such as a mobile phone and a laptop. If you
                would like more information about this practice, and to learn about your choices in connection with it,
                please visit:
              </p>
              <ol type="1">
                <li>
                  <a
                    href="http://www.networkadvertising.org/managing/opt_out.asp"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    http://www.networkadvertising.org/managing/opt_out.asp↗
                  </a>
                </li>
                <li>
                  <a href="http://www.aboutads.info/" target="_blank" rel="noopener noreferrer">
                    http://www.aboutads.info/↗
                  </a>
                </li>
                <li>
                  <a href="https://business.safety.google/privacy/" target="_blank" rel="noopener noreferrer">
                    https://business.safety.google/privacy/↗
                  </a>
                </li>
              </ol>
              <p>
                For any advertising in our Apps, you may download the AppChoices app at{" "}
                <a href="https://youradchoices.com/appchoices" target="_blank" rel="noopener noreferrer">
                  https://youradchoices.com/appchoices↗
                </a>{" "}
                to opt out.
              </p>
            </li>

            <li>
              <h3>HOW WE MAY COLLECT YOUR INFORMATION</h3>
              <p>We may collect PII and/or Sensitive Personal Information from you in the following ways:</p>
              <ol type="a">
                <li>Directly from you when you visit our Sites or contact us regarding our products or services.</li>
                <li>
                  From third parties such as credit reporting data (accounts, pay history, balances)or lead generation
                  data.
                </li>
                <li>
                  Indirectly form you such as through your interactions with our Sites or through the use of cookies or
                  other related tracking software.
                </li>
                <li>From marketing or analytics partners, such as Google, Meta, or other third-party partners.</li>
                <li>From government agencies or public records.</li>
                <li>
                  Various third parties, such as public databases, joint marketing partners, financial service
                  providers.
                </li>
              </ol>
            </li>

            <li>
              <h3>HOW DO WE USE YOUR PERSONAL INFORMATION</h3>
              <p>We may use your PII and/or Sensitive Personal Information for the following purposes:</p>

              <ol type="a">
                <li>to respond to your inquiries and fulfill your requests.</li>
                <li>
                  to inform you about important information regarding the Site, products or services for which you may
                  apply or in which you are enrolled, changes to terms, conditions, and policies and/or other
                  administrative information.
                </li>
                <li>
                  to deliver marketing communications that we believe may be of interest to you, including, ads or
                  offers tailored to you, including ads on other websites, or from affiliated or unaffiliated third
                  parties.
                </li>
                <li>to personalize your experience on the Site.</li>
                <li>
                  to refer you to various financial service providers that may offer you various financial services
                  independently of our Site.
                </li>
                <li>
                  to allow you to apply for various products or services and evaluate your eligibility for such products
                  or services.
                </li>
                <li>
                  to verify your identity and/or location (or the identity or location of your representative or agent)
                  in order to allow access to services you may enroll in, conduct online transactions and to maintain
                  measures aimed at preventing fraud and protecting the security of your Personal information.
                </li>
                <li>
                  to allow you to participate in surveys and other forms of market research, sweepstakes, contests and
                  similar promotions, and to administer these activities. These activities may have additional rules of
                  participation, which may also contain additional policies about how the PII or Sensitive Personal
                  Information provided is used and/or shared.
                </li>
                <li>
                  to allow us to verify your identity or other information collected from or through our social media
                  pages or campaigns and other interaction with online forms generated on our behalf. We may combine and
                  use PII and/or Sensitive Personal Information you provide in this Site with the information collected
                  from our social media pages or from your interaction with online forms generated on our behalf.
                </li>
                <li>
                  to allow you to enter and participate in our live chat, forums, and/or other types of bulletin boards
                  that we may have on the site from time to time.
                </li>
                <li>
                  for business purposes, including data and marketing analysis, audits, developing and improving
                  products and services, enhancing the Site, identifying usage trends and determining the effectiveness
                  of promotional campaigns.
                </li>
                <li>
                  for risk control, fraud detection and prevention, to comply with laws and regulations, and to comply
                  with other legal process and law enforcement requirements.
                </li>
                <li>
                  to allow you to utilize features within our Sites by granting us access to information from your
                  device, such as contact lists or geolocation, when you request certain services.
                </li>
              </ol>
            </li>

            <li>
              <h3>IV. HOW YOUR INFORMATION MAY BE SHARED</h3>
              <p>
                We may share your PII and/or Sensitive Personal Information for commercial, marketing, and business
                purposes, all in compliance with this Privacy Policy.
              </p>

              <p>
                <span style={{ textDecoration: "underline" }}>Information Disclosed for Business Purposes.</span> In the
                previous 12 months, we have disclosed the information that may includes the following categories of
                personal information for business purposes:
              </p>
              <ol type="a">
                <li>General Identifying Information</li>
                <li>Demographic Information and Important Dates</li>
                <li>Financial Information</li>
                <li>Credit Information</li>
                <li>Internet or Other Electronic Information</li>
                <li>Geolocation information</li>
                <li>Inferences drawn from the above information about your predicted characteristics</li>
                <li>Information that may be considered Sensitive Personal information</li>
              </ol>

              <p style={{ textDecoration: "underline" }}>
                Categories of Third Parties Who Receive Information for Business Purposes:
              </p>
              <ol type="a">
                <li>Affiliates</li>
                <li>Our Service Providers</li>
                <li>Third Party Financial Services Companies and Financial Institutions</li>
                <li>Marketing and Research Companies</li>
                <li>Regulatory Authorities or Law Enforcement</li>
                <li>Technology/Computer Software Companies</li>
                <li>Telecommunications Companies</li>
              </ol>

              <p>
                <span style={{ textDecoration: "underline" }}>
                  Information Sold or Shared for Non-Business Purposes.
                </span>{" "}
                In the previous 12 months, the following categories of Personal Information, PII, or Sensitive Personal
                Information were sold or disclosed to third parties for non-essential business purposes.
              </p>
              <ol type="a">
                <li>General Identifying Information</li>
                <li>Demographic Information and Important Dates</li>
                <li>Financial Information</li>
                <li>Credit Information</li>
                <li>Internet or Other Electronic Information</li>
                <li>Geolocation information</li>
                <li>Inferences drawn from the above information about your predicted characteristics</li>
                <li>Information that may be considered Sensitive Personal Information</li>
              </ol>

              <p style={{ textDecoration: "underline" }}>
                Categories of Third Parties to Whom Personal Information, PII, or Sensitive Personal Information Has
                Been Sold:
              </p>
              <ol type="a">
                <li>Affiliates</li>
                <li>Non-affiliated financial services organizations</li>
                <li>Targeted marketing providers</li>
                <li>
                  Financial services organizations, including debt settlement, credit repair, credit counseling,
                  bankruptcy, and debt management companies; other debt relief service providers.
                </li>
                <li>Marketing and Research Companies</li>
              </ol>

              <p style={{ textDecoration: "underline" }}>Sale of Personal Information and Targeted Marketing</p>
              <p>
                Debt.com may engage in the “Sharing” or “Sale” of your personal information. Debt.com may also engage in
                “targeted advertising” in its use of your personal information.{" "}
                <strong style={{ fontWeight: "bold" }}>
                  We do not knowingly collect, “sell” or “share” the personal information of individuals under the age
                  of 18. If you are below the age of 18, you are prohibited from making use of our Sites and providing
                  personal information on our Sites. See also COPPA Disclosure in Section VIII below.
                </strong>
              </p>
            </li>

            <li>
              <h3>YOUR DATA RIGHTS</h3>
              <p>
                Depending on when you reside, you may have certain rights regarding the personal information we collect
                and share. Residents of applicable states, including California, Virginia, Colorado, Connecticut, Iowa,
                Indiana, Tennessee, Texas, Montana, Minnesota, Oregon, Delaware, New Hampshire, New Jersey, Kentucky,
                Nebraska, and Rhode Island, may be provided the right to make the following request, depending on
                applicable law.
              </p>
              <ol type="a">
                <li>
                  <span style={{ textDecoration: "underline" }}>Request to Know.</span> You may request to know whether
                  we process your Personal information and to access such Personal information. You may request to
                  receive the specific pieces or a copy of your Personal information, including, where applicable, a
                  copy of the Personal information you provided to us in a portable format. Depending on where you
                  reside, you may request that we disclose to you the following information:
                  <ol type="i">
                    <li>
                      The categories of Personal information we collected about you and the categories of sources from
                      which we collected such Personal information;
                    </li>
                    <li>
                      The business or commercial purpose for collecting, selling, or sharing (if applicable) Personal
                      information about you;
                    </li>
                    <li>
                      The categories of Personal information about you that we sold or “shared” and the categories of
                      third parties to whom we sold or “shared” such Personal information (if applicable); and
                    </li>
                    <li>
                      The categories of Personal information about you that we otherwise disclosed and the categories of
                      third parties with whom we disclosed such Personal information (if applicable).
                    </li>
                  </ol>
                </li>
                <li>Request to Delete. You may request that we delete your Personal information.</li>
                <li>
                  Request to Appeal. If we refuse to act on your request, you may appeal our refusal within a reasonable
                  period after you have received notice of the refusal.
                </li>
                <li>Request to Correct. You may request that we correct inaccuracies in your Personal information.</li>
                <li>
                  Request to Opt-Out of Sale, Targeted Advertising, and/or Sharing. You may request to opt-out of the
                  “sale” of your Personal information and/or targeted advertising, including the “sharing” of your
                  Personal information for cross-context behavioral advertising purposes.
                </li>
              </ol>
              <h3>Submitting a Data Request</h3>
              <p>
                To submit a data rights request in accordance with the above, please visit our{" "}
                <span style={{ color: "blue", textDecoration: "underline" }}>Data Rights Request page</span>.
              </p>
              <h3>Automated Decision-Making Tools</h3>
              <ol type="a">
                <li>
                  In the event that automated decision-making processes are used on our Site in connection with an
                  application or request by you, depending on where you reside, you may request that we disclose to you
                  the following: That the decision regarding your request was made using an automated decision making
                  systems or processes;
                </li>
                <li>
                  If you are unsatisfied with the decision, you have the right to request an explanation of how the
                  decision was made using our automated decision-making process and how you may proceed to have the
                  decision changed or fixed.
                </li>
                <li>
                  Certain features on our Site(s) are subject to automated decision making. Any information you provide
                  in using those features will translate to automated decision regarding your inquiry or request. You
                  understand and agree that these automated decision making features are developed by third party
                  technology providers which will have access to your personal information you may provide on our Site
                  when using the automated decision making features. Your use of these features expressly authorize the
                  share of the information with these third parties. Furthermore, you understand that the mechanisms or
                  processes of the automated decision making features are not controlled by Debt.com, nor does Debt.com
                  has the ability to effect the decision results.
                </li>
              </ol>
              <h3>Sensitive Personal Information</h3>
              <p>
                We may collect and disclose certain information that may be considered ‘sensitive information’ under the
                applicable data privacy laws. We may disclose this information to affiliates, and non-affiliated third
                parties for various purposes, including marking or to provide you with services that you have. Depending
                on where you reside you may have the right to limit the disclosure of this information to non-related
                third parties for marketing purposes. To submit a request to limit the disclosure of your sensitive
                information, please visit our{" "}
                <a href="https://www.debt.com/data-request-page/" target="_blank" rel="noopener noreferrer">
                  Data Rights Request page
                </a>
                .
              </p>

              <p>
                <strong style={{ fontWeight: "bold" }}>Debt.com Managed Direct Marketing:</strong> If you prefer that we
                not deliver you marketing offers in email, postal mail or through telemarketing you may manage your
                direct marketing choices by emailing us your requests at{" "}
                <a href="mailto:admin@Debt.com">admin@Debt.com</a>.
              </p>

              <h3>Opt-Out Policy for Newsletter and Email Subscriptions</h3>
              <p>
                You may opt-out or unsubscribe from a newsletter or other email list at any time by following the
                instructions at the end of the newsletters or emails which you receive. Please allow five to ten (5-10)
                business days for changes to take effect. Please be advised that all opt-out requests will be honored by
                the 10th business day. You understand and agree that during that period and while your request is
                pending you may still receive newsletters and emails. Client service-related communications are an
                integral part of the services you receive from us, and you may continue to receive such service-related
                emails unless you cancel your account, even if you opt out of the newsletters or email list. If you have
                provided more than one email address to us, you may continue to be contacted unless you request to
                unsubscribe each email address you have provided. You may also opt-out of receiving our newsletter or
                marketing emails by contacting us at <a href="mailto:unsubscribe@Debt.com">unsubscribe@Debt.com</a> or
                by replying to an existing email with your request to be removed from the mailing list.
              </p>
            </li>

            <li>
              <h3>DATA SECURITY</h3>
              <p>
                At Debt.com, the security of your Personal Information is extremely important to us. We take
                commercially reasonable precautions to protect your personal information and only provide employees
                access to it on a need-to-know basis. In an effort to prevent the unauthorized access, disclosure,
                dissemination, misuse, loss, alteration or destruction of your personal information, we have employed
                commercially reasonable physical, electronic, and managerial measures to protect your data and we
                periodically review these policies and procedures to prevent these types of acts. However, despite our
                best efforts, no security policy, procedures or practices are adequate to protect against all types of
                threats or data losses and no type of data transmission method is impenetrable to interception.
              </p>
              <p>
                <span style={{ textDecoration: "underline" }}>Links to other sites:</span> We may provide links to
                third-party sites. If you follow links to sites not affiliated or controlled by Debt.com, you should
                review their privacy and security policies and other terms and conditions, as they may be different from
                those of our Site. Debt.com does not guarantee and is not responsible for any interaction between you
                and the third-party sites, including but not limited to the privacy or security of these sites,
                including the accuracy, completeness, or reliability of their information. You acknowledge that the
                availability of third-party site links is merely for convenience purposes, and that Debt.com does not
                endorse, support, acknowledge, agree with, or otherwise promote the contents of the third-party sites
                linked to our Site.
              </p>
              <p>
                <span style={{ textDecoration: "underline" }}>Security:</span> To protect Personal information from
                unauthorized access and use, we use security measures that comply with applicable federal and state
                laws. These measures may include device safeguards and secured files and buildings as well as oversight
                of our third-party service providers to ensure information remains confidential and secure. While we
                follow generally accepted industry standards to protect the Sensitive Personal Information and PII
                submitted to us (both during your submission and after we receive it), please be advised that no method
                of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, while we
                strive to use commercially acceptable means to protect your Personal information and PII, we cannot
                guarantee its absolute security. If you have any questions about security on our website, you can e-mail
                us at <a href="mailto:admin@Debt.com">admin@Debt.com</a>.
              </p>
              <p>
                <span style={{ textDecoration: "underline" }}>Data Retention:</span> We retain Personal information for
                as long as needed or permitted in light of the purpose(s) for which it was collected. The criteria used
                to determine our retention periods include:
              </p>
              <ul>
                <li>
                  The length of time we have an ongoing relationship with you and as necessary to provide requested
                  services to you;
                </li>
                <li>
                  Whether there is a legal obligation to which we are subject (for example, certain laws require us to
                  keep records of your transactions for a certain period of time before we can delete them); and
                </li>
                <li>
                  Whether retention is advisable considering our legal position (such as, for statutes of limitations,
                  litigation, or regulatory investigations).
                </li>
              </ul>
            </li>

            <li>
              <h3>STATE PRIVACY DISCLOSURES:</h3>
              <p>
                This section provides state specific disclosures which apply solely to consumers who reside in the
                states listed. If you are a resident of one of these states, you have certain rights with respect to
                personal information collected and the processing of your personal information which are subject to the
                applicable state privacy laws.
              </p>
              <p>
                <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Notice to California Residents:</span>{" "}
                As a California resident, you have the following rights regarding your personal information:
              </p>
              <ol type="A">
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Access.</span> You have a right,
                  twice in a 12-month period, to request that our Company disclose to you the personal information that
                  we collected about you, and any additional information about our collection, use, disclosure, or sale
                  of such personal information, which is also contained in this privacy policy.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Correction.</span> You have the
                  right to request that we correct inaccurate personal information under certain circumstances, subject
                  to a number of exceptions provided under California law. For information how to request the correction
                  of personal information, please see section V of this policy, and specifically subsection titled{" "}
                  <strong style={{ fontWeight: "bold" }}>Submitting a Data Request</strong>.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Deletion.</span> You have the right
                  to request that we delete your personal information under certain circumstances, subject to a number
                  of exceptions, subject to a number of exceptions provided under California law. For information how to
                  request the deletion of personal information, please see section V of this policy, and specifically
                  subsection titled <strong style={{ fontWeight: "bold" }}>Submitting a Data Request</strong>.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
                    Opt-Out of the Selling or Sharing of your Data or Joint Marketing.
                  </span>{" "}
                  You have the right to opt out of the selling or sharing of your data. You also have the right to
                  opt-out of joint marketing effort that Company has with other financial service providers. The CCPA
                  requires to describe the categories of personal information our company sell or share to third parties
                  and how to opt- out of future sales or share of said information. The CCPA defines a “Sale” broadly to
                  include the mere making of your personal information available to third parties in some cases. Under
                  the CCPA the term “Share” is defined as providing personal information to a third party to target
                  advertising to a consumer based on information about their activity on multiple websites across the
                  internet. For information how to request an opt-out of the selling or sharing of your personal
                  information, please see section V of this policy, and specifically subsection titled{" "}
                  <strong style={{ fontWeight: "bold" }}>Submitting a Data Request</strong>.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Disclosure Request.</span> You have
                  the right to request that we provide you with (i) a list of certain categories of personal information
                  we have disclosed to third parties for their direct marketing purposes during the immediately
                  preceding calendar year; and (ii) the identity of those third parties. For information how to obtain a
                  disclosure request of your personal information, please see section V of this policy, and specifically
                  subsection titled <strong style={{ fontWeight: "bold" }}>Submitting a Data Request</strong>.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Appeal.</span> You have the right to
                  appeal Company’s decision to refuse to act on a CCPA data privacy request within a reasonable period
                  after you receive our decision. To appeal our decision, you should send your objection to our denial
                  via email to <a href="mailto:admin@Debt.com">admin@Debt.com</a> and to allow Company to review your
                  data subject request. Within 45 days, Company will provide you with a written explanation of the
                  justification for declining to act on your request.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Authentication/Verification.</span>{" "}
                  To help protect your privacy and maintain security, we will take steps to verify your identity before
                  granting you access to your personal information or complying with your request (except for a request
                  to opt-out of sales or sharing). Company may require you to provide any of the following information:{" "}
                  <strong style={{ fontWeight: "bold" }}>
                    your name, date of birth, your phone number, your Social Security number, the email and physical
                    addresses
                  </strong>
                  . If you request that we provide you with specific pieces of personal information, we may require you
                  to sign a declaration under penalty of perjury that you are the consumer whose personal information is
                  the subject of the request, or authorized personnel of the consumer making the request. You understand
                  that Company may decline to process your request where: (i) Company is unable to authenticate that you
                  are the person to whom the data relates; (ii) the request is unreasonable or excessive; (iii) or where
                  otherwise permitted by applicable law.
                </li>

                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Retention.</span> We retain personal
                  data for as long as necessary to provide the Services and fulfill the transactions you have requested,
                  comply with our legal obligations, resolve disputes, enforce our agreements, and other legitimate and
                  lawful business purposes. Because these needs can vary for different data types in the context of
                  different Services, actual retention periods can vary significantly based on criteria such as user
                  expectations or consent, the sensitivity of the data, the availability of automated controls that
                  enable users to delete data, and our legal or contractual obligations.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
                    Notice of Financial Incentives.
                  </span>{" "}
                  Company may offer rewards or prizes for participation in certain activities that may be considered a
                  “financial incentive” under California law. These activities may involve the collection of personal
                  information. The categories of personal information we collect is limited to what information you
                  provide us, but may include: identifiers, protected class/demographic information, commercial
                  information, online activities, geolocation information (general and precise), sensory information,
                  employment information, and inferences. Activities we engage in that may be considered a financial
                  incentive include surveys where we may provide compensation such as a gift card or bonus in exchange
                  for your time and responses, or a prize through your participation in promotions and sweepstakes.
                  Participation in these programs may be subject to separate terms and conditions. Your participation in
                  these programs is voluntary and you can terminate at any time as explained in any applicable terms. If
                  we offer gift cards in exchange for your participation in a survey or when we engage in promotions or
                  sweepstakes, the amount provided is reasonably related to the value of the data you provide, which
                  takes into account a number of factors, including, the anticipated benefit we receive such as product
                  improvement, better understanding how you use our products, to enhance our understanding of consumer
                  and market trends, increased consumer engagement, and the anticipated expenses we incur in relation to
                  the collection, storage, and use of the information we receive. The value may vary across surveys,
                  promotions, and sweepstakes.
                  <li>
                    <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Declining Requests.</span> You
                    understand that we may decline requests where granting the request
                  </li>
                  would be prohibited by law, could adversely affect the privacy or rights of another person, would
                  reveal a trade secret or other confidential information, would interfere with a legal or business
                  obligation that requires retention or use of the data, or because the data at issue is not covered
                  under the law you are asserting. Also, where permitted by law if you send us a request to exercise
                  your rights or the choices in this section, we may charge a fee or decline requests in certain cases.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Non-Discrimination.</span> You have
                  the right to not be discriminated against for exercising any of your privacy rights.
                </li>
              </ol>

              <p>
                <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Notice to Nevada Residents.</span> We
                are providing you this Notice pursuant to state law. You may be placed on our internal Do Not Call List
                by following the directions set forth above. Nevada law requires that we also provide you with the
                following contact information: Bureau of Consumer Protection, Office of the Nevada Attorney General, 555
                E. Washington St., Suite 3900, Las Vegas, NV 89101; Phone number: 702- 486-3132; e-mail:{" "}
                <a href="mailto:BCPINFO@ag.state.nv.us">BCPINFO@ag.state.nv.us</a>.
              </p>
              <p>
                <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Notice to Colorado Residents.</span>{" "}
                If you are a Colorado resident, you have the following rights regarding your personal information:
              </p>
              <ol type="A">
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Access.</span> You have a right,
                  twice in a 12-month period, to request that our Company disclose to you the personal information that
                  we collected about you, and any additional information about our collection, use, disclosure, or sale
                  of such personal information, which is also contained in this privacy policy.
                </li>

                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Correction.</span> You have the
                  right to request that we correct inaccurate personal information under certain circumstances, subject
                  to a number of exceptions provided under Colorado law. For information how to request the correction
                  of personal information, please see section V of this policy, and specifically subsection titled{" "}
                  <strong style={{ fontWeight: "bold" }}>Submitting a Data Request</strong>.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Deletion.</span> You have the right
                  to request that we delete your personal information under certain circumstances, subject to a number
                  of exceptions, subject to a number of exceptions provided under Colorado law. For information how to
                  request the deletion of personal information, please see section V of this policy, and specifically
                  subsection titled <strong style={{ fontWeight: "bold" }}>Submitting a Data Request</strong>.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
                    Opt-Out of the Selling or Sharing of your Data or Joint Marketing.
                  </span>{" "}
                  You have the right to opt out of the selling or sharing of your data. You also have the right to
                  opt-out of joint marketing effort that Company has with other financial service providers. The
                  Colorado law requires to describe the categories of personal information our company sell or share to
                  third parties and how to opt-out of future sales or share of said information. The Colorado law
                  defines a “Sale” broadly to include the mere making of your personal information available to third
                  parties in some cases. Under the Colorado law the term “Share” is defined as providing personal
                  information to a third party to target advertising to a consumer based on information about their
                  activity on multiple websites across the internet. For information how to request an opt-out of the
                  selling or sharing of your personal information, please see section V of this policy, and specifically
                  subsection titled <strong style={{ fontWeight: "bold" }}>Submitting a Data Request</strong>.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Disclosure Request.</span> You have
                  the right to request that we provide you with (i) a list of certain categories of personal information
                  we have disclosed to third parties for their direct marketing purposes during the immediately
                  preceding calendar year; and (ii) the identity of those third parties. For information how to obtain a
                  disclosure request of your personal information, please see section V of this policy, and specifically
                  subsection titled <strong style={{ fontWeight: "bold" }}>Submitting a Data Request</strong>.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Appeal.</span> You have the right to
                  appeal Company’s decision to refuse to act on a Colorado law data privacy request within a reasonable
                  period after you receive our decision. To appeal our decision, you should send your objection to our
                  denial via email to <a href="mailto:admin@Debt.com">admin@Debt.com</a> and to allow Company to review
                  your data subject request. Within 45 days, Company will provide you with a written explanation of the
                  justification for declining to act on your request.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Authentication/Verification.</span>{" "}
                  To help protect your privacy and maintain security, we will take steps to verify your identity before
                  granting you access to your personal information or complying with your request (except for a request
                  to opt-out of sales or sharing). Company may require you to provide any of the following information:{" "}
                  <strong style={{ fontWeight: "bold" }}>
                    your name, date of birth, your phone number, your Social Security number, the email and physical
                    addresses
                  </strong>
                  . If you request that we provide you with specific pieces of personal information, we may require you
                  to sign a declaration under penalty of perjury that you are the consumer whose personal information is
                  the subject of the request, or authorized personnel of the consumer making the request. You understand
                  that Company may decline to process your request where: (i) Company is unable to authenticate that you
                  are the person to whom the data relates; (ii) the request is unreasonable or excessive; (iii) or where
                  otherwise permitted by applicable law.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Retention.</span> We retain personal
                  data for as long as necessary to provide the Services and fulfill the transactions you have requested,
                  comply with our legal obligations, resolve disputes, enforce our agreements, and other legitimate and
                  lawful business purposes. Because these needs can vary for different data types in the context of
                  different Services, actual retention periods can vary significantly based on criteria such as user
                  expectations or consent, the sensitivity of the data, the availability of automated controls that
                  enable users to delete data, and our legal or contractual obligations.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Declining Requests.</span> You
                  understand that we may decline requests where granting the request would be prohibited by law, could
                  adversely affect the privacy or rights of another person, would reveal a trade secret or other
                  confidential information, would interfere with a legal or business obligation that requires retention
                  or use of the data, or because the data at issue is not covered under the law you are asserting. Also,
                  where permitted by law if you send us a request to exercise your rights or the choices in this
                  section, we may charge a fee or decline requests in certain cases.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Non-Discrimination.</span> You have
                  the right to not be discriminated against for exercising any of your privacy rights.
                </li>
              </ol>

              <p>
                <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Notice for Vermont Residents.</span>{" "}
                As a Vermont resident, you have the right to opt out of the selling or sharing of your data. You also
                have the right to opt-out of joint marketing effort that Company has with other financial service
                providers.
              </p>
              <p>
                <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
                  Notice for Connecticut Residents.
                </span>{" "}
                As a Connecticut resident, you have the following rights regarding your personal information:
              </p>

              <ol type="A">
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Access.</span> You have a right,
                  twice in a 12-month period, to request that our Company disclose to you the personal information that
                  we collected about you, and any additional information about our collection, use, disclosure, or sale
                  of such personal information, which is also contained in this privacy policy.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Correction.</span> You have the
                  right to request that we correct inaccurate personal information under certain circumstances, subject
                  to a number of exceptions provided under CTDPA. For information how to request the correction of
                  personal information, please see section V of this policy, and specifically subsection titled{" "}
                  <strong style={{ fontWeight: "bold" }}>Submitting a Data Request</strong>.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Deletion.</span> You have the right
                  to request that we delete your personal information under certain circumstances, subject to a number
                  of exceptions, subject to a number of exceptions provided under CTDPA. For information how to request
                  the deletion of personal information, please see section V of this policy, and specifically subsection
                  titled <strong style={{ fontWeight: "bold" }}>Submitting a Data Request</strong>.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
                    Opt-Out of Targeted Advertising.
                  </span>{" "}
                  You have the right to opt-out of targeted advertising and the sale of personal data. For information
                  how to request an opt-out of targeted advertising, please see section V of this policy, and
                  specifically subsection titled{" "}
                  <strong style={{ fontWeight: "bold" }}>Submitting a Data Request</strong>.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Appeal.</span> You have the right to
                  appeal Company’s decision to refuse to act on a CTDPA data privacy request within a reasonable period
                  after you receive our decision. To appeal our decision, you should send your objection to our denial
                  via email to <a href="mailto:admin@Debt.com">admin@Debt.com</a> and to allow Company to review your
                  data subject request. Within 60 days, Company will provide you with a written explanation of the
                  justification for declining to act on your request. If you disagree with our explanation you have the
                  right to contact or file a complaint with the{" "}
                  <span style={{ textDecoration: "underline" }}>Connecticut Attorney General</span>.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Authentication/Verification.</span>{" "}
                  To help protect your privacy and maintain security, we will take steps to verify your identity before
                  granting you access to your personal information or complying with your request (except for a request
                  to opt-out of sales or sharing). Company may require you to provide any of the following information:{" "}
                  <strong style={{ fontWeight: "bold" }}>
                    your name, date of birth, your phone number, your Social Security number, the email and physical
                    addresses
                  </strong>
                  . If you request that we provide you with specific pieces of personal information, we may require you
                  to sign a declaration under penalty of perjury that you are the consumer whose personal information is
                  the subject of the request, or authorized personnel of the consumer making the request. You understand
                  that Company may decline to process your request where: (i) Company is unable to authenticate that you
                  are the person to whom the data relates; (ii) the request is unreasonable or excessive; (iii) or where
                  otherwise permitted by applicable law.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Declining Requests.</span> You
                  understand that we may decline requests where granting the request would be prohibited by law, could
                  adversely affect the privacy or rights of another person, would reveal a trade secret or other
                  confidential information, would interfere with a legal or business obligation that requires retention
                  or use of the data, or because the data at issue is not covered under the law you are asserting. Also,
                  where permitted by law if you send us a request to exercise your rights or the choices in this
                  section, we may charge a fee or decline requests in certain cases.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
                    Non-Discrimination For Exercising Your Rights.
                  </span>{" "}
                  You have the right to not be discriminated against for exercising any of your privacy rights.
                </li>
              </ol>

              <p>
                <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
                  Notice to North Dakota Residents.
                </span>{" "}
                As a North Dakota resident you have the right to opt-out of joint marketing with other financial
                institutions. For information how to request an opt-out of Joint Marketing, please see section V of this
                policy, and specifically subsection titled{" "}
                <strong style={{ fontWeight: "bold" }}>Submitting a Data Request</strong>.
              </p>
              <p>
                <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Notice to Utah Residents.</span> As a
                Utah resident, you have the following rights regarding your personal information:
              </p>
              <ul>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Access.</span> You have a right,
                  twice in a 12-month period, to request that our Company disclose to you the personal information that
                  we collected about you, and any additional information about our collection, use, disclosure, or sale
                  of such personal information, which is also contained in this privacy policy.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
                    Obtaining a portable copy of your personal data.
                  </span>{" "}
                  To obtain a copy of your personal data that you previously provided to us in a portable format, please
                  submit an “Access” request as described above. While these requests are distinct, we have not
                  identified any technically feasible and readily usable format that would allow you to transmit this
                  data to another controller. Therefore, we will provide you a copy of your personal data so that we
                  honor your request as best is technically feasible.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Deletion.</span> You have the right
                  to request that we delete your personal information under certain circumstances, subject to a number
                  of exceptions, subject to a number of exceptions provided under Utah law. For information how to
                  request the deletion of personal information, please see section V of this policy, and specifically
                  subsection titled <strong style={{ fontWeight: "bold" }}>Submitting a Data Request</strong>.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
                    Opt-Out of Targeted Advertising.
                  </span>{" "}
                  You have a right to opt-out of targeted advertising and the sale of personal data. For information how
                  to request the opt out of targeted advertising, please see section V of this policy, and specifically
                  subsection titled <strong style={{ fontWeight: "bold" }}>Submitting a Data Request</strong>.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Authentication/Verification.</span>{" "}
                  To help protect your privacy and maintain security, we will take steps to verify your identity before
                  granting you access to your personal information or complying with your request (except for a request
                  to opt-out of sales or sharing). Company may require you to provide any of the following information:{" "}
                  <strong style={{ fontWeight: "bold" }}>
                    your name, date of birth, your phone number, your Social Security number, the email and physical
                    addresses
                  </strong>
                  . If you request that we provide you with specific pieces of personal information, we may require you
                  to sign a declaration under penalty of perjury that you are the consumer whose personal information is
                  the subject of the request, or authorized personnel of the consumer making the request. You understand
                  that Company may decline to process your request where: (i) Company is unable to authenticate that you
                  are the person to whom the data relates; (ii) the request is unreasonable or excessive; (iii) or where
                  otherwise permitted by applicable law.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Declining Requests.</span> You
                  understand that we may decline requests where granting the request would be prohibited by law, could
                  adversely affect the privacy or rights of another person, would reveal a trade secret or other
                  confidential information, would interfere with a legal or business obligation that requires retention
                  or use of the data, or because the data at issue is not covered under the law you are asserting. Also,
                  where permitted by law if you send us a request to exercise your rights or the choices in this
                  section, we may charge a fee or decline requests in certain cases.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Non-Discrimination.</span> You have
                  the right to not be discriminated against for exercising any of your privacy rights.
                </li>
              </ul>

              <p>
                <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Notice to Virginia Residents.</span>{" "}
                As a Virginia resident, you have the following rights regarding your personal information:
              </p>
              <ol type="A">
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Access.</span> You have a right,
                  twice in a 12-month period, to request that our Company disclose to you the personal information that
                  we collected about you, and any additional information about our collection, use, disclosure, or sale
                  of such personal information, which is also contained in this privacy policy.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Correction.</span> You have the
                  right to request that we correct inaccurate personal information under certain circumstances, subject
                  to a number of exceptions provided under Virginia law. For information how to request the correction
                  of personal information, please see section V of this policy, and specifically subsection titled{" "}
                  <strong style={{ fontWeight: "bold" }}>Submitting a Data Request</strong>.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Deletion.</span> You have the right
                  to request that we delete your personal information under certain circumstances, subject to a number
                  of exceptions, subject to a number of exceptions provided under Virginia law. For information how to
                  request the deletion of personal information, please see section V of this policy, and specifically
                  subsection titled <strong style={{ fontWeight: "bold" }}>Submitting a Data Request</strong>.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
                    Opt-Out of Targeted Advertising.
                  </span>{" "}
                  You have a right to opt-out of targeted advertising and the sale of personal data. For information how
                  to request the opt out of targeted advertising, please see section V of this policy, and specifically
                  subsection titled <strong style={{ fontWeight: "bold" }}>Submitting a Data Request</strong>.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
                    Obtaining a portable copy of your personal data.
                  </span>{" "}
                  To obtain a copy of your personal data that you previously provided to us in a portable format, please
                  submit an “Access” request as described above. While these requests are distinct, we have not
                  identified any technically feasible and readily usable format that would allow you to transmit this
                  data to another controller. Therefore, we will provide you a copy of your personal data so that we
                  honor your request as best is technically feasible.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Appeal.</span> You have the right to
                  appeal Company’s decision to refuse to act on a CTDPA data privacy request within a reasonable period
                  after you receive our decision. To appeal our decision, you should send your objection to our denial
                  via email to <a href="mailto:admin@Debt.com">admin@Debt.com</a>
                  and to allow Company to review your data subject request. Within 60 days, Company will provide you
                  with a written explanation of the justification for declining to act on your request. If you disagree
                  with our explanation you have the right to file a complaint with the Virginia Attorney General.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Authentication/Verification.</span>{" "}
                  To help protect your privacy and maintain security, we will take steps to verify your identity before
                  granting you access to your personal information or complying with your request (except for a request
                  to opt-out of sales or sharing). Company may require you to provide any of the following information:{" "}
                  <strong style={{ fontWeight: "bold" }}>
                    your name, date of birth, your phone number, your Social Security number, the email and physical
                    addresses
                  </strong>
                  . If you request that we provide you with specific pieces of personal information, we may require you
                  to sign a declaration under penalty of perjury that you are the consumer whose personal information is
                  the subject of the request, or authorized personnel of the consumer making the request. You understand
                  that Company may decline to process your request where: (i) Company is unable to authenticate that you
                  are the person to whom the data relates; (ii) the request is unreasonable or excessive; (iii) or where
                  otherwise permitted by applicable law.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Declining Requests.</span> You
                  understand that we may decline requests where granting the request would be prohibited by law, could
                  adversely affect the privacy or rights of another person, would reveal a trade secret or other
                  confidential information, would interfere with a legal or business obligation that requires retention
                  or use of the data, or because the data at issue is not covered under the law you are asserting. Also,
                  where permitted by law if you send us a request to exercise your rights or the choices in this
                  section, we may charge a fee or decline requests in certain cases.
                </li>
                <li>
                  <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Non-Discrimination.</span> You have
                  the right to not be discriminated against for exercising any of your privacy rights.
                </li>
              </ol>
              <p>
                <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
                  Notice to Users Outside of the United States.
                </span>{" "}
                This Online Privacy Policy is intended to cover collection of information on our Sites from residents of
                the United States only. If you are visiting our Site from outside the United States, please be aware
                that your information may be transferred to, stored and processed in the United States where our servers
                are located and our central database is operated. The data protection and laws of the United States and
                other countries might not be as comprehensive as those in your country. By using our services, you
                understand that your information may be transferred to our facilities and those third- parties with whom
                we share it as described in this Notice. We do not sell, offer, or otherwise provide services to
                individuals outside of the United States.
              </p>
            </li>

            <li>
              <h3>MISCELLANEOUS</h3>

              <p>
                <strong style={{ fontWeight: "bold" }}>Social media sites.</strong> Debt.com provides experiences on
                social media platforms that enable online sharing and collaboration among users who have registered to
                use them. Any content you post on official Debt.com managed social media pages, such as pictures,
                information, opinions, or any Personal information that you make available to other participants on
                these social platforms, is subject to the Terms of Use and Privacy Policies of those respective
                platforms. By allowing you to post or share information on Debt.com’s social media pages, you
                acknowledge that Debt.com does not endorse, support, acknowledge, or otherwise agree with the posted
                information and any such posts shall be your sole responsibility. You also agree that Debt.com may use
                any information posted by you on Debt.com’s social media for any purpose provided in this Notice. Please
                refer to them to better understand your rights and obligations with regard to such content. In addition,
                please note that when visiting any official Debt.com social media pages, you are also subject to
                Debt.com‘s Privacy Notices, in addition to any social media platform’s Privacy Notice to which you may
                be subject to.
              </p>
              <p>
                <strong style={{ fontWeight: "bold" }}>
                  Short Message Service. Note that this policy notice does not replace your opt in requirements.
                </strong>{" "}
                We may make available a service through which you can receive communications your wireless device via
                short message service (“SMS Service”). Data obtained from you in connection with this SMS service may
                include your name, address, cell phone number, your provider’s name, and the date, time, and content of
                your messages. By providing your contact information, you affirmatively represent that you are 18 years
                of age and the owner or authorized user of the wireless device on which messages will be received, and
                that you understand that such communication may be subject to certain applicable charges, and that you
                approve all such applicable charges. In addition to any fee of which you are notified, your provider’s
                standard message and data rates may apply to our SMS confirmation and all subsequent SMS correspondence.
                All charges for data rates are billed by, and payable to, your mobile service provider. We will not be
                liable for any delays in the receipt of any SMS messages, as delivery is subject to effective
                transmission from your network operator. We will not be liable if SMS are received after hours due to
                such transmission delays. SMS message services are provided on an AS IS basis. We may also obtain the
                date, time, and content of your messages in the course of your use of the SMS Service. We will use the
                information we obtain in connection with our SMS Service in accordance with this Privacy Policy. We may
                provide your carrier with your applicable information in connection with your consent to receive SMS.
                Your wireless carrier and other service providers may also collect data about your wireless device
                usage, and their practices are governed by their own policies. We will not be liable for any data use or
                misuse by your wireless carrier.
              </p>
              <p>
                You acknowledge and agree that the SMS Service is provided via wireless systems which use radios (and
                other means) to transmit communications over complex networks. You must have a two-way text-enabled
                phone with compatible carrier and plan. Compatible major carriers include Alltel Wireless, AT&T, Boost
                Mobile, Nextel, Sprint, T-Mobile, Verizon Wireless and Virgin Mobile USA. We do not guarantee that your
                use of the SMS Service will be private or secure, and we are not liable to you for any lack of privacy
                or security you may experience. You are fully responsible for taking precautions and providing security
                measures best suited for your situation and intended use of the SMS Service.
              </p>

              <p>
                <strong style={{ fontWeight: "bold" }}>
                  SMS and Phone Call Opt-In. Note that this policy notice does not replace your express written consent
                  and opt in requirements.
                </strong>{" "}
                By completing a lead or contact form and affixing you electronic signature, in compliance with the
                E-SIGN act and disclosure which is available to you in the link above or in the footer of our Site, you
                have agreed to give <strong style={{ fontWeight: "bold" }}>Debt.com, LLC</strong> your express signed
                prior written consent and authorize <strong style={{ fontWeight: "bold" }}>Debt.com, LLC</strong>, and
                direct representatives calling on its behalf to contact you via telephone calls, or mobile device
                messages, online live chat and chatbots (including marketing calls and messages through by autodialing,
                text and pre-recorded messaging, AI generative voice, SMS, and MMS), even if my telephone number is
                currently listed on any internal, corporate, state or federal Do-Not-Call list. Msg. and data rates may
                apply, and you may opt out of text messages at any time by texting “HELP” for help, and “STOP”, “END”,
                “QUIT”, “UNSUBSCRIBE”, “OPT OUT”, “REVOKE” or “CANCEL” to opt out from any future communications. You
                may also choose to opt-out and remove your authorization to receive such calls and/or text messages by
                emailing us your instructions at <a href="mailto:admin@Debt.com">admin@Debt.com</a>. You also consent to
                the recording and monitoring of all calls to and from us. Further, by providing your e-mail above, you
                authorize and give <strong style={{ fontWeight: "bold" }}>Debt.com, LLC</strong> your express written
                consent, to receive electronic communications, including periodic emails with important news, financial
                tips, tools and more from Debt.com, LLC. You understand that you can always withdraw your consent or
                unsubscribe at any time at <a href="mailto:unsubscribe@Debt.com">unsubscribe@Debt.com</a>.{" "}
                <strong style={{ fontWeight: "bold" }}>
                  You are not required to obtain goods or services or to be connected with any of the service providers
                  that can fit my needs and that I may choose to call Debt.com, LLC’s representative instead at
                  1.800.810.0989.
                </strong>
              </p>
              <p>
                <strong style={{ fontWeight: "bold" }}>Making Sure Information is Accurate.</strong> You understand and
                agree that Debt.com presumes that the Personal Information you provided is, indeed, accurate. Debt.com
                will not be responsible for verifying that Personal Information. You understand that if you use any
                automated decision making features on our Site, it is crucial that you provide accurate PII and/or
                Sensitive Personal Information being requested in order for the automated decision making feature to
                process and provide you with an accurate decision and/or result. It is your responsibility to ensure the
                information provided is accurate and up to date. If you have previously submitted information which is
                incomplete, inaccurate or not current, please email or write us at the appropriate address, or complete
                a data request form, with updated information which you would like us to use in our communication with
                you.
              </p>
              <p>
                <strong style={{ fontWeight: "bold" }}>Business Transitions.</strong> You understand and agree that if
                Debt.com goes through a business transition, such as a merger, acquisition by another company, or sale
                of all or a portion of its assets, your Personal information, PII, and additionally collected
                information will likely be among the assets transferred.
              </p>

              <h3>Do Not Track Disclosures</h3>
              <ol type="1">
                <li>
                  How do we respond to Web browser “do not track” (also referred to as “DNT”) signals or other
                  mechanisms that provide consumers the ability to exercise choice regarding the collection of
                  personally identifiable information about an individual consumer’s online activities over time and
                  across third- party websites or online services?
                </li>
                <p>
                  ANSWER: We currently do not respond to DNT signals in browsers because we do not track individual
                  users across the web.
                </p>
                <li>
                  May other parties collect personally identifiable information about an individual consumer’s online
                  activities over time and across different websites when they visit{" "}
                  <a href="www.Debt.com" target="_blank" rel="noopener noreferrer">
                    www.Debt.com
                  </a>
                  ?
                </li>
                <p>
                  ANSWER: Yes, We currently have technology partners that provide us with Site tools which the consumer
                  may use while visiting our site, which when used share personally identifiable information with our
                  technology Partners. For Example, should you use the Instant Debt AdvisorSM, certain information is
                  shared with the technology provider, All Clear Decisioning, LLC, and may also be shared with
                  third-party service providers.
                </p>
              </ol>
              <p>
                <strong style={{ fontWeight: "bold" }}>
                  Protecting Children’s Privacy Online (Children’s Online Privacy Protection Act (COPPA)) Disclosure.
                </strong>{" "}
                The Site is not directed to individuals under the age of eighteen (18), and we request that these
                individuals do not provide Personally Identifiable Information (PII) through the Site. We do not
                knowingly collect information from children under 18 years of age without parental consent. If you are a
                User under the age of 18, please do not send any PII to us if we have not obtained prior consent from
                your parent or guardian. If we learn we have collected PII from a User under the age of 18 without
                parental consent, or if we learn a User under the age of 18 has provided us with PII beyond what we
                request from him or her, we will delete that information as quickly as possible. If you believe that a
                User under the age of 18 may have provided Debt.com with PII in violation of this Privacy Policy, please
                contact us at our e-mail or physical mailing address listed in the “Contact Us” section below and we
                will take all reasonable efforts to remove the information. For more information about the Children’s
                Online Privacy Protection Act (COPPA), please visit the Federal Trade Commission website at{" "}
                <a
                  href="https://www.ftc.gov/business-guidance/privacy-security/childrens-privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://www.ftc.gov/business-guidance/privacy- security/childrens-privacy
                </a>
                .
              </p>

              <p>
                <strong style={{ fontWeight: "bold" }}>English Language Govern this Privacy Notice.</strong> You
                acknowledge and agree that the text of this Privacy Notice, including any and all linked pages or other
                page Sites, have been written, and are offered, in both English and Spanish. Each version is authentic.
                In the event of any dispute regarding this Privacy Policy, however, the English version shall be given
                priority of interpretation and shall therefore be controlling. Additionally, all notifications shall be
                provided by one party hereunder to the other party in the English language. You may request a copy of
                this Privacy Notice in Spanish for your records.
              </p>

              <p>
                <strong style={{ fontWeight: "bold" }}>Updates to this Privacy Notice.</strong> This Online Privacy
                Notice is subject to change at the sole discretion of Debt.com. Please review the Privacy Policy
                periodically to see if there are any changes. If we make changes to the Privacy Notice, we will revise
                the “Last Updated” date at the top of this Notice. Any changes to this Notice will become effective when
                we post the revised Notice on the Site. You agree that your use of the Site following any changes to
                this Notice means that you accept the revised Notice and the Privacy policies therein.
              </p>
            </li>
          </ol>
          <h3>Contact Us</h3>
          <p>If you have any questions or suggestions regarding our privacy policy, please contact us:</p>
          <h3>Debt.com LLC</h3>
          <p>
            8220 W. State Road 84., <br />
            Fort Lauderdale, FL 33324 <br />
            Via Email at: <a href="mailto:admin@Debt.com">admin@Debt.com</a>; or <br />
            Via Phone: 1.800.810.0989. <br />
          </p>
          <h4>PLEASE PRINT AND RETAIN A COPY OF THIS PRIVACY POLICY FOR YOUR RECORDS.</h4>

          <h2 ref={esignTargetRef}>E-Sign Disclosure</h2>
          <p style={{ fontWeight: "bold" }}>
            DISCLOSURE: For the Consumer’s convenience, this is a copy of the E-sign Act Disclosures included in the
            TCPA Disclosure located in the form you signed and submitted to Debt.com, LLC in order that Debt.com, LLC
            will contact you about Debt.com, LLC services. Please be advised that the "Agree & Send Code" button
            reference in this Copy is our template action button, and that the action button you pressed when submitting
            the form may contained a different acceptance language. Being that this is a copy of the E-Sign Act
            Disclosures, there is no action to be taken by you. You may print and save this form for your records.
          </p>
          <h3>Consent to Use of Electronic Records and Signatures</h3>
          <p>
            You have the opportunity to complete and sign documents, as well as receive notices and other documents
            related to your Submission Form and interaction with{" "}
            <strong style={{ fontWeight: "bold" }}>Debt.com, LLC</strong> in electronic rather than paper form. To agree
            to these uses of electronic documents and signatures, and to sign this document with the same effect as
            physically signing your name, click the "Agree & Send Code" on the bottom of the form on the page after
            reviewing the information below. In order to sign, complete and receive documents electronically you will
            need the following:
          </p>
          <ul>
            <li>A personal e-mail address</li>
            <li>A computer or other device with standard e-mail software</li>
            <li>Current version of Chrome, Firefox, Edge or Safari</li>
            <li>A connection to the internet</li>
            <li>A printer if you want to print paper copies</li>
          </ul>
          <p>
            Alternatively, you may choose to contact <strong style={{ fontWeight: "bold" }}>Debt.com, LLC</strong> by
            contacting it through a phone call, and you may submit any request for services through paper versions of
            the documents.
          </p>
          <p>
            By clicking "Agree & Send Code" on the bottom of the form on the page after reviewing the information
            herein, you consent to electronically sign, complete and receive documents relating to your application for
            information and interaction with <strong style={{ fontWeight: "bold" }}>Debt.com, LLC</strong> during both
            this session and any future sessions relating to this process. Additionally, you consent to electronically
            receive: communications relating to your application and interaction with{" "}
            <strong style={{ fontWeight: "bold" }}>Debt.com, LLC</strong> including requests for additional information;
            notices of actions taken required by law; and notices of your rights under applicable laws. Your consent
            applies to documents completed, signed or provided via this website, as well as to documents transmitted via
            email.
          </p>
          <p>
            If your contact information changes, please update your information with{" "}
            <strong style={{ fontWeight: "bold" }}>Debt.com, LLC</strong>, by contacting{" "}
            <strong style={{ fontWeight: "bold" }}>Debt.com, LLC</strong> as provided below. Further, you have the right
            to withdraw your consent at any time by contact{" "}
            <strong style={{ fontWeight: "bold" }}>Debt.com, LLC</strong>. You may update your information or withdraw
            your consent by: calling (in the US only) Debt.com, LLC, at 1.800.810.0989, by e-mailing at admin@Debt.com,
            or by writing to: 8220 W. State Road 84, Fort Lauderdale, FL 33324.
          </p>
          <p>
            If you withdraw consent, you will no longer have the ability to sign documents electronically and to receive
            disclosures and other documents electronically. Your withdrawal of consent is prospective only and will
            become effective after we have had a reasonable opportunity to act upon it. After withdrawing your consent,
            please also contact the company representative who sent you this request to make arrangements to receive
            paper copies of documents and communications.
          </p>
          <p>
            After consenting, you can obtain copies of documents and communications relating to your application and
            interaction with <strong style={{ fontWeight: "bold" }}>Debt.com, LLC</strong> by using your browser to
            print paper copies or save electronic copies of documents or screens during this session and future
            sessions.
          </p>
          <p>
            I understand that by typing my name where indicated in the form, providing my email and phone number, and
            then clicking on "Esign Now", You consent to the use of electronic records and signatures in the manner
            described above, and the electronic storage of such documents.
          </p>
        </div>
      </div>
    );
  },
);
