import Rollbar from "rollbar";

import { appVersion } from "./src/version";

interface RollbarPayload extends Rollbar.Dictionary {
  message?: string;
  body?: { trace?: { exception?: { class?: string } }; telemetry?: { body?: { message?: string } }[] };
}

const environment = import.meta.env.VITE_ROLLBAR_ENVIRONMENT ?? "local";
const codeVersion = import.meta.env.VITE_CODE_VERSION ?? appVersion;

const transmit = !window.Cypress && !window.location.origin.includes("localhost");

export const rollbarConfig: Rollbar.Configuration = {
  accessToken: import.meta.env.VITE_ROLLBAR_ACCESS_TOKEN_CLIENT,
  captureUncaught: true,
  codeVersion,
  captureUnhandledRejections: true,
  uncaughtErrorLevel: "critical",
  environment,
  transmit,
  verbose: true,
  scrubTelemetryInputs: true,
  captureIp: false,
  captureEmail: false,
  scrubFields: [
    "pid",
    "token",
    "key",
    "email",
    "search",
    "address",
    "dob",
    "ssn",
    "phone",
    "firstName",
    "lastName",
    "employmentStatus",
    "hhiConsistency",
    "householdIncome",
    "analysis",
    "eligibleTradeLines",
    "planDetails",
    "debt-settlement",
  ],
  autoInstrument: {
    contentSecurityPolicy: true,
    errorOnContentSecurityPolicy: true,
  },
  payload: {
    client: {
      javascript: {
        code_version: codeVersion,
        source_map_enabled: true,
        guess_uncaught_frames: true,
      },
    },
  },
  checkIgnore: (_isUncaught, _args, payload: RollbarPayload) => {
    /* filters out "[unhandledrejection] error getting `reason` from event" caused by an empty promise from reCAPTCHA */
    const emptyRecaptchaPromiseError =
      !payload.message &&
      payload.body?.telemetry?.some((item) => item.body?.message?.includes("appCheck/recaptcha-error"));

    /* filters out "CanceledError" from toggling tradelines */
    const canceledError = payload.body?.trace?.exception?.class === "CanceledError";

    return emptyRecaptchaPromiseError || canceledError || false;
  },
  transform: (payload: Rollbar.Payload): void => {
    const trace = payload.body?.trace;
    if (trace?.frames) {
      trace.frames.forEach((frame: { filename?: string }) => {
        if (frame.filename) {
          frame.filename = frame.filename.replace(
            /https?:\/\/[a-zA-Z0-9.-]+(:\d+)?/,
            `https://ida-${environment}-rollbar-alias/dist`,
          );
        }
      });
    }
  },
};

export const rollbar = new Rollbar(rollbarConfig);
