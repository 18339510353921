import { Text } from "../Text";

export const Copyright = () => {
  const currentYear = new Date().getFullYear();

  return (
    <Text
      sx={{
        fontWeight: 300,
        fontSize: "10px",
        whiteSpace: "nowrap !important",
      }}
      color="gray"
    >
      &copy; {currentYear} {"Instant Debt Advisor™,"}
      {" All Rights Reserved."}
    </Text>
  );
};
