import { makeAutoObservable } from "mobx";

import { isProfileExpired, ProfileSubStatusEnum } from "@utils";
import { AxiosErrorData } from "src/types/common";

import { store } from "./store";

const CLOSE_MODAL_VALUE = "_userClosedModal";
export class CommonStore {
  language: string = "en";
  serverError: AxiosErrorData | null = null;
  userClosedModal: boolean = sessionStorage.getItem(CLOSE_MODAL_VALUE) === "true";

  constructor() {
    makeAutoObservable(this);
  }

  get loadingEngagementOrProfile(): boolean {
    return (
      store.sessionStore.authUserLoaded === "VERIFYING" ||
      store.engagementStore.loader?.isLoading.get() ||
      store.profileStore.loader?.isLoading.get()
    );
  }

  get isMissingCreditReport(): boolean {
    return (
      this.helpPageView === "helpB" && !!store.profileStore.profile?.id && !store.callToActionsStore.creditResponse
    );
  }

  get isEngagementOrProfileLoaded(): boolean {
    return store.engagementStore.loader?.isSuccess.get() || store.profileStore.loader?.isSuccess.get();
  }

  clearStores = (): void => {
    store.engagementStore.clearEngagementStore();
    store.profileStore.clearProfileStore();
    store.sessionStore.clearSessionStore();
    store.modalStore.setShowLogOffModal(false);
    store.modalStore.clearModalStore();
  };

  setUserClosedModal = (value: boolean): void => {
    this.userClosedModal = value;
    sessionStorage.setItem(CLOSE_MODAL_VALUE, `${value}`);
  };

  setServerError = (error: AxiosErrorData | null): void => {
    this.serverError = error;
  };

  get helpPageView() {
    const isExpired = store.profileStore?.profile && isProfileExpired(store.profileStore.profile);
    const subStatus = isExpired ? store.profileStore.timedOutEnrollmentStatus : store.profileStore.profile?.subStatus;

    switch (subStatus) {
      case ProfileSubStatusEnum.SYSTEM_TIMEOUT_BEFORE_CREDIT:
      case ProfileSubStatusEnum.SYSTEM_ERROR:
      case ProfileSubStatusEnum.IDA_DECLINED_CREDIT:
      case ProfileSubStatusEnum.USER_DECLINED_CREDIT:
      case ProfileSubStatusEnum.IDA_CREDIT_ERROR:
      case ProfileSubStatusEnum.IDA_ANALYSIS_ERROR:
      case ProfileSubStatusEnum.SYSTEM_TIMEOUT_CREDIT_AUTH:
      case ProfileSubStatusEnum.SYSTEM_TIMEOUT:
        return "helpA";
      case ProfileSubStatusEnum.USER_DECLINED_SELECTION:
      case ProfileSubStatusEnum.IDA_SYSTEM_COMPLETED:
      case ProfileSubStatusEnum.IDA_ENROLLMENT_COMPLETED:
      case ProfileSubStatusEnum.CALL_ACTION:
      case ProfileSubStatusEnum.IDA_DECLINED_SELECTION:
        return "helpB";
      case ProfileSubStatusEnum.IDA_TIMED_OUT:
        return store.profileStore.hasSelectedPlan ? "helpC" : "helpA";
      default:
        return "";
    }
  }

  getHelpPhoneNumber = (): string => {
    return "8445652669";
  };

  get pageTitle() {
    if (store.modalStore.display) {
      return store.modalStore.module.name;
    }

    return location.pathname;
  }
}
