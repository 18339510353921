import { useRollbar } from "@rollbar/react";
import { RecaptchaVerifier } from "firebase/auth";
import { action } from "mobx";
import { RefObject, MutableRefObject, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useStore } from "@stores";
import { StoredProfile } from "@types";
import { getErrorMessage } from "@utils";

import { auth } from "../../../firebase.config.ts";
import { AuthError } from "../errors/AuthError.ts";

interface AuthVerificationProps {
  recaptchaVerifier: MutableRefObject<RecaptchaVerifier | undefined>;
  recaptchaWrapperRef: RefObject<HTMLDivElement>;
  storedProfile?: StoredProfile;
  phone: string;
}

export const useAuthVerification = ({
  recaptchaWrapperRef,
  recaptchaVerifier,
  storedProfile,
  phone,
}: AuthVerificationProps) => {
  const rollbar = useRollbar();

  const { sessionStore, profileStore } = useStore();
  const [verificationSent, setVerificationSent] = useState<"Sent" | "Sending" | "Failed" | "Empty">("Empty");
  const [error, setError] = useState("");
  const { t } = useTranslation("verification_page");
  const { profile } = profileStore;
  const [smsVerificationId, setSmsVerificationId] = useState<string>();

  useEffect(() => {
    const error = sessionStore.profileError;
    if (error && error.name) {
      setError(t(getErrorMessage(error.name)));
    }
  }, [sessionStore.profileError, t]);

  const initRecaptchaCloseListener = () => {
    // Look for backdrop and add event on click
    setTimeout(() => {
      const recaptchaWindow = document.querySelector("iframe[title~='recaptcha']")?.parentNode
        ?.parentNode as HTMLDivElement;
      if (recaptchaWindow) {
        new MutationObserver(() => {
          if (recaptchaWindow?.style?.opacity == "0") {
            onClose();
            recaptchaWindow?.remove();
          }
        }).observe(recaptchaWindow, {
          attributes: true,
          attributeFilter: ["style"],
        });
      }
    }, 1000);
  };

  // upon reCAPTCHA window close, reset loader state
  function onClose() {
    action(sessionStore.loader.updateState("EMPTY"));
    reset();
  }

  const sendSMS = async (): Promise<string | undefined> => {
    try {
      if (recaptchaVerifier.current && recaptchaWrapperRef.current) {
        recaptchaVerifier.current.clear();
        recaptchaWrapperRef.current.children[0].remove();
        recaptchaWrapperRef.current.innerHTML = `<div id="recaptcha"></div>`;
      }

      recaptchaVerifier.current = new RecaptchaVerifier(auth, "recaptcha", {
        size: "invisible", // 'invisible' or 'compact'
      });
      await recaptchaVerifier.current.render();
    } catch (error) {
      rollbar.warning("Error rendering recaptcha", error as Error);
    }

    try {
      if (recaptchaVerifier.current) {
        initRecaptchaCloseListener();
        return await sessionStore.verifyPhoneNumber(
          storedProfile?.phone || profile?.phone || profileStore.tempUser.phone || phone,
          recaptchaVerifier.current,
        );
      }
    } catch (error) {
      if (error instanceof AuthError) {
        if (error.name === "auth/too-many-requests") {
          rollbar.warning("Verification error", error as Error);
        }
        setError(t(getErrorMessage(error.name)));
      }
      rollbar.debug("Verification error", error as Error);
    }
  };

  async function verifyUser() {
    sessionStore.clearProfileError();
    setError("");
    setVerificationSent("Sending");
    const verificationId = await sendSMS();
    if (verificationId) {
      setSmsVerificationId(verificationId);
    }
    setVerificationSent("Sent");
  }

  const reset = () => {
    setSmsVerificationId(undefined);
    setVerificationSent("Empty");
    setError("");
  };

  return {
    verifyUser,
    sendSMS,
    verificationSent,
    error,
    smsVerificationId,
    reset,
  };
};
