import { Box, Typography, Button } from "@mui/material";
import { motion } from "framer-motion";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";

import { useStore } from "@stores";
import { theme } from "@styles";
import { CONSENT_SELECTION, CONSENT_SELECTION_DEC, CONSENT_SELECTION_ACC } from "@types";
import { GTMAcceptAllCookies, GTMAcceptNecessaryOnly } from "@utils";

export const CookiesModal = observer(() => {
  const { t } = useTranslation("cookie_modal");
  const { remoteConfigStore } = useStore();

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem(CONSENT_SELECTION);
    if (!consent || consent === "undefined") {
      setIsOpen(true);
    }
  }, []);

  const handleCookiesUpdate = async (decision: string) => {
    if (decision) {
      localStorage.setItem(CONSENT_SELECTION, decision);
      decision === CONSENT_SELECTION_DEC ? GTMAcceptNecessaryOnly() : await GTMAcceptAllCookies();
    }
    setIsOpen(false);
  };

  const handlePrivacyClick = () => {
    window.location.href = `${remoteConfigStore.redirectUrl}legal/privacy-policy`;
  };

  const styles = {
    container: {
      backgroundColor: "white",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      position: "fixed",
      zIndex: 50,
      bottom: { xs: "25%", md: "30px" },
      left: { md: "30px" },
      width: { xs: "90%", md: "40%" },
      maxWidth: { xs: "90%", md: "480px" },
      borderRadius: "20px",
      padding: "20px",
      boxShadow:
        "0 9px 19px 0 rgba(0, 0, 0, 0.1), 0 35px 35px 0 rgba(0, 0, 0, 0.04), 0 78px 47px 0 rgba(0, 0, 0, 0.03), 0 138px 55px 0 rgba(0, 0, 0, 0.01), 0 216px 60px 0 rgba(0, 0, 0, 0)",
      [theme.breakpoints.between(768, 900)]: {
        left: "30px",
        width: "60%",
      },
    },
    button: {
      width: "100%",
      fontSize: "12px",
      padding: "8px 12px",
      fontWeight: "600",
      borderRadius: "6px",
      minHeight: "40px",
    },
  };

  if (!isOpen || window.Cypress) return null;

  return (
    <Box sx={{ position: "relative", display: "flex", justifyContent: "center", zIndex: 9999 }}>
      <Box
        sx={styles.container}
        component={motion.div}
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0, transition: { delay: 1, duration: 0.5, ease: "easeOut" } }}
      >
        <Typography variant="body1" sx={{ fontSize: "14px", fontWeight: "bold", color: "#2A353D" }}>
          {t("title")}
        </Typography>

        <Box sx={{ color: "#2A353D", fontSize: "12px", mt: 2, lineHeight: 1.36 }}>
          <Trans
            i18nKey="description"
            t={t}
            components={{
              strong: <span style={{ fontWeight: "600" }} />,
              privacy: (
                <span
                  onClick={handlePrivacyClick}
                  style={{ cursor: "pointer", fontWeight: "600", textDecoration: "underline" }}
                />
              ),
            }}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", mt: 3, width: "100%", gap: 1 }}>
          <Button
            variant="contained"
            onClick={() => handleCookiesUpdate(CONSENT_SELECTION_ACC)}
            sx={{
              backgroundColor: "#35463b",
              color: "white",
              ...styles.button,
              "&:hover": { backgroundColor: "#D5E6D1", color: "#35463b" },
            }}
          >
            {t("agree")}
          </Button>
          <Button
            variant="contained"
            onClick={() => handleCookiesUpdate(CONSENT_SELECTION_DEC)}
            sx={{
              backgroundColor: "white",
              color: "#35463b",
              border: "2px solid #35463b",
              "&:hover": { backgroundColor: "#D5E6D1" },
              ...styles.button,
            }}
          >
            {t("reject")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
});
