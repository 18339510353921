import { Box, Divider, Grid, useMediaQuery } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ButtonBranded } from "@components/common";
import { ConfirmationModalView } from "@components/layout/Footer/ConfirmationModalView.tsx";
import { TotalDebt } from "@components/views/Modules/DebtReview/TotalDebt.tsx";
import { useStore } from "@stores";
import { theme } from "@styles";
import { JourneyId, VTPageTitlesEnum } from "@types";
import {
  capitalize,
  CTAModuleID,
  CTAModulesEnum,
  getFormattedTodayDateUS,
  GTMOffRampClicked,
  GTMOffRampConfirmed,
  GTMSeePlanOptions,
  handlePercentageAndPageCompleted,
  JourneyCTAEnum,
  ProfileSubStatusEnum,
} from "@utils";

import { FooterLegalInfo } from "../../common/FooterLegalInfo";

export const DebtReviewFooter = observer(() => {
  const { t } = useTranslation("debt_review");
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const {
    profileStore,
    modalStore: { openModal, showOffRampModal, setShowOffRampModal, setClickSource },
    callToActionsStore: { totalEligibleDebt },
    callToActionsStore,
    remoteConfigStore: { featureFlags, threshold },
    digitalSignatureStore,
  } = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const isBudgetNextCTA =
    profileStore.profile?.callToActionIds.includes(JourneyCTAEnum["complete-your-budget"]) &&
    Boolean(totalEligibleDebt! > threshold!);
  const handleOffRamp = () => {
    GTMOffRampClicked("Debt-Review");
    setClickSource("footer");
    setShowOffRampModal(!showOffRampModal);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    GTMSeePlanOptions();

    await handlePercentageAndPageCompleted(VTPageTitlesEnum.DEBT_REVIEW, profileStore);

    if (profileStore.profile?.id) {
      const updatedProfile = await callToActionsStore.updateCta(
        profileStore.profile.id,
        JourneyCTAEnum["review-debt-eligibility"],
      );
      if (updatedProfile.id && profileStore.journeyId === JourneyId.DEBT_MANAGEMENT) {
        profileStore.setProfile(updatedProfile);
      }

      if (isBudgetNextCTA) {
        openModal(CTAModulesEnum["complete-your-budget" as CTAModuleID]);
      } else {
        openModal(CTAModulesEnum["select-plan" as CTAModuleID]);
      }

      // This is a background call to create a digital signature document to speed up the process of generating signature link
      if (featureFlags.enableDigitalSignature) {
        const plans = callToActionsStore.creditResponse?.plans?.[profileStore.journeyId]?.planDetails;

        const longestPlan = Array.isArray(plans)
          ? plans.slice().sort((a, b) => b.programTimePeriod - a.programTimePeriod)[0]
          : null;

        const firstName = profileStore.profile?.firstName || "";
        const lastName = profileStore.profile?.lastName || "";
        const full_name = `${capitalize(firstName)} ${capitalize(lastName)}`;

        const payment = longestPlan?.estimatedPmtWithLegal
          ? new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(longestPlan.estimatedPmtWithLegal)
          : "";
        const amount = callToActionsStore.totalDebt || "";
        const date = getFormattedTodayDateUS();

        const payload = {
          document_type: "NTLOI",
          prefill_data: {
            client_timestamp: Math.floor(Date.now() / 1000).toString(),
            data: [{ full_name }, { payment }, { amount }, { date }],
          },
        };

        digitalSignatureStore.createDigitalSignDocument(profileStore.profile.id, payload);
      }
    }
    setIsLoading(false);
  };

  return (
    <>
      {isDesktop && <Divider sx={{ color: "black", width: "100%" }} />}
      <Box sx={{ boxShadow: { xs: "0 4px 20px 0 rgba(0, 0, 0, 0.12)", md: "none" }, zIndex: 200, p: 2 }}>
        <Grid
          container
          spacing={{ xs: 2, md: 0 }}
          flexDirection={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: "normal", md: "center" }}
        >
          <Grid item>
            <TotalDebt />
          </Grid>

          <Grid item>{!isDesktop && <Divider sx={{ color: "black", width: "100%" }} />}</Grid>
          <Grid item>
            <Grid container direction={{ xs: "column-reverse", md: "row" }} alignItems="center" spacing={2}>
              {featureFlags.enableReviewDebtOffRamp && (
                <Grid item>
                  <ButtonBranded
                    data-cy="callMeButton"
                    variant="textonly"
                    fontSize={theme.fontSizes.medium}
                    fontWeight={theme.fontWeights.medium}
                    lineHeight={theme.fontLineHeights.xxxsmall}
                    onClick={handleOffRamp}
                  >
                    {t("footer.secondaryButtonLabel")}
                  </ButtonBranded>
                </Grid>
              )}
              <Grid item sx={{ width: { xs: "100%", md: "auto" } }}>
                <ButtonBranded
                  loading={isLoading || callToActionsStore.tradeLinesLoading}
                  disabled={callToActionsStore.thresholdError}
                  data-cy="see-plan-options"
                  variant="primary"
                  onClick={handleSubmit}
                >
                  {isBudgetNextCTA ? t("footer.primaryButtonBudgetLabel") : t("footer.primaryButtonLabel")}
                </ButtonBranded>
              </Grid>
            </Grid>
            <ConfirmationModalView
              isOpen={showOffRampModal}
              handlePrimary={async () => {
                GTMOffRampConfirmed("Debt-Review");
                setShowOffRampModal(false);
                await profileStore.updateEnrollmentStatus(ProfileSubStatusEnum.IDA_DECLINED_SELECTION);
                openModal(CTAModulesEnum["plan-selected"]);
              }}
              handleSecondary={() => {
                setShowOffRampModal(false);
              }}
              handleCancel={() => {
                setShowOffRampModal(false);
              }}
              name={"debt-review"}
            />
          </Grid>
          <FooterLegalInfo />
        </Grid>
      </Box>
    </>
  );
});
