import { CONSENT_SELECTION } from "@types";
import { isCookieAllowed } from "@utils";

const updateWithPII = (eventData: object, userPII: object) => {
  if (isCookieAllowed()) {
    return {
      ...eventData,
      ...userPII,
    };
  }
  return eventData;
};

export const GTMPageView = (pageTitle: string) => {
  window.dataLayer.push({
    event: "virtualPageview",
    page_title: pageTitle,
  });
};

export const GTMButtonClick = (buttonText: string) => {
  window.dataLayer.push({
    event: "button_click",
    click_text: buttonText,
  });
};

export const GTMNavigationClick = (navText: string) => {
  window.dataLayer.push({
    event: "navigation_click",
    click_text: navText,
  });
};

export const GTMExitClick = (pageTitle: string) => {
  window.dataLayer.push({
    event: "exit_click",
    page_title: pageTitle,
  });
};

export const GTMOnboardingInitialization = (
  ga_client_id: string,
  program_type: string,
  partner_name: string,
  source: "IDA SDK" | "Direct Entry",
) => {
  window.dataLayer.push(
    updateWithPII({ event: "onboarding_initialized", source, program_type, partner_name }, { ga_client_id }),
  );
};

export const GTMProfileCreated = (affiliate_name: string, workflow: string) => {
  window.dataLayer.push({
    event: "profile_created",
    source: "IDA SDK",
    affiliate_name,
    workflow_variant: workflow,
  });
};

export const GTMAdvanceMatchinInfo = (userData: {
  first_name?: string;
  last_name?: string;
  email?: string;
  phone?: string;
  zip_code?: string;
}) => {
  window.dataLayer.push(updateWithPII({ event: "advanced_matching_info" }, userData));
};

export const GTMVerificationError = () => {
  window.dataLayer.push({
    event: "issue",
    issue_type: "code: Verification Error, name: invalid code",
  });
};

export const GTMOffRampClicked = (pageTitle: string) => {
  window.dataLayer.push({
    event: "off_ramp_click",
    page_title: pageTitle,
  });
};

export const GTMOffRampConfirmed = (pageTitle: string) => {
  window.dataLayer.push({
    event: "off_ramp_confirmed",
    page_title: pageTitle,
  });

  switch (pageTitle) {
    case "Credit-Auth":
      window.dataLayer.push({
        event: "negative_end_state_no_credit",
        debt_program: "debt settlement",
      });
      break;
    case "Debt-Review":
    case "Select-Plan":
      window.dataLayer.push({
        event: "semi_positive_end_state_with_credit",
        debt_program: "debt settlement",
      });
      break;
  }
};

export const GTMUserLoggedIn = () => {
  window.dataLayer.push({ event: "user_login" });
};

export const GTMCreditPullSuccess = () => {
  window.dataLayer.push({ event: "credit_report_successful" });
};

export const GTMCreditConsent = (servicer: string) => {
  window.dataLayer.push({
    event: "data_sharing_consent_provided",
    servicer_name: servicer,
  });
};

export const GTMSeePlanOptions = () => {
  window.dataLayer.push({ event: "see_plan_options_click" });
};

export const GTMPlanOptionsToolTip = () => {
  window.dataLayer.push({
    event: "tooltip_clicked",
    tooltip_location: "PlanOptions",
    tooltip_name: "PlanOptionsToolTip",
  });
};

export const GTMSessionTimeoutPrompted = (pageTitle: string) => {
  window.dataLayer.push({
    event: "session_timeout_prompted",
    page_title: pageTitle,
  });
};

export const GTMSessionExtended = (page_title: string) => {
  window.dataLayer.push({ event: "session_extended", page_title });
};

export const GTMSessionNotExtended = (page_title: string) => {
  window.dataLayer.push({ event: "session_not_extended", page_title });
};

export const GTMProvideMotivations = () => {
  window.dataLayer.push({
    event: "debt_motivations_provided",
    debt_motivations_selected: "",
  });
};

export const GTMUserLoggedOut = () => {
  window.dataLayer.push({ event: "user_logout" });
};

export const GTMWorkflowExitConfirmed = (pageTitle: string) => {
  window.dataLayer.push({
    event: "workflow_exit_confirmed",
    page_title: pageTitle,
  });
};

export const GTMCreditReportTimeout = () => {
  window.dataLayer.push({
    event: "issue",
    issue_type: "credit_report_timeout",
  });
};

export const GTMCreditReportError = () => {
  window.dataLayer.push({
    event: "issue",
    issue_type: "credit_report_retrieval",
  });
};

export const GTMUserCallNowClicked = (pageTitle: string, endPath: string) => {
  window.dataLayer.push({
    event: "call_now",
    end_state_path: endPath,
    page_title: pageTitle,
  });
};

export const GTMLinkOutClicked = (externalLink: string) => {
  window.dataLayer.push({
    event: "link_out_click",
    external_link_url: externalLink,
  });
};

export const GTMDynamicEvent = (name: string) => {
  window.dataLayer.push({ event: name });
};

export const GTMNotUserClicked = () => {
  window.dataLayer.push({
    event: "button_click",
    click_text: `Not User`,
  });
};

export const GTMInitiate = (ga_client_id: string) => {
  window.dataLayer.push(updateWithPII({ event: "initiate" }, { ga_client_id }));
};

export const GTMPlanSubmitted = (cta: string) => {
  window.dataLayer.push({
    event: "plan_submitted",
    plan_submitted_cta: cta,
  });
};

export const GTMEsignViewed = () => {
  window.dataLayer.push({ event: "esign_viewed" });
};

export const GTMEsignButtonClicked = () => {
  window.dataLayer.push({ event: "esign_button_click" });
};

export const GTMEsignSkipped = () => {
  window.dataLayer.push({ event: "esign_skipped" });
};

export const GTMEsignCompleted = () => {
  window.dataLayer.push({ event: "esign_completed" });
};

export const GTMAgentConnectAttempted = () => {
  window.dataLayer.push({ event: "agent_connect_attempted" });
};

export const GTMProgramType = (program_type: string) => {
  window.dataLayer.push({
    event: "program_type",
    program_type,
  });
};

export const GTMPartnerName = (partner_name: string) => {
  window.dataLayer.push({
    event: "partner_name",
    partner_name,
  });
};

export const GTMTradeLineSelected = (program_type: string, tradeline_type: string) => {
  window.dataLayer.push({
    event: "tradeline_selected",
    program_type,
    tradeline_type,
  });
};

export const GTMTradeLineDeselected = (program_type: string, tradeline_type: string) => {
  window.dataLayer.push({
    event: "tradeline_deselected",
    program_type,
    tradeline_type,
  });
};

export const GTMBelowDebtThreshold = () => {
  window.dataLayer.push({
    event: "below_debt_threshold_alert",
  });
};

export const GTMWorkflowVariant = (workflow_variant: string) => {
  window.dataLayer.push({
    event: "workflow_variant",
    workflow_variant,
  });
};
export const GTMAcceptConsent = () => {
  window.dataLayer.push({
    event: CONSENT_SELECTION,
    selected_necessary_cookies: false,
  });
};

export const GTMDeclineConsent = () => {
  window.dataLayer.push({
    event: CONSENT_SELECTION,
    selected_necessary_cookies: true,
  });
};

export const GTMCallNowShown = () => {
  window.dataLayer.push({
    event: "call_now_shown_open_hours",
  });
};

export const GTMAcceptAllCookies = async () => {
  window.dataLayer.push({
    event: "gtm.click",
    click_text: "ACCEPT ALL COOKIES",
  });
};

export const GTMAcceptNecessaryOnly = () => {
  window.dataLayer.push({
    event: "gtm.click",
    click_text: "ACCEPT NECESSARY COOKIES",
  });
};
