import { CircularProgress, Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { Text } from "@components/common";
import { useStore } from "@stores";

export const TotalDebt = observer(() => {
  const { t } = useTranslation("debt_review");
  const {
    callToActionsStore: { totalDebt, tradeLinesLoading },
  } = useStore();

  return (
    <Grid
      data-cy="TotalDebt-container"
      container
      spacing={4}
      sx={{
        alignItems: "center",
        justifyContent: {
          xs: "space-between",
          md: "flex-start",
        },
      }}
    >
      <Grid item>
        <Text fontSize="medium" fontWeight="semiBold" lineHeight="medium" data-cy="totalDebt">
          {t("totalDebt")}
        </Text>
      </Grid>
      <Grid item>
        <Text data-cy="review-debt-total-debt-amount" fontSize="xxlarge" fontWeight="bold" lineHeight="xxlarge">
          {tradeLinesLoading ? (
            <CircularProgress size={"1.5rem"} sx={{ color: (theme) => theme.palette.brandGray.darker }} />
          ) : (
            totalDebt
          )}
        </Text>
      </Grid>
    </Grid>
  );
});
