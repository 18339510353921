import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { Outlet } from "react-router-dom";

import { useStore } from "@stores";
import { theme } from "@styles";

import { ModalForm } from "../Form/index.tsx";

/**
 * DashboardOutlet renders the ModuleProcessor if a module is active.
 * Otherwise, renders an Outlet component
 */

export const DashboardOutlet = observer(() => {
  const {
    modalStore: { module },
  } = useStore();

  if (!module.component) {
    return <Outlet />;
  }

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
        zIndex: 99,
        [theme.breakpoints.up("md")]: {
          margin: "0 auto",
          width: "80%",
        },
      }}
      data-cy="DashboardOutlet.centerContainer"
    >
      <ModalForm />
    </Box>
  );
});
