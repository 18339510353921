import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Backdrop, Box, Card, Grid } from "@mui/material";
import { observer } from "mobx-react";
import { Trans, useTranslation } from "react-i18next";

import { ButtonBranded, Slider, Text } from "@components/index";
import { useStore } from "@stores";
import { theme } from "@styles";

export const DebtAlertModal = observer(
  ({ isOpen, setIsOpen }: { isOpen: boolean; setIsOpen: (bool: boolean) => void }) => {
    const { t } = useTranslation("debt_review");
    const {
      remoteConfigStore: { threshold },
    } = useStore();

    const currency = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    const amount = threshold && currency.format(threshold);

    return (
      <Backdrop
        sx={{
          color: (theme) => theme.palette.background.overlay,
          zIndex: (theme) => theme.zIndex.drawer + 1,
          justifyContent: "end",
        }}
        open={isOpen}
        onClick={() => {
          setIsOpen(false);
        }}
      >
        <Box onClick={(e) => e.stopPropagation()}>
          <Grid container justifyContent="center" data-cy="slider">
            <Slider direction={"up"} show transitionTime={500}>
              <Card
                sx={{
                  zIndex: "99",
                  position: { xs: "absolute", md: "relative" },
                  bottom: 0,
                  right: 0,
                  height: "auto",
                  width: "100%",
                  borderRadius: { xs: "10 !important", md: "16px !important" },
                  boxShadow: "none !important",
                  display: { md: "flex" },
                  flexBasis: { md: "50%" },
                }}
              >
                <Grid container p={4} alignItems="center" spacing={4}>
                  <Grid item>
                    <Grid container direction="column">
                      <Grid item>
                        <Grid container spacing={1}>
                          <Grid item>
                            <ErrorOutlineIcon sx={{ color: (theme) => theme.palette.brandYellow.dark }} />
                          </Grid>
                          <Grid item>
                            <Text
                              fontSize="large"
                              fontWeight="semiBold"
                              lineHeight="medium"
                              sx={{ color: (theme) => theme.palette.brandYellow.dark }}
                            >
                              {t("alert.header")}
                            </Text>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Text fontSize="medium" fontWeight="regular" lineHeight="medium" color="darkerGray">
                          <Trans
                            i18nKey="alert.description"
                            ns="debt_review"
                            components={{
                              bold: <Box sx={{ fontWeight: 600 }} component="span" />,
                            }}
                            values={{
                              amount,
                            }}
                          />
                        </Text>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item width="100%">
                    <ButtonBranded
                      data-cy="debt-alert-modal-button"
                      fullWidth
                      sx={{
                        backgroundColor: theme.palette.brandYellow.dark,
                        "&:hover": {
                          textDecoration: "none",
                          cursor: "pointer",
                          backgroundColor: theme.palette.brandYellow.darker,
                        },
                      }}
                      onClick={() => {
                        setIsOpen(false);
                      }}
                    >
                      {t("alert.buttonLabel")}
                    </ButtonBranded>
                  </Grid>
                </Grid>
              </Card>
            </Slider>
          </Grid>
        </Box>
      </Backdrop>
    );
  },
);
