import image from "@assets/mock-cta_optimized.jpg";
import { BudgetTypeEnum, CallToAction, CallToActionIdEnum, ProfileResponse, VTPageTitlesEnum } from "@types";

import { ProfileStatusEnum, ProfileSubStatusEnum } from "./subStatus.ts";

export const mockCallToActionMultipleSummary: CallToAction = {
  id: "1",
  priority: 1,
  name: "Authorize Credit Pull",
  summary: ["See Debt Relief Offers", "No Credit Score Impact"],
  buttonLabel: "Authorize",
  imageUrl: image,
};
export const mockCallToActionSingleSummary: CallToAction = {
  id: "1",
  priority: 1,
  name: "Review Debt Eligibility",
  summary: ["Check your eligible debts to see your debt plan offers."],
  buttonLabel: "Review",
  imageUrl: image,
};

export const mockProfile: ProfileResponse = {
  fileNumber: "",
  dob: { day: "", month: "", year: "" },
  ssn: "",
  budget: {
    [BudgetTypeEnum.INCOME]: [],
    [BudgetTypeEnum.DEBT]: [],
    [BudgetTypeEnum.EXPENSE]: [],
  },
  id: "bobsunc90-2385235",
  firstName: "Jane",
  lastName: "Smith",
  email: "test@test.com",
  phone: "(804) 804-8044",
  profilePercentageComplete: 50,
  address: {
    street: "456 Another St",
    city: "Another City",
    state: "Another State",
    zipCode: "54321",
    country: "Another Country",
  },
  employmentStatus: "Employed",
  hhiConsistency: "Stable",
  householdIncome: 60000,
  verification: {
    email: {
      value: "",
      isPreferred: false,
      isVerified: false,
    },
    phone: {
      value: "",
      isPreferred: true,
      isVerified: true,
    },
  },
  callToActions: {},
  callToActionIds: [],
  preferredLanguage: "en",
  journeys: {
    "debt-management": {
      completedCallToActionIds: [],
      callToActions: {},
      totalEligibleDebt: 10,
      submittedDate: "",
    },
    "debt-settlement": {
      callToActions: {},
      completedCallToActionIds: [],
      totalEligibleDebt: 10,
      submittedDate: "",
    },
  },
  journeyIds: [],
  subStatus: ProfileSubStatusEnum.IDA_ENROLLMENT_STARTED,
  enrollmentPercentageComplete: 0,
  lastCompletedPage: VTPageTitlesEnum.CONSUMER_VERIFIED,
  expirationDate: new Date(),
  profileCreatedDate: new Date(),
  completedCallToActionIds: CallToActionIdEnum.VERIFY_PHONE,
  documents: {},
  status: ProfileStatusEnum.PENDING_CREDIT_ENROLLED,
};
