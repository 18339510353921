import { keyframes, css } from "@emotion/react";
import styled from "@emotion/styled";
import { autorun } from "mobx";
import { observer } from "mobx-react-lite";
import { FC, ReactElement, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useStore } from "@stores";

type ColorBarProps = {
  initialAnimationRunTime: string;
  color?: string;
  nextUserCtaAfterCreditPull: () => Promise<void>;
  children: ReactElement;
};

type DynamicColorBarProps = {
  isLoading: boolean;
  isSuccess: boolean;
  hasError: boolean;
  height: number;
  initialAnimationRunTime: string;
  color: string;
};

type LoaderProps = { isSuccess: boolean; lang: string };

const FillAnimation = keyframes`
  from {
    height: 0;
  }
  50% {
    height: 50%;
  }
  75% {
    height: 75%;
  }
  to {
    height: 90%;
  }
`;

const FillAnimation2 = (height: number) => {
  return keyframes`
  from {
    height: ${height}%;
    background-color: #cff3d3;
  }
  to {
    height: 100%;
  }
`;
};

const Spinner = keyframes`
  0% {
    transform: rotate(0deg);
  }
  30% {
    border-top: 16px solid black;
  } 
  60% {
    border-top: 16px solid green;
  }
  100% { 
    transform: rotate(360deg); 
  }
`;

const SpinnerTitle = keyframes`
  0% {
    content: "Gathering Your Report...";
  }
  25% {
    content: "Gathering Your Report...";
  }
  30% {
    content: "Analyzing Report...";
  }
  75% {
    content: "Analyzing Report...";
  }
  90% {
    content: "Calculating Savings...";
  }
  100% {
    content: "Calculating Savings...";
  }
`;

const SpinnerTitleEs = keyframes`
  0% {
    content: "Reuniendo datos de la cuenta...";
  }
  25% {
    content: "Reuniendo datos de la cuenta...";
  }
  30% {
    content: "Analizando informe...";
  }
  75% {
    content: "Analizando informe...";
  }
  90% {
    content: "Calculando ahorros...";
  }
  100% {
    content: "Calculando ahorros...";
  }
`;

const SpinnerTitleSuccess = keyframes`
  from {
    content: "Calculating Savings...";
  }
  to {
    content: "Calculating Savings...";
  }
`;

const SpinnerTitleSuccessEs = keyframes`
  from {
    content: "Calculando ahorros...";
  }
  to {
    content: "Calculando ahorros...";
  }
`;

const ColorBarContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: 500px;
  position: relative;
  overflow: hidden;
  bottom: 0;
`;

const Color = styled.div<DynamicColorBarProps>`
  position: absolute;
  bottom: 0;
  left: 0;
  min-height: ${(props) => props.height && props.height + "%"};
  width: 100%;
  background-color: ${(props) => props.color};
  animation: ${(props) => {
    return props.isLoading
      ? css`
          ${FillAnimation} ${props.initialAnimationRunTime}s linear forwards
        `
      : css`
          ${FillAnimation2(props.height)} 2s linear forwards
        `;
  }};
`;

const TextContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

const Parent = styled.div<{ isSuccess: boolean; hasError: boolean }>`
  margin: 0 auto;
  height: 300px;
  width: 400px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

const Loader = styled.div`
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #7cb9e8;
  width: 120px;
  height: 120px;
  animation: ${Spinner} 3s linear infinite;
`;

const Wrapper = styled.div`
  height: 300px;
  width: 300px;
  display: flex;
  justify-content: center;
  padding: 20px;
`;

const getLoaderText = ({ isSuccess, lang }: LoaderProps) => {
  if (isSuccess) {
    return css`
      ${lang === "es" ? SpinnerTitleSuccessEs : SpinnerTitleSuccess} 3s linear forwards
    `;
  }

  return css`
    ${lang === "es" ? SpinnerTitleEs : SpinnerTitle} 15s linear forwards
  `;
};

const LoaderText = styled.div<LoaderProps>`
  &::after {
    content: '';
    animation: ${(props) => getLoaderText(props)}
`;

export const AnimatedColorBar: FC<ColorBarProps> = observer(
  ({ color = "#cff3d3", nextUserCtaAfterCreditPull, initialAnimationRunTime, children }) => {
    const [loading, setLoading] = useState<boolean | undefined>(undefined);
    const {
      callToActionsStore: { loader, isRetry },
    } = useStore();
    const timer = useRef<undefined | ReturnType<typeof setTimeout>>(undefined);
    const initialTimer = useRef<number>(0);
    const heightToFillColorFrom = useRef(0);
    const {
      i18n: { language },
    } = useTranslation();

    const calculateHeight = (isLoadingSuccess: boolean) => {
      if (isLoadingSuccess && !heightToFillColorFrom.current) {
        const timeNow = new Date().getTime();
        const timeDiff = (timeNow - initialTimer.current) / 1000;
        const maxColorFillHeight = 90;
        const timeDiffPercentage = Math.min(
          maxColorFillHeight,
          Math.round((timeDiff / Number(initialAnimationRunTime)) * 100),
        );
        heightToFillColorFrom.current = timeDiffPercentage;
        return timeDiffPercentage;
      }
      return heightToFillColorFrom.current;
    };
    useEffect(() => {
      autorun(() => {
        if (loader.isLoading.get()) {
          initialTimer.current = new Date().getTime();
          setLoading(true);
        }
      });

      if (!timer.current) {
        if (loader.isSuccess.get() || loader.hasError.get()) {
          timer.current = setTimeout(async () => {
            await nextUserCtaAfterCreditPull();
            setLoading(false);
          }, 2000);
        }
      }
    }, [
      loader.isLoading,
      loader.isSuccess,
      loader.hasError,
      nextUserCtaAfterCreditPull,
      setLoading,
      initialAnimationRunTime,
    ]);

    useEffect(() => {
      if (isRetry) {
        timer.current = undefined;
      }
    }, [isRetry]);

    return (
      <>
        {loading ? (
          <ColorBarContainer>
            <Color
              color={color}
              isLoading={loader.isLoading.get()}
              isSuccess={loader.isSuccess.get()}
              hasError={loader.hasError.get()}
              height={calculateHeight(loader.isSuccess.get())}
              initialAnimationRunTime={initialAnimationRunTime}
            />
            <TextContainer>
              <Parent isSuccess={loader.isSuccess.get()} hasError={loader.hasError.get()}>
                <Container>
                  <Loader />
                  <Wrapper>
                    <LoaderText isSuccess={loader.isSuccess.get()} lang={language} />
                  </Wrapper>
                </Container>
              </Parent>
            </TextContainer>
          </ColorBarContainer>
        ) : (
          children
        )}
      </>
    );
  },
);
