import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { Chip } from "@mui/material";
import { useTranslation } from "react-i18next";

import { theme } from "@styles";
import { BudgetStatusEnum } from "@types";

interface BudgetStatusProps {
  type: BudgetStatusEnum;
  "data-cy"?: string;
}

const statusStyles = {
  pending: {
    styles: {
      backgroundColor: "#007bff",
      color: theme.palette.common.white,
    },
    icon: undefined,
  },
  started: {
    styles: {
      backgroundColor: "#ffc107",
      color: theme.palette.common.white,
    },
    icon: undefined,
  },
  completed: {
    styles: {
      backgroundColor: "#28a745",
      color: theme.palette.common.white,
      display: "flex",
      flexDirection: "row-reverse",
      borderRadius: "14px",
      cursor: "pointer",
      textAlign: "center",
    },
    icon: <CheckCircleOutlineIcon />,
  },
};

export const BudgetStatus = ({ type = BudgetStatusEnum.PENDING, "data-cy": dataCy }: BudgetStatusProps) => {
  const { t } = useTranslation("budget_tool", { keyPrefix: "status" });

  return (
    <Chip
      sx={{
        ...statusStyles[type].styles,
        fontWeight: 600,
        textTransform: "uppercase",
        fontSize: 12,
        height: 24,
        "& .MuiChip-label": {
          marginTop: 0.25,
        },
        "& .MuiChip-icon": {
          color: statusStyles[type].styles.color,
          fontSize: 16,
          marginRight: 1,
          marginLeft: -1,
          textAlign: "center",
        },
      }}
      icon={statusStyles[type].icon}
      data-cy={`${dataCy}-${type.toString()}`}
      label={t(type)}
    />
  );
};
