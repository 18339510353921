import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { PlanCard, Text } from "@components/common";
import { useStore } from "@stores";
import { formatToNearestDollar } from "@utils";

interface DebtSettlementPlanCardProps {
  condensed?: boolean;
}

export const DebtSettlementPlanCard = observer((props: DebtSettlementPlanCardProps) => {
  const {
    profileStore,
    callToActionsStore: { allDebtSettlementPlans: plans },
  } = useStore();
  const methods = useFormContext();
  const { t } = useTranslation("plan_option");

  const longestPlan = plans?.planDetails.slice().sort((a, b) => b.programTimePeriod - a.programTimePeriod)[0];

  useEffect(() => {
    if (methods && longestPlan && profileStore.profile?.id) {
      methods?.setValue("plan", {
        id: longestPlan.id,
        description: t("planDescription"),
      });
    }
  }, [longestPlan, methods, t, profileStore]);

  if (!longestPlan) return <></>;

  return (
    <PlanCard
      months={longestPlan.programTimePeriod}
      savings={longestPlan.savingsWithLegal >= 100 ? longestPlan.savingsWithLegal : undefined}
      {...props}
    >
      <Box
        data-cy="DebtSettlementPlan"
        sx={{
          p: "10px",
          mx: 2.5,
          borderRadius: 2,
          border: (theme) => `1px solid ${theme.palette.brandGreen.main}`,
          backgroundColor: (theme) => theme.palette.brandGreen.lighter,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: { xs: 0, md: props.condensed ? 0 : 1 },
          }}
        >
          <div>
            <Text
              data-cy="DebtSettlementPlan.monthlyPayment"
              color="green"
              fontSize="small"
              fontWeight="bold"
              sx={{ fontSize: { xs: "14px", md: props.condensed ? "14px" : "16px" } }}
            >
              {t("planCard.monthlyPayment")}
            </Text>
            <Text
              data-cy="DebtSettlementPlan.estimatedPayment"
              fontSize="xsmall"
              color="gray"
              sx={{ mt: 0.5, display: { xs: "none", md: props.condensed ? "none" : "block" } }}
            >
              *{t("planCard.estimatedPayment")}
            </Text>
          </div>
          <Box sx={{ position: "relative" }}>
            <Text
              fontSize="small"
              sx={{ position: "absolute", top: "2px", left: "-8px" }}
              fontWeight="bold"
              component="span"
              color="green"
            >
              *
            </Text>
            <Text
              data-cy="DebtSettlementPlan.estimatedPayment"
              color="green"
              component="span"
              fontWeight="bold"
              sx={{ fontSize: { xs: " 24px", md: props.condensed ? "24px" : "30px" }, lineHeight: 1 }}
            >
              ${formatToNearestDollar(longestPlan.estimatedPmtWithLegal)}
            </Text>
          </Box>
        </Box>
        <Text
          data-cy="DebtSettlementPlan.estimatedPayment"
          fontSize="xsmall"
          color="gray"
          sx={{ mt: 0, color: "#4a4a4a", mb: 1, display: { xs: "block", md: props.condensed ? "block" : "none" } }}
        >
          *{t("planCard.estimatedPayment")}
        </Text>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Text
            data-cy="DebtSettlementPlan.monthlyPayment"
            color="green"
            fontSize="small"
            fontWeight="bold"
            sx={{ fontSize: { xs: "14px", md: props.condensed ? "14px" : "16px" } }}
          >
            {t("planCard.newBalance")}
          </Text>
          <Text
            data-cy="DebtManagementPlanCard.estimatedDebtPayoff"
            color="green"
            component="span"
            fontWeight="bold"
            sx={{ fontSize: { xs: " 24px", md: props.condensed ? "24px" : "30px" }, lineHeight: 1 }}
          >
            ${formatToNearestDollar(longestPlan.estimatedDebtPayoff)}
          </Text>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Text color="gray" fontWeight="bold">
            {t("planCard.vs")}
          </Text>
          <div>
            <Text
              data-cy="DebtManagementPlanCard.totalEligibleDebt"
              color="gray"
              component="span"
              fontWeight="bold"
              fontSize="large"
              sx={{ textDecoration: "line-through" }}
            >
              ${formatToNearestDollar(plans.totalEligibleDebt)}
            </Text>
          </div>
        </Box>
      </Box>
    </PlanCard>
  );
});
