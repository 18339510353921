import { makeAutoObservable } from "mobx";

import { AxiosAgent } from "@services";
import { Engagement, EngagementStatus, DirectEntryEngagement, Servicer } from "@types";

import { LoadingStore } from "./LoadingStore.ts";
import { rollbar } from "../../rollbarConfig.ts";

export class EngagementStore {
  engagementLoading: boolean = false;
  loader: LoadingStore;
  private retrievedEngagement?: Engagement | DirectEntryEngagement = undefined;
  private retrievedDirectEntryEngagement?: DirectEntryEngagement = undefined;
  private retrievedServicer?: Servicer = undefined;

  constructor() {
    makeAutoObservable(this);
    this.loader = new LoadingStore();
  }

  get engagement() {
    if (this.retrievedEngagement) return this.retrievedEngagement;
    if (this.retrievedDirectEntryEngagement) return this.retrievedDirectEntryEngagement;
    if (localStorage.getItem("engagementData")) return JSON.parse(localStorage.getItem("engagementData") as string);
  }

  get servicer() {
    if (this.retrievedServicer) return this.retrievedServicer;
    if (localStorage.getItem("servicer")) return JSON.parse(localStorage.getItem("servicer") as string);
  }

  setEngagementLoading = (isLoading: boolean): void => {
    this.engagementLoading = isLoading;
  };

  clearEngagementStore = (): void => {
    this.retrievedEngagement = undefined;
  };

  setEngagement = (newEngagement: Engagement | DirectEntryEngagement): void => {
    this.retrievedEngagement = newEngagement;
  };

  setDirectEntryEngagement = (newEngagement: DirectEntryEngagement): void => {
    this.retrievedDirectEntryEngagement = newEngagement;
  };

  private setServicer = (servicer: Servicer): void => {
    window.localStorage.setItem("servicer", JSON.stringify(servicer));
    this.retrievedServicer = servicer;
  };

  getEngagementAndServicerById = async (id: string) => {
    try {
      this.loader.updateState("LOADING");
      const engagement = await this.getEngagementById(id);
      await this.getServicer(engagement);
      this.loader.updateState("SUCCESS");
      return engagement;
    } catch (error) {
      rollbar.debug(error as Error);
      this.loader.updateState("ERROR");
    }
  };

  getDirectEntryEngagementAndServicerById = async (id: string) => {
    try {
      this.loader.updateState("LOADING");
      const engagement = await this.getDirectEntryEngagementById(id);
      await this.getServicerForDirectEntryEngagement(engagement);
      this.loader.updateState("SUCCESS");
      return engagement;
    } catch (error) {
      rollbar.debug(error as Error);
      this.loader.updateState("ERROR");
      throw error;
    }
  };

  private getEngagementById = async (id: string) => {
    try {
      const engagement = await AxiosAgent.Engagement.getById(id);
      this.setEngagement(engagement);
      return engagement;
    } catch (error) {
      throw new Error("Error retrieving the engagement", error as Error);
    }
  };

  private getDirectEntryEngagementById = async (pid: string) => {
    try {
      const response = await AxiosAgent.DirectEntryEngagement.getById(pid);
      const engagement = {
        ...response,
        directEntryEngagement: true,
        directEntryEngagementId: response.id,
        ga_client_id: "",
        profile: {
          ...response.profile,
          contact_preference: "",
          language: "en",
        },
      };

      this.setDirectEntryEngagement(engagement);
      return engagement;
    } catch (error) {
      throw new Error("Error retrieving the engagement", error as Error);
    }
  };

  private getServicer = async (engagement: Engagement) => {
    try {
      const servicers = await AxiosAgent.Utils.getServicers(engagement.profile.address.state);
      let servicer;
      if (Array.isArray(servicers)) {
        servicer = servicers.find((s) => s.settings.programType === engagement.lead_type);
      } else {
        servicer = servicers;
      }
      if (servicer) {
        this.setServicer(servicer);
      } else {
        throw new Error("Servicer could not be found for the engagement.");
      }
    } catch (error) {
      throw new Error("Error retrieving the servicer", error as Error);
    }
  };

  private getServicerForDirectEntryEngagement = async (engagement: DirectEntryEngagement) => {
    try {
      const servicers = await AxiosAgent.Utils.getAllServicers();
      let servicer;
      if (Array.isArray(servicers)) {
        servicer = servicers.find((s) => s.settings.programType === engagement.lead_type);
      } else {
        servicer = servicers;
      }
      if (servicer) {
        this.setServicer(servicer);
      } else {
        throw new Error("Servicer could not be found for the direct entry engagement.");
      }
    } catch (error) {
      throw new Error("Error retrieving the servicer", error as Error);
    }
  };

  updateEngagementStatus = async (id: string, status: EngagementStatus) => {
    const engagement = await AxiosAgent.Engagement.updateStatus(id, status.toString());
    this.setEngagement(engagement);
  };
}
