import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { ButtonBranded } from "@components/common";
import { useStyles } from "@components/layout/Form/form.styles.ts";
import { useStore } from "@stores";
import { VTPageTitlesEnum } from "@types";
import { handlePercentageAndPageCompleted } from "@utils";

import { FooterLegalInfo } from "../../common/FooterLegalInfo";

interface ISelectPlanFooter {
  name: string;
}

export const SelectedPlanFooter = observer(({ name }: ISelectPlanFooter) => {
  const classes = useStyles({ name });

  const { t: planSelectedTranslation } = useTranslation("plan_selected");
  const {
    profileStore,
    modalStore: { closeModal },
  } = useStore();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);

    await handlePercentageAndPageCompleted(VTPageTitlesEnum.DONE, profileStore);

    closeModal();
  };

  return (
    <>
      <div className={classes.planSelectedFooter}>
        <div className={classes.planSelectedButtons}>
          <ButtonBranded
            loading={isLoading}
            data-cy="plan-selected-done-button"
            variant="primary"
            onClick={handleSubmit}
          >
            {planSelectedTranslation("buttonPrimary")}
          </ButtonBranded>
        </div>
      </div>
      <FooterLegalInfo />
    </>
  );
});
