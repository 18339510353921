import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTranslation } from "react-i18next";

import { ButtonBranded, Text } from "@components/common";
import { Copyright } from "@components/common/Copyright";
import { TermsAndPolicy } from "@components/common/TermsAndPolicy";
import { useSignInStyles } from "@components/views/Authentication/SignIn/SignInForm.styles.ts";
import { theme } from "@styles";
import { AuthProps } from "src/components/views/Authentication/SignUp";

import { VerificationSelection } from "../VerificationSelection";

export const SignInForm = ({ storedProfile, setPhone, verifyUser, children }: AuthProps) => {
  const { t } = useTranslation("verification_page");
  const classes = useSignInStyles();
  const matchWidth = useMediaQuery("(max-width:900px)");
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Box className={classes.contentViewContainer}>
      <div className={classes.viewContainer}>
        <div className={classes.header}>
          <Text fontSize="xxxxlarge" fontWeight="bold" lineHeight="xxxxxlarge" color="brandDarkBlue">
            {storedProfile?.firstName ? `${t("return")}, ${storedProfile.firstName}.` : t("greeting")}
          </Text>
        </div>
        <div className={classes.signIn}>
          <Text
            fontSize={matchWidth ? "xxlarge" : "xxxlarge"}
            fontWeight="semiBold"
            lineHeight={matchWidth ? "xxlarge" : "xxxlarge"}
            color="brandDarkBlue"
          >
            {t("sign-in")}
          </Text>
          {/* //TODO: The verification selection options and flow needs to be reworked to match the designs */}
          <VerificationSelection setPhone={setPhone}></VerificationSelection>

          {isDesktop && (
            <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
              <div className={classes.setButton}>
                <ButtonBranded variant={"primary"} onClick={verifyUser} data-cy="sign-in-continue-button">
                  {t("continueButton")}
                </ButtonBranded>
              </div>
            </Box>
          )}
        </div>
        {!isDesktop && (
          <div className={classes.setButton}>
            <ButtonBranded variant={"primary"} onClick={verifyUser} data-cy="sign-in-continue-button">
              {t("continueButton")}
            </ButtonBranded>
          </div>
        )}
        {children}
      </div>
      <div className={classes.termsTextContainer}>
        {/* @TODO: Use re-usable component instead */}
        <div>
          <TermsAndPolicy />
        </div>
        <div>
          <Copyright />
        </div>
      </div>
    </Box>
  );
};
