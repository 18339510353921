import { useRollbar } from "@rollbar/react";
import { useCallback, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useStore } from "@stores";
import {
  BudgetType,
  CreditAuthForm,
  FORM_TYPES,
  IBudgetItemData,
  initializeForm,
  JourneyId,
  PartialCreditAuthFormType,
  PlanSelectionForm,
  VTPageTitlesEnum,
} from "@types";
import { CTAModulesEnum, handlePercentageAndPageCompleted, JourneyCTAEnum, ProfileSubStatusEnum } from "@utils";

export const useFormActions = (name: string) => {
  const { t } = useTranslation("credit_authorization");
  const rollbar = useRollbar();
  const { profileStore, modalStore, callToActionsStore, remoteConfigStore, commonStore } = useStore();

  const [enableSsnValidation, setEnableSsnValidation] = useState(
    remoteConfigStore.featureFlags.enableSsnValidation ?? true,
  );

  useEffect(() => {
    setEnableSsnValidation(remoteConfigStore.featureFlags.enableSsnValidation ?? true);
  }, [remoteConfigStore.featureFlags.enableSsnValidation]);

  const form = initializeForm(name, t, enableSsnValidation);

  const methods = useForm<
    CreditAuthForm | PlanSelectionForm | PartialCreditAuthFormType | BudgetType<IBudgetItemData[]>
  >({
    mode: "onChange",
    defaultValues: form?.defaultValues,
    resolver: form?.resolver,
  });

  const selectPlanSubmitHandler = useCallback(
    async (data: PlanSelectionForm) => {
      if (profileStore.profile?.id && profileStore.journeyId) {
        await callToActionsStore.saveSelectedPlan(profileStore.profile.id, data);
        await callToActionsStore.updateCta(profileStore.profile.id, JourneyCTAEnum["select-plan"]);
        profileStore.updateEnrollmentStatus(ProfileSubStatusEnum.IDA_ENROLLMENT_COMPLETED);
        profileStore.setIsEnrollmentComplete(true);
      }
    },
    [callToActionsStore, profileStore],
  );

  const submitHandler: SubmitHandler<FORM_TYPES> = async (data) => {
    await selectPlanSubmitHandler(<PlanSelectionForm>data);
  };

  const nextUserCtaAfterCreditPull = async () => {
    const {
      ssnError,
      isRetry,
      loader: { hasError, isSuccess },
    } = callToActionsStore;
    const { setUserClosedModal } = commonStore

    // second try
    if (ssnError && !isRetry) {
      callToActionsStore.loader.updateState("EMPTY");
      callToActionsStore.setIsRetry(true);
      return modalStore.openModal(CTAModulesEnum["authorize-credit-pull"]);
    }
    setUserClosedModal(true);

    if (isSuccess.get()) {
      return modalStore.openModal(CTAModulesEnum["review-debt-eligibility"]);
    }

    // error or skip for DMP
    if ((hasError.get() && !ssnError) || (ssnError && isRetry)) {
      if (profileStore.journeyId === JourneyId.DEBT_MANAGEMENT && profileStore?.profile?.id) {
        try {
          await callToActionsStore.updateCta(profileStore.profile.id, JourneyCTAEnum["authorize-credit-pull"]);
          await callToActionsStore.updateCta(profileStore.profile.id, JourneyCTAEnum["verify-credit"]);
          const profile = await callToActionsStore.updateCta(
            profileStore.profile.id,
            JourneyCTAEnum["review-debt-eligibility"],
          );
          profileStore.setProfile(profile);
          await handlePercentageAndPageCompleted(VTPageTitlesEnum.BUDGET_TOOL, profileStore);
        } catch (error) {
          rollbar.error("Error when submitting credit auth", error as Error);
        }
        return modalStore.openModal(CTAModulesEnum["complete-your-budget"]);
      } else {
        return modalStore.openModal(CTAModulesEnum["call-for-help"]);
      }
    }
  };

  return {
    submitHandler,
    methods: methods,
    nextUserCtaAfterCreditPull,
  };
};
