import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Grid, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import { Text } from "@components/common";

import { useStyles } from "./SsnErrorCard.styles";

export const SsnErrorCard = () => {
  const classes = useStyles();
  const { t } = useTranslation("credit_authorization");

  return (
    <Grid container alignItems="center" className={classes.errorContainer}>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          gap: "8px",
        }}
      >
        <InfoOutlinedIcon color="warning" />
        <Text className={classes.errorHeader}>{t("ssnFailedHeader")}</Text>
      </Box>
      <Text fontSize={"small"} className={classes.errorDescription}>
        {t("ssnFailedDescription")}
      </Text>
    </Grid>
  );
};
