import { Button, Typography, Box } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { DrawerModal } from "@components/common/DrawerModal";
import { TermsEnVersion, TermsEsVersion } from "@components/layout/Footer";

import { useStyles } from "./TermsAndPolicy.styles";

export const TermsAndPolicy = () => {
  const {
    t: termsAndPolicyTranslation,
    i18n: { language },
  } = useTranslation("footer_links");
  const [showTermsOfUse, setShowTermsOfUse] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showEsign, setShowEsign] = useState(false);
  const classes = useStyles();

  const hideModal = () => {
    setShowTermsOfUse(false);
    setShowPrivacyPolicy(false);
    setShowEsign(false);
  };

  const displayTermsLangVersion = () => {
    if (language === "es") {
      return <TermsEsVersion hideModal={hideModal} />;
    }
    return <TermsEnVersion hideModal={hideModal} />;
  };

  const displayPrivacyPolicyLangVersion = () => {
    if (language === "es") {
      return <TermsEsVersion hideModal={hideModal} scrollToPrivacyPolicyRef={true} />;
    }
    return <TermsEnVersion hideModal={hideModal} scrollToPrivacyPolicyRef={true} />;
  };

  const displayEsignLangVersion = () => {
    if (language === "es") {
      return <TermsEsVersion hideModal={hideModal} scrollToEsignRef={true} />;
    }
    return <TermsEnVersion hideModal={hideModal} scrollToEsignRef={true} />;
  };

  return (
    <div className={classes.termsAndPolicyContainer}>
      <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
        <Box sx={{ display: "flex", flexDirection: "row", gap: "8px" }}>
          <Button
            className={classes.button}
            onClick={() => {
              setShowTermsOfUse(true);
              setShowPrivacyPolicy(false);
              setShowEsign(false);
            }}
          >
            {termsAndPolicyTranslation("termsOfUseButton")}
          </Button>
          <Typography variant="body2" component="span" sx={{ fontSize: "12px", display: "flex", alignItems: "center" }}>
            •
          </Typography>
          <Button
            className={classes.button}
            onClick={() => {
              setShowPrivacyPolicy(true);
              setShowTermsOfUse(false);
              setShowEsign(false);
            }}
          >
            {termsAndPolicyTranslation("privacyPolicyButton")}
          </Button>
        </Box>
        <Button
          className={classes.button}
          sx={{
            textTransform: "none !important",
          }}
          onClick={() => {
            setShowPrivacyPolicy(false);
            setShowTermsOfUse(false);
            setShowEsign(true);
          }}
        >
          {termsAndPolicyTranslation("esignButton")}
        </Button>
      </Box>

      <DrawerModal show={showTermsOfUse || showPrivacyPolicy || showEsign} setShow={hideModal}>
        <div>
          {showTermsOfUse && displayTermsLangVersion()}
          {showPrivacyPolicy && displayPrivacyPolicyLangVersion()}
          {showEsign && displayEsignLangVersion()}
        </div>
      </DrawerModal>
    </div>
  );
};
