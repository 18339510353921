import { computed, action, makeAutoObservable } from "mobx";

type STATES = "EMPTY" | "LOADING" | "ERROR" | "SUCCESS";

// loading object
export class LoadingStore {
  private state: STATES;
  constructor() {
    makeAutoObservable(this);
    this.state = "EMPTY";
  }

  isLoading = computed(() => this.state === "LOADING");
  isNotLoading = computed(() => this.state === "EMPTY");
  isSuccess = computed(() => this.state === "SUCCESS");
  hasError = computed(() => this.state === "ERROR");
  updateState = action((state: STATES) => (this.state = state));
}
