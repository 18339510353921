import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  termsAndPolicyContainer: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    margin: "0 auto",
    gap: "8px",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      height: "5vh",
      alignItems: "flex-start",
    },
  },
  button: {
    color: "#2f90af !important",
    padding: "0 !important",
    margin: "0 !important",
    "&.MuiButton-root": {
      textTransform: "capitalize",
      width: "initial",
      lineHeight: "initial",
      textDecoration: "none",
      textAlign: "start",
      paddingLeft: "0",
      fontSize: "12px",
      whiteSpace: "nowrap",
      fontWeight: "500",
    },
  },
}));
