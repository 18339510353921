import { makeAutoObservable } from "mobx";

import { AxiosAgent } from "@services";
import {
  FeatureFlag,
  FeatureFlagIds,
  FeatureFlagMap,
  FormatedFeatureFlagMap,
  Thresholds,
  Timer,
  TimerMap,
} from "@types";

import { store } from "./store";
import { rollbar } from "../../rollbarConfig";

export class RemoteConfigStore {
  remoteConfigs: unknown | undefined = undefined;
  timers: TimerMap = {};
  featureFlags: FormatedFeatureFlagMap = {};
  redirectUrl: string = "";
  creditPullAnimationRunTime: string = "";
  redirectHomePageDelay: number | undefined;
  threshold: number | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  get configsExistsInStorage() {
    const ids = [
      "feature-flags",
      "timers",
      "redirect-url",
      "credit-pull-animation-run-time",
      "redirect-home-page-delay",
    ];

    return ids.some((id) => sessionStorage.getItem(id));
  }

  setRemoteConfigs = (remoteConfigs: unknown): void => {
    this.remoteConfigs = remoteConfigs;
  };

  setTimers = (timersArray: Timer[]) => {
    this.timers = timersArray.reduce((acc, timer) => {
      acc[timer.id] = timer.value;
      return acc;
    }, {} as TimerMap);
  };

  setRedirectUrl = (url: string) => {
    this.redirectUrl = url;
  };

  setCreditPullAnimationRunTime = (creditPullAnimationRunTime: string) => {
    this.creditPullAnimationRunTime = creditPullAnimationRunTime;
  };

  setRedirectHomePageDelay = (redirectHomePageDelay: string) => {
    this.redirectHomePageDelay = Number(redirectHomePageDelay);
  };

  setThresholds = (thresholds: Thresholds) => {
    this.threshold = thresholds[store.profileStore.journeyId]?.value;
  };

  computeFeatureFlag = (featureFlags: FeatureFlagMap) => {
    const journeyIds = store.profileStore.profile?.journeyIds || [];

    const isFFEnabled = (ff?: FeatureFlag) => {
      if (ff?.global) return true;

      return journeyIds.some((journeyId) => {
        const enabledJourneys = Object.keys(ff?.journeys || {}).filter((k) => ff?.journeys[k]);
        return enabledJourneys.includes(journeyId);
      });
    };

    const formatedFF = Object.keys(featureFlags)
      .map((ffKey) => ({
        [ffKey]: isFFEnabled(featureFlags[ffKey as FeatureFlagIds]),
      }))
      .reduce((acc, item) => ({ ...acc, ...item }), {});
    return formatedFF;
  };

  setFeatureFlags = (ff: FeatureFlagMap) => {
    this.featureFlags = this.computeFeatureFlag(ff);
  };

  getAllRemoteConfigs = async (): Promise<void> => {
    try {
      const configs = await AxiosAgent.Configs.get();
      this.setRemoteConfigs(configs);
    } catch (error: unknown) {
      rollbar.debug("Error retrieving the remote configs", error as Error);
    }
  };

  private retrieveConfigFromApiOrStorage = async <T>(id: string, setStateCallback?: (item: T) => void) => {
    const config = JSON.parse(sessionStorage.getItem(id) as string) as T;

    if (config) {
      if (setStateCallback) setStateCallback(config);
    } else {
      try {
        const config = await AxiosAgent.Configs.getById(id);

        if (config) {
          if (setStateCallback) setStateCallback(config as T);
          sessionStorage.setItem(id, JSON.stringify(config));
        }
      } catch (error) {
        rollbar.debug(`Error retrieving config ${id}`, error as Error);
      }
    }
  };

  getTimerRemoteConfig = async () => this.retrieveConfigFromApiOrStorage("timers", this.setTimers);

  getRedirectUrlConfig = async () => this.retrieveConfigFromApiOrStorage("redirect-url", this.setRedirectUrl);

  getCreditPullAnimationRunTime = async () =>
    this.retrieveConfigFromApiOrStorage("credit-pull-animation-run-time", this.setCreditPullAnimationRunTime);

  getRedirectHomePageDelay = async () =>
    this.retrieveConfigFromApiOrStorage("redirect-home-page-delay", this.setRedirectHomePageDelay);

  getFeatureFlags = async () => this.retrieveConfigFromApiOrStorage("feature-flags", this.setFeatureFlags);

  getThresholds = async () => this.retrieveConfigFromApiOrStorage("thresholds", this.setThresholds);

  getConfigs = async () => {
    return Promise.all([
      this.getTimerRemoteConfig(),
      this.getFeatureFlags(),
      this.getRedirectUrlConfig(),
      this.getCreditPullAnimationRunTime(),
      this.getRedirectHomePageDelay(),
      this.getThresholds(),
    ]);
  };
}
