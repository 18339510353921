import { Grid } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { Text } from "@components/common";
import { useStore } from "@stores";

export const ChartNavigation = observer(() => {
  const { budgetStore } = useStore();
  const { t } = useTranslation("budget_tool");
  const { currGaugeCategoryIdx, gaugeCategories } = budgetStore;

  return (
    <Grid container justifyContent="center" alignItems="center" sx={{ marginTop: "-110px" }} position="relative">
      <Grid item xs={4}>
        <Text
          data-testid="pie-chart-nav-amount-section"
          textTransform="uppercase"
          textAlign="center"
          fontWeight="semiBold"
          fontSize="xsmall"
        >
          {t(`gaugeNav.${gaugeCategories[currGaugeCategoryIdx]}`)}
        </Text>
      </Grid>
    </Grid>
  );
});
