import { Grid, useMediaQuery } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { BudgetGauge, Module } from "@components/layout";
import {
  BUDGET_COLORS,
  BudgetToolAccordion,
  DMP_DEBT_OPTIONAL_ITEMS,
  DMP_EXPENSE_OPTIONAL_ITEMS,
  DMP_INCOME_OPTIONAL_ITEMS,
  EXPENSE_OPTIONAL_ITEMS,
  INCOME_OPTIONAL_ITEMS,
} from "@components/layout/BudgetTool";
import { BudgetToolFooter } from "@components/layout/Footer/BudgetToolFooter";
import { useStore } from "@stores";
import { theme } from "@styles";
import { BudgetTypeEnum, BudgetStatusEnum, GaugeDataValue, BudgetType, JourneyId } from "@types";

export const BudgetToolModule = observer(() => {
  const { t } = useTranslation("budget_tool");
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const { budgetStore, profileStore } = useStore();
  const { profile, updateIsBudgetPageVisited } = profileStore;
  const [expanded, setExpanded] = useState<BudgetTypeEnum | null>(null);
  const { totalValues, isEmptyData } = budgetStore;
  const isDMP = profileStore.journeyId === JourneyId.DEBT_MANAGEMENT;
  const isDSP = profileStore.journeyId === JourneyId.DEBT_SETTLEMENT;
  const gaugeData = Object.values(BudgetTypeEnum).reduce(
    (acc, v, i) => ({
      ...acc,
      [v]: {
        id: i,
        value: totalValues[v] || 0,
        color: BUDGET_COLORS[v],
        budgetType: v,
      },
    }),
    {},
  ) as BudgetType<GaugeDataValue>;

  useEffect(() => {
    budgetStore.setData(profile?.budget);
    async function updatePageVisit() {
      if (profile && !profile.isBudgetPageVisited) {
        if (isDSP) {
          budgetStore.setStatus(BudgetTypeEnum.DEBT, BudgetStatusEnum.COMPLETED);
        }
        await updateIsBudgetPageVisited();
      }
    }
    updatePageVisit();
  }, [profile, budgetStore, updateIsBudgetPageVisited, isDSP]);

  const toggleAccordion = (itemType: BudgetTypeEnum) => {
    if (expanded === itemType) {
      setExpanded(null);
    } else {
      setExpanded(itemType);

      if (budgetStore.statuses[itemType] === BudgetStatusEnum.PENDING) {
        budgetStore.setStatus(itemType, BudgetStatusEnum.STARTED);
      }
    }
  };

  const handleSectionSubmission = (itemType: BudgetTypeEnum) => {
    budgetStore.setStatus(itemType, BudgetStatusEnum.COMPLETED);
    const budgetTypes = Object.values(BudgetTypeEnum);
    const itemIndex = budgetTypes.indexOf(itemType);
    // lastElement
    if (itemIndex === budgetTypes.length - 1) {
      setExpanded(null);
    } else {
      toggleAccordion(budgetTypes[itemIndex + 1]);
    }
  };

  return (
    <Module data-cy="BudgetTool-box-container">
      <Module.Main data-cy="budgetTool-grid-container">
        <Module.Sidebar gap={0}>
          <Module.Title data-cy="budget-tool-header">{t("header")}</Module.Title>
          <Module.SubTitle m={0} pt={2} color="darkerGray">
            {t("subHeaderLabel")}
          </Module.SubTitle>
          <Module.Description>{t("subHeader")}</Module.Description>
        </Module.Sidebar>
        <Module.Content>
          <Grid container direction="column" alignItems="center">
            <Grid item>{isEmptyData ? <BudgetGauge /> : !expanded && <BudgetGauge data={gaugeData} />}</Grid>
            {profile && (
              <Grid item sx={{ width: "100%" }}>
                <BudgetToolAccordion
                  budgetType={BudgetTypeEnum.DEBT}
                  optionalItems={isDMP ? DMP_DEBT_OPTIONAL_ITEMS : []}
                  expanded={expanded === BudgetTypeEnum.DEBT}
                  onChange={() => toggleAccordion(BudgetTypeEnum.DEBT)}
                  handleSubmit={handleSectionSubmission}
                  sx={{ "&:before": { display: "none" } }}
                />

                <BudgetToolAccordion
                  budgetType={BudgetTypeEnum.INCOME}
                  optionalItems={isDMP ? DMP_INCOME_OPTIONAL_ITEMS : INCOME_OPTIONAL_ITEMS}
                  expanded={expanded === BudgetTypeEnum.INCOME}
                  onChange={() => toggleAccordion(BudgetTypeEnum.INCOME)}
                  handleSubmit={handleSectionSubmission}
                />
                <BudgetToolAccordion
                  budgetType={BudgetTypeEnum.EXPENSE}
                  optionalItems={isDMP ? DMP_EXPENSE_OPTIONAL_ITEMS : EXPENSE_OPTIONAL_ITEMS}
                  expanded={expanded === BudgetTypeEnum.EXPENSE}
                  onChange={() => toggleAccordion(BudgetTypeEnum.EXPENSE)}
                  handleSubmit={handleSectionSubmission}
                />
              </Grid>
            )}
          </Grid>
          {!isDesktop && <BudgetToolFooter />}
        </Module.Content>
      </Module.Main>
    </Module>
  );
});
