import axios from "axios";
import { makeAutoObservable } from "mobx";

import { AxiosAgent } from "@services";
import { DebtManagementPlanDetails, JourneyId, PlanSelectionForm } from "@types";
import { DebtSettlementPlanDetails, CreditResponse } from "@types";
import { GTMCreditPullSuccess, GTMCreditReportError, JourneyCTAEnum } from "@utils";

import { LoadingStore } from "./LoadingStore.ts";
import { store } from "./store.ts";
import { rollbar } from "../../rollbarConfig.ts";

export class ProfileCallToActions {
  loader: LoadingStore;
  creditResponse?: CreditResponse = undefined;
  selectedPlan?: DebtSettlementPlanDetails | DebtManagementPlanDetails = undefined;
  ssnError: boolean = false;
  hasRetried: boolean = false;
  debtLimit: boolean = false;
  isEligibleDebtHigher: boolean = false;
  isRetry: boolean = false;

  constructor() {
    makeAutoObservable(this);
    this.loader = new LoadingStore();
  }

  authorizeCreditPull = async (profileId: string) => {
    try {
      this.loader.updateState("LOADING");
      this.setSsnError(false);
      const data = await AxiosAgent.CallToActions.authorizeCreditPull(profileId);
      this.loader.updateState("SUCCESS");
      GTMCreditPullSuccess();
      this.setCreditResponse(data);
    } catch (error) {
      GTMCreditReportError();
      if ((store.profileStore.profile?.creditPullFails ?? 0) >= 1) {
        this.setIsRetry(true);
      }

      if (
        axios.isAxiosError(error) &&
        !this.isRetry &&
        error.response?.data?.message ===
          "No credit liability data found, resultStatusTypeOtherDescription - NoFileReturnedBadSsn"
      ) {
        this.setSsnError(true);
      }
      rollbar.error("Error occurred while authorizing credit pull", error as Error);
      this.loader.updateState("ERROR");
    }
  };

  get planInfo() {
    if (store.profileStore.journeyId === JourneyId.DEBT_SETTLEMENT) {
      return this.creditResponse?.plans[store.profileStore.journeyId].planDetails.find(
        (plan) => plan.primaryDefaultPlan,
      );
    } else if (store.profileStore.journeyId === JourneyId.DEBT_MANAGEMENT) {
      return this.creditResponse?.plans[store.profileStore.journeyId].planDetails;
    }
  }

  setCreditResponse = (data: CreditResponse | undefined) => {
    this.creditResponse = data;
    if (this.planInfo) {
      this.setSelectedPlan(this.planInfo);
    }
  };

  setSelectedPlan = (plan: DebtSettlementPlanDetails | DebtManagementPlanDetails) => {
    this.selectedPlan = plan;
  };

  setSsnError = (ssnError: boolean) => {
    this.ssnError = ssnError;
  };

  setIsRetry = (isRetry: boolean) => {
    this.isRetry = isRetry;
  };

  saveSelectedPlan = async (profileId: string, data: PlanSelectionForm) => {
    try {
      this.loader.updateState("LOADING");
      const planInfo = this.planInfo;

      if (planInfo) {
        if (data.plan.description) {
          planInfo.description = data.plan.description;
        }

        this.setSelectedPlan(planInfo);
        await AxiosAgent.CallToActions.saveSelectedPlan(profileId, store.profileStore.journeyId, {
          selectedPlan: planInfo,
          submittedBy: "user",
        });
        this.loader.updateState("SUCCESS");
      }
    } catch (error) {
      rollbar.debug("Error saving the selected plan", error as Error);
      this.loader.updateState("ERROR");
    }
  };

  get allDebtSettlementPlans() {
    const plan = this.creditResponse?.plans[JourneyId.DEBT_SETTLEMENT];

    if (plan && Object.keys(plan).length) return plan;

    return null;
  }

  get debtSettlementPlan(): DebtSettlementPlanDetails | null {
    const selectedPlan = store.profileStore?.journey?.selectedPlan;

    if (
      store.profileStore.journeyId === JourneyId.DEBT_SETTLEMENT &&
      selectedPlan &&
      Object.keys(selectedPlan).length
    ) {
      return selectedPlan as DebtSettlementPlanDetails;
    }

    return null;
  }

  get debtManagementPlan(): DebtManagementPlanDetails | null {
    const selectedPlan = store.profileStore?.journey?.selectedPlan;

    if (
      store.profileStore.journeyId === JourneyId.DEBT_MANAGEMENT &&
      selectedPlan &&
      Object.keys(selectedPlan).length
    ) {
      return selectedPlan as DebtManagementPlanDetails;
    }

    return null;
  }

  calculateDebtManagementPlan = async (profileId: string) => {
    this.loader.updateState("LOADING");
    try {
      await AxiosAgent.CallToActions.getDebtManagementPlan(profileId);
      await store.profileStore.updateProfile();
      this.loader.updateState("SUCCESS");
    } catch (error) {
      this.loader.updateState("ERROR");
      rollbar.error("Error retrieving debt management plan", error as Error);
      throw error;
    }
  };

  getCreditReport = async (profileId: string) => {
    this.loader.updateState("LOADING");
    try {
      const response = await AxiosAgent.CallToActions.getCreditReport(profileId);
      this.loader.updateState("SUCCESS");
      if (Array.isArray(response)) {
        const filteredResponse = response.filter((item) => {
          return item.plans && item;
        });
        this.setCreditResponse(filteredResponse[0]);
      } else {
        this.setCreditResponse(response);
      }
    } catch (error) {
      this.loader.updateState("ERROR");
      rollbar.error("Error retrieving credit report", error as Error);
      throw error;
    }
  };

  updateCta = async (profileId: string, ctaId: JourneyCTAEnum) => {
    return AxiosAgent.CallToActions.updateCta(profileId, ctaId);
  };

  private updatedTotalEligibleDebt: number | undefined = undefined;

  /** Provides the total eligible debt of selected tradelines  */
  get totalEligibleDebt() {
    const { journeyId } = store.profileStore;

    if (this.updatedTotalEligibleDebt !== undefined) {
      return this.updatedTotalEligibleDebt;
    }

    return this.creditResponse?.analysis[journeyId].totalEligibleDebt;
  }

  /** Provides the total eligible debt regardless of each tradeline's selected state */
  getUnmodifiedTotalEligibleDebt = () => {
    const { journeyId } = store.profileStore;

    const eligibleTradeLines = this.creditResponse?.analysis[journeyId].eligibleTradeLines;
    if (eligibleTradeLines?.length) {
      return eligibleTradeLines.reduce((total, tradeline) => {
        const balance = Number(tradeline.currentBalance);
        if (!isNaN(balance)) {
          total += balance;
        }
        return total;
      }, 0);
    }

    return undefined;
  };

  setUpdatedTotalEligibleDebt = (debt: number) => {
    this.updatedTotalEligibleDebt = debt;
  };

  get totalDebt() {
    const currency = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });

    if (this.totalEligibleDebt !== undefined) {
      return currency.format(this.totalEligibleDebt);
    }
    return "";
  }

  tradeLinesLoading = false;
  thresholdError = false;

  setLoadingTradeLines = (bool: boolean) => {
    this.tradeLinesLoading = bool;
  };

  setThresholdError = (bool: boolean) => {
    this.thresholdError = bool;
  };

  private controller: AbortController | null = null;

  updateSelectedTradelines = async (tradeLines: { [key: string]: boolean }) => {
    try {
      const { journeyId } = store.profileStore;

      // cancel previous request if one is ongoing
      if (this.controller) {
        this.controller.abort();
      }

      this.controller = new AbortController();
      const analysis = await AxiosAgent.CallToActions.updateSelectedTradeLines(
        store.profileStore.profile!.id!,
        tradeLines,
        journeyId,
        this.controller,
      );

      const { totalEligibleDebt } = analysis[journeyId];

      if (typeof totalEligibleDebt === "number" && !isNaN(totalEligibleDebt)) {
        this.setUpdatedTotalEligibleDebt(totalEligibleDebt);
      } else {
        throw new Error("Missing totalEligibleDebt");
      }
    } catch (error) {
      rollbar.error("Error toggling tradeline", error as Error);
    } finally {
      this.setLoadingTradeLines(false);
    }
  };
}
