import CheckIcon from "@mui/icons-material/Check";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { Box, Grid, Checkbox, Divider } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { Text } from "@components/common";
import { EligibleTradeLine } from "@types";
import { formatNumberToCurrencyString } from "@utils";

export const TradeLine = observer(
  ({
    tradeLine,
    toggleTradeLine,
    isChecked,
    displayCheckbox,
  }: {
    tradeLine: EligibleTradeLine;
    toggleTradeLine: () => void;
    isChecked: boolean;
    displayCheckbox: boolean;
  }) => {
    const { t } = useTranslation("debt_review");
    return (
      <Box
        sx={{ "&:hover": { cursor: displayCheckbox ? "pointer" : "auto" } }}
        data-cy={`${tradeLine.name}-${tradeLine.amount}`}
        key={`${tradeLine.name}-${tradeLine.amount}`}
        onClick={toggleTradeLine}
      >
        <Grid container display="flex" justifyContent="space-between" alignItems="center" paddingY={1}>
          <Grid item data-cy="debtInfo-row-name">
            <Grid container alignItems="center">
              {displayCheckbox && (
                <Grid item>
                  <Checkbox
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fill: (theme) => theme.palette.common.white,
                        border: "2px solid #74808b",
                        "&:hover": {
                          backgroundColor: (theme) => theme.palette.common.white,
                        },
                        borderRadius: 1,
                      },

                      "&.Mui-checked .MuiSvgIcon-root": {
                        fill: (theme) => theme.palette.brandGreen.dark,
                        backgroundColor: (theme) => theme.palette.brandGreen.lighter,
                        border: (theme) => `2px solid ${theme.palette.brandGreen.dark}`,
                      },
                    }}
                    checked={isChecked}
                    checkedIcon={<CheckIcon />}
                    disableRipple
                  />
                </Grid>
              )}
              <Grid item>
                {typeof tradeLine.name === "string" && tradeLine.name.length ? (
                  <Text fontSize="small" fontWeight="semiBold" lineHeight="medium">
                    {tradeLine.name}
                  </Text>
                ) : (
                  <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", textAlign: "center" }}>
                    <Text fontSize="small" fontStyle={"italic"} fontWeight="semiBold" lineHeight="medium">
                      {t("missingTradeline")}
                    </Text>
                    <WarningAmberOutlinedIcon sx={{ color: "#ffcc00" }} />
                  </Box>
                )}
                <Text
                  fontSize="xsmall"
                  fontWeight="medium"
                  sx={{
                    color: "#74808b",
                  }}
                >
                  {tradeLine.type}
                </Text>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Text fontSize="small" fontWeight="semiBold" lineHeight="medium">
              ${formatNumberToCurrencyString(tradeLine.amount)}
            </Text>
          </Grid>
        </Grid>
        <Divider sx={{ color: "black", width: "100%" }} />
      </Box>
    );
  },
);
