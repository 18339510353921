import { Drawer, useMediaQuery } from "@mui/material";
import { observer } from "mobx-react-lite";

import { FooterLegalInfo } from "@components/common";
import { useStore } from "@stores";
import { theme } from "@styles";

import { ESign } from "./ESign";

interface ESignDrawerProps {
  showEsign: boolean;
  setShowEsign: (show: boolean) => void;
  handleSubmit: () => void;
  isLoading: boolean;
}

export const ESignDrawer = observer(({ showEsign, setShowEsign, handleSubmit, isLoading }: ESignDrawerProps) => {
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const {
    modalStore: { setDeclineAgentDirectContact, showExtendModal },
  } = useStore();

  return (
    <Drawer
      anchor={isDesktop ? "right" : "bottom"}
      open={showEsign && !showExtendModal}
      onClose={() => {
        setShowEsign(false);
        setTimeout(() => setDeclineAgentDirectContact(false), 225);
      }}
      PaperProps={{
        sx: isDesktop
          ? { width: "400px", padding: 2, display: "flex", alignItems: "center", justifyContent: "center" }
          : undefined,
      }}
    >
      <ESign handleSubmit={handleSubmit} isLoading={isLoading} />
      {!isDesktop && <FooterLegalInfo />}
    </Drawer>
  );
});
