import { isCookieAllowed } from "@utils";

export const createsProfile = () => {
  if (!isCookieAllowed()) return;

  const eventID = Date.now().toString();
  window.fbq("track", "Purchase", { value: 71.2, currency: "USD" }, { eventID });
  window.fbq("trackCustom", "SAAS_Lead", { eventID });
};

export const submitPlan = () => {
  if (!isCookieAllowed()) return;

  const eventID = Date.now().toString();
  window.fbq("track", "Purchase", { value: 1.0, currency: "USD" }, { eventID });
  window.fbq("trackCustom", "SAAS_Plan_Submitted", { eventID });
};
