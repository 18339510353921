import { Box, Grid, SvgIcon } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import ChartCalculator from "@assets/icons/calculator.svg?react";
import ChartBackground from "@assets/icons/chart-background.svg?react";
import ChartSircle from "@assets/icons/chart-circle.svg?react";
import { Text } from "@components/common";
import { useStore } from "@stores";
import { BudgetTypeEnum } from "@types";
import { formatNumberToCurrencyString } from "@utils";

import { IChartBranded } from ".";

export const ChartCenter = observer(({ data }: IChartBranded) => {
  const { t } = useTranslation("budget_tool");
  const { budgetStore } = useStore();
  const { isEmptyData, currGaugeCategoryIdx, gaugeCategories } = budgetStore;
  const budgetType = Object.values(BudgetTypeEnum)[currGaugeCategoryIdx - 1];
  const amount = useMemo(() => {
    if (!data) return 0;
    if (currGaugeCategoryIdx === 0) {
      return (
        data[BudgetTypeEnum.INCOME]?.value - data[BudgetTypeEnum.DEBT]?.value - data[BudgetTypeEnum.EXPENSE]?.value
      );
    }
    return data[budgetType]?.value;
  }, [data, budgetType, currGaugeCategoryIdx]);

  const amountPerMonth = formatNumberToCurrencyString(amount);

  return !isEmptyData ? (
    <Grid
      container
      data-cy="pie-chart-center-amount"
      direction="column"
      display="flex"
      alignItems="center"
      position="relative"
      top={-154}
    >
      <Grid item xs={12} m={2}>
        <Text
          data-cy="pie-chart-center-amount-section"
          textTransform="uppercase"
          textAlign="center"
          fontWeight="semiBold"
          fontSize="xsmall"
        >
          {t(`gaugeTitle.${gaugeCategories[currGaugeCategoryIdx]}`)}
        </Text>
      </Grid>
      <Grid item xs={12}>
        <Text data-cy="budget-gauge-center-amount" fontWeight="bold" fontSize="xlarge">
          {t("amountPerMonth", { v: amountPerMonth })}
        </Text>
      </Grid>
    </Grid>
  ) : (
    <>
      <Box
        data-cy="pie-chart-empty-center-box"
        display="flex"
        justifyContent="center"
        alignItems="center"
        position="relative"
        top={-132}
      >
        <SvgIcon
          inheritViewBox
          component={ChartSircle}
          style={{ width: "65px", height: "65px", position: "absolute" }}
        />
        <SvgIcon
          inheritViewBox
          component={ChartCalculator}
          style={{ width: "42px", height: "42px", position: "absolute" }}
        />
        <SvgIcon
          inheritViewBox
          component={ChartBackground}
          style={{ width: "48px", height: "48px", position: "absolute" }}
        />
      </Box>
      <Box sx={{ marginLeft: "auto", marginRight: "auto", marginTop: "-70px", width: "126px" }}>
        <Text fontSize="xsmall" fontStyle="normal" fontWeight="light" textAlign="center" lineHeight="xsmall">
          {t("gaugeEmpty")}
        </Text>
      </Box>
    </>
  );
});
