(function () {
  const enableSandbox = import.meta.env.VITE_ROLLBAR_ENVIRONMENT !== "production";
  const tf = document.createElement("script");
  tf.type = "text/javascript";
  tf.async = true;
  tf.src =
    ("https:" == document.location.protocol ? "https" : "http") +
    "://api.trustedform.com/trustedform.js?field=xxTrustedFormCertUrl&ping_field=xxTrustedFormPingUrl&invert_field_sensitivity=true" +
    (enableSandbox && "&sandbox=true") +
    "&l=" +
    new Date().getTime() +
    Math.random();
  const s = document.getElementsByTagName("script")[0];
  s?.parentNode?.insertBefore(tf, s);
})();
