import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { SelectedPlanDetailsCard, Text } from "@components/common";
import { useStore } from "@stores";
import { ProfileSubStatusEnum, getServicerLogo } from "@utils";

import { useStyles } from "./PlanSelected.styles";

interface SupportMaterial {
  title: string;
  description: string;
}

export const PlanSelected: React.FC = observer(() => {
  const classes = useStyles();
  const { t, ready } = useTranslation("plan_selected");
  const { callToActionsStore, profileStore } = useStore();
  const [idaDeclined, setIdaDeclined] = useState(false);
  const support = t("material", {
    returnObjects: true,
    servicer: profileStore.profile?.servicer?.displayName,
  }) as SupportMaterial[];

  const servicer = profileStore.profile!.servicer!.id;

  const servicerLogo = getServicerLogo(servicer!);

  useEffect(() => {
    if (profileStore.profile?.subStatus === ProfileSubStatusEnum.IDA_DECLINED_SELECTION) {
      setIdaDeclined(true);
    }
  }, [profileStore.profile?.subStatus, callToActionsStore.selectedPlan]);

  return (
    <div className={classes.container}>
      <div className={classes.headerContainer}>
        {idaDeclined ? (
          <>
            <Text fontSize={"medium"} fontWeight={"semiBold"} color="accent">
              {t("ida_declined.header")}
            </Text>
            <Text data-cy="plan-selected-header" fontSize={"xxxxlarge"} fontWeight={"bold"} color="brandBlueOther">
              {t("ida_declined.subHeader")}
            </Text>
          </>
        ) : (
          <>
            <Text fontSize={"medium"} fontWeight={"semiBold"} color="accent">
              {t("nice")}
            </Text>
            <Text data-cy="plan-selected-header" fontSize={"xxxxlarge"} fontWeight={"bold"} color="brandBlueOther">
              {t("header")}
            </Text>
          </>
        )}
      </div>
      <div className={classes.content}>
        {idaDeclined ? (
          <Text fontSize={"large"} fontWeight={"semiBold"} data-cy="recommended-plan">
            {t("ida_declined.heading")}
          </Text>
        ) : (
          <Text fontSize={"xxlarge"} fontWeight={"semiBold"} color="brandBlueOther">
            {t("subHeader")}
          </Text>
        )}

        <SelectedPlanDetailsCard data-cy="plan-selected-card" pendingEnrollment />

        <div className={classes.svgContainer}>
          <div>
            <Text fontSize={"small"} fontWeight={"light"}>
              {t("vendorInfo")}
            </Text>
          </div>
          <img src={servicerLogo} alt="servicerLogo" />
        </div>

        <div className={classes.supportMaterial}>
          {ready &&
            support.map((item, idx) => {
              return (
                <div key={idx} className={classes.supportMaterialItem}>
                  <Text fontSize={"xlarge"} fontWeight={"semiBold"} color="brandBlueOther" lineHeight={"large"}>
                    {item.title}
                  </Text>
                  <Text fontSize={"small"} fontWeight={"light"} color="brandBlueOther" lineHeight={"xxsmall"}>
                    {item.description}
                  </Text>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
});
