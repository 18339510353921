import {
  ExpandMore,
  Help as HelpIcon,
  ErrorOutline as ErrorOutlineIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, Grid } from "@mui/material";
import { autorun } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Text, TradeLine } from "@components/common";
import { Module } from "@components/layout";
import { useStore } from "@stores";
import { TradeLines, EligibleTradeLine } from "@types";
import {
  CTAModulesEnum,
  GTMBelowDebtThreshold,
  GTMTradeLineDeselected,
  GTMTradeLineSelected,
  useDebounce,
} from "@utils";

import { DebtAlertModal } from "./DebtAlertModal";

export const DebtReviewModule = observer(() => {
  const {
    callToActionsStore,
    callToActionsStore: {
      setLoadingTradeLines,
      getCreditReport,
      updateSelectedTradelines,
      totalEligibleDebt,
      getUnmodifiedTotalEligibleDebt,
      thresholdError,
      setThresholdError,
    },
    profileStore: { profile, leadType, journeyId },
    modalStore: { setModalLoading, openModal },
    remoteConfigStore: { threshold },
  } = useStore();
  const [eligibleTradeLines, setEligibleTradelines] = useState<EligibleTradeLine[]>([]);
  const { t } = useTranslation("debt_review");
  const [expanded, setExpanded] = useState<boolean>(false);
  const [selectedTradeLines, setSelectedTradeLines] = useState<{ [key: string]: boolean }>({});
  const debouncedTradeLines = useDebounce(selectedTradeLines, 300);
  const [warningType, setWarningType] = useState<"" | "highDebt" | "lowDebt" | "belowQual">("");
  const [displayAlert, setDisplayAlert] = useState(false);
  const [displayCheckboxes, setDisplayCheckboxes] = useState(true);
  const [sendUpdate, setSendUpdate] = useState(false);

  useEffect(() => {
    if (eligibleTradeLines) {
      const initialSelectedTradeLines = eligibleTradeLines.reduce((obj: { [key: string]: boolean }, tradeLine) => {
        obj[tradeLine.accountIdentifier] = !tradeLine.deselected;
        return obj;
      }, {});

      setSelectedTradeLines(initialSelectedTradeLines);
    }
  }, [eligibleTradeLines]);

  const getTradeLineCreditorType = (id: string) => {
    const tradeline = eligibleTradeLines.find((tradeline) => tradeline.accountIdentifier === id);

    if (tradeline) return tradeline.creditorType;
  };

  const toggleTradeLine = (id: string) => () => {
    if (displayCheckboxes) {
      setLoadingTradeLines(true);
      setSendUpdate(true);
      const selected = !selectedTradeLines[id];
      const creditorType = getTradeLineCreditorType(id);

      if (selected) {
        GTMTradeLineSelected(leadType ?? "", creditorType ?? "");
      } else {
        GTMTradeLineDeselected(leadType ?? "", creditorType ?? "");
      }

      setSelectedTradeLines((tradeLines) => ({ ...tradeLines, [id]: !selectedTradeLines[id] }));
    }
  };

  useEffect(() => {
    if (Object.keys(debouncedTradeLines).length && warningType !== "belowQual" && sendUpdate) {
      updateSelectedTradelines(debouncedTradeLines);
    }
  }, [debouncedTradeLines, sendUpdate, updateSelectedTradelines, warningType]);

  // sets warnings based on the user's stated debt vs actual debt amount
  useEffect(() => {
    const debtAmount = profile?.debtAmount;
    const unmodifiedTotalEligibleDebt = getUnmodifiedTotalEligibleDebt();

    if (debtAmount && unmodifiedTotalEligibleDebt) {
      const highThresh = debtAmount * 1.2;
      const lowThresh = debtAmount * 0.85;

      if (unmodifiedTotalEligibleDebt >= highThresh) {
        setWarningType("highDebt");
      }

      if (unmodifiedTotalEligibleDebt <= lowThresh) {
        setWarningType("lowDebt");
      }

      if (threshold) {
        const isBelowQual = unmodifiedTotalEligibleDebt <= threshold;

        if (isBelowQual) {
          setWarningType("belowQual");
          setDisplayCheckboxes(false);
        }
      }
    }
  }, [leadType, profile?.debtAmount, setWarningType, threshold, totalEligibleDebt, getUnmodifiedTotalEligibleDebt]);

  // sets alert is user has deselected tradelines below a certain balance threshold
  useEffect(() => {
    if (threshold && totalEligibleDebt !== undefined) {
      const isBelowThreshold = totalEligibleDebt <= threshold;

      // only display the alert once while in an error state
      if (isBelowThreshold && !thresholdError && warningType && warningType !== "belowQual") {
        setDisplayAlert(true);
        setThresholdError(true);
        GTMBelowDebtThreshold();
      }

      // clear the error state after user has resolved the error
      if ((thresholdError && !isBelowThreshold) || warningType === "belowQual") {
        setDisplayAlert(false);
        setThresholdError(false);
      }
    }
  }, [leadType, setThresholdError, threshold, thresholdError, totalEligibleDebt, warningType]);

  useEffect(() => {
    autorun(() => {
      if (!callToActionsStore.creditResponse) {
        if (profile?.id) {
          setModalLoading(true);
          getCreditReport(profile.id).catch(() => {
            openModal(CTAModulesEnum["call-for-help"]);
          });
        }
      }
    });
  }, [profile, callToActionsStore, setModalLoading, openModal, getCreditReport]);

  useEffect(() => {
    autorun(() => {
      if (callToActionsStore.creditResponse) {
        const getTradeLines = (tradeLines: TradeLines[]) => {
          return tradeLines.map((item) => {
            return {
              name: item.currentCreditor.name,
              creditorType: item.creditorType,
              type: item.debtType,
              amount: Number(item.currentBalance),
              accountIdentifier: item.accountIdentifier,
              deselected: item.deselected,
            };
          });
        };

        const eligibleTradeLines = getTradeLines(
          callToActionsStore.creditResponse.analysis[journeyId].eligibleTradeLines,
        );

        setEligibleTradelines(eligibleTradeLines);

        setModalLoading(false);
      }
    });
  }, [callToActionsStore.creditResponse, journeyId, setModalLoading]);

  return (
    <Module>
      <Module.Main sx={{ overflowY: "hidden", p: 0, gap: 0 }}>
        <Module.Sidebar
          sx={{ overflow: { xs: "visible", md: "auto" }, padding: { xs: "32px 24px 0px 24px", md: "32px 24px" } }}
        >
          <Module.Title data-cy="DebtReviewModule-title">
            {t("greatNews")}
            <br />
            {t("header")}
          </Module.Title>

          <Module.Description fontWeight="medium" data-cy="DebtReviewModule-description">
            {t("description")}
          </Module.Description>

          <Accordion
            data-cy="DebtReviewModule-accordion-container"
            disableGutters
            elevation={0}
            sx={{ border: 1, borderColor: "gray" }}
            expanded={expanded}
            onChange={() => setExpanded(!expanded)}
          >
            <AccordionSummary expandIcon={<ExpandMore />} data-cy="DebtReviewModule-accordion-clickarea">
              <Grid container direction="row" alignItems="center" flexWrap="nowrap">
                <Grid item mr="4px">
                  <Text fontSize="medium" fontWeight="medium" data-cy="DebtReviewModule-accordion-header">
                    {t("accordion.header")}
                  </Text>
                </Grid>
                <Grid item height="24px">
                  <HelpIcon sx={{ width: "18px", color: (theme) => theme.palette.brandBlue.dark }} />
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails data-cy="DebtReviewModule-accordion-description">
              <Text fontSize="small" lineHeight="xxsmall">
                {t("accordion.description")}
              </Text>
            </AccordionDetails>
          </Accordion>
        </Module.Sidebar>
        <Module.Content
          sx={{ overflow: { xs: "visible", md: "auto" }, padding: { xs: "0px 24px 32px 24px", md: "32px 24px" } }}
        >
          <Grid container data-cy="tradelines-container">
            {warningType && (
              <Grid item sx={{ mb: 2 }}>
                <Card
                  data-cy="debt-review-high-warning"
                  variant="outlined"
                  sx={{
                    py: 1,
                    px: "12px",
                    backgroundColor: (theme) => theme.palette.brandYellow.light,
                    borderColor: (theme) => theme.palette.brandYellow.dark,
                  }}
                >
                  <Grid container direction="column">
                    <Grid item>
                      <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                          <Grid container spacing={1} alignItems="center">
                            <Grid item height="32px">
                              <ErrorOutlineIcon sx={{ color: (theme) => theme.palette.brandYellow.dark }} />
                            </Grid>
                            <Grid item>
                              <Text
                                fontSize="medium"
                                fontWeight="medium"
                                sx={{ color: (theme) => theme.palette.brandYellow.dark }}
                              >
                                {t(`warning.${warningType}.header`)}
                              </Text>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item height="32px">
                          <Box onClick={() => setWarningType("")} sx={{ cursor: "pointer" }}>
                            <CloseIcon sx={{ width: "18px", color: (theme) => theme.palette.brandYellow.dark }} />
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item ml={4}>
                      <Text fontSize="small" lineHeight="xsmall">
                        {t(`warning.${warningType}.description`)}
                      </Text>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            )}
            <Grid item width={1}>
              <Text
                data-cy="DebtReviewModule-rowsTitle"
                fontSize="small"
                fontWeight="semiBold"
                lineHeight="medium"
                color="gray"
              >
                {t("rowsTitle")}
              </Text>
            </Grid>
            <Grid item width={1}>
              <Box data-cy="debt-review-debt-list">
                {eligibleTradeLines.map((tradeLine) => (
                  <TradeLine
                    key={tradeLine.accountIdentifier}
                    tradeLine={tradeLine}
                    isChecked={selectedTradeLines[tradeLine.accountIdentifier] ?? false}
                    toggleTradeLine={toggleTradeLine(tradeLine.accountIdentifier)}
                    displayCheckbox={displayCheckboxes}
                  />
                ))}
                <DebtAlertModal isOpen={displayAlert} setIsOpen={setDisplayAlert} />
              </Box>
            </Grid>
          </Grid>
        </Module.Content>
      </Module.Main>
    </Module>
  );
});
