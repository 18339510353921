import { Box, useMediaQuery } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { SubmitHandler, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ButtonBranded, ESignDrawer } from "@components/common";
import { FooterLegalInfo } from "@components/common";
import { ConfirmationModalView } from "@components/layout/Footer/ConfirmationModalView.tsx";
import { useStyles } from "@components/layout/Form/form.styles.ts";
import { useStore } from "@stores";
import { theme } from "@styles";
import { FORM_TYPES, PIXEL_PARTNER_NAME, VTPageTitlesEnum } from "@types";
import {
  CTAModulesEnum,
  getServicerPhoneNumber,
  GTMAgentConnectAttempted,
  GTMUserCallNowClicked,
  handlePercentageAndPageCompleted,
  ProfileSubStatusEnum,
  GTMPlanSubmitted,
  GTMOffRampConfirmed,
  GTMCallNowShown,
} from "@utils";

import { rollbar } from "../../../../rollbarConfig.ts";
import * as pixel from "../../../utils/pixel.ts";
import { NextStep } from "../SelectPlan/index.ts";

interface ISelectPlanFooter {
  methods: UseFormReturn<FORM_TYPES>;
  onSubmit: SubmitHandler<FORM_TYPES>;
  name: string;
}

export const SelectPlanFooter = observer(({ methods, onSubmit, name }: ISelectPlanFooter) => {
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const { t } = useTranslation("plan_option");
  const classes = useStyles({ name });
  const {
    profileStore,
    modalStore: {
      closeModal,
      openModal,
      showOffRampModal,
      setShowOffRampModal,
      setDeclineAgentDirectContact,
      isWithinServicerHours,
    },
    remoteConfigStore: { featureFlags, redirectHomePageDelay },
  } = useStore();
  const { enableDigitalSignature, enableAgentDirectContact, enableSelectPlansOffRamp } = featureFlags;

  const [isLoading, setIsLoading] = useState(false);
  const [showEsign, setShowEsign] = useState(false);

  const shouldDirectConnect = enableAgentDirectContact && isWithinServicerHours;

  const handleNavigation = async () => {
    if (isDesktop) {
      openModal("MODULE_PLAN_SELECTED");
    } else {
      if (profileStore.profile?.engagementLeadType) {
        const engagementLeadType = profileStore.profile?.engagementLeadType;
        window.location.href = `tel:${getServicerPhoneNumber(engagementLeadType!)}`;
        if (redirectHomePageDelay) {
          setTimeout(closeModal, redirectHomePageDelay * 1000);
        }
      }
    }
  };

  const handleDirectConnect = async () => {
    const promises = [profileStore.updatePercentageCompleteAndLastPageCompleted(100, VTPageTitlesEnum.SELECT_PLAN)];

    if (onSubmit && !profileStore.isEnrollmentComplete) promises.push(methods?.handleSubmit(onSubmit)());

    await Promise.all(promises);

    if (profileStore.profile?.id && profileStore.isEnrollmentComplete) {
      if (!isDesktop) {
        await profileStore.updateUserProfileAPI(profileStore.profile.id, {
          callActionEnrolled: true,
        });
      }
      profileStore.invokeSweeper(profileStore.profile.id);
    }
  };

  const handleCallLater = async () => {
    handlePercentageAndPageCompleted(VTPageTitlesEnum.SELECT_PLAN, profileStore);

    if (onSubmit && !profileStore.isEnrollmentComplete) {
      methods?.handleSubmit(onSubmit)();
    }

    openModal("MODULE_PLAN_SELECTED");
  };

  const handleSubmit = async (directConnect?: boolean) => {
    try {
      setIsLoading(true);
      if (directConnect) {
        GTMAgentConnectAttempted();

        await handleDirectConnect();
        handleNavigation();
        if (!isDesktop) {
          GTMUserCallNowClicked(document.title, "positive");
        }
      } else {
        handleCallLater();
      }
    } catch (error) {
      rollbar.error(`Error in Select Plan: ${error}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleESign = (skip?: boolean) => {
    const { documents } = profileStore.profile ?? {};
    const documentStatus = documents?.NTLOI?.documentStatus;
    const directConnect = skip ? false : shouldDirectConnect;

    if (enableDigitalSignature && documentStatus !== "signed") {
      setShowEsign(true);
    } else {
      handleSubmit(directConnect);
    }
  };

  useEffect(() => {
    if (shouldDirectConnect && !isDesktop) {
      GTMCallNowShown();
    }
  }, [shouldDirectConnect, isDesktop]);

  return (
    <div className={classes.footer}>
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
          width: "100%",
        }}
      >
        {enableDigitalSignature && (
          <ESignDrawer
            showEsign={showEsign}
            setShowEsign={setShowEsign}
            handleSubmit={handleSubmit}
            isLoading={isLoading}
          />
        )}

        <Box
          sx={{
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className={classes.footerButtonContainer}>
            {shouldDirectConnect ? (
              <>
                {enableSelectPlansOffRamp && (
                  <ButtonBranded
                    data-cy="callMeButton"
                    variant="textonly"
                    id="direct-connect-call-me-later-button"
                    fontSize={theme.fontSizes.medium}
                    fontWeight={theme.fontWeights.medium}
                    lineHeight={theme.fontLineHeights.xxxsmall}
                    className={classes.debtReviewCallButton}
                    onClick={() => {
                      GTMPlanSubmitted("off-ramp");
                      if (profileStore.profile?.partnerName === PIXEL_PARTNER_NAME) {
                        pixel.submitPlan();
                      }
                      setDeclineAgentDirectContact(true);
                      handleESign(true);
                    }}
                  >
                    {t("footer.secondaryButtonLabel")}
                  </ButtonBranded>
                )}
                <ButtonBranded
                  loading={isLoading}
                  data-cy="select-plan-button"
                  className={classes.nextActionButton}
                  type={"submit"}
                  variant={"primary"}
                  onClick={() => {
                    if (isDesktop) {
                      GTMPlanSubmitted("okay");
                      if (profileStore.profile?.partnerName === PIXEL_PARTNER_NAME) {
                        pixel.submitPlan();
                      }
                    }

                    handleESign();
                  }}
                >
                  {isDesktop ? t("footer.desktopPrimaryButtonLabel") : t("footer.mobilePrimaryButtonLabel")}
                </ButtonBranded>
              </>
            ) : (
              <ButtonBranded
                loading={isLoading}
                id="call-me-later-button"
                data-cy="select-plan-button"
                className={classes.nextActionButton}
                type={"submit"}
                variant={"primary"}
                onClick={() => {
                  GTMPlanSubmitted("okay");
                  if (profileStore.profile?.partnerName === PIXEL_PARTNER_NAME) {
                    pixel.submitPlan();
                  }
                  handleESign();
                }}
              >
                {t("footer.callMeLater")}
              </ButtonBranded>
            )}

            {!isDesktop && (
              <Box mt={2} sx={{ position: "relative" }}>
                <NextStep />
              </Box>
            )}

            <ConfirmationModalView
              isOpen={showOffRampModal}
              handlePrimary={async () => {
                GTMOffRampConfirmed("Select-Plan");
                setShowOffRampModal(false);
                await profileStore.updateEnrollmentStatus(ProfileSubStatusEnum.IDA_DECLINED_SELECTION);
                openModal(CTAModulesEnum["plan-selected"]);
              }}
              handleSecondary={() => setShowOffRampModal(false)}
              handleCancel={() => setShowOffRampModal(false)}
              name={"select-plan"}
            />
          </div>
        </Box>
      </Box>
      <FooterLegalInfo />
    </div>
  );
});

export default SelectPlanFooter;
