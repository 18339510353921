import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    boxShadow: `${theme.customShadows.clickable} !important`,
    width: "100%",
    height: "100%",
    position: "fixed",
    top: 0,
    left: 0,
    borderRadius: "0px !important",

    [theme.breakpoints.up("md")]: {
      position: "relative",
      height: "calc(100vh - 128px)",
      borderRadius: "16px !important",
    },
  },
  header: {
    backgroundColor: "#FFF",
    padding: `${theme.spacing(0.5)} ${theme.spacing(2)}`,
    boxShadow: `${theme.customShadows.clickable} !important`,
    isolation: "isolate",
    alignItems: "center",
    height: "49px",
    [theme.breakpoints.up("md")]: {
      boxShadow: "none !important",
      borderWidth: 0,
      borderStyle: "solid",
      borderColor: "rgba(0, 0, 0, 0.12)",
      borderBottomWidth: "thin",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: "100%",
    gap: "20px",
    overflow: "auto",
  },
}));
