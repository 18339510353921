import { Backdrop, Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { ButtonVariants } from "@components/common";
import { ConfirmationModal } from "@components/views";
import { useStore } from "@stores";
import { theme } from "@styles";
import { GTMWorkflowExitConfirmed } from "@utils";

export const ConfirmationModalView = observer(
  ({
    isOpen,
    handlePrimary,
    handleSecondary,
    handleCancel,
    name,
  }: {
    isOpen: boolean;
    handlePrimary: () => void;
    handleSecondary?: () => void;
    handleCancel: () => void;
    name: string;
  }) => {
    const {
      modalStore: { closeModal, clickSource, setShowOffRampModal },
      profileStore: { getUserProfileFromAPI },
      sessionStore: { authUser },
      commonStore: { setUserClosedModal },
    } = useStore();

    const close = () => {
      getUserProfileFromAPI(authUser!.uid).then(() => {
        closeModal();
        setShowOffRampModal(false);
      });
    };

    const getTranslation = () => {
      switch (name) {
        case "complete-your-budget":
          return "budget_tool";
        case "debt-review":
          return "debt_review";
        case "credit-authorization":
          return "credit_authorization";
        case "select-plan":
        case "select-plan-tooltip":
          return "plan_option";
        case "sign-up":
          return "sign_up";
        default:
          return "debt_review";
      }
    };

    const { t } = useTranslation(getTranslation());

    const getHeader = () => {
      switch (name) {
        case "sign-up":
          return t("skipModal.areYouSure");
        case "credit-authorization":
          return t("modal.headers.noImpact");
        case "debt-review":
          return t("modal.headers.seeDebts");
        case "select-plan":
          return t("modal.headers.getHelpNow");
        case "select-plan-tooltip":
          return t("tooltip.header");
        case "complete-your-budget":
          return t("skipModal.areYouSure");
        default:
          return;
      }
    };

    const getMessage = () => {
      switch (name) {
        case "complete-your-budget":
          if (clickSource === "footer") {
            return t("skipModal.SkipHeader");
          } else {
            return t("skipModal.ExitHeader");
          }
        case "review-debt-eligibility":
        case "sign-up":
          return t("skipModal.minutesAway");
        case "credit-authorization":
          return t("modal.infoText.weUnderstand");
        case "debt-review":
          return t("modal.infoText.dontWorry");
        case "select-plan":
          return t("modal.infoText.clickAway");
        case "select-plan-tooltip":
          return t("tooltip.content");
        default:
          return;
      }
    };

    const getSummary = () => {
      switch (name) {
        case "complete-your-budget":
          return Object.values(t("skipModal.DescTwo", { returnObjects: true }));
        case "sign-up":
          return Object.values(t("skipModal.summary", { returnObjects: true }));
        case "credit-authorization":
          return Object.values(t("modal.summary.helpSummary", { returnObjects: true }));
        case "debt-review":
          return Object.values(t("modal.summary.helpSummary", { returnObjects: true }));
        case "select-plan":
          return Object.values(t("modal.summary.helpSummary", { returnObjects: true }));
        default:
          return;
      }
    };

    const getDisclaimer = () => {
      switch (name) {
        case "complete-your-budget":
          if (clickSource === "footer") {
            return t("skipModal.SkipDesc");
          } else {
            return t("skipModal.ExitDesc");
          }
        case "sign-up":
          return t("skipModal.disclaimer");
        case "credit-authorization":
          switch (clickSource) {
            case "footer":
              return t("modal.disclaimer.offrampDisclaimer");
            case "header":
              return t("modal.disclaimer.exitDisclaimer");
            case "skipButton":
              return;
            default:
              return t("");
          }
        case "debt-review":
          if (clickSource === "footer") {
            return t("modal.disclaimer.offrampDisclaimer");
          } else {
            return t("modal.disclaimer.exitDisclaimer");
          }
        case "select-plan":
          return t("modal.disclaimer.helpDisclaimer");
        default:
          return;
      }
    };

    const getPrimaryButtonText = () => {
      switch (name) {
        case "complete-your-budget":
          return clickSource === "header" ? t("skipModal.continue") : t("skipModal.yes");
        case "sign-up":
          return t("skipModal.preferACall");
        case "credit-authorization":
          switch (clickSource) {
            case "header":
              return t("modal.buttonText.primaryButtonLabelCloseOut");
            case "skipButton":
              return t("modal.buttonText.primaryButtonSkipModal");
            default:
              return t("modal.buttonText.primaryButtonLabel");
          }
        case "debt-review":
        case "select-plan":
          return clickSource === "header"
            ? t("modal.buttonText.primaryButtonLabelCloseOut")
            : t("modal.buttonText.primaryButtonLabel");
        case "select-plan-tooltip":
          return t("toolTip.buttonText.primaryButtonLabel");
        default:
          return "Next";
      }
    };

    const getSecondaryButtonText = () => {
      switch (name) {
        case "complete-your-budget":
          return clickSource === "header" ? t("skipModal.skipForNow") : t("skipModal.no");
        case "sign-up":
          return t("skipModal.noSeePlanOpts");
        case "credit-authorization":
        case "debt-review":
        case "select-plan":
          return clickSource === "header"
            ? t("modal.buttonText.secondaryButtonLabelCloseOut")
            : t("modal.buttonText.secondaryButtonLabel");
        default:
          return;
      }
    };

    const handlePrimaryButtonClick = () => {
      switch (name) {
        case "sign-up":
        case "complete-your-budget":
          handlePrimary();
          break;
        case "credit-authorization":
        case "debt-review":
        case "select-plan":
          clickSource === "header" ? handleCancel() : handlePrimary();
          break;
        case "select-plan-tooltip":
        default:
          handleCancel();
          break;
      }
    };

    const handleSecondaryButtonClick = () => {
      GTMWorkflowExitConfirmed(name);
      switch (name) {
        case "sign-up":
        case "complete-your-budget":
          if (clickSource === "header") {
            setUserClosedModal(true);
            close();
          } else handleSecondary?.();
          break;
        case "credit-authorization":
          if (clickSource === "header") {
            setUserClosedModal(true);
            close();
          } else {
            handleCancel();
          }
          break;
        case "debt-review":
        case "select-plan":
          if (clickSource === "header") {
            setUserClosedModal(true);
            close();
          } else {
            handleSecondary?.();
          }
          break;
        default:
          handleCancel();
          break;
      }
    };

    const handlePrimaryVariant = (): ButtonVariants => {
      switch (name) {
        case "select-plan-tooltip":
          return "secondary";
        case "sign-up":
        case "credit-authorization":
        case "debt-review":
        case "select-plan":
          return "primary";
        default:
          return "primary";
      }
    };

    return (
      <>
        <Backdrop
          sx={{
            color: theme.palette.background.overlay,
            zIndex: theme.zIndex.drawer + 1,
            justifyContent: "end",
          }}
          open={isOpen}
          onClick={handleCancel}
        >
          {isOpen && (
            <Box onClick={(e) => e.stopPropagation()}>
              <ConfirmationModal
                data-cy="confirmation-modal"
                header={getHeader()}
                infoText={getMessage()}
                summary={getSummary()}
                disclaimer={getDisclaimer()}
                primaryVariant={handlePrimaryVariant()}
                primaryButtonLabel={getPrimaryButtonText()}
                secondaryButtonLabel={getSecondaryButtonText()}
                primaryOnClick={handlePrimaryButtonClick}
                secondaryOnClick={handleSecondaryButtonClick}
              />
            </Box>
          )}
        </Backdrop>
      </>
    );
  },
);
