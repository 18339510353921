import { ServicerIdEnum } from "./servicers";

export enum JourneyId {
  DEBT_SETTLEMENT = "debt-settlement",
  DEBT_MANAGEMENT = "debt-management",
}

export enum JourneyName {
  DEBT_SETTLEMENT = "Debt Settlement",
  DEBT_MANAGEMENT = "Debt Management",
}

export const JourneyToServicerMap = {
  [JourneyId.DEBT_SETTLEMENT]: ServicerIdEnum.LIGHT_HOUSE_FINANCIAL,
  [JourneyId.DEBT_MANAGEMENT]: ServicerIdEnum.CONSOLIDATED_CREDIT,
} as const;
