import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { memo, useEffect, useRef } from "react";

import { useStyles } from "../TermsAndConditions.styles";

export const TermsEsVersion = memo(
  ({
    hideModal,
    scrollToPrivacyPolicyRef,
    scrollToEsignRef,
  }: {
    hideModal: () => void;
    scrollToPrivacyPolicyRef?: boolean;
    scrollToEsignRef?: boolean;
  }) => {
    const privatePolicyTargetRef = useRef<HTMLDivElement>(null);
    const disputeRef = useRef<HTMLDivElement>(null);

    const esignTargetRef = useRef<HTMLDivElement>(null);
    const classes = useStyles();

    useEffect(() => {
      if (scrollToPrivacyPolicyRef) {
        setTimeout(() => {
          privatePolicyTargetRef.current?.scrollIntoView({
            behavior: "instant",
            block: "start",
          });
        }, 100);
      }
      if (scrollToEsignRef) {
        setTimeout(() => {
          esignTargetRef.current?.scrollIntoView({
            behavior: "instant",
            block: "start",
          });
        }, 100);
      }
    }, [scrollToPrivacyPolicyRef, scrollToEsignRef]);

    const scrollIntoView = (element: React.RefObject<HTMLElement>) => {
      setTimeout(() => {
        element.current?.scrollIntoView({
          behavior: "instant",
          block: "start",
        });
      }, 100);
    };

    return (
      <div className={classes.container}>
        <style>
          {`
          a {
            color: blue;
            transition: color 0.3s ease;
          }
          a:active {
            color: lightblue;
          }
          a:hover {
            cursor: pointer;
          }
        `}
        </style>
        <div className={classes.header}>
          <IconButton aria-label="terms-of-use" onClick={() => hideModal()}>
            <Close />
          </IconButton>
        </div>
        <div className={classes.content}>
          <h2 style={{ margin: 0 }}>TÉRMINOS Y CONDICIONES DE DEBT.COM</h2>
          <p>
            <strong>Última actualización y fecha de vigencia: 30 de enero de 2025.</strong>
          </p>
          <h2>ACUERDO ENTRE EL USUARIO Y DEBT.COM</h2>
          <p>
            Estos Términos y Condiciones de Uso (“Acuerdo”) son un acuerdo legal entre usted y Debt.com, LLC (en
            adelante denominado “Propietario del sitio web”, “Nosotros”, “NOS”, “Nuestro”, “Debt.com”), el propietario y
            desarrollador de Debt.com (el “Sitio”). Al utilizar, registrarse o interactuar de cualquier otra forma con
            el Sitio de cualquier manera, incluso a través de cualquier servicio proporcionado a través de Debt.com,
            usted acepta estar sujeto a todos los términos y condiciones (los "Términos") establecidos en este Acuerdo.
            Usted comprende que no puede modificar estos términos y que debe aceptar todos los términos “TAL COMO
            ESTÁN”. Si decide no aceptar todos los Términos aquí establecidos, simplemente no registre su información,
            ni utilice ni interactúe con este Sitio.
          </p>
          <p style={{ fontWeight: "bold" }}>
            Este Acuerdo contiene un acuerdo de arbitraje vinculante en la Sección XVI titulada{" "}
            <span style={{ textDecoration: "underline" }}>RESOLUCIÓN DE DISPUTAS MEDIANTE ARBITRAJE VINCULANTE </span>n
            este Acuerdo, que establece que usted y el Propietario del sitio web, y cualquiera de los directores,
            empleados, afiliados, miembros o funcionarios del Propietario del sitio web, así como todos los proveedores
            independientes de servicios y tecnología que brindan servicios al Sitio o en el Sitio, acuerdan resolver
            todas las disputas que surjan de o estén relacionadas con su registro, uso y/o interacción con el Sitio de
            cualquier manera a través de un arbitraje individual vinculante y no en ningún entorno de arbitraje
            colectivo o masivo, y que usted y nosotros renunciamos a cualquier derecho a que cualquier disputa entre las
            partes se decida en un tribunal por un juez o un jurado. Tiene derecho a optar por no participar en nuestro
            acuerdo de arbitraje. Consulte la Sección XVI{" "}
            <a onClick={() => scrollIntoView(disputeRef)}>Resolución de disputas mediante arbitraje vinculante</a> e
            este Acuerdo. USTED ACEPTA QUE HA LEÍDO, ENTENDIDO Y ACEPTA ESTAR OBLIGADO POR ESTOS TÉRMINOS.
          </p>
          <p>
            El Sitio es un sitio web de educación y referencia financiera que proporciona materiales de educación
            financiera a los consumidores sobre cómo abordar adecuadamente sus finanzas y deudas y conecta a los
            consumidores con proveedores de servicios financieros independientes de terceros que brindan servicios
            financieros de alivio de deuda y ayudan a los consumidores a vivir vidas financieras más saludables. Usted
            comprende que cuando llama al número que aparece en nuestro Sitio, puede ser conectado con uno de nuestros
            representantes o puede ser conectado directamente con uno de nuestros socios o un tercero para ayudarlo. Los
            proveedores de servicios financieros independientes de terceros podrían cobrar tarifas por sus servicios y
            tener sus propios términos de servicio.{" "}
            <span style={{ fontWeight: "bold" }}>
              Debt.com no es responsable y no garantiza ningún resultado de estos proveedores de servicios financieros
              independientes de terceros. Debt.com no garantiza que los proveedores de servicios financieros
              independientes de terceros presten servicios en su estado.
            </span>
          </p>
          <p>
            Tenga en cuenta que la <a onClick={() => scrollIntoView(privatePolicyTargetRef)}>Política de Privacidad</a>{" "}
            de Debt.com se incorpora por referencia a este Acuerdo. La Política de Privacidad explica cómo recopilamos,
            protegemos, compartimos y utilizamos su información como parte de nuestro Sitio y de todos nuestros
            productos y servicios.
          </p>
          <h3>I. MODIFICACIÓN DE ESTOS TÉRMINOS DE USO</h3>
          <p>
            DEBT.COM SE RESERVA EL DERECHO, CON O SIN AVISO, DE REALIZAR CAMBIOS A LOS TÉRMINOS A SU DISCRECIÓN. USTED
            DEBE REVISAR ESTE ACUERDO PERIÓDICAMENTE PARA DETERMINAR SI SE HA REALIZADO ALGÚN CAMBIO. LA VERSIÓN MÁS
            ACTUAL DE ESTOS TÉRMINOS, QUE SE INDICARÁ POR LA “FECHA DE LA ÚLTIMA ACTUALIZACIÓN” DEL PRESENTE, REEMPLAZA
            A TODAS LAS VERSIONES ANTERIORES. INDEPENDIENTEMENTE DE SI HA REVISADO O NO LOS CAMBIOS A ESTOS TÉRMINOS,
            USTED RECONOCE EXPRESAMENTE QUE SU USO CONTINUO DE CUALQUIER PARTE DE ESTE SITIO O CUALQUIER FUNCIÓN DE
            NUESTRO SITIO CONSTITUYE SU ACEPTACIÓN DE TODOS LOS CAMBIOS DE TÉRMINOS DE ESTE ACUERDO.
          </p>
          <h3>II. ENLACES A SITIOS DE TERCEROS</h3>
          <p>
            Este Sitio puede contener enlaces a sitios web externos (“Sitios Vinculados”). Los Sitios Vinculados no
            están bajo el control ni la administración de Debt.com, y Debt.com no es responsable del contenido de
            ninguno de los Sitios Vinculados, incluidos, entre otros, los enlaces contenidos en un Sitio Vinculado o
            cualquier cambio o actualización de un Sitio Vinculado. Debt.com no es responsable de las transmisiones web
            ni de cualquier otra forma de transmisión recibida desde cualquier Sitio Vinculado. Debt.com le proporciona
            estos enlaces únicamente para su conveniencia, y la inclusión de cualquier enlace a Sitios Vinculados no
            implica el respaldo de Debt.com al Sitio Vinculado ni ninguna asociación con sus operadores.
          </p>
          <h3>III. EXCLUSIÓN DEL USO ILEGAL O PROHIBIDO</h3>
          <p>
            Como condición de su uso del Sitio, usted garantiza a Debt.com que no utilizará el Sitio para ningún
            propósito que sea ilegal, prohibido por ley y/o regulación, o que esté prohibido por estos Términos. No
            puede utilizar el Sitio de ninguna manera que pueda dañar, deshabilitar, sobrecargar, impactar negativamente
            o perjudicar el Sitio o interferir con el uso y disfrute del Sitio por parte de terceros. No puede obtener
            ni intentar obtener ningún material o información del Sitio a través de ningún medio que no esté
            intencionalmente disponible o proporcionado a través de Debt.com en este Sitio.
          </p>
          <h3>IV. USTED ES EL RESPONSABLE DE SUS DECISIONES FINANCIERAS</h3>
          <p>
            Debt.com, a través de nuestro Sitio y sitios afiliados, puede brindarle un lugar a través del cual puede
            obtener información financiera educativa, así como también aprender sobre varios{" "}
            <span style={{ fontWeight: "bold" }}>proveedores de servicios externos independientes </span>como
            instituciones financieras, proveedores de tarjetas de crédito, empresas de gestión o liquidación de deudas,
            proveedores de reparación de crédito, prestamistas y otros profesionales financieros (“
            <span style={{ fontWeight: "bold" }}>Proveedores de Servicios</span>”). Usted comprende y reconoce que
            Debt.com puede ser compensado por los Proveedores de Servicios por las referencias realizadas por medio de
            este Sitio. Debt.com no garantiza la exactitud o integridad de la información proporcionada en el Sitio o
            con respecto a los Proveedores de Servicios y no es responsable de ninguna pérdida que resulte de su
            confianza en dicha información.
          </p>
          <p>
            Usted acepta examinar la información proporcionada y realizar su propia investigación para determinar si los
            proveedores de servicios comercializados en este sitio se ajustan a su situación financiera. No respaldamos
            ni recomendamos los productos o servicios de ningún Proveedor de Servicios, y no somos un agente ni asesor
            para usted ni para ningún Proveedor de Servicios. No validamos ni investigamos las licencias,
            certificaciones u otros requisitos y calificaciones de los Proveedores de Servicios. Es su responsabilidad
            investigar a los proveedores de servicios con los que está considerando trabajar. Usted reconoce y acepta
            que los proveedores de servicios son los únicos responsables de cualquier servicio que puedan brindarle, y
            que Debt.com no es responsable de pérdidas, costos, daños o reclamos relacionados con su uso de los
            productos o servicios de cualquier proveedor de servicios. Antes de tomar cualquier decisión financiera, le
            instamos a obtener el asesoramiento de profesionales calificados (como asesores fiscales, contadores,
            abogados, etc.) que conozcan bien todas sus circunstancias individuales. Al interactuar con cualquier
            Proveedor de Servicios, usted reconoce y acepta que confía únicamente en su propio criterio y en el de
            dichos asesores al seleccionar cualquier producto o servicio ofrecido por los Proveedores de Servicios.
          </p>
          <h3>
            V. SIN GARANTÍA DE COTIZACIONES, TARIFAS, TÉRMINOS, TASAS, COBERTURA O SERVICIOS POR PARTE DE LOS
            PROVEEDORES DE SERVICIOS
          </h3>
          <p>
            No ofrecemos garantías ni representaciones de ningún tipo con respecto a las cotizaciones, tarifas,
            términos, tasas, cobertura o servicios ofrecidos o puestos a disposición por los Proveedores de Servicios a
            los que pueda ser remitido desde nuestro sitio. No garantizamos que las cotizaciones, comisiones, términos,
            tarifas, cobertura o servicios ofrecidos por los Proveedores de Servicios sean los mejores disponibles en el
            mercado. Es posible que pueda encontrar ofertas más adecuadas en otros lugares.
          </p>
          <h3>VI. USO DE SERVICIOS DE COMUNICACIÓN</h3>
          <p>
            El Sitio puede contener servicios de tablón de anuncios, áreas de chat, grupos de noticias, foros,
            comunidades, páginas web personales, calendarios y/u otras facilidades de mensajes o comunicación diseñadas
            para permitirle comunicarse con el público en general o con un grupo (colectivamente, “Servicios de
            Comunicación”). Usted acepta utilizar los Servicios de Comunicación únicamente para publicar, enviar y
            recibir mensajes y material relacionados con el contenido de este Sitio, los materiales educativos de este
            Sitio o los servicios promocionados o comercializados por este Sitio. A modo de ejemplo, y no como
            limitación, usted acepta que al utilizar un Servicio de Comunicación, bajo ninguna circunstancia:
          </p>
          <ul>
            <li>
              Difamar, abusar, acosar, acechar o amenazar a otras personas, o violar de algún otro modo los derechos
              legales (como los derechos de privacidad y publicidad) de otras personas.
            </li>
            <li>
              Publicar, cargar, distribuir o difundir cualquier tema, nombre, material o información inapropiados,
              soeces, difamatorios, ilícitos, obscenos, indecentes o ilegales.
            </li>
            <li>
              Cargar archivos que contengan software u otro material protegido por leyes de propiedad intelectual (o por
              derechos de privacidad o publicidad), a menos que sea propietario o controle los derechos de dichos
              materiales o haya recibido todos los consentimientos necesarios.
            </li>
            <li>
              Cargar archivos que contengan virus o estén corruptos, o cualquier otro software o programa similares que
              puedan perjudicar el funcionamiento de la computadora de otra persona.
            </li>
            <li>
              Publicitar u ofrecer vender o comprar bienes o servicios para cualquier fin comercial, a menos que dicho
              Servicio de Comunicación permita específicamente dichos mensajes.
            </li>
            <li>Realizar o reenviar encuestas, concursos, esquemas piramidales o cartas en cadena.</li>
            <li>
              Descargar cualquier archivo publicado por otro usuario de un Servicio de Comunicación que usted
              razonablemente debería saber o creer razonablemente que no puede distribuirse legalmente de esa manera.
            </li>
            <li>
              Falsificar o eliminar cualquier atribución de autor, avisos legales u otros avisos adecuados o
              designaciones o etiquetas de propiedad exclusiva sobre el origen o la fuente del software u otro material
              contenido en un archivo que se cargue.
            </li>
            <li>Restringir o inhibir a cualquier otro usuario el uso y goce de los Servicios de Comunicación.</li>
            <li>
              Violar cualquier código de conducta u otras pautas que puedan ser aplicables a cualquier Servicio de
              Comunicación en particular.
            </li>
            <li>
              Recolectar o recopilar de algún otro modo información sobre otras personas, incluidas direcciones de
              correo electrónico, sin su consentimiento.
            </li>
            <li>Violar los Términos de este Acuerdo y/o los términos de la Política de Privacidad de este Sitio.</li>
            <li>Violar cualquier ley o reglamento aplicable.</li>
          </ul>
          <p>
            Debt.com no tiene obligación de supervisar los Servicios de Comunicación. Sin embargo, Debt.com se reserva
            el derecho de revisar los materiales publicados en los Servicios de Comunicación y eliminarlos a su
            exclusivo criterio. Debt.com se reserva el derecho de cancelar su acceso a cualquiera de los Servicios de
            Comunicación en cualquier momento sin previo aviso y por cualquier motivo. Debt.com se reserva el derecho en
            todo momento de divulgar cualquier información según sea necesario para cumplir con cualquier ley,
            reglamento, proceso legal o solicitud gubernamental aplicable, o negarse a publicar o eliminar cualquier
            información o material, en su totalidad o en parte, a criterio exclusivo de Debt.com.
          </p>
          <p>
            Se le recomienda tener siempre cuidado al proporcionar cualquier información de identificación personal
            (“PII”) sobre usted, su cónyuge, sus hijos o su hogar en general en cualquier Servicio de Comunicación,
            incluso si se ofrece en este Sitio. Debt.com no controla ni respalda el contenido, los mensajes o la
            información que se encuentra en cualquier Servicio de Comunicación y, por lo tanto, Debt.com niega
            específicamente todas las garantías y responsabilidades asociadas con, o en relación con, los Servicios de
            Comunicación, incluidas, entre otras, cualquier acción resultante de su participación en cualquier Servicio
            de Comunicación. Los gerentes y hosts no son portavoces autorizados de Debt.com, y sus puntos de vista no
            reflejan necesariamente los de Debt.com. Los materiales cargados en un Servicio de Comunicación pueden estar
            sujetos a limitaciones de tamaño de publicación, limitaciones de uso, reproducción y/o difusión. Usted es
            responsable de cumplir con dichas limitaciones si descarga los materiales.
          </p>
          <h3>VII. MATERIALES SUMINISTRADOS A DEBT.COM O PUBLICADOS EN CUALQUIER SITIO WEB DE DEBT.COM</h3>
          <p>
            Debt.com no reclama la propiedad de los materiales que usted proporciona a Debt.com (incluidos los
            comentarios y las sugerencias) o que publica, carga, ingresa o envía a cualquier Sitio de Debt.com o sus
            servicios asociados (colectivamente, “Envíos”). Sin embargo, al publicar, cargar, ingresar, enviar o
            proporcionar su Envío, usted está otorgando a Debt.com, sus compañías afiliadas y afiliados necesarios de
            Debt.com una licencia no exclusiva, libre de regalías y perpetua para usar su Envío y/o sus contenidos en
            relación con el funcionamiento del Sitio o negocio, incluidos, entre otros, los derechos a: copiar,
            distribuir, transmitir, exhibir públicamente, ejecutar públicamente, reproducir, editar, traducir y
            reformatear su Envío; y publicar su nombre en relación con su Envío.
          </p>
          <p>
            No se pagará ninguna compensación con respecto al uso de su Envío, según lo dispuesto en este documento.
            Debt.com no tiene ninguna obligación de publicar o utilizar ningún Envío que usted pueda proporcionar, y
            puede eliminar cualquier Envío en cualquier momento a su exclusivo criterio.
          </p>
          <p>
            Al publicar, cargar, ingresar, proporcionar o enviar su Envío, usted garantiza y declara que posee o
            controla de algún otro modo todos los derechos sobre su Envío tal como se describe en esta sección,
            incluidos, entre otros, todos los derechos necesarios para que usted proporcione, publique, cargue, ingrese
            o envíe los Envíos.
          </p>
          <h3>VIII. USO DE COMUNICACIONES ELECTRÓNICAS</h3>
          <p style={{ fontWeight: "bold" }}>
            <b>
              Todas las interacciones con el sitio web y las llamadas telefónicas se registran y graban con fines de
              marketing, cumplimiento normativo y control de la calidad.
            </b>
          </p>
          <ol type="a">
            <li>
              <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
                Cumplimiento de la Ley de Protección al Consumidor Telefónico
              </span>{" "}
              <p>
                Debt.com se toma con la máxima seriedad el cumplimiento de la Ley de Protección al Consumidor Telefónico
                (“TCPA”). Por lo tanto, todos los procedimientos de TCPA se realizan de conformidad con una estricta
                política de cumplimiento de TCPA de la Compañía. Esta disposición describe nuestros requisitos generales
                de consentimiento y reconocimientos a los que estará sujeto en caso de que envíe un formulario de
                consentimiento de TCPA o un formulario de contacto solicitando una comunicación nuestra. Al
                proporcionarnos su nombre, correo electrónico, número de teléfono en el formulario de envío que contiene
                la divulgación de TCPA y firmar con su firma E-Sign, usted autoriza expresamente a Debt.com, LLC a
                comunicarse con usted (incluso mediante marcadores automáticos, mensajes de texto automatizados y
                mensajes artificiales o pregrabados) a través de su teléfono, teléfono celular, dispositivo móvil
                (incluso a través del Servicio de mensajes cortos (“SMS”) o Internet inalámbrico (“Servicio WAP”)) y
                chats de bots o por correo electrónico, incluso si su número de teléfono figura actualmente en cualquier
                lista estatal o federal de No Llamar. En el caso de que usted haya solicitado previamente estar en la
                Lista de No Llamar de la Compañía, usted comprende que incluso si envía una solicitud para que lo llamen
                nuevamente, la política de la Compañía es no contactar a nadie que haya solicitado estar en la Lista de
                No Llamar de la Compañía. Usted comprende que pueden aplicarse cargos estándar por teléfono y datos. No
                se requiere su consentimiento de TCPA y no está obligado a completar un formulario de consentimiento de
                TCPA como condición para recibir nuestros servicios. También acepta la grabación y el seguimiento de
                todas las llamadas hechas por usted a nosotros y hechas por nosotros a usted. Usted declara que es el
                propietario o usuario autorizado del dispositivo móvil y del número de teléfono asociado con ese
                dispositivo móvil al que realizaremos llamadas telefónicas y en el que se recibirán mensajes, y que está
                autorizado a aprobar todos los cargos aplicables asociados con la recepción de mensajes nuestros.{" "}
                <strong>
                  Puede optar por no recibir llamadas y/o mensajes en su teléfono o dispositivo móvil en cualquier
                  momento mediante cualquier método razonable, incluido responder a un mensaje de texto con cualquiera
                  de las siguientes palabras: "detener", "salir", "finalizar", "revocar", "excluir", "cancelar" o "darse
                  de baja", o notificándonos a <a href="mailto:admin@Debt.com">admin@Debt.com</a>, o mediante nuestro
                  método interactivo o automatizado de revocación por voz o pulsación de teclas si está en la llamada
                  con nosotros, y puede optar por no recibir correos electrónicos haciendo clic en el enlace "excluir"
                  en la parte inferior del correo electrónico.
                </strong>{" "}
                Usted reconoce y acepta que sólo los residentes de los Estados Unidos pueden usar el Servicio SMS o WAP
                para comunicarse con nosotros.
              </p>
            </li>
            <li>
              <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
                Seguridad de datos de comunicaciones mediante servicios SMS y WAP
              </span>{" "}
              <p>
                Los Servicios SMS o WAP se proporcionan mediante sistemas inalámbricos que utilizan frecuencias de radio
                (y otros medios) para transmitir comunicaciones por redes complejas. Debt.com no garantiza que su uso de
                los Servicios SMS o WAP será privado o seguro, y Debt.com no será responsable ante usted bajo ninguna
                circunstancia por cualquier falta de privacidad o violación de seguridad causada a usted o que pueda
                experimentar al enviar o recibir Servicios SMS o WAP usando su dispositivo móvil. Usted es totalmente
                responsable de tomar todas las precauciones y garantizar que se tomen medidas de seguridad al utilizar
                el servicio SMS o WAP. Usted reconoce y acepta que Debt.com puede obtener de la cuenta inalámbrica de su
                proveedor información sobre el dispositivo que está utilizando con su proveedor con el fin de
                identificar y resolver problemas técnicos y mejorar su experiencia de servicio con nuestro Sitio. Revise
                nuestra
                <a onClick={() => scrollIntoView(privatePolicyTargetRef)}> Política de Privacidad</a> para obtener
                información adicional que rige la privacidad de su información personal.
              </p>
            </li>
            <li>
              <span style={{ fontWeight: "bold", textDecoration: "underline" }}>Instant Debt Advisor℠</span>{" "}
              <p>
                El Sitio incluye una aplicación SaaS conocida como Instant Debt Advisor℠ recientemente desarrollada por
                All Clear Decisioning, LLC (“All Clear”), una empresa líder en desarrollo de herramientas y plataformas
                SaaS de tecnología financiera. El Instant Debt Advisor℠ (“Asesor de Deuda”) le hace preguntas simples y
                obtiene de usted cierta información personal identificable, que incluye, entre otros:
                <span style={{ fontWeight: "bold", fontStyle: "italic" }}>
                  {" "}
                  su nombre legal completo, dirección, número de teléfono, número de seguro social, aprobación para
                  hacer una consulta suave a su crédito, finanzas actuales y otra información relevante sobre su deuda y
                  situación financiera.{" "}
                </span>
                Luego, con la información que usted proporcionó mientras utiliza tecnología patentada de toma de
                decisiones automatizada, el Asesor de Deuda analiza sus finanzas y su situación financiera general,
                analiza posibles programas o productos que probablemente sean beneficiosos para usted de proveedores de
                servicios examinados y le brinda opciones de productos o soluciones para permitirle tomar el control de
                sus finanzas y mejorar su situación financiera.
              </p>
              <p>
                Usted comprende y acepta que All Clear, como desarrollador de la plataforma Asesor de Deuda, puede tener
                acceso a la información que ingresa o proporciona al Asesor de Deuda. Además de la política de
                privacidad de Debt.com, la información también estará sujeta a la política de privacidad de All Clear.
                Para obtener más información sobre la Política de Privacidad de All Clear, visite
                <a href="https://allclear.ai/legal-notices/#privacy-policy" target="_blank" rel="noopener noreferrer">
                  {" "}
                  Política de Privacidad de All Clear
                </a>
                .
              </p>
              <p>
                La toma de decisiones automatizada del Asesor de Deuda depende en gran medida de sus respuestas a las
                preguntas y de la precisión de la información ingresada al Asesor de Deuda. Usted comprende y acepta que
                usted, y sólo usted, es responsable de la información ingresada y de las respuestas dadas a las
                consultas del Asesor de Deuda. No responder de forma precisa o completa a las consultas del Asesor de
                Deuda afectará sus resultados.
              </p>
              <p>
                Usted acepta que la información que proporcionará en el Asesor de Deuda le pertenece a usted o es una
                persona autorizada que tiene autoridad para proporcionar la información en nombre de otra persona. Usted
                acepta que utilizará el Asesor de Deuda únicamente para uso personal y que bajo ninguna circunstancia lo
                utilizará para actividades comerciales, financieras o de cualquier otra índole de la cual pueda obtener
                un beneficio financiero. Tiene prohibido proporcionar información de otra persona sin la autorización de
                esa persona y, en caso de que se determine que ha violado esta prohibición, usted comprende que Debt.com
                y/o All Clear pueden informar sus acciones a las autoridades.
              </p>
              <p>
                También comprende y acepta que si proporciona intencionalmente información falsa o inexacta, o
                información personal que no le pertenece, deberá indemnizar y eximir de responsabilidad a Debt.com, All
                Clear y cualquier proveedor externo de todos los daños, reclamos, demandas o juicios que surjan de, o
                estén relacionados con su información falsa o inexacta, o suplantación no autorizada de otro consumidor,
                incluidos, entre otros, todos y cada uno de los honorarios de abogados (incluidos los honorarios de
                abogados internos) y los costos gastados como resultado de su conducta.
              </p>
              <p>
                Además, usted comprende y acepta que ni Debt.com ni All Clear son sus proveedores de servicios ni serán
                responsables de brindarle servicios. Se le solicita, y debe, realizar una revisión independiente de cada
                una de las opciones proporcionadas por el Asesor de Deuda y examinar de forma independiente el mejor
                proveedor de servicios que se adapte a su situación. Usted comprende que Debt.com y/o All Clear no
                pueden influir en los resultados de ningún producto o servicio que usted elija, y que puede optar por
                consultar primero con un asesor profesional antes de registrarse o utilizar cualquier proveedor de
                servicios o producto que le recomiende el Asesor de Deuda automatizado. Bajo ninguna circunstancia
                Debt.com y/o All Clear serán responsables ante usted por problemas presentados por un proveedor de
                servicios, y usted comprende que el Asesor de Deuda es simplemente una herramienta de referencia basada
                en información proporcionada por usted y procesada en su nombre.
              </p>
              <p>
                La plataforma Asesor de Deuda de SaaS se proporciona TAL CUAL con todos los fallos, errores de código,
                problemas de diseño, problemas de software u otros problemas que puedan verse afectados por su navegador
                o nuestro Sitio. Ni Debt.com ni All Clear serán responsables de ningún problema de latencia, error en
                los resultados, problemas en el envío o de otro modo que surja de o esté relacionado con su uso del
                Asesor de Deuda.
              </p>
              <p>
                En el caso de que elija utilizar el Asesor de Deuda en el Sitio de Debt.com en relación con una
                solicitud o pedido suyo, dependiendo de dónde resida, puede solicitar que le revelemos lo siguiente: (i)
                una explicación del proceso de toma de decisiones; y/o (ii) cómo puede obtener un resultado diferente.
                Para obtener más información sobre este requisito de divulgación, consulte la{" "}
                <a href="https://www.debt.com/es/terminos-y-condiciones/#politica-de-privacida" target="_blank">
                  Política de Privacidad de Debt.com
                </a>
                .
              </p>
            </li>
          </ol>
          <h3>IX. LIMITACIÓN DE RESPONSABILIDAD Y RENUNCIA DE GARANTÍA</h3>
          <p>
            LA INFORMACIÓN, EL SOFTWARE, LOS PRODUCTOS Y LOS SERVICIOS INCLUIDOS O DISPONIBLES A TRAVÉS DEL SITIO PUEDEN
            INCLUIR INEXACTITUDES, INFORMACIÓN ANTIGUA O ERRORES TIPOGRÁFICOS, Y DE NINGUNA MANERA DEBEN INTERPRETARSE
            COMO LIBRES DE ERRORES. DEBT.COM REALIZA CAMBIOS PERIÓDICOS A LA INFORMACIÓN Y A LOS MATERIALES
            PROPORCIONADOS EN EL SITIO, Y PUEDE ACTUALIZAR O CORREGIR CUALQUIER INEXACTITUD, INFORMACIÓN ANTIGUA U OTROS
            ERRORES TIPOGRÁFICOS EN EL SITIO WEB. ADEMÁS, DEBT.COM Y/O SU ADMINISTRADOR DEL SITIO PUEDEN REALIZAR
            MEJORAS Y/O CAMBIOS AL SITIO EN CUALQUIER MOMENTO.
          </p>
          <p>
            DEBT.COM Y/O SU ADMINISTRADOR DEL SITIO NO HACEN REPRESENTACIONES SOBRE Y NIEGAN TODAS Y CADA UNA DE LAS
            GARANTÍAS, YA SEAN EXPRESAS O IMPLÍCITAS CON RESPECTO A LA IDONEIDAD, CONFIABILIDAD, DISPONIBILIDAD,
            OPORTUNIDAD Y PRECISIÓN DE LA INFORMACIÓN, SOFTWARE (YA SEA PROPIO O CON LICENCIA DE TERCEROS), PRODUCTO DE
            ASESOR DE DEUDA, PRODUCTOS, SERVICIOS Y GRÁFICOS RELACIONADOS CONTENIDOS O DISPONIBLES A TRAVÉS DEL SITIO
            PARA CUALQUIER PROPÓSITO. EN LA MÁXIMA MEDIDA PERMITIDA POR LA LEY APLICABLE, TODA ESA INFORMACIÓN, SOFTWARE
            (YA SEA PROPIO O CON LICENCIA DE TERCEROS), PRODUCTO DE ASESOR DE DEUDA, PRODUCTOS, SERVICIOS Y GRÁFICOS
            RELACIONADOS SE PROPORCIONAN "TAL CUAL" SIN GARANTÍA O CONDICIÓN DE NINGÚN TIPO, INCLUIDAS TODAS LAS
            GARANTÍAS O CONDICIONES IMPLÍCITAS DE COMERCIABILIDAD, IDONEIDAD PARA UN PROPÓSITO PARTICULAR, TÍTULO Y NO
            INFRACCIÓN.
          </p>
          <p>
            ADEMÁS, TODAS LAS OPINIONES, CONSEJOS GENERALES, DECLARACIONES U OTROS COMENTARIOS EN EL SITIO PUEDEN SER
            DEL ESCRITOR Y NO SON ASUMIDOS NI ADOPTADOS POR DEBT.COM, Y USTED NO NECESARIAMENTE DEBE CONFIAR EN ELLOS.
            USTED ENTIENDE Y ACEPTA QUE TODAS LAS OPINIONES, CONSEJOS GENERALES, DECLARACIONES O COMENTARIOS NO DEBEN
            INTERPRETARSE COMO ASESORAMIENTO PROFESIONAL DE DEBT.COM, SUS EMPLEADOS O EL PROPIETARIO DE SU SITIO WEB Y
            NO DEBEN SER UTILIZADAS PARA TOMAR DECISIONES PERSONALES, MÉDICAS, LEGALES O FINANCIERAS. USTED DEBE
            REALIZAR SU PROPIA INVESTIGACIÓN Y ANÁLISIS INDEPENDIENTE CON RESPECTO A LAS OPINIONES, DECLARACIONES,
            INFORMACIÓN Y CONSEJOS GENERALES RECIBIDOS A TRAVÉS DEL SITIO, Y DEBE CONSULTAR A UN PROFESIONAL APROPIADO
            PARA OBTENER ASESORAMIENTO ESPECÍFICO ADAPTADO A SU SITUACIÓN.
          </p>
          <p>
            DEBT.COM PUEDE PROPORCIONARLE VARIAS HERRAMIENTAS Y SOFTWARE EN TODO EL SITIO PARA AYUDARLO EN VARIAS TAREAS
            O PERMITIRLE ENVIAR Y EXAMINAR SU ELEGIBILIDAD PARA VARIOS SERVICIOS PROPORCIONADOS POR TERCEROS
            PROVEEDORES. ALGUNAS HERRAMIENTAS Y SOFTWARE PUEDEN REQUERIR QUE USTED PROPORCIONE CIERTA INFORMACIÓN
            PERSONAL Y/O DEPENDERÁN DE LA INFORMACIÓN INGRESADA POR USTED PARA PROPORCIONARLE RESULTADOS PRECISOS
            BASADOS EN DECISIONES AUTOMATIZADAS. USTED ENTIENDE Y ACEPTA QUE NI DEBT.COM NI SU SOCIO TECNOLÓGICO ALL
            CLEAR DECISIONSING, LLC (QUE PROPORCIONA PARTE DEL SOFTWARE DE DECISIONES AUTOMATIZADAS EN EL SITIO), SON
            RESPONSABLES DE LOS RESULTADOS DEL SISTEMA DE DECISIONES AUTOMATIZADAS QUE DEPENDERÁN DE SU INFORMACIÓN
            INTRODUCIDA, Y CUALQUIER INEXACTITUD (YA SEA INTENCIONAL O NO) PUEDE AFECTAR LA EXACTITUD DE LOS RESULTADOS.
          </p>
          <p>
            HASTA EL MÁXIMO GRADO PERMITIDO POR LA LEY APLICABLE, EN NINGÚN CASO DEBT.COM Y/O SU PROPIETARIO DE SITIO
            WEB, FUNCIONARIOS, EMPLEADOS, MIEMBROS O REPRESENTANTES SERÁN RESPONSABLES POR NINGÚN DAÑO DIRECTO,
            INDIRECTO, PUNITIVO, INCIDENTAL, ESPECIAL, CONSECUENTE O CUALQUIER DAÑO DE CUALQUIER TIPO, INCLUIDOS, ENTRE
            OTROS, DAÑOS POR PÉRDIDA DE USO, DATOS O BENEFICIOS, QUE SURJAN DE O ESTÉN RELACIONADOS DE ALGUNA MANERA
            CON: EL USO O RENDIMIENTO DEL SITIO DEBT.COM; CON LA DEMORA O IMPOSIBILIDAD DE USAR EL SITIO DEBT.COM O LOS
            SERVICIOS RELACIONADOS U OFRECIDOS; LA PRESTACIÓN O FALTA DE PRESTACIÓN DE SERVICIOS POR PARTE DE DEBT.COM O
            CUALQUIER TERCERO QUE PUEDA COMERCIALIZAR SUS SERVICIOS EN DEBT.COM; O QUE SURJA DE O ESTÉ RELACIONADO CON
            CUALQUIER INFORMACIÓN, SOFTWARE (YA SEA PROPIEDAD DE DEBT.COM O LICENCIADO A DEBT.COM POR UN TERCERO),
            PRODUCTO DE ASESOR DE DEUDA, PRODUCTOS, SERVICIOS Y GRÁFICOS RELACIONADOS OBTENIDOS A TRAVÉS DEL SITIO
            DEBT.COM; O QUE SURJA DE O ESTÉ RELACIONADO CON EL USO DEL SITIO DEBT.COM, YA SEA CON BASE EN UN CONTRATO,
            AGRAVIO, NEGLIGENCIA, RESPONSABILIDAD ESTRICTA O DE OTRO MODO, INCLUSO SI DEBT.COM O CUALQUIERA DE SUS
            PROVEEDORES, SOCIOS, TERCEROS PROVEEDORES O PROVEEDORES DE TECNOLOGÍA, HAN SIDO ADVERTIDOS DE LA POSIBILIDAD
            DE UN RECLAMO, DISPUTA O DAÑOS. DEBIDO A QUE ALGUNOS ESTADOS/JURISDICCIONES NO PERMITEN LA EXCLUSIÓN O
            LIMITACIÓN DE RESPONSABILIDAD POR DAÑOS DERIVADOS O INCIDENTALES, LA LIMITACIÓN ANTERIOR PUEDE NO APLICARSE
            EN SU CASO SI VIVE EN UN ESTADO/JURISDICCIÓN QUE PROHÍBE EXPRESAMENTE EL USO DE LA LIMITACIÓN DE
            RESPONSABILIDAD. SI NO ESTÁ SATISFECHO CON ALGUNA PARTE DEL SITIO DEBT.COM, O CON ALGUNO DE ESTOS TÉRMINOS
            DE USO, SU ÚNICO Y EXCLUSIVO RECURSO ES DEJAR DE UTILIZAR EL SITIO DEBT.COM.
          </p>
          <h3>X. CANCELACIÓN Y RESTRICCIÓN DEL ACCESO</h3>
          <p>
            Debt.com puede establecer áreas en el Sitio que requieran la obtención de credenciales de acceso. Si obtiene
            dicho acceso a áreas restringidas del Sitio, Debt.com se reserva el derecho, a su exclusivo criterio, de
            cancelar su acceso al Sitio de Debt.com y los servicios relacionados o cualquier parte de ellos en cualquier
            momento, sin previo aviso. En el caso de que usted haga un mal uso de nuestro Sitio, las herramientas y el
            software en nuestro Sitio, o incumpla de otra manera los términos de estos Términos y Condiciones o la
            Política de Privacidad, usted comprende que Debt.com se reserva el derecho, a su entera discreción, de
            terminar su acceso y/o uso del Sitio, o cualquier herramienta o software en el Sitio.
          </p>
          <h3>XI. SITIO NO DESTINADO A SER UTILIZADO POR MENORES DE 18 AÑOS</h3>
          <p>
            El Sitio no está diseñado ni dirigido a personas menores de dieciocho (18) años, y solicitamos que estas
            personas no proporcionen información de identificación personal (PII) a través del Sitio. La información de
            menores que se considera PII según la Ley de Protección de la Privacidad Infantil en Internet (COPPA)
            incluye: (i) Nombre y apellido; (ii) Dirección física que incluye calle y nombre de ciudad; (iii) Dirección
            de correo electrónico; (iv) Identificador en línea que permite contactar a una persona directamente (por
            ejemplo, un nombre de mensajería instantánea, un nombre de usuario para un chat de video o audio u otra
            forma de nombre de pantalla); (v) Número de teléfono; (vi) Número de seguro social; (vii) Imagen, video o
            audio que contenga la imagen o la voz de una persona; (viii) Información suficiente para identificar el
            domicilio u otra dirección física de una persona; (ix) Un número de cookie, dirección IP, número de
            dispositivo único u otro identificador persistente que pueda usarse para rastrear y reconocer a una persona
            a lo largo del tiempo y en diferentes sitios web o aplicaciones; y/o (x) Pasatiempos, intereses, información
            recopilada mediante el uso de cookies y cualquier otra información recopilada de un niño que se refiera a
            ese niño o a los padres/tutores de ese niño que, cuando se combina con cualquiera de los anteriores, puede
            usarse para identificar al niño.
          </p>
          <p>
            Debt.com no recopila intencionalmente ni a sabiendas información de identificación personal (PII) de menores
            de 18 años sin el consentimiento de los padres. Si cree que su hijo menor proporcionó su información en
            nuestro sitio sin tener en cuenta estos Términos y Condiciones, le solicitamos que nos notifique por escrito
            comunicándose con nosotros por correo electrónico a <a href="mailto:admin@Debt.com">admin@Debt.com</a> y nos
            informe de inmediato para que podamos tomar todas las medidas razonables para eliminar y borrar cualquier
            PII proporcionada por su hijo a través de nuestro Sitio o las herramientas proporcionadas en nuestro Sitio.
            Para obtener más información sobre esta política, visite nuestra{" "}
            <a onClick={() => scrollIntoView(privatePolicyTargetRef)}>Política de Privacidad</a>.
          </p>
          <h3>XII. AVISOS DE DERECHOS DE AUTOR Y MARCAS COMERCIALES:</h3>
          <p>
            Debt.com y el logotipo de Debt.com son marcas comerciales registradas, nombres comerciales o marcas de
            servicio de Debt.com, LLC o sus empresas relacionadas. Todas las demás marcas comerciales y marcas de
            servicio presentadas en el Sitio son propiedad de sus respectivos dueños, y Debt.com ha obtenido permiso
            para usarlas en asociación con la información educativa y los servicios de referencia proporcionados. No se
            le permite a usted utilizar ninguna marca comercial o de servicio mostrada en este Sitio sin el
            consentimiento previo por escrito de Debt.com o el propietario de dicha marca comercial o de servicio. Usted
            reconoce y comprende que no tiene derechos sobre ninguna marca comercial, marca de servicio o derechos de
            autor. Debt.com se reserva todos los derechos sobre su propiedad intelectual en el Sitio.
          </p>
          <h3>XIII. POLÍTICA DE INFRACCIÓN DE DERECHOS DE AUTOR:</h3>
          <p>
            De acuerdo con los requisitos establecidos en la Ley de Derechos de Autor de Digital Millennium, título 17
            del Código de los Estados Unidos, sección 512(c)(2) (“DMCA”), Debt.com investigará los avisos de infracción
            de derechos de autor y tomará medidas correctivas adecuadas. Si cree que algún Contenido del Sitio se ha
            utilizado o copiado de una manera que infrinja su trabajo, proporcione una notificación por escrito de la
            presunta infracción de derechos de autor al Agente Designado para el Sitio, que contenga los siguientes
            elementos según lo establecido en la DMCA:
          </p>
          <ul>
            <li>
              una firma física o electrónica del propietario del derecho de autor supuestamente infringido o de la
              persona autorizada a actuar en nombre del propietario;
            </li>
            <li>
              identificación de las obras protegidas por derechos de autor que se afirma que se han infringido, incluida
              la fecha de los derechos de autor;
            </li>
            <li>
              identificación del Contenido que usted afirma que se infringe sus derechos y que usted solicita que se
              elimine del Sitio o se deshabilite su acceso, junto con una descripción de dónde se encuentra el Contenido
              infractor;
            </li>
            <li>
              información razonablemente suficiente para permitirnos comunicarnos con usted, como un domicilio físico,
              un número de teléfono y una dirección de correo electrónico;
            </li>
            <li>
              una declaración suya de que cree de buena fe que el uso del Contenido identificado en su notificación
              escrita en la forma reclamada no está autorizado por usted o el propietario de los derechos de autor, su
              agente o la ley; y
            </li>
            <li>
              una declaración suya de que la información contenida en su notificación escrita es exacta y que, bajo pena
              de perjurio, usted es el propietario de los derechos de autor o está autorizado a actuar en nombre del
              propietario de los derechos de autor.
            </li>
          </ul>
          <p>
            Los siguientes son los datos para comunicarse con el agente designado de Debt.com para la notificación
            escrita de reclamos por infracción de derechos de autor:
          </p>
          <p>
            Agente Designado – Reclamación por Infracción de Derechos de Autor <br /> Debt.com LLC
            <br /> 8220 W. State Road 84, <br /> Fort Lauderdale, FL 33324 <br /> Correo electrónico:{" "}
            <a href="mailto:admin@Debt.com">
              <strong style={{ fontWeight: "bold" }}>admin@Debt.com</strong>
            </a>
          </p>
          <h3>XIV. SUS REQUISITOS DE EXENCIÓN DE RESPONSABILIDAD</h3>
          <p>
            Usted acepta defender, indemnizar y eximir de responsabilidad a Debt.com y sus funcionarios, directores,
            accionistas, empleados, contratistas independientes, agentes, representantes, socios tecnológicos (All
            Clear) y afiliados de cualquier y contra todos los reclamos y gastos, incluidos, entre otros, los honorarios
            de abogados, que surjan de o estén relacionados con: (i) cualquier incumplimiento o violación de este
            Acuerdo por su parte; (ii) su falta de proporcionar información de identificación personal ("PII") precisa,
            completa y actual en respuesta a cualquier formulario de solicitud en nuestro Sitio, a través de una
            herramienta o software en nuestro Sitio, o específicamente en el Asesor de Deuda en nuestro Sitio; (iii) su
            acceso o uso de los Servicios a través de nuestro Sitio; (iv) acceso a nuestro Sitio o uso de nuestros
            Servicios bajo cualquier contraseña que se le pueda emitir; (v) sus transmisiones, envíos o publicaciones
            (es decir, su propio Contenido Generado por el Usuario); (vi) los Servicios que recibe de cualquiera de los
            Proveedores de Servicios a los que lo remitimos; y/o (vii) cualquier lesión personal o daño a la propiedad
            causado por usted.
          </p>
          <h3>XV. TÉRMINOS GENERALES / VARIOS</h3>
          <ol type="a">
            <li>
              <b style={{ fontWeight: "bold" }}>Ley aplicable, jurisdicción, lugar; renuncia al juicio por jurado</b>.
              Este Acuerdo se rige por las leyes del Estado de Florida, sin tener en cuenta ninguna disposición o
              principio de elección de ley. Si opta de manera adecuada y oportuna por no participar en el arbitraje, por
              la presente acepta la jurisdicción y competencia exclusiva de los tribunales estatales o federales con
              sede en el condado de Broward, Florida, en todas las disputas que surjan o estén relacionadas con el uso
              del sitio de Debt.com. Si no optó por no participar en arbitraje, consulte la disposición sobre arbitraje
              más abajo para conocer la legislación aplicable y la jurisdicción que rige su arbitraje. Usted y nosotros
              aceptamos someternos a la jurisdicción personal de los tribunales situados en el condado de Broward
              (Florida) con el fin de litigar la totalidad de dichos reclamos o disputas.{" "}
              <span style={{ fontWeight: "bold" }}>
                SI USTED OPTA POR NO PARTICIPAR DEL ARBITRAJE DE FORMA ADECUADA Y OPORTUNA, USTED, NO OBSTANTE, ENTIENDE
                QUE CADA PARTE RECONOCE Y ACEPTA QUE CUALQUIER CONTROVERSIA QUE PUEDA SURGIR BAJO ESTE ACUERDO ES
                PROBABLE QUE IMPLIQUE CUESTIONES COMPLICADAS Y DIFÍCILES Y, POR LO TANTO, CADA PARTE POR EL PRESENTE
                RENUNCIA IRREVOCABLE E INCONDICIONALMENTE A CUALQUIER DERECHO QUE DICHA PARTE PUEDA TENER A UN JUICIO
                POR JURADO CON RESPECTO A CUALQUIER PROCEDIMIENTO QUE SURJA DIRECTA O INDIRECTAMENTE DE O ESTÉ
                RELACIONADO CON ESTE ACUERDO O LAS TRANSACCIONES CONTEMPLADAS POR ESTE ACUERDO.
              </span>
            </li>
            <li>
              <b style={{ fontWeight: "bold" }}>Relación de las Partes. </b>
              Usted acepta que no existe ninguna relación de empresa conjunta, contratista independiente, sociedad,
              empleo o agencia entre usted y Debt.com como resultado de este Acuerdo o del uso del Sitio de Debt.com.
            </li>
            <li>
              <b style={{ fontWeight: "bold" }}>Aplicabilidad de las leyes. </b>
              El cumplimiento de este acuerdo por parte de Debt.com está sujeto a las leyes y procesos legales
              existentes, y nada de lo contenido en este acuerdo constituye una derogación del derecho de Debt.com a
              cumplir con las solicitudes o requisitos gubernamentales, judiciales y policiales relacionados con su uso
              del Sitio Debt.com o cualquier información proporcionada o recopilada por Debt.com con respecto a dicho
              uso. El uso del sitio Debt.com no está autorizado en ninguna jurisdicción que no dé efecto a todas las
              disposiciones de los presentes términos y condiciones, incluidos, entre otros, este párrafo.
            </li>
            <li>
              <b style={{ fontWeight: "bold" }}>Separabilidad. </b>Si se determina que alguna parte de este Acuerdo es
              inválida o inaplicable de conformidad con las leyes pertinentes, incluidas, entre otras, las renuncias de
              garantía y las limitaciones de responsabilidad establecidas anteriormente, entonces la disposición
              inválida o inaplicable se considerará reemplazada por una disposición válida y aplicable que coincida en
              la mayor medida posible con la intención de la disposición original, y el resto del acuerdo continuará en
              vigor.
            </li>
            <li>
              <b style={{ fontWeight: "bold" }}>Acuerdo completo. </b>A menos que se especifique lo contrario en este
              documento, el presente Acuerdo, junto con nuestra Política de Privacidad y todo Aviso de nuestro Sitio,
              que se incorporan aquí como referencia, juntos constituyen el acuerdo completo entre Usted y Debt.com con
              respecto al Sitio y reemplaza a todas las comunicaciones y propuestas anteriores o contemporáneas, ya sean
              electrónicas, orales o escritas, entre el usuario y Debt.com con respecto al Sitio Debt.com. Una versión
              impresa de este Acuerdo y de cualquier aviso dado en formato electrónico será admisible en procedimientos
              judiciales o administrativos basados en este Acuerdo o relacionados con él, en la misma medida y con
              sujeción a las mismas condiciones que otros documentos y registros comerciales generados originalmente y
              mantenidos en forma impresa. Es el deseo expreso de las partes que este Acuerdo y todos los documentos
              relacionados se redacten en inglés, a pesar de tener estos términos traducidos a otros idiomas.
            </li>
            <li>
              <b style={{ fontWeight: "bold" }}>Modificaciones al presente Acuerdo. </b>Nos reservamos el derecho de
              actualizar, modificar o cambiar este Acuerdo en cualquier momento a nuestro exclusivo criterio y sin
              previo aviso. Las actualizaciones de este Acuerdo se publicarán aquí. Las modificaciones entrarán en vigor
              inmediatamente después de que publiquemos el Acuerdo actualizado en nuestros Servicios. Le recomendamos
              que revise este Acuerdo cada cierto tiempo para revisar los cambios que se haya realizado. La fecha en la
              que este Acuerdo se actualizó por última vez se indicará antes del comienzo de este Acuerdo. Si usted
              continúa usando nuestros Servicios y accediendo a ellos después de la publicación de dichos cambios, se
              considerará automáticamente que usted acepta todos los cambios.
            </li>
          </ol>
          <h3 style={{ textDecoration: "underline" }} ref={disputeRef}>
            XVI. RESOLUCIÓN DE DISPUTAS MEDIANTE ARBITRAJE VINCULANTE: AVISO IMPORTANTE DE EXENCIÓN DE SU DERECHO A
            PRESENTAR UNA RECLAMACIÓN ANTE UN JUEZ O JURADO EN TRIBUNAL.
          </h3>
          <p>
            En caso de cualquier controversia entre usted y Debt.com, incluidas, entre otras, cualquier demanda,
            reclamo, demanda cruzada, contrademanda o queja o disputa de terceros, ya sea contractual, estatutaria, por
            agravio o de otro tipo (incluidos, entre otros, nuestros proveedores de tecnología, proveedores de servicios
            designados y cualquier proveedor de cumplimiento que trabaje en su nombre) que surja de, o de alguna manera
            relacionada con, nuestra relación, incluidos, entre otros, este Acuerdo, nuestra Política de Privacidad,
            Descargos de Responsabilidad del Sitio, el uso del Sitio de Debt.com, la información en el Sitio de
            Debt.com, cualquier servicio de referencia proporcionado por Debt.com, cualquier formulario de
            consentimiento de TCPA de Debt.com, o supuesta violación de TCPA por parte de Debt.com, o el incumplimiento,
            terminación, ejecución, interpretación o validez del Acuerdo o su desempeño, incluida cualquier
            determinación del alcance, ejecución o aplicabilidad de esta disposición para arbitraje, se resolverá
            mediante arbitraje vinculante en el área metropolitana más cercana al condado en el que usted reside, o en
            cualquier otra ubicación que las partes puedan acordar. Este acuerdo de arbitraje se regirá exclusivamente
            por la Ley Federal de Arbitraje (“FAA”), 9 U.S.C. §1 et sec., que regirá la interpretación y ejecución de
            nuestro acuerdo mutuo de arbitraje, y no por ninguna normativa o reglamento estatal que rija el arbitraje y
            sin tener en cuenta ninguna ley de arbitraje estatal. Este acuerdo de arbitraje seguirá teniendo vigencia
            tras la extinción del Acuerdo. Las Partes acuerdan los siguientes Términos de Arbitraje:
          </p>
          <ol type="A">
            <li>
              <b style={{ fontWeight: "bold" }}>Administración Arbitral. </b>El arbitraje será administrado por la
              Asociación Estadounidense de Arbitraje (“AAA”) (
              <a href="http://www.adr.org/" target="_blank" rel="noopener noreferrer">
                www.adr.org
              </a>
              ), los Servicios de Mediación de Arbitraje Judicial (“JAMS”) u otro servicio de arbitraje para
              consumidores conocido a nivel nacional acordado por las partes en caso de que AAA y JAMS ya no realicen
              arbitrajes para consumidores. La Parte que pretende solicitar el arbitraje debe primero enviar a la otra
              Parte un Aviso de Disputa por escrito antes de presentarla ante el proveedor de servicios de arbitraje. El
              Aviso debe describir tanto la naturaleza como el fundamento de la disputa; y la reparación específica
              buscada. Todo aviso se considerará entregado y recibido a todos los efectos cuando se entregue en mano, un
              día después de su recepción mediante un servicio de mensajería nocturno reconocido a nivel nacional, o un
              día después de haber sido recibido por correo certificado o registrado, con franqueo y cargos pagados por
              adelantado y con acuse de recibo, a nosotros en la siguiente dirección: Debt.com, LLC, Attn: Departamento
              Legal, 8220 W. State Road 84, Fort Lauderdale, FL 33324.
            </li>
            <li>
              <b style={{ fontWeight: "bold" }}>Inicio y reglas de arbitraje. </b>
              Si no resolvemos un reclamo dentro de los 30 días posteriores a la recepción del Aviso, la parte que envía
              el Aviso puede iniciar un procedimiento de arbitraje ante la AAA, JAMS u otra disposición de arbitraje
              nacional si la AAA o JAMS no están disponibles, para lo cual debe presentar una Demanda de Arbitraje que
              cobrará vigor al momento de iniciarse la acción. Si elige presentar la demanda ante la AAA, puede
              descargar o copiar el formulario de inicio de arbitraje desde el siguiente sitio web:
              http://www.adr.org/consumer. La AAA proporcionará a las partes una lista de 5 árbitros y las partes podrán
              ponerse de acuerdo en uno de ellos. Si las partes no pueden llegar a un acuerdo mutuo sobre un árbitro,
              las partes clasificarán a los árbitros en orden de preferencia y la AAA designará un único árbitro que
              administrará el procedimiento de arbitraje de conformidad con sus Procedimientos de Resolución de Disputas
              Comerciales y los Procedimientos Suplementarios para Disputas Relacionadas con el Consumidor (“Reglas de
              la AAA”), con excepción de las reglas de arbitraje masivo o colectivo. Puede consultar las reglas de la
              AAA en www.adr.org o pedirlas por teléfono a la AAA al 1-800-778-7879. Si elige presentar una solicitud
              ante JAMS, puede descargar o copiar el formulario para iniciar el arbitraje desde el siguiente sitio web:{" "}
              <a href="https://www.jamsadr.com/adr-forms" target="_blank" rel="noopener noreferrer">
                https://www.jamsadr.com/adr-forms
              </a>
              . JAMS proporcionará a las partes una lista de 5 árbitros y las partes podrán ponerse de acuerdo en uno de
              ellos. Si las partes no pueden llegar a un acuerdo mutuo sobre un árbitro, las partes clasificarán a los
              árbitros en orden de preferencia y JAMS designará un único árbitro que administrará el procedimiento de
              arbitraje de conformidad con sus Procedimientos de Resolución de Disputas Comerciales y las Normas Mínimas
              de Arbitraje del Consumidor (“Normas de JAMS”), con excepción de las reglas de arbitraje masivo o
              colectivo. En caso de que se proporcione otro servicio de arbitraje, las partes acuerdan seguir las reglas
              y principios de ese servicio de arbitraje, excepto en lo que respecta a cualquier derecho a las reglas de
              arbitraje masivo o colectivo, que las Partes acuerdan expresamente que no se aplicarán.
            </li>
            <li>
              <b style={{ fontWeight: "bold" }}>El árbitro. </b>El árbitro seleccionado deberá ser un juez jubilado o un
              abogado experimentado, será neutral e independiente, y deberá cumplir con el código de ética del servicio
              de arbitraje seleccionado. El árbitro conducirá el arbitraje de buen modo y garantizará el buen
              comportamiento de las partes al presentar sus argumentos. Además, al llevar a cabo la audiencia de
              arbitraje, además de las reglas del servicio de arbitraje seleccionadas, el árbitro se guiará por las
              Reglas Federales de Procedimiento Civil, las Reglas Federales de Evidencia y la ley sustantiva de Florida,
              y en caso de un reclamo basado en la ley federal, la ley federal aplicable en cuestión.
            </li>
            <li>
              <b style={{ fontWeight: "bold" }}>El laudo arbitral. </b>El árbitro emitirá un laudo escrito y razonado en
              el que abordará las reclamaciones, defensas, pruebas presentadas, derecho a honorarios legales y costos de
              arbitraje (incluidos los costos del árbitro y los costos de cualquier testigo experto) y el razonamiento
              legal para tomar su decisión. Si el árbitro determina que deben pagarse honorarios de abogados y costos
              razonables según la ley aplicable, las partes acuerdan que el árbitro también determinará el monto de los
              honorarios de abogados razonables que deben pagarse. Todo laudo dictado por el árbitro será definitivo y
              vinculante, y no se lo podrá anular o modificar, excepto según lo permita expresamente la Ley Federal de
              Arbitraje. La sentencia sobre el laudo arbitral podrá dictarse en la jurisdicción en la que se inició el
              arbitraje o en cualquier tribunal que tenga competencia sobre la Parte contra la cual se dictará
              sentencia.
            </li>
            <li>
              <b style={{ fontWeight: "bold" }}>
                Renuncia al derecho de presentar demandas masivas o colectivas. Las partes acuerdan que cualquiera de
                las partes puede presentar reclamaciones contra la otra solo a título individual y no como demandante o
                miembro de un grupo en cualquier supuesto procedimiento masivo, colectivo u otro procedimiento
                representativo. Además, las partes acuerdan que el árbitro no podrá consolidar los procedimientos de más
                de una persona y no podrá presidir ninguna forma de procedimiento representativo o colectivo.
              </b>
            </li>
            <li>
              <b style={{ fontWeight: "bold" }}>Limitación de medidas cautelares: </b>
              El árbitro puede dictar medidas cautelares solo a favor de una parte individual que busque reparación y
              solo en la medida necesaria para proporcionar la reparación que amerite el reclamo individual de esa
              parte.
            </li>
            <li>
              <b style={{ fontWeight: "bold" }}>Costos del arbitraje: </b>El pago de todos los gastos de presentación y
              administración, y los honorarios del árbitro se regirá por las reglas del servicio de arbitraje
              seleccionado. Cada parte pagará sus propios honorarios de abogados, si los hubiere, a menos que dichos
              honorarios estén expresamente previstos por la ley aplicable. En el caso de que una parte no proceda con
              el arbitraje, objete sin éxito el laudo del árbitro o no cumpla con el laudo del árbitro, la otra parte
              tendrá derecho a que le pague los costos de la demanda, incluidos los honorarios razonables de los
              abogados por tener que obligar al arbitraje o defenderse o hacer cumplir el laudo.
            </li>
            <li>
              <b style={{ fontWeight: "bold" }}>Exclusión voluntaria del arbitraje. </b>USTED PUEDE EXCLUIRSE DE LOS
              TÉRMINOS DE ESTA DISPOSICIÓN DE ARBITRAJE, PARA LO CUAL DEBE PROPORCIONAR A DEBT.COM UN AVISO POR ESCRITO
              EN UN PLAZO DE TREINTA (30) DÍAS DESPUÉS DE UTILIZAR NUESTRO SITIO POR PRIMERA VEZ. Si lo hace, ni usted
              ni nosotros podemos exigir al otro que participe en un procedimiento de arbitraje. Para optar por
              excluirse, debe notificarnos por escrito, ya sea por correo postal de EE. UU. a la dirección Attn:
              Departamento Legal, Debt.com, LLC, 8220 W. State Road 84, Fort Lauderdale, FL 33324 o por correo
              electrónico enviado a admin@debt.com. Debe incluir: (1) su nombre y domicilio particular; (2) la dirección
              de correo electrónico o número de teléfono celular asociado con su cuenta; y (3) una declaración clara de
              que desea excluirse de este acuerdo de arbitraje.
            </li>
            <li>
              <b style={{ fontWeight: "bold" }}>
                <span style={{ textDecoration: "underline" }}>¿Qué significa arbitraje?</span> Al aceptar el arbitraje,
                usted y nosotros reconocemos y aceptamos que cada uno de nosotros: (i) renunciamos al derecho de seguir
                cualquier otro proceso de resolución de disputas disponible, incluidos, entre otros, las demandas
                judiciales y los procedimientos administrativos, (ii) renunciamos al derecho de participar en cualquier
                demanda colectiva o masiva, (iii) renunciamos al derecho a juicio ante juez o jurado, y (iv) renunciamos
                a todo derecho a apelar la decisión del árbitro, excepto por determinadas cuestiones apelables
                expresamente previstas en la FAA. Las partes consideran que esta sección es un requisito esencial e
                irrevocable, y forma parte de este Acuerdo, y las partes acuerdan que antes de firmar este Acuerdo,
                incluida la aceptación de esta sección: (1) han tenido la oportunidad de revisarlos cuidadosamente,
                incluso con su propio asesor legal si es necesario, (2) las partes comprenden las limitaciones de los
                derechos legales contenidos en esta sección, entre ellos, la renuncia al juicio con jurado, la renuncia
                a procedimientos judiciales y demandas colectivas, y (3) las partes acuerdan y aceptan que esta sección
                es justa, razonable, representa las intenciones de cada parte al momento de celebrar este Acuerdo y
                ningún tribunal debe dejarla de lado más adelante por ningún motivo. Esta sección y el requisito de
                arbitraje seguirán teniendo vigencia tras la extinción del Acuerdo.
              </b>
            </li>
          </ol>
          <p style={{ fontWeight: "bold" }}>
            Sin perjuicio de cualquier disposición en contrario en el Acuerdo, usted y nosotros aceptamos que si
            realizamos un cambio en este acuerdo para someternos a arbitraje en el futuro, ese cambio no se aplicará a
            reclamos presentados en un procedimiento judicial entre usted y nosotros antes de la fecha efectiva del
            cambio.
          </p>
          <h3>XVII. CONTÁCTENOS</h3>
          <p>
            Si tiene preguntas sobre estos términos o necesita información adicional sobre nuestros servicios, puede
            comunicarse con nosotros por correo postal o electrónico a:
          </p>
          <p>
            <b style={{ fontWeight: "bold" }}>Debt.com LLC</b> <br />
            8220 W. State Road 84, <br />
            Fort Lauderdale, FL 33324 <br />
            Correo electrónico: <a href="mailto:admin@Debt.com">admin@Debt.com</a> <br />
          </p>
          <h3>
            IMPRIMA Y CONSERVE UNA COPIA DE ESTOS TÉRMINOS Y CONDICIONES PARA SU REGISTRO. ASEGÚRESE DE REVISAR
            PERIÓDICAMENTE NUESTROS TÉRMINOS Y CONDICIONES PARA OBTENER ACTUALIZACIONES.
          </h3>

          <h2 ref={privatePolicyTargetRef}>POLÍTICA DE PRIVACIDAD DE DEBT.COM</h2>
          <p>
            <strong>Última actualización vigente a partir de: 30 de enero de 2025.</strong>
          </p>
          <p>
            Debt.com, LLC (“Debt.com”) es el operador de este sitio web. Esta Política de Privacidad (en adelante, a
            veces denominada el “Aviso” o “Política de Privacidad” o “Aviso de Privacidad”) se aplica a esta interfaz en
            línea de <strong>Debt.com</strong> (es decir, sitio web o aplicación móvil (“Aplicación(es)”)) (el “Sitio”).
            El término “Debt.com” o “nosotros” o “nuestro” en este Aviso se refiere a Debt.com y a cualquiera de las
            afiliadas o subsidiarias de Debt.com que puedan adoptar este Aviso o ciertas partes aplicables de este
            Aviso. Este Aviso describe cómo este Sitio, o cualquier página de inicio de Debt.com vinculada a este Sitio
            (las páginas de inicio de Debt.com y este Sitio se denominarán colectivamente "Sitios"), pueden recopilar,
            usar, almacenar y compartir información de o sobre usted, y explica cómo la información recopilada de o
            sobre usted puede usarse con fines publicitarios o compartirse con terceros. Debt.com puede proporcionar
            otras interfaces en línea no cubiertas por este Aviso, que estarán sujetas a sus propios avisos de
            privacidad. Si visita sus servicios o accede a estos desde uno de dichos sitios, revise las prácticas de
            privacidad en línea de ese sitio para comprender cómo se puede recopilar, utilizar y compartir su
            información en línea.
          </p>
          <p>
            <strong>Aceptación de esta Política:</strong> Esta Política de privacidad se incorpora por referencia a los
            Términos y Condiciones de Debt.com, incluida la obligación de arbitrar sus reclamos bajo esta Política de
            Privacidad de conformidad con su acuerdo de arbitraje en la Sección XVI titulada{" "}
            <strong>
              <u>RESOLUCIÓN DE DISPUTAS MEDIANTE ARBITRAJE VINCULANTE</u>. Consulte la Sección XVI{" "}
              <a
                style={{ cursor: "pointer", color: "blue", textDecoration: "underline" }}
                onClick={() => scrollIntoView(disputeRef)}
              >
                Resolución de disputas mediante arbitraje vinculante
              </a>{" "}
              de este Acuerdo.
            </strong>{" "}
            Al usar, interactuar con, ingresar información o ver información en este Sitio, usted reconoce y acepta
            expresamente esta Política de Privacidad en su totalidad, incluido el consentimiento para que usemos y
            divulguemos la información recopilada de usted o sobre usted en la forma descrita en este documento. Usted
            acepta que Debt.com confiará en su uso e interacción con este Sitio como su consentimiento expreso a los
            términos de este Aviso de Privacidad.{" "}
            <strong>
              USTED ACEPTA QUE HA LEÍDO, ENTENDIDO Y ACEPTA ESTAR OBLIGADO POR LOS TÉRMINOS Y CONDICIONES Y ESTA
              POLÍTICA DE PRIVACIDAD.
            </strong>
          </p>

          <p>
            <strong>Descripción</strong>
            <br />
            <ol type="I">
              <li style={{ fontWeight: "bold" }}>INFORMACIÓN QUE PODEMOS RECOPILAR</li>
              <li style={{ fontWeight: "bold" }}>CÓMO PODEMOS RECOPILAR SU INFORMACIÓN</li>
              <li style={{ fontWeight: "bold" }}>¿CÓMO UTILIZAMOS SU INFORMACIÓN PERSONAL?</li>
              <li style={{ fontWeight: "bold" }}>CÓMO SE PUEDE COMPARTIR SU INFORMACIÓN</li>
              <li style={{ fontWeight: "bold" }}>SUS DERECHOS SOBRE LOS DATOS</li>
              <li style={{ fontWeight: "bold" }}>SEGURIDAD DE DATOS</li>
              <li style={{ fontWeight: "bold" }}>VARIOS</li>
            </ol>
          </p>

          <h3>I. INFORMACIÓN QUE PODEMOS RECOPILAR</h3>
          <p>
            Debt.com puede recopilar cierta información, incluida información de identificación personal (“PII”), de
            acuerdo con la ley y para brindarle servicios ejemplares.
            <br />
            En particular, Debt.com puede recopilar las siguientes categorías de información personal identificable:
          </p>

          <ol type="a">
            <li>
              <strong>Información de identificación general</strong>: La información de identificación general puede
              incluir, entre otros, su nombre completo, dirección postal, dirección de correo electrónico, número de
              teléfono, ID de cuenta de redes sociales, foto de perfil, otros datos puestos a disposición del público o
              datos puestos a disposición al vincular sus redes sociales u otros identificadores similares.
            </li>
            <li>
              <strong>Información demográfica y fechas importantes</strong>: La información demográfica y las fechas
              importantes pueden incluir, entre otras, su género, preferencias de idioma, nacionalidad y/o fecha de
              nacimiento.
            </li>
            <li>
              <strong>Información financiera</strong>: La información financiera puede incluir, entre otros, su tarjeta
              de crédito, débito u otros datos de pago, información de cuenta bancaria, estados financieros personales u
              otra información financiera personal sobre usted o su hogar.
            </li>
            <li>
              <strong>Información crediticia</strong>: La información crediticia puede incluir, entre otros, su puntaje
              crediticio, el estado de sus cuentas de crédito y otra información de solvencia sobre usted o su hogar.
            </li>
            <li>
              <strong>Internet u otra información electrónica</strong>: La información de Internet u otra información
              electrónica puede incluir, entre otros, su historial web, historial de búsqueda, interacciones con un
              sitio web, correo electrónico, aplicaciones o anuncios, dirección IP, ubicación del host IP, cookies
              habilitadas o cualquier otra información que pueda obtenerse automáticamente de usted mientras hace uso de
              los Sitios.
            </li>
            <li>
              <strong>Información de geolocalización</strong>: La información de geolocalización puede incluir, entre
              otros, su ubicación en tiempo real o datos de coordenadas GPS proporcionados por su computadora,
              dispositivo móvil o navegador web, datos derivados de su dirección IP, ubicación general basada en redes
              Wi-Fi o torres de telefonía celular cercanas, registros del historial de ubicación, geoetiquetas en
              contenido cargado en el Sitio u otra información de ubicación del usuario ingresada por usted.
            </li>
            <li>
              <strong>Inferencias extraídas de la información anterior sobre sus características previstas.</strong>
            </li>
            <li>
              <strong>Categorías que pueden considerarse información personal confidencial</strong>: Dicha información
              puede incluir identificadores gubernamentales, información financiera, información crediticia u otra
              información confidencial según lo define la ley aplicable.
            </li>
          </ol>

          <p>
            <strong>
              <u>Aviso sobre información personal confidencial</u>
            </strong>
            <br />
            <u>"Información personal confidencial"</u> significa cualquier información que pueda usarse para
            identificarlo individualmente y que se relacione con sus finanzas o cuentas financieras con nosotros. Esto
            incluye, entre otros:
            <ul>
              <li>Número de seguro social.</li> <li>Números de cuenta.</li> <li>Historial de transacciones.</li>{" "}
              <li>Puntuación crediticia e informes crediticios.</li> <li>Información de inversión.</li>{" "}
              <li>Estados financieros personales.</li>{" "}
              <li>
                Cualquier otra información que se utilice o asocie con una cuenta financiera que le pertenezca a usted o
                a su bienestar financiero.
              </li>
            </ul>
          </p>

          <p>
            <u>Finalidades de la recopilación:</u>
            <br />
            Recopilamos información confidencial para los siguientes fines:
            <ul>
              <li>
                Proporcionar y mantener servicios y productos financieros (por ejemplo, procesar transacciones,
                administrar cuentas, recomendar servicios de ayuda financiera).
              </li>{" "}
              <li>Verificar su identidad y protegerse contra el fraude.</li>{" "}
              <li>Cumplir con los requisitos legales y reglamentarios.</li>
              <li>Mejorar nuestros servicios y ofertas.</li>{" "}
              <li>
                Comercialización de productos y servicios por parte nuestra o de proveedores terceros de servicios
                (sujeto a sus opciones de exclusión voluntaria).
              </li>
            </ul>
          </p>

          <p>
            <u>
              Terceros con quienes se puede compartir información personal identificable o información personal
              confidencial.
            </u>{" "}
            Podemos compartir su información personal identificable y/o información confidencial con los siguientes
            tipos de terceros:
            <ul>
              <li>
                Afiliados: Empresas dentro de nuestra familia corporativa para los fines descritos en esta política.
              </li>{" "}
              <li>
                Nuestros proveedores de servicios: Empresas que realizan servicios en nuestro nombre, como procesamiento
                de datos, marketing y atención al cliente. Requerimos contractualmente que estos proveedores protejan la
                confidencialidad y seguridad de su información confidencial.
              </li>{" "}
              <li>
                Proveedores de servicios financieros de terceros: Empresas que prestan servicios financieros a
                consumidores a quienes pueden ser referidos los consumidores que utilizan nuestro Sitio.
              </li>
              <li>
                Otras instituciones financieras: Otras instituciones financieras con las que nos asociamos para ofrecer
                productos o servicios específicos.
              </li>{" "}
              <li>Agencias gubernamentales: Según lo requiera la ley o el proceso legal.</li>
            </ul>
          </p>

          <p>
            <u>Opciones de consentimiento y exclusión voluntaria:</u>
            <br /> Podemos compartir su PII y/o información personal confidencial para fines de marketing con nuestros
            afiliados y socios externos. Tiene derecho a optar por no compartir esta información en cualquier momento.
            Para cancelar su suscripción, visite nuestra{" "}
            <a href="https://www.debt.com/es/pagina-de-solicitud-de-datos/" target="_blank" rel="noopener noreferrer">
              página Solicitud de derechos de datos.
            </a>
          </p>

          <p>
            Podemos compartir su PII y/o información personal confidencial con proveedores de servicios externos e
            instituciones financieras según sea necesario para brindarle servicios y productos financieros. No puede
            optar por no compartir esta información, ya que es esencial para que podamos cumplir con nuestras
            obligaciones contractuales con usted.
          </p>

          <p>
            <u>Otra información que recopilamos en línea</u>
            <br /> Otra información es cualquier información, distinta de información personal, PII o información
            personal confidencial, que no revela su identidad específica, como información del navegador, información
            recopilada a través de cookies, etiquetas de píxeles, balizas web y otras tecnologías, información
            demográfica y datos agregados y no identificados. Otra información también puede incluir información
            geográfica, información de referencia e información de URL. Aunque dicha información por sí sola no revele
            su identidad específica, podría ser utilizada por terceros que tengan relaciones independientes con usted
            para identificarlo a usted, sus actividades web y sus intereses.
          </p>

          <p>
            También podemos instalar cookies, balizas web u otras tecnologías, incluidas tecnologías que identifican la
            información de marcación, enrutamiento, direccionamiento y señalización generada por los usuarios de los
            Sitios, que brindan acceso a terceros a su información, incluida, entre otras, su dirección IP y actividad
            de navegación en páginas de nuestros Sitios donde se colocan estos dispositivos. Las cookies pueden estar
            activas durante un período de entre 30 y 90 días; sin embargo, puede eliminarlas mediante la configuración
            de su navegador. Usted comprende que no controlamos ninguna información recopilada por dichos dispositivos
            ni su uso por parte de dichos terceros. Estos terceros están obligados a manejar su información personal de
            acuerdo con sus propias políticas de privacidad y las leyes y regulaciones de protección de datos
            aplicables.
          </p>

          <p>
            Usted acepta que dicha PII, información personal confidencial u otra información recopilada a través de
            cookies, etiquetas de píxeles, balizas web y otras tecnologías, incluidas las tecnologías que identifican la
            información de marcación, enrutamiento, direccionamiento y señalización generada por los usuarios del sitio
            web, puede ser utilizada y compartida por Debt.com según lo dispuesto en este Aviso.
          </p>

          <p>
            En general, podemos utilizar o usar estos diferentes tipos de cookies:
            <br />
            <ul>
              <li>
                <strong>Cookies estrictamente necesarias</strong>: Estas cookies son necesarias para habilitar las
                funciones básicas de nuestros sitios y aplicaciones, como proporcionar un inicio de sesión seguro o
                reservar una estadía.
              </li>
              <li>
                <strong>Cookies funcionales</strong>: Estas cookies permiten que nuestros Sitios recuerden sus
                preferencias y las elecciones que realiza en el sitio. También utilizamos cookies funcionales para
                facilitar la navegación, mostrar el contenido de forma más efectiva y/o personalizar su experiencia.
              </li>{" "}
              <li>
                <strong>Cookies publicitarias</strong>: Las cookies publicitarias nos permiten seleccionar qué anuncios
                u ofertas tienen más probabilidades de interesarle. También las utilizamos para rastrear respuestas a
                anuncios y marketing en línea, y podemos usarlas para comprender mejor sus intereses y poder presentarle
                mensajes y ofertas relevantes. Estas cookies también pueden permitirle compartir ciertas páginas con
                redes sociales.
              </li>{" "}
              <li>
                <strong>Cookies de análisis</strong>: Las cookies de análisis nos ayudan a mejorar nuestros sitios y
                aplicaciones al recopilar y reportar información sobre cómo las utiliza.
              </li>{" "}
              <li>
                <strong>Cookies esenciales y no esenciales del Instant Debt Advisor:</strong>
                <ul>
                  <li>
                    <strong>Cookies esenciales:</strong> Las cookies son necesarias para la funcionalidad básica de la
                    aplicación y no se pueden desactivar sin afectar la experiencia del usuario. En nuestra aplicación
                    las cookies esenciales incluyen:{" "}
                    <ul>
                      <li>
                        <strong>Cookies de autenticación</strong>: Se utiliza para administrar sesiones de usuario y
                        garantizar el acceso seguro a la aplicación.
                      </li>{" "}
                      <li>
                        <strong>Cookies de rendimiento de la aplicación</strong>: Necesario para mantener la
                        confiabilidad del servicio, como equilibrar la carga y garantizar el buen funcionamiento de la
                        aplicación.
                      </li>{" "}
                      <li>
                        <strong>Cookies de cumplimiento</strong>: Se utiliza para funciones como grabación de pantalla
                        para capturar acciones del usuario para el cumplimiento de TCPA y certificados de aceptación.
                      </li>{" "}
                      <li>
                        <strong>Cookies de equilibrio de carga</strong>: Garantizar una distribución eficiente del
                        tráfico de red entre servidores.
                      </li>
                      <li>
                        <strong>Cookies de seguridad</strong>: Proteja el sitio web y al usuario de actividades
                        maliciosas.
                      </li>
                    </ul>
                  </li>

                  <li>
                    <strong>Cookies no esenciales</strong>: Cookies que mejoran la experiencia del usuario pero no son
                    necesarias para que la aplicación funcione. Los usuarios pueden aceptar o rechazar estas cookies sin
                    afectar la funcionalidad principal de la aplicación. Algunos ejemplos incluyen:{" "}
                    <ul>
                      <li>
                        <strong>Cookies de análisis</strong>: Google Analytics para rastrear el comportamiento del
                        usuario, comprender patrones de uso y mejorar la aplicación.
                      </li>{" "}
                      <li>
                        <strong>Cookies de marketing o personalización</strong>: (Si corresponde) Se utiliza para
                        publicidad dirigida o para personalizar la experiencia del usuario según las preferencias.
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            Puede obtener más información sobre las cookies en:{" "}
            <a
              href="http://www.allaboutcookies.org/manage-cookies/index.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://www.allaboutcookies.org/manage-cookies/index.html↗
            </a>
            .
          </p>

          <p>
            <strong>
              <u>Opciones de cookies.</u>
            </strong>{" "}
            Existen varias formas de gestionar las cookies:
            <ol>
              <li>
                <strong>Configuración del navegador</strong>: Puede inhabilitar las cookies en su navegador. Cada
                navegador es diferente, por favor revise las instrucciones proporcionadas por su navegador. Tenga en
                cuenta que si decide desactivar las cookies en su navegador o deshabilitarlas, dicha acción puede
                afectar su uso de nuestro Sitio.
              </li>{" "}
              <li>
                <strong>Preferencias de cookies</strong>: A través de nuestra herramienta{" "}
                <span style={{ color: "blue", textDecoration: "underline" }}>“Preferencias de cookies”</span>.
                Actualmente sólo puede aceptar o rechazar el uso de cookies en nuestro sitio. Sin embargo, tenga en
                cuenta que actualmente no se pueden rechazar las cookies esenciales y solo se rechazarán las cookies no
                esenciales.
              </li>
              <li>
                <strong>Google y Adobe Analytics</strong>: Recopilamos datos a través de Google Analytics y Adobe
                Analytics, que utilizan cookies y tecnologías para recopilar y analizar datos sobre el uso de los
                Servicios en línea. Estos servicios también recopilan datos sobre el uso de otros sitios web,
                aplicaciones y recursos en línea. Puede obtener información sobre las prácticas de Google visitando{" "}
                <a href="https://google.com/policies/privacy/partners/" target="_blank" rel="noopener noreferrer">
                  www.google.com/policies/privacy/partners/↗
                </a>{" "}
                y cancelar su suscripción descargando el complemento de inhabilitación para navegadores de Google
                Analytics, disponible en{" "}
                <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">
                  https://tools.google.com/dlpage/gaoptout↗
                </a>
                .
              </li>
            </ol>
          </p>
          <h3 style={{ fontWeight: "bold", textDecoration: "underline" }}>Opciones de publicidad</h3>
          <p>
            Podemos utilizar empresas de publicidad de terceros para publicar anuncios en nuestro Sitio. Para mostrar
            dichos anuncios, estas empresas colocan o reconocen una cookie única en su navegador (incluso mediante el
            uso de etiquetas de píxeles). También utilizan estas tecnologías, junto con la información que recopilan
            sobre su uso en línea, para reconocerlo en los dispositivos que utiliza, como un teléfono móvil y una
            computadora portátil. Si desea obtener más información sobre esta práctica y conocer sus opciones al
            respecto, visite:
            <ol>
              <li>
                <a
                  href="http://www.networkadvertising.org/managing/opt_out.asp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  http://www.networkadvertising.org/managing/opt_out.asp↗
                </a>{" "}
                ;
              </li>
              <li>
                <a href="http://www.aboutads.info" target="_blank" rel="noopener noreferrer">
                  http://www.aboutads.info/↗
                </a>
                , o{" "}
              </li>
              <li>
                <a href="https://business.safety.google/privacy/" target="_blank" rel="noopener noreferrer">
                  https://business.safety.google/privacy/↗
                </a>
                .
              </li>
            </ol>
          </p>

          <h3>II. CÓMO RECOPILAMOS SU INFORMACIÓN</h3>
          <p>
            Podemos recopilar su información personal identificable y/o información personal confidencial de las
            siguientes maneras:{" "}
            <ol type="a">
              <li>
                Directamente de usted cuando visita nuestros Sitios o nos contacta con respecto a nuestros productos o
                servicios.
              </li>{" "}
              <li>
                De terceros, como datos de informes crediticios (cuentas, historial de pagos, saldos) o datos de
                generación de clientes potenciales.
              </li>{" "}
              <li>
                Indirectamente de usted, como a través de sus interacciones con nuestros Sitios o mediante el uso de
                cookies u otro software de seguimiento relacionado.
              </li>{" "}
              <li>De socios de marketing o análisis, como Google, Meta u otros socios externos.</li>{" "}
              <li>De agencias gubernamentales o registros públicos.</li>{" "}
              <li>
                Varios terceros, como bases de datos públicas, socios de marketing conjunto y proveedores de servicios
                financieros.
              </li>
            </ol>
          </p>

          <h3>III. ¿CÓMO UTILIZAMOS SU INFORMACIÓN PERSONAL?</h3>
          <p>
            Podemos utilizar su información personal identificable y/o información personal confidencial para los
            siguientes fines:{" "}
            <ol type="a">
              <li>Para responder a sus consultas y cumplir con sus solicitudes.</li>{" "}
              <li>
                Para comunicarle información importante sobre el Sitio, productos o servicios que puede solicitar o en
                los que esté inscrito, cambios en los términos, condiciones y políticas, u otra información
                administrativa.
              </li>{" "}
              <li>
                Para enviar comunicaciones de marketing que creemos que pueden ser de su interés, tales como anuncios u
                ofertas adaptados a usted, incluidos anuncios en otros sitios web o de terceros relacionados o no
                relacionados.
              </li>{" "}
              <li>Para personalizar su experiencia en el Sitio.</li>{" "}
              <li>
                Para referirlo a varios proveedores de servicios financieros que pueden ofrecerle varios servicios
                financieros independientemente de nuestro Sitio.
              </li>{" "}
              <li>
                Para permitirle solicitar diversos productos o servicios, y evaluar si reúne los requisitos para dichos
                productos o servicios.
              </li>{" "}
              <li>
                Para verificar su identidad o ubicación (o la identidad o ubicación de su representante o agente) con el
                fin de permitir el acceso a los servicios en los que pueda inscribirse, realizar transacciones en línea
                y mantener medidas destinadas a prevenir el fraude y proteger la seguridad de su Información Personal.
              </li>{" "}
              <li>
                Para permitirle participar en encuestas y otras formas de investigación de mercado, sorteos, concursos y
                promociones similares, y para administrar estas actividades. Estas actividades pueden tener reglas de
                participación adicionales, que también pueden contener políticas adicionales sobre cómo se utiliza y/o
                comparte la PII o Información Personal Confidencial proporcionada.
              </li>{" "}
              <li>
                Para permitirnos verificar su identidad u otra información recopilada por medio de nuestras páginas o
                campañas de redes sociales y otras interacciones con formularios en línea generados en nuestro nombre.
                Podemos combinar y utilizar la PII y/o la Información Personal Confidencial que usted proporcione en
                este sitio con la información recopilada de nuestras páginas de redes sociales o de su interacción con
                formularios en línea generados en nuestro nombre.
              </li>{" "}
              <li>
                Para permitirle ingresar y participar en nuestro chat en vivo, foros y/u otros tipos de tableros de
                anuncios que podamos tener en el sitio de vez en cuando.
              </li>{" "}
              <li>
                Para fines comerciales, incluidos análisis de datos y marketing, auditorías, desarrollo y mejora de
                productos y servicios, mejora del Sitio, identificación de tendencias de uso y determinación de la
                efectividad de las campañas promocionales.
              </li>{" "}
              <li>
                Para control de riesgos, detección y prevención de fraude, para cumplir con leyes y reglamentos, y para
                cumplir con otros procesos legales y requisitos de aplicación de la ley.
              </li>{" "}
              <li>
                Para permitirle utilizar funciones dentro de nuestro Sitio, al otorgarnos acceso a información de su
                dispositivo, como listas de contactos o geolocalización, cuando solicita ciertos servicios.
              </li>
            </ol>
          </p>

          <h3>IV. CÓMO SE PUEDE COMPARTIR SU INFORMACIÓN</h3>
          <p>
            Podemos compartir su PII y/o Información Personal Confidencial para fines comerciales, de marketing y
            empresariales, todo ello en cumplimiento de esta Política de Privacidad.
          </p>

          <p>
            <u>Información divulgada para fines comerciales.</u> En los últimos 12 meses, hemos divulgado información
            que puede incluir las siguientes categorías de información personal para fines comerciales:{" "}
            <ol type="a">
              <li>Información general de identificación</li> <li>Información demográfica y fechas importantes</li>{" "}
              <li>Información financiera</li> <li>Información crediticia</li>{" "}
              <li>Internet u otra información electrónica</li> <li>Información de geolocalización</li>{" "}
              <li>Inferencias extraídas de la información anterior sobre sus características previstas</li>{" "}
              <li>Información que puede considerarse Información Personal Confidencial</li>
            </ol>
          </p>

          <p>
            <u>Categorías de terceros que reciben información con fines comerciales:</u>
            <ol type="a">
              <li>Afiliados</li> <li>Nuestros proveedores de servicios</li>{" "}
              <li>Empresas de servicios financieros de terceros e instituciones financieras</li>{" "}
              <li>Empresas de marketing e investigación</li>{" "}
              <li>Autoridades reguladoras o encargadas de hacer cumplir la ley</li>{" "}
              <li>Empresas de tecnología y software informático</li> <li>Empresas de telecomunicaciones</li>
            </ol>
          </p>

          <p>
            <u>Información vendida o compartida con fines no comerciales.</u> En los últimos 12 meses, las siguientes
            categorías de información personal, PII o Información Personal Confidencial se vendieron o divulgaron a
            terceros para fines comerciales no esenciales.{" "}
            <ol type="a">
              <li>Información general de identificación</li> <li>Información demográfica y fechas importantes</li>{" "}
              <li>Información financiera</li> <li>Información crediticia</li>{" "}
              <li>Internet u otra información electrónica</li> <li>Información de geolocalización</li>{" "}
              <li>Inferencias extraídas de la información anterior sobre sus características previstas</li>{" "}
              <li>Información que puede considerarse Información Personal Confidencial</li>
            </ol>
          </p>

          <p>
            <u>
              Categorías de terceros a quienes se les ha vendido información personal, PII o Información Personal
              Confidencial:
            </u>{" "}
            <ol type="a">
              <li>Afiliados</li> <li>Organizaciones de servicios financieros no afiliadas</li>{" "}
              <li>Proveedores de marketing dirigido</li>
              <li>
                Organizaciones de servicios financieros, incluidas empresas de liquidación de deudas, reparación de
                crédito, asesoramiento crediticio, quiebras y gestión de deudas; otros proveedores de servicios de
                alivio de deudas.
              </li>{" "}
              <li>Empresas de marketing e investigación</li>
            </ol>
          </p>

          <p>
            <u>Venta de información personal y marketing dirigido</u>
            <br /> Debt.com puede participar en el “intercambio” o “venta” de su información personal. Debt.com también
            puede realizar “publicidad dirigida” en el uso de su información personal.{" "}
            <strong>
              No recopilamos, “vendemos” ni “compartimos” intencionalmente información personal de personas menores de
              18 años. Si es menor de 18 años, tiene prohibido utilizar nuestros Sitios y proporcionar información
              personal en nuestros Sitios. Véase también la Divulgación de COPPA en la Sección VIII a continuación.
            </strong>
          </p>

          <h3>V. SUS DERECHOS SOBRE LOS DATOS</h3>
          <p>
            Dependiendo de dónde resida, puede tener ciertos derechos con respecto a la información personal que
            recopilamos y compartimos. A los residentes de los estados aplicables, incluidos California, Virginia,
            Colorado, Connecticut, Iowa, Indiana, Tennessee, Texas, Montana, Minnesota, Oregón, Delaware, Nueva
            Hampshire, Nueva Jersey, Kentucky, Nebraska y Rhode Island, se les puede otorgar el derecho a realizar la
            siguiente solicitud, según la ley aplicable.{" "}
            <ol type="a">
              <li>
                <u>Solicitud de información.</u> Puede solicitar saber si procesamos su información personal y acceder a
                dicha información personal. Puede solicitar recibir piezas específicas o una copia de su información
                personal, incluida, cuando corresponda, una copia de la información personal que nos proporcionó en un
                formato portátil. Dependiendo de dónde resida, puede solicitar que le revelemos la siguiente
                información:{" "}
                <ol type="i">
                  <li>
                    Las categorías de información personal que recopilamos sobre usted y las categorías de fuentes de
                    las que recopilamos dicha información personal;
                  </li>{" "}
                  <li>
                    El propósito comercial o empresarial para recopilar, vender o compartir (si corresponde) información
                    personal sobre usted;
                  </li>{" "}
                  <li>
                    Las categorías de información personal sobre usted que vendimos o “compartimos” y las categorías de
                    terceros con quienes vendimos o “compartimos” dicha información personal (si corresponde); y
                  </li>{" "}
                  <li>
                    Las categorías de información personal sobre usted que de otra manera divulgamos y las categorías de
                    terceros con quienes divulgamos dicha información personal (si corresponde).
                  </li>
                </ol>
              </li>{" "}
              <li>
                <u>Solicitud de eliminación.</u> Puede solicitar que eliminemos su información personal.
              </li>
              <li>
                <u>Solicitud de apelación.</u> Si nos negamos a actuar conforme a su solicitud, usted puede apelar
                nuestra negativa dentro de un período razonable después de haber recibido la notificación de la
                negativa.
              </li>{" "}
              <li>
                <u>Solicitud de corrección.</u> Puede solicitar que corrijamos inexactitudes en su información personal.
              </li>{" "}
              <li>
                <u>
                  Solicitud de exclusión voluntaria de venta, publicidad dirigida, limitación de divulgación y/o
                  intercambio de información confidencial.
                </u>{" "}
                Puede solicitar la exclusión voluntaria de la “venta” de su información personal y/o publicidad
                dirigida, incluido el “intercambio” de su información personal para fines de publicidad conductual en
                contexto cruzado. También puede limitar la divulgación y el intercambio de su información confidencial.
              </li>
            </ol>
          </p>

          <p>
            <strong>Presentar una solicitud de datos</strong>
            <br /> Para enviar una solicitud de derechos de datos de acuerdo con lo anterior, visite nuestra{" "}
            <a href="https://www.debt.com/es/pagina-de-solicitud-de-datos/" target="_blank" rel="noopener noreferrer">
              página de Solicitud de derechos de datos
            </a>
            .
          </p>

          <p>
            <strong>Herramientas de toma de decisiones automatizadas</strong>{" "}
            <ol type="a">
              <li>
                En el caso de que se utilicen procesos automatizados de toma de decisiones en nuestro Sitio en relación
                con una solicitud o pedido suyo, dependiendo de dónde resida, puede solicitar que le revelemos lo
                siguiente: Que la decisión respecto de su solicitud se haya tomado mediante sistemas o procesos
                automatizados de toma de decisiones;
              </li>{" "}
              <li>
                Si no está satisfecho con la decisión, tiene derecho a solicitar una explicación de cómo se tomó la
                decisión mediante nuestro proceso automatizado de toma de decisiones y cómo puede proceder para cambiar
                o corregir la decisión.
              </li>{" "}
              <li>
                Ciertas funciones de nuestro(s) Sitio(s) están sujetas a toma de decisiones automatizada. Cualquier
                información que usted proporcione al utilizar dichas funciones se traducirá en una decisión automatizada
                con respecto a su consulta o solicitud. Usted comprende y acepta que estas funciones de toma de
                decisiones automatizadas son desarrolladas por proveedores de tecnología de terceros que tendrán acceso
                a su información personal que pueda proporcionar en nuestro Sitio al utilizar las funciones de toma de
                decisiones automatizadas. Su uso de estas funciones autoriza expresamente a compartir la información con
                estos terceros. Además, usted comprende que los mecanismos o procesos de las funciones de toma de
                decisiones automatizadas no están controlados por Debt.com, ni Debt.com tiene la capacidad de afectar
                los resultados de las decisiones.
              </li>
              <li>
                <strong>El Instant Debt Advisor℠ (“Asesor de Deuda”):</strong> El Asesor de Deuda le hace preguntas
                simples y obtiene de usted cierta información personal identificable, que incluye, entre otros:{" "}
                <strong>
                  <em>
                    su nombre legal completo, dirección, número de teléfono, número de seguro social, aprobación para
                    hacer una consulta suave a su crédito, finanzas actuales y otra información relevante sobre su deuda
                    y situación financiera.
                  </em>
                </strong>{" "}
                Usted comprende y acepta que All Clear, como desarrollador de la plataforma Asesor de Deuda, puede tener
                acceso a la información que usted ingresa o proporciona al Asesor de Deuda, y puede compartirla con
                proveedores de servicios independientes con los que se empareja al consumidor. Además de la política de
                privacidad de Debt.com, la información también estará sujeta a la política de privacidad de All Clear.
                Para obtener más información sobre la Política de Privacidad de All Clear, visite{" "}
                <a href="https://allclear.ai/legal-notices/#privacy-policy" target="_blank" rel="noopener noreferrer">
                  Política de Privacidad de All Clear
                </a>
                .
              </li>
            </ol>
          </p>

          <p>
            <strong>Información Personal Confidencial</strong>
            <br />
            Podemos recopilar y divulgar cierta información que puede considerarse “información confidencial” según las
            leyes de privacidad de datos aplicables. Podemos divulgar esta información a afiliados y a terceros no
            afiliados para diversos fines, incluido el de marcar o brindarle servicios que usted tiene. Dependiendo de
            dónde resida, es posible que tenga derecho a limitar la divulgación de esta información a terceros no
            relacionados para fines de marketing. Para enviar una solicitud para limitar la divulgación de su
            información confidencial, visite nuestra{" "}
            <a href="https://www.debt.com/es/pagina-de-solicitud-de-datos/" target="_blank" rel="noopener noreferrer">
              Página de Solicitud de Derechos de Datos.
            </a>
          </p>

          <p>
            <strong>Marketing directo gestionado por Debt.com:</strong> Si prefiere que no le enviemos ofertas de
            marketing por correo electrónico, correo postal o telemercadeo, puede administrar sus opciones de marketing
            directo enviándonos sus solicitudes por correo electrónico a{" "}
            <strong>
              <a href="mailto:admin@Debt.com">admin@Debt.com</a>
            </strong>
            .
          </p>

          <p>
            <strong>
              Política de exclusión voluntaria para suscripciones a boletines informativos y correo electrónico
            </strong>
            <br /> Puede optar por no recibir o darse de baja de un boletín informativo u otra lista de correo
            electrónico en cualquier momento, para lo cual debe seguir las instrucciones que se encuentran al final de
            los boletines o mensajes de correo electrónico que reciba. Espere de cinco a diez (5 a 10) días hábiles para
            que los cambios entren en vigencia. Tenga en cuenta que todas las solicitudes de cancelación se atenderán el
            décimo día hábil. Usted comprende y acepta que durante ese período y mientras su solicitud esté pendiente,
            es posible que continúe recibiendo boletines informativos y mensajes de correo electrónico. Las
            comunicaciones relacionadas con el servicio al cliente son una parte esencial de los servicios que recibe de
            nosotros, y es posible que continúe recibiendo dichos mensajes de correo electrónico relacionados con el
            servicio a menos que cancele su cuenta, incluso si opta por no recibir los boletines informativos o la lista
            de correo electrónico. Si nos ha proporcionado más de una dirección de correo electrónico, es posible que
            sigamos poniéndonos en contacto con usted a menos que solicite cancelar la suscripción de cada dirección de
            correo electrónico que haya proporcionado. Otra opción para dejar de recibir nuestro boletín informativo o
            mensajes de correo electrónico de marketing es comunicarse con nosotros en{" "}
            <a href="mailto:unsubscribe@Debt.com">unsubscribe@Debt.com</a> o responder un mensaje de correo electrónico
            existente e incluir en él su solicitud de que lo eliminen de la lista de correo.
          </p>

          <h3>VI. SEGURIDAD DE DATOS</h3>
          <p>
            En Debt.com, la seguridad de su información personal es extremadamente importante para nosotros. Tomamos
            precauciones razonables desde el punto de vista comercial para proteger su información personal y solo
            brindamos acceso a ella a los empleados cuando es necesario. En un esfuerzo por evitar el acceso no
            autorizado, la divulgación, la difusión, el uso indebido, la pérdida, la alteración o la destrucción de su
            información personal, hemos empleado medidas físicas, electrónicas y administrativas razonables desde el
            punto de vista comercial para proteger sus datos, y revisamos periódicamente estas políticas y
            procedimientos para prevenir este tipo de actos. Sin embargo, a pesar de nuestros mejores esfuerzos, ninguna
            política, procedimiento o práctica de seguridad es adecuada para proteger contra todo tipo de amenazas o
            pérdidas de datos, y ningún tipo de método de transmisión de datos es impenetrable a la interceptación.
          </p>

          <p>
            <u>Enlaces a otros sitios</u>: Podemos proporcionar enlaces a sitios de terceros. Si sigue enlaces a sitios
            no relacionados ni controlados por Debt.com, debe revisar sus políticas de privacidad y seguridad y otros
            términos y condiciones, ya que pueden ser diferentes de los de nuestro Sitio. Debt.com no garantiza ni es
            responsable de ninguna interacción entre usted y los sitios de terceros, tal como la privacidad o seguridad
            de estos sitios, incluida la exactitud, integridad o confiabilidad de su información. Usted reconoce que la
            disponibilidad de enlaces a sitios de terceros es simplemente para fines de comodidad, y que Debt.com no
            respalda, apoya, reconoce, acepta ni promueve de ninguna otra manera los contenidos de los sitios de
            terceros con enlaces en nuestro Sitio.
          </p>

          <p>
            <u>Seguridad</u>: Para proteger la información personal del acceso y uso no autorizados, utilizamos medidas
            de seguridad que cumplen con las leyes federales y estatales aplicables. Estas medidas pueden incluir
            protecciones de dispositivos y archivos y edificios seguros, así como la supervisión de nuestros proveedores
            de servicios externos para garantizar que la información permanezca confidencial y segura. Si bien seguimos
            estándares del sector generalmente aceptados para proteger la Información Personal Confidencial y la PII que
            se nos envía (tanto durante su envío como después de recibirla), tenga en cuenta que ningún método de
            transmisión por Internet, o método de almacenamiento electrónico, es 100% seguro. Por lo tanto, si bien nos
            esforzamos por utilizar medios comercialmente aceptables de proteger su información personal y su PII, no
            podemos garantizar su seguridad absoluta. Si tiene alguna pregunta sobre la seguridad en nuestro sitio web,
            puede escribirnos por correo electrónico a{" "}
            <strong>
              <a href="mailto:admin@Debt.com">admin@Debt.com</a>
            </strong>
            .
          </p>

          <p>
            <u>Retención de datos</u>: Conservamos la información personal durante el tiempo que sea necesario o
            permitido en función del propósito para el que fue recopilada. Los criterios utilizados para determinar
            nuestros períodos de retención incluyen:
            <ul>
              <li>
                El tiempo que mantengamos una relación continua con usted y según sea necesario para brindarle los
                servicios solicitados;
              </li>{" "}
              <li>
                Si existe una obligación legal a la que estamos sujetos (por ejemplo, ciertas leyes requieren que
                mantengamos registros de sus transacciones durante un cierto período de tiempo antes de que podamos
                eliminarlas); y
              </li>{" "}
              <li>
                Si la retención es aconsejable considerando nuestra posición legal (por ejemplo, para plazos de
                prescripción, litigios o investigaciones regulatorias).
              </li>
            </ul>
          </p>

          <h3>VII. DIVULGACIONES DE PRIVACIDAD ESTATALES:</h3>
          <p>
            Esta sección proporciona información específica de cada estado que se aplica únicamente a los consumidores
            que residen en los estados enumerados. Si usted es residente de uno de estos estados, tiene ciertos derechos
            con respecto a la información personal recopilada y el procesamiento de su información personal que están
            sujetos a las leyes de privacidad estatales aplicables.
          </p>

          <p>
            <strong>
              <u>Aviso para residentes de California</u>
            </strong>
            : Como residente de California, usted tiene los siguientes derechos con respecto a su información personal:
            <ol type="A">
              <li>
                <strong>
                  <u>Acceso</u>
                </strong>
                . Usted tiene derecho, dos veces en un período de 12 meses, a solicitar que nuestra Compañía le revele
                la información personal que recopilamos sobre usted y cualquier información adicional sobre nuestra
                recopilación, uso, divulgación o venta de dicha información personal, que también está contenida en esta
                política de privacidad.
              </li>{" "}
              <li>
                <strong>
                  <u>Corrección</u>
                </strong>
                . Usted tiene derecho a solicitar que corrijamos información personal inexacta en determinadas
                circunstancias, sujeto a una serie de excepciones previstas en la ley de California. Para obtener
                información sobre cómo solicitar la corrección de información personal, consulte la sección V de esta
                política, y específicamente la subsección titulada <strong>Envío de una solicitud de datos</strong>.
              </li>{" "}
              <li>
                <strong>
                  <u>Eliminación</u>
                </strong>
                . Tiene derecho a solicitar que eliminemos su información personal en determinadas circunstancias,
                sujeto a una serie de excepciones previstas por la ley de California. Para obtener información sobre
                cómo solicitar la eliminación de información personal, consulte la sección V de esta política y,
                específicamente, la subsección titulada <strong>Envío de una solicitud de datos</strong>.
              </li>
              <li>
                <strong>
                  <u>Exclusión voluntaria de la venta o intercambio de sus datos o de marketing conjunto</u>
                </strong>
                . Tiene derecho a optar por no vender ni compartir sus datos. También tiene derecho a optar por no
                participar en el esfuerzo de marketing conjunto que la Compañía realiza con otros proveedores de
                servicios financieros. La CCPA requiere describir las categorías de información personal que nuestra
                Compañía vende o comparte con terceros y cómo optar por no participar en futuras ventas o intercambios
                de dicha información. La CCPA define una “venta” de manera amplia para incluir la mera puesta a
                disposición de terceros de su información personal en algunos casos. Según la CCPA, el término
                “compartir” se define como proporcionar información personal a un tercero para orientar la publicidad a
                un consumidor en función de información sobre su actividad en varios sitios web de Internet. Para
                obtener información sobre cómo solicitar la exclusión voluntaria de la venta o el intercambio de su
                información personal, consulte la sección V de esta política y, específicamente, la subsección titulada{" "}
                <strong>Envío de una solicitud de datos</strong>.
              </li>
              <li>
                <strong>
                  <u>Solicitud de divulgación</u>
                </strong>
                . Tiene derecho a solicitar que le proporcionemos (i) una lista de ciertas categorías de información
                personal que hemos revelado a terceros para sus fines de marketing directo durante el año calendario
                inmediatamente anterior; y (ii) la identidad de esos terceros. Para obtener información sobre cómo
                obtener una solicitud de divulgación de su información personal, consulte la sección V de esta política,
                y específicamente la subsección titulada <strong>Envío de una solicitud de datos.</strong>
              </li>
              <li>
                <strong>
                  <u>Apelación</u>
                </strong>
                . Tiene derecho a apelar la decisión de la Compañía de negarse a actuar sobre una solicitud de
                privacidad de datos de la CCPA dentro de un período razonable después de recibir nuestra decisión. Para
                apelar nuestra decisión, debe enviar su objeción a nuestra denegación por correo electrónico a{" "}
                <strong>
                  <a href="mailto:Privacy@Debt.com">Privacy@Debt.com</a>
                </strong>{" "}
                y permitir que la Compañía revise su solicitud de titular de los datos. Dentro de los 45 días, la
                Compañía le proporcionará una explicación por escrito de la justificación para negarse a actuar conforme
                a su solicitud.
              </li>
              <li>
                <strong>
                  <u>Autenticación/Verificación</u>
                </strong>
                . Para ayudar a proteger su privacidad y mantener la seguridad, tomaremos medidas para verificar su
                identidad antes de otorgarle acceso a su información personal o cumplir con su solicitud (a excepción de
                una solicitud para optar por no vender o compartir). La Compañía puede solicitarle que proporcione
                cualquiera de la siguiente información:{" "}
                <strong>
                  su nombre, fecha de nacimiento, su número de teléfono, su número de Seguro Social, su dirección de
                  correo electrónico y dirección física
                </strong>
                . Si solicita que le proporcionemos información personal específica, es posible que le solicitemos que
                firme una declaración bajo pena de perjurio de que usted es el consumidor cuya información personal es
                objeto de la solicitud, o personal autorizado del consumidor que realiza la solicitud. Usted comprende
                que la Compañía puede negarse a procesar su solicitud cuando: (i) la Compañía no puede autenticar que
                usted es la persona a la que se refieren los datos; (ii) la solicitud es irrazonable o excesiva; (iii) o
                cuando lo permita la ley aplicable.
              </li>
              <li>
                <strong>
                  <u>Retención</u>
                </strong>
                . Conservamos datos personales durante el tiempo que sea necesario para proporcionar los Servicios y
                cumplir con las transacciones que ha solicitado, cumplir con nuestras obligaciones legales, resolver
                disputas, hacer cumplir nuestros acuerdos y otros fines comerciales legítimos y legales. Debido a que
                estas necesidades pueden variar para diferentes tipos de datos en el contexto de diferentes Servicios,
                los períodos de retención reales pueden variar significativamente según criterios como las expectativas
                o el consentimiento del usuario, la sensibilidad de los datos, la disponibilidad de controles
                automatizados que permitan a los usuarios eliminar datos y nuestras obligaciones legales o
                contractuales.
              </li>
              <li>
                <strong>
                  <u>Aviso de incentivos financieros</u>
                </strong>
                . La Compañía puede ofrecer recompensas o premios por la participación en ciertas actividades que pueden
                considerarse un “incentivo financiero” según la ley de California. Estas actividades pueden implicar la
                recopilación de información personal. Las categorías de información personal que recopilamos se limitan
                a la información que usted nos proporciona, pero pueden incluir: identificadores, información
                demográfica/de clase protegida, información comercial, actividades en línea, información de
                geolocalización (general y precisa), información sensorial, información de empleo e inferencias. Las
                actividades que realizamos y que pueden considerarse un incentivo financiero incluyen encuestas en las
                que podemos ofrecer una compensación, como una tarjeta de regalo o un bono a cambio de su tiempo y
                respuestas, o un premio a través de su participación en promociones y sorteos. La participación en estos
                programas puede estar sujeta a términos y condiciones separados. Su participación en estos programas es
                voluntaria y puede cancelarla en cualquier momento según se explica en los términos aplicables. Si
                ofrecemos tarjetas de regalo a cambio de su participación en una encuesta o cuando participamos en
                promociones o sorteos, la cantidad proporcionada está razonablemente relacionada con el valor de los
                datos que usted proporciona, que tiene en cuenta una serie de factores, incluido el beneficio anticipado
                que recibimos, como la mejora del producto, una mejor comprensión de cómo utiliza nuestros productos,
                para mejorar nuestra comprensión de las tendencias del consumidor y del mercado, una mayor participación
                del consumidor y los gastos anticipados en los que incurrimos en relación con la recopilación, el
                almacenamiento y el uso de la información que recibimos. El valor puede variar según encuestas,
                promociones y sorteos.
              </li>
              <li>
                <strong>
                  <u>Solicitudes rechazadas</u>
                </strong>
                . Usted comprende que podemos rechazar solicitudes cuando concederlas esté prohibido por ley, pueda
                afectar negativamente la privacidad o los derechos de otra persona, revele un secreto comercial u otra
                información confidencial, interfiera con una obligación legal o comercial que requiera la retención o el
                uso de los datos, o porque los datos en cuestión no estén cubiertos por la ley que usted reclama.
                Además, cuando lo permita la ley si nos envía una solicitud para ejercer sus derechos o las opciones de
                esta sección, podemos cobrar una tarifa o rechazar solicitudes en ciertos casos.
              </li>
              <li>
                <strong>
                  <u>No discriminación</u>
                </strong>
                . Usted tiene derecho a no ser discriminado por ejercer cualquiera de sus derechos de privacidad.
              </li>
            </ol>
          </p>

          <p>
            <strong>
              <u>Aviso para los residentes de Nevada</u>
            </strong>
            . Le proporcionamos este Aviso de conformidad con la ley estatal. Es posible que lo incluyan en nuestra
            lista interna de no llamar si sigue las instrucciones establecidas anteriormente. La ley de Nevada exige que
            también le proporcionemos la siguiente información de contacto: Bureau of Consumer Protection, Office of the
            Nevada Attorney General, 555 E. Washington St., Suite 3900, Las Vegas, NV 89101; número de teléfono:
            702-486-3132; correo electrónico:{" "}
            <strong>
              <a href="mailto:BCPINFO@ag.state.nv.us.">BCPINFO@ag.state.nv.us</a>
            </strong>
            .
          </p>

          <p>
            <strong>
              <u>Aviso para los residentes de Colorado.</u>
            </strong>{" "}
            Si usted es residente de Colorado, tiene los siguientes derechos con respecto a su información personal:
            <ol type="A">
              <li>
                <strong>
                  <u>Acceso</u>
                </strong>
                . Usted tiene derecho, dos veces en un período de 12 meses, a solicitar que nuestra Compañía le revele
                la información personal que recopilamos sobre usted y cualquier información adicional sobre nuestra
                recopilación, uso, divulgación o venta de dicha información personal, que también está contenida en esta
                política de privacidad.
              </li>
              <li>
                <strong>
                  <u>Corrección</u>
                </strong>
                . Tiene derecho a solicitar que corrijamos información personal inexacta en determinadas circunstancias,
                sujeto a una serie de excepciones previstas en la ley de Colorado. Para obtener información sobre cómo
                solicitar la corrección de información personal, consulte la sección V de esta política, y
                específicamente la subsección titulada <strong>Envío de una solicitud de datos</strong>.
              </li>
              <li>
                <strong>
                  <u>Eliminación</u>
                </strong>
                . Tiene derecho a solicitar que eliminemos su información personal en determinadas circunstancias,
                sujeto a una serie de excepciones previstas bajo la ley de Colorado. Para obtener información sobre cómo
                solicitar la eliminación de información personal, consulte la sección V de esta política y,
                específicamente, la subsección titulada <strong>Envío de una solicitud de datos</strong>.
              </li>
              <li>
                <strong>
                  <u>Exclusión voluntaria de la venta o intercambio de sus datos o de marketing conjunto</u>
                </strong>
                . Tiene derecho a optar por no vender ni compartir sus datos. También tiene derecho a optar por no
                participar en el esfuerzo de marketing conjunto que la Compañía realiza con otros proveedores de
                servicios financieros. La ley de Colorado requiere describir las categorías de información personal que
                nuestra compañía vende o comparte con terceros y cómo optar por no participar en futuras ventas o
                intercambios de dicha información. La ley de Colorado define una “Venta” de manera amplia para incluir
                el mero hecho de poner su información personal a disposición de terceros en algunos casos. Según la ley
                de Colorado, el término “compartir” se define como proporcionar información personal a un tercero para
                orientar la publicidad a un consumidor en función de información sobre su actividad en varios sitios web
                de Internet. Para obtener información sobre cómo solicitar la exclusión voluntaria de la venta o el
                intercambio de su información personal, consulte la sección V de esta política y, específicamente, la
                subsección titulada <strong>Envío de una solicitud de datos</strong>.
              </li>
              <li>
                <strong>
                  <u>Solicitud de divulgación</u>
                </strong>
                . Tiene derecho a solicitar que le proporcionemos (i) una lista de ciertas categorías de información
                personal que hemos revelado a terceros para sus fines de marketing directo durante el año calendario
                inmediatamente anterior; y (ii) la identidad de esos terceros. Para obtener información sobre cómo
                obtener una solicitud de divulgación de su información personal, consulte la sección V de esta política,
                y específicamente la subsección titulada <strong>Envío de una solicitud de datos</strong>.
              </li>
              <li>
                <strong>
                  <u>Apelación</u>
                </strong>
                . Tiene derecho a apelar la decisión de la Compañía de negarse a actuar sobre una solicitud de
                privacidad de datos según la ley de Colorado dentro de un período razonable después de recibir nuestra
                decisión. Para apelar nuestra decisión, debe enviar su objeción a nuestra denegación por correo
                electrónico a{" "}
                <strong>
                  <a href="mailto:Privacy@Debt.com">Privacy@Debt.com</a>
                </strong>{" "}
                y permitir que la Compañía revise su solicitud de titular de los datos. Dentro de los 45 días, la
                Compañía le proporcionará una explicación por escrito de la justificación para negarse a actuar conforme
                a su solicitud.
              </li>
              <li>
                <strong>
                  <u>Autenticación/Verificación</u>
                </strong>
                . Para ayudar a proteger su privacidad y mantener la seguridad, tomaremos medidas para verificar su
                identidad antes de otorgarle acceso a su información personal o cumplir con su solicitud (a excepción de
                una solicitud para optar por no vender o compartir). La Compañía puede solicitarle que proporcione
                cualquiera de la siguiente información:{" "}
                <strong>
                  su nombre, fecha de nacimiento, su número de teléfono, su número de Seguro Social, su dirección de
                  correo electrónico y dirección física
                </strong>
                . Si solicita que le proporcionemos información personal específica, es posible que le solicitemos que
                firme una declaración bajo pena de perjurio de que usted es el consumidor cuya información personal es
                objeto de la solicitud, o personal autorizado del consumidor que realiza la solicitud. Usted comprende
                que la Compañía puede negarse a procesar su solicitud cuando: (i) la Compañía no puede autenticar que
                usted es la persona a la que se refieren los datos; (ii) la solicitud es irrazonable o excesiva; (iii) o
                cuando lo permita la ley aplicable.
              </li>
              <li>
                <strong>
                  <u>Retención</u>
                </strong>
                . Conservamos datos personales durante el tiempo que sea necesario para proporcionar los Servicios y
                cumplir con las transacciones que ha solicitado, cumplir con nuestras obligaciones legales, resolver
                disputas, hacer cumplir nuestros acuerdos y otros fines comerciales legítimos y legales. Debido a que
                estas necesidades pueden variar para diferentes tipos de datos en el contexto de diferentes Servicios,
                los períodos de retención reales pueden variar significativamente según criterios como las expectativas
                o el consentimiento del usuario, la sensibilidad de los datos, la disponibilidad de controles
                automatizados que permitan a los usuarios eliminar datos y nuestras obligaciones legales o
                contractuales.
              </li>
              <li>
                <strong>
                  <u>Solicitudes rechazadas</u>
                </strong>
                . Usted comprende que podemos rechazar solicitudes cuando concederlas esté prohibido por ley, pueda
                afectar negativamente la privacidad o los derechos de otra persona, revele un secreto comercial u otra
                información confidencial, interfiera con una obligación legal o comercial que requiera la retención o el
                uso de los datos, o porque los datos en cuestión no estén cubiertos por la ley que usted reclama.
                Además, cuando lo permita la ley si nos envía una solicitud para ejercer sus derechos o las opciones de
                esta sección, podemos cobrar una tarifa o rechazar solicitudes en ciertos casos.
              </li>
              <li>
                <strong>
                  <u>No discriminación</u>
                </strong>
                . Usted tiene derecho a no ser discriminado por ejercer cualquiera de sus derechos de privacidad.
              </li>
            </ol>
          </p>

          <p>
            <strong>
              <u>Aviso para residentes de Vermont</u>
            </strong>
            . Como residente de Vermont, usted tiene derecho a optar por no vender o compartir sus datos. También tiene
            derecho a optar por no participar en el esfuerzo de marketing conjunto que la Compañía realiza con otros
            proveedores de servicios financieros.
          </p>

          <p>
            <strong>
              <u>Aviso para residentes de Connecticut</u>
            </strong>
            . Como residente de Connecticut, usted tiene los siguientes derechos con respecto a su información personal:
            <ol type="A">
              <li>
                <strong>
                  <u>Acceso</u>
                </strong>
                . Usted tiene derecho, dos veces en un período de 12 meses, a solicitar que nuestra Compañía le revele
                la información personal que recopilamos sobre usted y cualquier información adicional sobre nuestra
                recopilación, uso, divulgación o venta de dicha información personal, que también está contenida en esta
                política de privacidad.
              </li>
              <li>
                <strong>
                  <u>Corrección</u>
                </strong>
                . Tiene derecho a solicitar que corrijamos información personal inexacta en determinadas circunstancias,
                sujeto a una serie de excepciones previstas en la CTDPA. Para obtener información sobre cómo solicitar
                la corrección de información personal, consulte la sección V de esta política, y específicamente la
                subsección titulada <strong>Envío de una solicitud de datos</strong>.
              </li>
              <li>
                <strong>
                  <u>Eliminación</u>
                </strong>
                . Tiene derecho a solicitar que eliminemos su información personal en determinadas circunstancias,
                sujeto a una serie de excepciones previstas en la CTDPA. Para obtener información sobre cómo solicitar
                la eliminación de información personal, consulte la sección V de esta política y, específicamente, la
                subsección titulada <strong>Envío de una solicitud de datos</strong>.
              </li>
              <li>
                <strong>
                  <u>Exclusión voluntaria de publicidad dirigida</u>
                </strong>
                . Tiene derecho a rechazar la publicidad dirigida y la venta de datos personales. Para obtener
                información sobre cómo solicitar una exclusión voluntaria de la publicidad dirigida, consulte la sección
                V de esta política y, específicamente, la subsección titulada{" "}
                <strong>Envío de una solicitud de datos</strong>.
              </li>
              <li>
                <strong>
                  <u>Apelación</u>
                </strong>
                . Tiene derecho a apelar la decisión de la Compañía de negarse a actuar sobre una solicitud de
                privacidad de datos de CTDPA dentro de un período razonable después de recibir nuestra decisión. Para
                apelar nuestra decisión, debe enviar su objeción a nuestra denegación por correo electrónico a{" "}
                <strong>
                  <a href="mailto:Privacy@Debt.com">Privacy@Debt.com</a>
                </strong>{" "}
                y permitir que la Compañía revise su solicitud de titular de los datos. Dentro de los 60 días, la
                Compañía le proporcionará una explicación por escrito de la justificación para negarse a actuar conforme
                a su solicitud. Si no está de acuerdo con nuestra explicación, tiene derecho a comunicarse o presentar
                una queja ante el <u>Fiscal General de Connecticut</u>.
              </li>
              <li>
                <strong>
                  <u>Autenticación/Verificación</u>
                </strong>
                . Para ayudar a proteger su privacidad y mantener la seguridad, tomaremos medidas para verificar su
                identidad antes de otorgarle acceso a su información personal o cumplir con su solicitud (a excepción de
                una solicitud para optar por no vender o compartir). La Compañía puede solicitarle que proporcione
                cualquiera de la siguiente información: su nombre, fecha de nacimiento, su número de teléfono,{" "}
                <strong>su número de Seguro Social, su dirección de correo electrónico y dirección física</strong>. Si
                solicita que le proporcionemos información personal específica, es posible que le solicitemos que firme
                una declaración bajo pena de perjurio de que usted es el consumidor cuya información personal es objeto
                de la solicitud, o personal autorizado del consumidor que realiza la solicitud. Usted comprende que la
                Compañía puede negarse a procesar su solicitud cuando: (i) la Compañía no puede autenticar que usted es
                la persona a la que se refieren los datos; (ii) la solicitud es irrazonable o excesiva; (iii) o cuando
                lo permita la ley aplicable.
              </li>
              <li>
                <strong>
                  <u>Solicitudes rechazadas</u>
                </strong>
                . Usted comprende que podemos rechazar solicitudes cuando concederlas esté prohibido por ley, pueda
                afectar negativamente la privacidad o los derechos de otra persona, revele un secreto comercial u otra
                información confidencial, interfiera con una obligación legal o comercial que requiera la retención o el
                uso de los datos, o porque los datos en cuestión no estén cubiertos por la ley que usted reclama.
                Además, cuando lo permita la ley si nos envía una solicitud para ejercer sus derechos o las opciones de
                esta sección, podemos cobrar una tarifa o rechazar solicitudes en ciertos casos.
              </li>
              <li>
                <strong>
                  <u>No discriminación para ejercer sus derechos</u>
                </strong>
                . Usted tiene derecho a no ser discriminado por ejercer cualquiera de sus derechos de privacidad.
              </li>
            </ol>
          </p>

          <p>
            <strong>
              <u>Aviso para los residentes de Dakota del Norte</u>
            </strong>
            . Como residente de Dakota del Norte, usted tiene derecho a optar por no participar en marketing conjunto
            con otras instituciones financieras. Para obtener información sobre cómo solicitar la exclusión voluntaria
            del marketing conjunto, consulte la sección V de esta política y, específicamente, la subsección titulada{" "}
            <strong>Envío de una solicitud de datos</strong>.
          </p>

          <p>
            <strong>
              <u>Aviso para los residentes de Utah</u>
            </strong>
            . Como residente de Utah, usted tiene los siguientes derechos con respecto a su información personal:
            <ol type="A">
              <li>
                <strong>
                  <u>Acceso</u>
                </strong>
                . Usted tiene derecho, dos veces en un período de 12 meses, a solicitar que nuestra Compañía le revele
                la información personal que recopilamos sobre usted y cualquier información adicional sobre nuestra
                recopilación, uso, divulgación o venta de dicha información personal, que también está contenida en esta
                política de privacidad.
              </li>
              <li>
                <strong>
                  <u>Obtener una copia portátil de sus datos personales</u>
                </strong>
                . Para obtener una copia de sus datos personales que nos proporcionó previamente en un formato portátil,
                envíe una solicitud de “Acceso” como se describe anteriormente. Si bien estas solicitudes son distintas,
                no hemos identificado ningún formato técnicamente factible y fácilmente utilizable que le permita
                transmitir estos datos a otro controlador. Por lo tanto, le proporcionaremos una copia de sus datos
                personales para que podamos honrar su solicitud de la mejor manera técnicamente posible.
              </li>
              <li>
                <strong>
                  <u>Eliminación</u>
                </strong>
                . Tiene derecho a solicitar que eliminemos su información personal en determinadas circunstancias,
                sujeto a una serie de excepciones previstas por la ley de Utah. Para obtener información sobre cómo
                solicitar la eliminación de información personal, consulte la sección V de esta política y,
                específicamente, la subsección titulada <strong>Envío de una solicitud de datos</strong>.
              </li>
              <li>
                <strong>
                  <u>Exclusión voluntaria de publicidad dirigida</u>
                </strong>
                . Tiene derecho a rechazar la publicidad dirigida y la venta de datos personales. Para obtener
                información sobre cómo solicitar la exclusión voluntaria de la publicidad dirigida, consulte la sección
                V de esta política y, específicamente, la subsección titulada{" "}
                <strong>Envío de una solicitud de datos</strong>.
              </li>
              <li>
                <strong>
                  <u>Autenticación/Verificación</u>
                </strong>
                . Para ayudar a proteger su privacidad y mantener la seguridad, tomaremos medidas para verificar su
                identidad antes de otorgarle acceso a su información personal o cumplir con su solicitud (a excepción de
                una solicitud para optar por no vender o compartir). La Compañía puede solicitarle que proporcione
                cualquiera de la siguiente información: su nombre, fecha de nacimiento, su número de teléfono,{" "}
                <strong>su número de Seguro Social, su dirección de correo electrónico y dirección física</strong>. Si
                solicita que le proporcionemos información personal específica, es posible que le solicitemos que firme
                una declaración bajo pena de perjurio de que usted es el consumidor cuya información personal es objeto
                de la solicitud, o personal autorizado del consumidor que realiza la solicitud. Usted comprende que la
                Compañía puede negarse a procesar su solicitud cuando: (i) la Compañía no puede autenticar que usted es
                la persona a la que se refieren los datos; (ii) la solicitud es irrazonable o excesiva; (iii) o cuando
                lo permita la ley aplicable.
              </li>
              <li>
                <strong>
                  <u>Solicitudes rechazadas</u>
                </strong>
                . Usted comprende que podemos rechazar solicitudes cuando concederlas esté prohibido por ley, pueda
                afectar negativamente la privacidad o los derechos de otra persona, revele un secreto comercial u otra
                información confidencial, interfiera con una obligación legal o comercial que requiera la retención o el
                uso de los datos, o porque los datos en cuestión no estén cubiertos por la ley que usted reclama.
                Además, cuando lo permita la ley si nos envía una solicitud para ejercer sus derechos o las opciones de
                esta sección, podemos cobrar una tarifa o rechazar solicitudes en ciertos casos.
              </li>
              <li>
                <strong>
                  <u>No discriminación</u>
                </strong>
                . Usted tiene derecho a no ser discriminado por ejercer cualquiera de sus derechos de privacidad.
              </li>
            </ol>
          </p>

          <p>
            <strong>
              <u>Aviso para los residentes de Virginia</u>
            </strong>
            . Como residente de Virginia, usted tiene los siguientes derechos con respecto a su información personal:
            <ol type="A">
              <li>
                <strong>
                  <u>Acceso</u>
                </strong>
                . Usted tiene derecho, dos veces en un período de 12 meses, a solicitar que nuestra Compañía le revele
                la información personal que recopilamos sobre usted y cualquier información adicional sobre nuestra
                recopilación, uso, divulgación o venta de dicha información personal, que también está contenida en esta
                política de privacidad.
              </li>
              <li>
                <strong>
                  <u>Corrección</u>
                </strong>
                . Usted tiene derecho a solicitar que corrijamos información personal inexacta en determinadas
                circunstancias, sujeto a una serie de excepciones previstas en la ley de Virginia. Para obtener
                información sobre cómo solicitar la corrección de información personal, consulte la sección V de esta
                política, y específicamente la subsección titulada Envío de una solicitud de datos.
              </li>
              <li>
                <strong>
                  <u>Eliminación</u>
                </strong>
                . Tiene derecho a solicitar que eliminemos su información personal en determinadas circunstancias,
                sujeto a una serie de excepciones previstas bajo la ley de Virginia. Para obtener información sobre cómo
                solicitar la eliminación de información personal, consulte la sección V de esta política y,
                específicamente, la subsección titulada Envío de una solicitud de datos.
              </li>
              <li>
                <strong>
                  <u>Exclusión voluntaria de publicidad dirigida</u>
                </strong>
                . Tiene derecho a rechazar la publicidad dirigida y la venta de datos personales. Para obtener
                información sobre cómo solicitar la exclusión voluntaria de la publicidad dirigida, consulte la sección
                V de esta política y, específicamente, la subsección titulada{" "}
                <strong>Envío de una solicitud de datos</strong>.
              </li>
              <li>
                <strong>
                  <u>Obtener una copia portátil de sus datos personales</u>
                </strong>
                . Para obtener una copia de sus datos personales que nos proporcionó previamente en un formato portátil,
                envíe una solicitud de “Acceso” como se describe anteriormente. Si bien estas solicitudes son distintas,
                no hemos identificado ningún formato técnicamente factible y fácilmente utilizable que le permita
                transmitir estos datos a otro controlador. Por lo tanto, le proporcionaremos una copia de sus datos
                personales para que podamos honrar su solicitud de la mejor manera técnicamente posible.
              </li>
              <li>
                <strong>
                  <u>Apelación</u>
                </strong>
                . Tiene derecho a apelar la decisión de la Compañía de negarse a actuar sobre una solicitud de
                privacidad de datos de CTDPA dentro de un período razonable después de recibir nuestra decisión. Para
                apelar nuestra decisión, debe enviar su objeción a nuestra denegación por correo electrónico a{" "}
                <strong>
                  <a href="mailto:Privacy@Debt.com">Privacy@Debt.com</a>
                </strong>{" "}
                y permitir que la Compañía revise su solicitud de titular de los datos. Dentro de los 60 días, la
                Compañía le proporcionará una explicación por escrito de la justificación para negarse a actuar conforme
                a su solicitud. Si no está de acuerdo con nuestra explicación, tiene derecho a presentar una queja ante
                el <u>Fiscal General de Virginia</u>.
              </li>
              <li>
                <strong>
                  <u>Autenticación/Verificación</u>
                </strong>
                . Para ayudar a proteger su privacidad y mantener la seguridad, tomaremos medidas para verificar su
                identidad antes de otorgarle acceso a su información personal o cumplir con su solicitud (a excepción de
                una solicitud para optar por no vender o compartir). La Compañía puede solicitarle que proporcione
                cualquiera de la siguiente información:{" "}
                <strong>
                  su nombre, fecha de nacimiento, su número de teléfono, su número de Seguro Social, su dirección de
                  correo electrónico y dirección física
                </strong>
                . Si solicita que le proporcionemos información personal específica, es posible que le solicitemos que
                firme una declaración bajo pena de perjurio de que usted es el consumidor cuya información personal es
                objeto de la solicitud, o personal autorizado del consumidor que realiza la solicitud. Usted comprende
                que la Compañía puede negarse a procesar su solicitud cuando: (i) la Compañía no puede autenticar que
                usted es la persona a la que se refieren los datos; (ii) la solicitud es irrazonable o excesiva; (iii) o
                cuando lo permita la ley aplicable.
              </li>
              <li>
                <strong>
                  <u>Solicitudes rechazadas</u>
                </strong>
                . Usted comprende que podemos rechazar solicitudes cuando concederlas esté prohibido por ley, pueda
                afectar negativamente la privacidad o los derechos de otra persona, revele un secreto comercial u otra
                información confidencial, interfiera con una obligación legal o comercial que requiera la retención o el
                uso de los datos, o porque los datos en cuestión no estén cubiertos por la ley que usted reclama.
                Además, cuando lo permita la ley si nos envía una solicitud para ejercer sus derechos o las opciones de
                esta sección, podemos cobrar una tarifa o rechazar solicitudes en ciertos casos.
              </li>
              <li>
                <strong>
                  <u>No discriminación</u>
                </strong>
                . Usted tiene derecho a no ser discriminado por ejercer cualquiera de sus derechos de privacidad.
              </li>
            </ol>
          </p>

          <p>
            <strong>
              <u>Aviso para usuarios fuera de los Estados Unidos</u>
            </strong>
            . Esta Política de privacidad en línea está destinada a cubrir la recopilación de información en nuestros
            Sitios de residentes de los Estados Unidos únicamente. Si visita nuestros sitios web desde fuera de los
            Estados Unidos, tenga en cuenta que su información podría transferirse a los Estados Unidos y almacenarse y
            procesarse en los Estados Unidos, donde se encuentran nuestros servidores y funciona nuestra base de datos
            central. Es posible que las leyes y la protección de datos de los Estados Unidos y otros países no sean tan
            completas como las de su país. Al utilizar nuestros servicios, usted comprende que su información puede ser
            transferida a nuestras instalaciones y a aquellos terceros con quienes la compartimos tal como se describe
            en este Aviso. No vendemos, ofrecemos ni brindamos servicios a personas de fuera de los Estados Unidos.
          </p>

          <h3>VIII. VARIOS</h3>
          <p>
            <strong>Sitios de redes sociales</strong>. Debt.com ofrece experiencias en plataformas de redes sociales que
            permiten compartir y colaborar en línea entre los usuarios que se han registrado para usarlas. Todo
            contenido que publique en las páginas de redes sociales oficiales administradas por Debt.com, como imágenes,
            información, opiniones o cualquier información personal que ponga a disposición de otros participantes en
            estas plataformas sociales, está sujeto a los Términos de Uso y las Políticas de Privacidad de esas
            respectivas plataformas. Al permitirle a usted publicar o compartir información en las páginas de redes
            sociales de Debt.com, usted reconoce que Debt.com no respalda, apoya, reconoce ni está de acuerdo de algún
            otro modo con la información publicada y que dichas publicaciones serán de su exclusiva responsabilidad.
            También acepta que Debt.com puede utilizar cualquier información publicada por usted en las redes sociales
            de Debt.com para cualquier propósito previsto en este Aviso. Consúltelos para comprender mejor sus derechos
            y obligaciones con respecto a dicho contenido. Además, tenga en cuenta que al visitar cualquier página
            oficial de redes sociales de Debt.com, también estará sujeto a los Avisos de Privacidad de Debt.com, además
            del Aviso de Privacidad de cualquier plataforma de redes sociales al que pueda estar sujeto.
          </p>

          <p>
            <strong>
              Servicio de mensajes cortos. Tenga en cuenta que este aviso de política no reemplaza sus requisitos de
              suscripción.
            </strong>{" "}
            Podemos poner a su disposición un servicio a través del cual puede recibir comunicaciones en su dispositivo
            inalámbrico mediante un servicio de mensajes cortos (“Servicio de SMS”). Los datos obtenidos de usted en
            relación con este Servicio de SMS pueden ser su nombre, dirección, número de teléfono celular, nombre de su
            proveedor y la fecha, hora y contenido de sus mensajes. Al proporcionar su información de contacto, usted
            declara que tiene 18 años de edad y es el propietario o usuario autorizado del dispositivo inalámbrico en el
            que se recibirán los mensajes, y que comprende que dicha comunicación puede estar sujeta a ciertos cargos
            aplicables, y que usted aprueba todos los cargos aplicables. Además de toda tarifa que se le notifique,
            pueden aplicarse las tarifas estándares de mensajes y datos de su proveedor a nuestra confirmación por SMS y
            a toda la correspondencia por SMS posterior. Todos los cargos por tarifas de datos son facturados por su
            proveedor de servicios móviles y pagaderos a dicho proveedor. No seremos responsables de ningún retraso en
            la recepción de ningún mensaje SMS, ya que la entrega está sujeta a la eficacia de la transmisión por parte
            de su operador de red. No seremos responsables si los SMS se reciben fuera del horario de atención debido a
            dichos retrasos en la transmisión. Los servicios de mensajes SMS se proporcionan TAL COMO ESTÁN. También
            podemos obtener la fecha, hora y contenido de sus mensajes durante el uso del Servicio de SMS. Utilizaremos
            la información que obtengamos en relación con nuestro Servicio de SMS de conformidad con esta Política de
            Privacidad. Podemos proporcionarle a su proveedor de servicios de telefonía su información correspondiente
            en relación con su consentimiento para recibir SMS. Su proveedor de servicios de telefonía inalámbrica y
            otros proveedores de servicios también pueden recopilar datos sobre el uso de su dispositivo inalámbrico, y
            sus prácticas se rigen por sus propias políticas. No seremos responsables de ningún uso o uso indebido de
            datos por parte de su proveedor de servicios de telefonía inalámbrica.
          </p>

          <p>
            Usted reconoce y acepta que el Servicio de SMS se proporciona mediante sistemas inalámbricos que utilizan
            radios (y otros medios) para transmitir comunicaciones a través de redes complejas. Debe tener un teléfono
            con capacidad para enviar mensajes de texto bidireccionales con un proveedor de servicios de telefonía y un
            plan compatibles. Algunos grandes proveedores de servicios de telefonía compatibles son Alltel Wireless,
            AT&T, Boost Mobile, Nextel, Sprint, T-Mobile, Verizon Wireless y Virgin Mobile USA. No garantizamos que su
            uso del Servicio de SMS sea privado o seguro, y no somos responsables ante usted por cualquier falta de
            privacidad o seguridad que pueda sufrir. Usted es plenamente responsable de tomar precauciones y
            proporcionar las medidas de seguridad que mejor se adapten a su situación y al uso previsto del Servicio de
            SMS.
          </p>

          <p>
            <strong>
              Suscripción voluntaria a SMS y llamadas telefónicas. Tenga en cuenta que este aviso de política no
              reemplaza su consentimiento expreso por escrito ni los requisitos de aceptación.
            </strong>{" "}
            Al completar un formulario de contacto o cliente potencial y colocar su firma electrónica, de conformidad
            con la{" "}
            <strong>
              <u>Ley E-SIGN y divulgación</u>
            </strong>{" "}
            que está disponible para usted en el enlace anterior o en el pie de página de nuestro Sitio, usted acepta
            dar a <strong>Debt.com, LLC</strong> su consentimiento expreso por escrito firmado y autoriza a{" "}
            <strong>Debt.com, LLC</strong> y a los representantes directos que llamen en su nombre a comunicarse con
            usted a través de llamadas telefónicas o mensajes de dispositivos móviles, chat en vivo en línea y chatbots
            (incluidas llamadas y mensajes de marketing a través de marcación automática, mensajes de texto y
            pregrabados, voz generativa de IA, SMS y MMS), incluso si mi número de teléfono figura actualmente en
            cualquier lista interna, corporativa, estatal o federal de No llamar. Pueden aplicarse tarifas por mensajes
            y datos, y usted puede optar por no recibir mensajes de texto en cualquier momento enviando un mensaje de
            texto con la palabra “HELP” para obtener ayuda, y “STOP”, “END”, “QUIT”, “UNSUBSCRIBE”, “OPT OUT”, “REVOKE”
            o “CANCEL” para optar por no recibir comunicaciones futuras. Puede optar por no participar y eliminar su
            autorización para recibir dichas llamadas o mensajes de texto, para lo cual debe enviarnos sus instrucciones
            por correo electrónico a <a href="mailto:admin@Debt.com">admin@Debt.com</a>. También acepta la grabación y
            el seguimiento de todas las llamadas hechas por usted a nosotros y hechas por nosotros a usted. Además, al
            proporcionar su dirección de correo electrónico arriba, usted autoriza y otorga a Debt.com, LLC su
            consentimiento expreso por escrito para recibir comunicaciones electrónicas, incluidos correos electrónicos
            periódicos con noticias importantes, consejos financieros, herramientas y más de Debt.com, LLC. Usted
            comprende que siempre puede retirar su consentimiento o cancelar su suscripción en cualquier momento en{" "}
            <strong>
              <a href="mailto:unsubscribe@Debt.com">unsubscribe@Debt.com</a>
            </strong>
            .{" "}
            <strong>
              No es obligatorio que obtenga bienes o servicios ni que se conecte con ninguno de los proveedores de
              servicios que puedan satisfacer mis necesidades y que, en su lugar, pueda optar por llamar al
              representante de Debt.com, LLC al 1.800.810.0989.
            </strong>
          </p>

          <p>
            <strong>Asegurarse de que la información sea precisa</strong>. Usted comprende y acepta que Debt.com supone
            que la Información Personal que usted proporcionó es, en efecto, exacta. Debt.com no será responsable de
            verificar esa Información Personal. Usted comprende que si utiliza alguna función de toma de decisiones
            automatizada en nuestro Sitio, es fundamental que proporcione Información Personal Confidencial y/o
            información identificable precisa que se solicita para que la función de toma de decisiones automatizada la
            procese y le proporcione una decisión y/o resultado preciso. Es responsabilidad de usted asegurarse de que
            la información proporcionada sea exacta y esté al día. Si anteriormente ha enviado información incompleta,
            inexacta o no actual, envíenos un correo electrónico o escríbanos a la dirección correspondiente, o complete
            un formulario de solicitud de datos, con la información actualizada que desea que usemos en nuestra
            comunicación con usted.
          </p>

          <p>
            <strong>Transiciones comerciales</strong>. Usted comprende y acepta que si Debt.com pasa por una transición
            comercial, como una fusión, adquisición por parte de otra empresa o venta de la totalidad o parte de sus
            activos, su información personal, PII y otra información recopilada probablemente se encontrarán entre los
            activos transferidos.
          </p>

          <p>
            <strong>No rastrear divulgaciones</strong>{" "}
            <ol start={1}>
              <li>
                ¿Cómo respondemos a las señales de “no rastrear” (también conocidas como “DNT”) del navegador web u
                otros mecanismos que brindan a los consumidores la capacidad de elegir con respecto a la recopilación de
                información de identificación personal sobre las actividades en línea de consumidores individuales a lo
                largo del tiempo y en sitios web o servicios en línea de terceros?
              </li>
            </ol>{" "}
            RESPUESTA: Actualmente, no respondemos a las señales DNT de los navegadores porque no rastreamos a usuarios
            individuales en la web.{" "}
            <ol start={2}>
              <li>
                ¿Pueden otras partes recopilar información de identificación personal sobre las actividades en línea de
                un consumidor individual a lo largo del tiempo y en diferentes sitios web cuando visita{" "}
                <a href="http://debt.com" target="_blank" rel="noopener noreferrer">
                  www.Debt.com
                </a>
                ?
              </li>
            </ol>
            RESPUESTA: Sí, actualmente contamos con socios tecnológicos que nos brindan herramientas del Sitio que el
            consumidor puede utilizar mientras visita nuestro sitio, las cuales al ser utilizadas comparten información
            de identificación personal con nuestros Socios tecnológicos. Por ejemplo, si utiliza Instant Debt Advisor℠,
            cierta información se comparte con el proveedor de tecnología, All Clear Decisioning, LLC, y también puede
            compartirse con proveedores de servicios externos.
          </p>

          <p>
            <strong>
              Divulgación sobre protección de la privacidad de los niños en línea (Ley de Protección de la Privacidad de
              los Niños en Línea (COPPA))
            </strong>
            . El Sitio no está dirigido a personas menores de dieciocho (18) años, y solicitamos que estas personas no
            proporcionen Información de Identificación Personal (PII) por medio del Sitio. No recopilamos
            deliberadamente información de niños menores de 18 años sin el consentimiento de los padres. Si es un
            Usuario menor de 18 años, no nos envíe ninguna PII si no hemos obtenido el consentimiento previo de su padre
            o tutor. Si descubrimos que hemos recopilado PII de un Usuario menor de 18 años sin el consentimiento de sus
            padres, o si descubrimos que un Usuario menor de 18 años nos ha proporcionado más PII de la que le
            solicitamos, eliminaremos esa información lo más rápido posible. Si cree que un Usuario menor de 18 años
            podría haber proporcionado PII a Debt.com de una manera que infrinja esta Política de Privacidad, escríbanos
            a la dirección de correo electrónico o a la dirección postal física que figura en la sección “Información de
            contacto” más abajo y haremos todos los esfuerzos razonables para eliminar la información. Para obtener más
            información sobre la Ley de Protección de la Privacidad Infantil en Línea (COPPA, por sus siglas en inglés),
            visite el sitio web de la Comisión Federal de Comercio en{" "}
            <strong>
              <a
                href="https://www.ftc.gov/business-guidance/privacy-security/childrens-privacy"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.ftc.gov/business-guidance/privacy-security/childrens-privacy
              </a>
            </strong>
            .
          </p>

          <p>
            <strong>El idioma inglés rige este Aviso de Privacidad</strong>. Usted reconoce y acepta que el texto de
            este Aviso de Privacidad, incluidas todas y cada una de las páginas vinculadas u otros Sitios, se han
            escrito y se ofrecen tanto en inglés como en español. Cada versión es auténtica. Sin embargo, en caso de
            cualquier disputa con respecto a esta Política de Privacidad, la versión en inglés tendrá prioridad de
            interpretación y, por lo tanto, será la que prevalezca. Además, todas las notificaciones que se proporcionen
            de una parte a la otra parte en virtud del presente documento deberán realizarse en idioma inglés. Puede
            solicitar una copia de este Aviso de Privacidad en español para sus registros.
          </p>

          <p>
            <strong>Actualizaciones de este Aviso de Privacidad</strong>. Este Aviso de Privacidad en Línea está sujeto
            a cambios a discreción exclusiva de Debt.com. Revise la Política de Privacidad periódicamente para ver si
            hay algún cambio. Si realizamos cambios en el Aviso de Privacidad, modificaremos la “Fecha de la última
            actualización” que aparece al comienzo de este Aviso. Todo cambio que se le haga a este Aviso entrará en
            vigencia cuando publiquemos el Aviso corregido en el Sitio. Usted acepta que su uso del Sitio después de
            cualquier cambio en este Aviso significa que acepta el Aviso corregido y las Políticas de Privacidad que
            contiene.
          </p>

          <p>
            <strong>CONTÁCTENOS</strong>
            <br />
            Si tiene alguna pregunta o sugerencia sobre nuestra política de privacidad, contáctenos:
            <br />
            <strong>Debt.com LLC</strong>
            <br />
            8220 W. State Road 84.,
            <br /> Fort Lauderdale, Florida 33324
            <br /> Por correo electrónico a:{" "}
            <strong>
              <a href="mailto:admin@Debt.com">admin@Debt.com</a>
            </strong>
            ; o <br />
            Por teléfono: <strong>1.800.810.0989</strong>.
          </p>

          <p>
            <strong>IMPRIMA Y CONSERVE UNA COPIA DE ESTA POLÍTICA DE PRIVACIDAD PARA SUS ARCHIVOS.</strong>
          </p>

          <h2 ref={esignTargetRef} style={{ textDecoration: "underline" }}>
            DIVULGACIÓN DE FIRMA ELECTRÓNICA DE Debt.com (en el enlace):
          </h2>
          <p style={{ fontWeight: "bold" }}>
            DIVULGACIÓN: Para la conveniencia del Consumidor, esta es una copia de la Ley de Firma Electrónica y
            Divulgación, incluidas en la Divulgación TCPA que se encuentra en el formulario que usted firmó y presentó a
            Debt.com, LLC para que Debt.com, LLC pueda contactarlo acerca de los servicios de Debt.com, LLC. Tenga en
            cuenta que la referencia al botón "Aceptar Y Enviar Código" en esta copia es un botón de acción de nuestra
            plantilla, y que el botón de acción que presionó al enviar el formulario puede haber contenido un lenguaje
            de aceptación diferente. Dado que esta es una copia de la Ley de Firma Electrónica y Divulgación, no se
            requiere ninguna acción de su parte. Puede imprimir y guardar este formulario para sus registros
          </p>
          <h3>Consentimiento para el uso de registros y firmas electrónicas</h3>
          <p>
            Usted tiene la oportunidad de completar y firmar documentos, así como recibir avisos y otros documentos
            relacionados con su Formulario de envío y la interacción con{" "}
            <strong style={{ fontWeight: "bold" }}>Debt.com, LLC</strong> en formato electrónico en lugar de formato
            impreso. Para aceptar estos usos de documentos y firmas electrónicos, y firmar este documento con el mismo
            efecto que firmar físicamente su nombre, haga clic en "Aceptar Y Enviar Código" en la parte inferior del
            formulario en la página después de revisar la siguiente información. Para firmar, completar y recibir
            documentos electrónicamente necesitará lo siguiente:
          </p>
          <ul>
            <li>Una dirección de correo electrónico personal;</li>
            <li>Una computadora u otro dispositivo con software de correo electrónico estándar;</li>
            <li>La versión actual de Chrome, Firefox, Edge o Safari;</li>
            <li>Una conexión a Internet;</li>
            <li>Una impresora si desea imprimir copias en papel.</li>
          </ul>
          <p>
            Como alternativa, puede optar por comunicarse con{" "}
            <strong style={{ fontWeight: "bold" }}>Debt.com, LLC</strong> a través de una llamada telefónica y puede
            enviar cualquier solicitud de servicios a través de versiones impresas de los documentos.
          </p>
          <p>
            Al hacer clic en "Aceptar Y Enviar Código" en la parte inferior del formulario en la página después de
            revisar la información aquí contenida, usted acepta firmar, completar y recibir electrónicamente documentos
            relacionados con su solicitud de información e interacción con{" "}
            <strong style={{ fontWeight: "bold" }}>Debt.com, LLC</strong>
            durante esta sesión y cualquier sesión futura relacionada con este proceso. Además, usted acepta recibir
            electrónicamente: comunicaciones relacionadas con su solicitud e interacción con
            <strong style={{ fontWeight: "bold" }}>Debt.com, LLC</strong> incluidas solicitudes de información
            adicional; avisos de acciones tomadas requeridas por ley; y avisos de sus derechos de conformidad con las
            leyes aplicables. Su consentimiento se aplica a los documentos completados, firmados o proporcionados a
            través de este sitio web, así como a los documentos transmitidos por correo electrónico.
          </p>
          <p>
            Si su información de contacto cambia, actualice su información con <strong>Debt.com, LLC</strong>{" "}
            contactando a <strong style={{ fontWeight: "bold" }}>Debt.com, LLC</strong> con la información suministrada
            previamente. Además, usted tiene el derecho de retirar su consentimiento en cualquier momento contactando a{" "}
            <strong>Debt.com, LLC</strong>. Usted puede actualizar su información o retirar su consentimiento de las
            siguientes maneras: llamando (solo en los EE. UU.) a Debt.com, LLC al 1.844.669.4596, enviando un correo
            electrónico a admin@Debt.com o escribiendo a: 8220 W. State Road 84, Fort Lauderdale, FL 33324.
          </p>
          <p>
            Si usted retira su consentimiento, ya no tendrá la posibilidad de firmar documentos electrónicamente ni de
            recibir divulgaciones y otros documentos electrónicamente. Su retiro del consentimiento es solo prospectivo
            y entrará en vigor después de que hayamos tenido una oportunidad razonable para procesarlo. Después de
            retirar su consentimiento, comuníquese también con el representante de la empresa que le envió esta
            solicitud para hacer los arreglos necesarios para recibir copias impresas de los documentos y
            comunicaciones.
          </p>
          <p>
            Después de dar su consentimiento, puede obtener copias de documentos y comunicaciones relacionados con su
            solicitud e interacción con <strong style={{ fontWeight: "bold" }}>Debt.com, LLC</strong> utilizando su
            navegador para imprimir copias en papel o guardar copias electrónicas de documentos o pantallas durante esta
            sesión y sesiones futuras.
          </p>
          <p>
            Entiendo que al escribir mi nombre donde se indica en el formulario, proporcionar mi correo electrónico y
            número de teléfono, y luego hacer clic en "Aceptar Y Enviar Código", usted acepta el uso de registros y
            firmas electrónicas de la manera descrita anteriormente, y el almacenamiento electrónico de dichos
            documentos.
          </p>
        </div>
      </div>
    );
  },
);
