import { Box } from "@mui/material";
import { autorun } from "mobx";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate, useSearchParams } from "react-router-dom";

import { Loader } from "@components/common";
import { useStore } from "@stores";
import { CONSENT_SELECTION, CONSENT_SELECTION_ACC, CONSENT_SELECTION_DEC } from "@types";
import { GTMAcceptConsent, GTMDeclineConsent } from "@utils";

import { auth } from "../../firebase.config.ts";

export const ProtectedRouteOutlet: React.FC = observer(() => {
  const navigate = useNavigate();
  const { commonStore, sessionStore, profileStore, engagementStore, callToActionsStore, remoteConfigStore } =
    useStore();
  const { authUser, onAuthStateChange } = sessionStore;
  const [searchParams] = useSearchParams();
  const {
    t,
    i18n: { language },
  } = useTranslation("verification_page");

  const { updateProfile } = profileStore;
  const token: string | null = searchParams.get("token");
  const pid: string | null = searchParams.get("pid");
  const cookieConsent: string | null = searchParams.get(CONSENT_SELECTION);

  useEffect(() => {
    const handleNavigation = () => {
      if (sessionStore.isLoggingOut) {
        window.location.replace("/auth");
        return;
      }
      if (auth.currentUser?.isAnonymous && engagementStore.engagement) {
        navigate("/auth");
        return;
      }
      if (sessionStore.authUserLoaded === "EMPTY" && !engagementStore.engagement && !profileStore.profile) {
        navigate("/auth");
        return;
      }

      if (sessionStore.authUserLoaded && profileStore.profile) {
        navigate(location.pathname);
        return;
      }
    };

    const dispose = autorun(handleNavigation);

    return () => {
      dispose();
    };
  }, [
    navigate,
    profileStore.profile,
    sessionStore.authUserLoaded,
    sessionStore.isLoggingOut,
    engagementStore.engagement,
    remoteConfigStore.redirectUrl,
    language,
  ]);

  useEffect(() => {
    updateProfile();
  }, [language, updateProfile]);

  useEffect(() => {
    if (commonStore.isMissingCreditReport) {
      callToActionsStore.getCreditReport(profileStore.profile!.id!);
    }
  }, [callToActionsStore, commonStore.isMissingCreditReport, profileStore.profile]);

  useEffect(() => {
    const unsubscribe = onAuthStateChange(token || "", pid || "");
    return () => unsubscribe();
  }, [onAuthStateChange, token, pid]);

  useEffect(() => {
    const localCookieConsent = localStorage.getItem(CONSENT_SELECTION);
    if (!localCookieConsent && cookieConsent) {
      localStorage.setItem(CONSENT_SELECTION, cookieConsent);
      switch (cookieConsent) {
        case CONSENT_SELECTION_ACC:
          GTMAcceptConsent();
          break;
        case CONSENT_SELECTION_DEC:
          GTMDeclineConsent();
          break;
      }
    }
  }, [cookieConsent]);

  useEffect(() => {
    /**
     *After authentication if page is refreshed with just root url, redirect to dashboard
     */
    autorun(() => {
      if (profileStore.profile && location.pathname === "/") {
        navigate("/dashboard");
      }
    });
  }, [navigate, profileStore.profile]);

  useEffect(() => {
    autorun(() => {
      if (sessionStore.profileError) {
        auth.signOut().then(() => {
          navigate("/auth");
        });
      }
    });
  }, [navigate, sessionStore.profileError, t]);

  if (commonStore.loadingEngagementOrProfile) {
    return <Loader />;
  } else {
    const profileExists = profileStore.profile;
    if (profileExists) {
      if (!authUser?.isAnonymous) {
        return (
          <Box sx={{ height: "100vh" }} data-tf-sensitive="true">
            <Outlet />
          </Box>
        );
      }
    }
  }
});
