import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

import { Text, PlanCard } from "@components/common";
import { useStore } from "@stores";
import { formatToNearestDollar } from "@utils";

interface DebtManagementPlanCardProps {
  displayTerms?: boolean;
  condensed?: boolean;
}

export const DebtManagementPlanCard = observer((props: DebtManagementPlanCardProps) => {
  const { t } = useTranslation("plan_option", { keyPrefix: "planCard" });
  const { callToActionsStore } = useStore();
  const plan = callToActionsStore.debtManagementPlan;

  if (!plan) return <></>;

  return (
    <PlanCard months={plan.inputParameters?.programLength} savings={plan.estimatedSavings} displayTerms {...props}>
      <Box
        data-cy="DebtManagementPlanCard.Box.container"
        sx={{
          p: "10px",
          mx: 2.5,
          borderRadius: 2,
          border: (theme) => `1px solid ${theme.palette.brandGreen.main}`,
          backgroundColor: (theme) => theme.palette.brandGreen.lighter,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            columnGap: 1,
            mb: { xs: 0, md: props.condensed ? 0 : 1 },
          }}
        >
          <div>
            <Text
              data-cy="DebtManagementPlanCard.monthlyPayment"
              color="green"
              fontSize="small"
              fontWeight="bold"
              sx={{ fontSize: { xs: "14px", md: props.condensed ? "14px" : "16px" } }}
            >
              {t("monthlyPayment")}
            </Text>
            <Text
              data-cy="DebtManagementPlanCard.estimatedPayment"
              fontSize="xsmall"
              color="gray"
              sx={{ mt: 0.5, display: { xs: "none", md: props.condensed ? "none" : "block" } }}
            >
              *{t("estimatedPayment")}
            </Text>
          </div>
          <Box sx={{ position: "relative" }}>
            <Text
              fontSize="small"
              sx={{ position: "absolute", top: "2px", left: "-8px" }}
              fontWeight="bold"
              component="span"
              color="green"
            >
              *
            </Text>
            <Text
              data-cy="DebtManagementPlanCard.monthlyPayment"
              color="green"
              component="span"
              fontWeight="bold"
              sx={{ fontSize: { xs: " 24px", md: props.condensed ? "24px" : "36px" }, lineHeight: 1 }}
            >
              ${formatToNearestDollar(plan.debtManagementPayment.monthlyPayment)}
            </Text>
            <Text color="green" component="span" fontSize="small">
              {t("monthly")}
            </Text>
          </Box>
        </Box>
        <Text
          data-cy="DebtManagementPlanCard.estimatedPayment"
          fontSize="xsmall"
          color="gray"
          sx={{ mt: 0.5, color: "#4a4a4a", mb: 1, display: { xs: "block", md: props.condensed ? "block" : "none" } }}
        >
          *{t("estimatedPayment")}
        </Text>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Text fontWeight="medium" color="gray">
            {t("vs")}
          </Text>
          <div>
            <Text
              data-cy="DebtManagementPlanCard.monthlyPayment"
              color="gray"
              component="span"
              fontWeight="bold"
              fontSize="large"
              sx={{ textDecoration: "line-through" }}
            >
              ${formatToNearestDollar(plan.regularPayment.monthlyPayment)}
            </Text>
            <Text color="gray" component="span" fontSize="xsmall" sx={{ textDecoration: "line-through" }}>
              {t("monthly")}
            </Text>
          </div>
        </Box>
      </Box>
    </PlanCard>
  );
});
