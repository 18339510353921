import { useRef } from "react";

import { useStore } from "@stores";

import { isDateExpired } from "../common";
import { GTMPlanSubmitted, GTMSessionExtended, GTMSessionNotExtended, GTMSessionTimeoutPrompted } from "../gtm";
import { ProfileSubStatusEnum } from "../subStatus";

/**
 * Custom hook to manage session timeout with live countdown.
 *
 * This hook starts a timer for user sessions/absolute timer and updates a `timeLeft` variable
 * which can be used to display the remaining time to the user in a "mm:ss" format.
 *
 * @returns An object containing the `startTimer` function, `extendSession` function, and `timeLeft` state.
 */

export const useSessionTimer = () => {
  const {
    modalStore,
    profileStore,
    remoteConfigStore: { timers },
    sessionStore: { clearSessionTimer, setSessionTimer, setTimeLeft, logout },
    commonStore,
  } = useStore();

  const sessionTimeout = timers.sessionTimeout || 15;
  const allowedIdleTime = timers.allowedIdleTime || 1;

  const { IDA_ENROLLMENT_STARTED, IDA_CREDIT_AUTH } = ProfileSubStatusEnum;
  const enrollmentIsInProgress = [IDA_ENROLLMENT_STARTED, IDA_CREDIT_AUTH].includes(profileStore.profile!.subStatus);

  /** If the session is in the process of being manually extended. */
  const isSessionExtendingRef = useRef<boolean>(false);

  /**
   * Formats the time left as a string.
   *
   * @param minutes The minutes left.
   * @param seconds The seconds left.
   * @returns A string formatted as "mm:ss".
   */
  const _formatTimeLeft = (minutes: number, seconds: number) => {
    if (minutes <= 0 && seconds <= 0) return "00:00";
    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  /**
   * Handles session timeout by clearing the timer and logging the user out.
   */
  const _handleSessionTimeout = () => {
    clearSessionTimer();

    if (modalStore.module?.name === "SelectPlan") {
      GTMPlanSubmitted("abandoned");
    }

    GTMSessionNotExtended(commonStore.pageTitle);
    logout();
  };

  /**
   * Extends the session by clearing the timer and starting a new one
   */
  const extendSession = () => {
    isSessionExtendingRef.current = true;

    clearSessionTimer();
    modalStore.setShowExtendModal(false);

    setTimeout(() => {
      startTimer();
      GTMSessionExtended(commonStore.pageTitle);
    }, 1000);
  };

  /**
   * Starts the session timer and updates the `timeLeft` state.
   */
  const startTimer = () => {
    setTimeLeft(_formatTimeLeft(sessionTimeout, 0));

    const sessionTimeoutDate = new Date();
    sessionTimeoutDate.setMinutes(sessionTimeoutDate.getMinutes() + sessionTimeout);
    sessionTimeoutDate.setMilliseconds(0);

    let profileExpirationDate = new Date(profileStore.profile!.expirationDate);

    const sessionEndsAfterProfileExpiration = sessionTimeoutDate.getTime() > profileExpirationDate.getTime();

    const isProfileExpired = isDateExpired(profileExpirationDate);

    if (isProfileExpired && !isSessionExtendingRef.current) {
      profileStore.updateEnrollmentStatusTimedOut();
    } else if (sessionEndsAfterProfileExpiration && enrollmentIsInProgress) {
      profileExpirationDate = sessionTimeoutDate;
      profileStore.updateProfileExpirationDate(sessionTimeoutDate);
    }

    isSessionExtendingRef.current = false;

    setSessionTimer(setInterval(() => _updateTimer(sessionTimeoutDate, profileExpirationDate), 1000));
  };

  /** The timer function that is called once per second. Updates how much time is left in the session. Handles effects such as the display of the Extend Time modal and handling when there is no time left in the session.
   * @param sessionTimeoutDate The date when the session ends.
   * @param profileExpirationDate The date when the profile expires.
   */
  const _updateTimer = async (sessionTimeoutDate: Date, profileExpirationDate: Date) => {
    const now = new Date();
    const sessionTimeRemaining = sessionTimeoutDate.getTime() - now.getTime();
    const minutes = Math.floor(sessionTimeRemaining / 1000 / 60);
    const seconds = Math.floor((sessionTimeRemaining / 1000) % 60);

    setTimeLeft(_formatTimeLeft(minutes, seconds));

    // handling extend timeout modal
    const sessionAboutToExpire = (minutes === allowedIdleTime && seconds === 0) || minutes < allowedIdleTime;

    if (sessionAboutToExpire && enrollmentIsInProgress && !modalStore.showExtendModal) {
      GTMSessionTimeoutPrompted(commonStore.pageTitle);
      modalStore.setShowExtendModal(true);
    }

    // handling session timeout
    if (sessionTimeRemaining < 0) {
      const isProfileExpired = isDateExpired(profileExpirationDate);

      if (isProfileExpired) {
        await profileStore.updateEnrollmentStatusTimedOut();
      }

      _handleSessionTimeout();
    }
  };

  return { startTimer, extendSession };
};
