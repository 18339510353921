import { observer } from "mobx-react-lite";
import { useEffect } from "react";

import { CallToActionCard, MiniDashboard, HelpView, HelpViewB } from "@components/layout";
import { HelpViewC } from "@components/layout/HelpViewC";
import { useStore } from "@stores";
import { CallToActionIdEnum } from "@types";
import { CTAModuleID, CTAModulesEnum } from "@utils";

import { useStyles } from "./Home.styles";

export const Home = observer(() => {
  const { profileStore, commonStore, modalStore } = useStore();
  const { profile, hasSelectedPlan, journeyId } = profileStore;
  const { userClosedModal, setUserClosedModal } = commonStore;
  const helpView = commonStore.helpPageView;

  const userHasCompletedCreditAuth = profileStore.profile?.completedCallToActionIds?.includes(
    CallToActionIdEnum.AUTHORIZE_CREDIT_PULL,
  );

  useEffect(() => {
    if (!userClosedModal) {
      if (helpView !== "") {
        return setUserClosedModal(true)
      }
      if (profile?.nextCallToAction?.id) {
        modalStore.openModal(CTAModulesEnum[profile.nextCallToAction?.id as CTAModuleID]);
      }
    }
  }, [journeyId, userClosedModal, setUserClosedModal, helpView, modalStore, profile, userHasCompletedCreditAuth]);

  const helpPageCSS = () => {
    const helpPageView = commonStore.helpPageView;

    if (helpPageView === "helpA" || (hasSelectedPlan && (helpPageView === "helpB" || helpPageView === "helpC"))) {
      return classes.contenthelpA;
    }
    return "";
  };

  const calculateMarginTop = () => {
    switch (helpView) {
      case "helpA":
        return profile?.profilePercentageComplete !== 100 ? 210 : 250;
      case "helpB":
        return 0;
      case "helpC":
        return profile?.profilePercentageComplete !== 100 ? 315 : 205;
      default:
        return 400;
    }
  };

  const classes = useStyles({
    marginTop: calculateMarginTop(),
  });

  return (
    <div className={classes.centerContainer}>
      {helpView !== "helpA" && helpView !== "helpB" && helpView !== "helpC" && profile?.nextCallToAction?.id ? (
        <div className={classes.callToActionsContainer}>
          <CallToActionCard data={profile.nextCallToAction} />
          <MiniDashboard />
        </div>
      ) : (
        <>
          <div className={helpPageCSS()}>
            {helpView == "helpA" && <HelpView />}
            {helpView == "helpB" && hasSelectedPlan && <HelpViewB />}
            {helpView == "helpC" && hasSelectedPlan && <HelpViewC />}
          </div>
          <div className={classes.miniDashboardWrapper}>
            <MiniDashboard endState={helpView} />
          </div>
        </>
      )}
    </div>
  );
});
