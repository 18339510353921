export enum ProfileSubStatusEnum {
  IDA_ENROLLMENT_STARTED = "ida_enrollment_started",
  IDA_DECLINED_CREDIT = "ida_declined_credit",
  IDA_CREDIT_ERROR = "ida_credit_error",
  IDA_ANALYSIS_ERROR = "ida_analysis_error",
  IDA_DECLINED_SELECTION = "ida_declined_selection",
  IDA_CREDIT_AUTH = "ida_credit_auth",
  IDA_ENROLLMENT_COMPLETED = "ida_enrollment_completed",
  SYSTEM_TIMEOUT_BEFORE_CREDIT = "system_timeout_before_credit",
  USER_DECLINED_CREDIT = "user_declined_credit",
  SYSTEM_ERROR = "system_error",
  USER_DECLINED_SELECTION = "user_declined_selection",
  IDA_SYSTEM_COMPLETED = "ida_system_completed",
  SYSTEM_TIMEOUT_CREDIT_AUTH = "system_timeout_credit_auth",
  SYSTEM_TIMEOUT = "system_timeout",
  IDA_TIMED_OUT = "ida_timed_out",
  CALL_ACTION = "call_action",
}

export enum ProfileStatusEnum {
  PENDING_CREDIT_ENROLLED = "PENDING_CREDIT_AUTHORIZATION",
  FAILED_CREDIT_PULL = "FAILED_CREDIT_PULL",
  NO_ELIGIBLE_TRADELINES = "NO_ELIGIBLE_TRADELINES",
  NO_CREDIT_LIABILITY_DATA = "NO_CREDIT_LIABILITY_DATA",
  FAILED_CREDIT_RESPONSE_TRANSFORMATION = "FAILED_CREDIT_RESPONSE_TRANSFORMATION",
}

export type ProfileSubStatus = (typeof ProfileSubStatusEnum)[keyof typeof ProfileSubStatusEnum];
export type ProfileStatus = (typeof ProfileStatusEnum)[keyof typeof ProfileStatusEnum];
