export enum CallToActionIdEnum {
  AUTHORIZE_CREDIT_PULL = "authorize-credit-pull",
  REVIEW_DEBT_ELIGIBILITY = "review-debt-eligibility",
  SELECT_PLAN = "select-plan",
  VERIFY_CREDIT = "verify-credit",
  VERIFY_EMAIL = "verify-email",
  VERIFY_PHONE = "verify-phone",
}

export type CallToActionId = (typeof CallToActionIdEnum)[keyof typeof CallToActionIdEnum];
