import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

interface Props {
  name?: string;
}

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    flex: "1 1 auto",
    height: "100%",
    display: (props: Props) => (props.name == "CreditModule" || props.name == "SelectPlan" ? "block" : "flex"),
    flexDirection: "column",
    overflow: "auto",
    backgroundImage: (props: Props) =>
      `${props.name == "PlanSelected" || props.name == "SelectPlan" ? "linear-gradient(#FBFBFB, #DCDCDC)" : "unset"}`,
    [theme.breakpoints.up("md")]: {
      display: "flex !important",
      backgroundImage: `unset !important`,
      backgroundColor: theme.palette.common.white,
    },
  },
  content: {
    overflow: "auto",
    flex: 1,
    position: "relative",
    padding: (props: Props) =>
      ["SelectPlan", "BudgetTool", "DebtReview"].includes(props.name as string)
        ? "unset"
        : `${theme.spacing(4)} ${theme.spacing(3)}`,
    [theme.breakpoints.up("md")]: {
      overflowY: (props: Props) => (["DebtReview"].includes(props.name as string) ? "hidden" : "visible"),
    },
  },
  borderTop: {
    borderWidth: 0,
    borderStyle: "solid",
    borderColor: "rgba(0, 0, 0, 0.12)",
    borderTopWidth: "thin",
  },
  totalDebtBorderBottom: {
    padding: "16px 24px",
    borderWidth: 0,
    borderStyle: "solid",
    borderColor: "rgba(0, 0, 0, 0.12)",
    borderBottomWidth: "thin",
  },
  footer: {
    border: "none",
    display: "flex",
    flexDirection: "column",
    borderRadius: "1rem 1rem 0 0",
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.up("md")]: {
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "rgba(0, 0, 0, 0.12)",
      borderTopWidth: "thin !important",
      borderRadius: "unset",
    },
  },
  footerButtonContainer: {
    display: "flex",
    flex: "1",
    flexDirection: "column-reverse",
    gap: (props: Props) => `${props.name == "CallForHelp" ? "unset" : "16px"}`,
    padding: "18px 32px",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "flex-end",
      gap: (props: Props) => `${props.name == "CallForHelp" ? "unset" : "32px"}`,
    },
  },
  callMeButton: {
    margin: "0 auto",
    [theme.breakpoints.up("md")]: {
      margin: 0,
    },
  },
  nextActionButton: {
    zIndex: "1",
    [theme.breakpoints.up("md")]: {
      width: "unset",
      height: "min-content",
      fontSize: theme.fontSizes.medium,
      fontWeight: theme.fontWeights.semiBold,
      lineHeight: theme.fontLineHeights.xxxsmall,
    },
  },
  debtReviewCallButton: {
    display: "flex",
    padding: "13px 0px",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      paddingTop: "unset",
      height: "min-content",
      padding: "unset",
    },
  },
  doneCallButton: {
    width: "100%",
    [theme.breakpoints.up("md")]: {
      paddingTop: "unset",
      height: "min-content",
      padding: "unset",
      fontSize: theme.fontSizes.medium,
      fontWeight: theme.fontWeights.medium,
      lineHeight: theme.fontLineHeights.xxxsmall,
    },
  },
  doneCallButtonWrapper: {
    padding: "16px 0 0 0",
    // width: '100%',
    [theme.breakpoints.up("md")]: {
      padding: "0 24px 0 0",
    },
  },
  debtReviewGridContainer: {
    justifyContent: "flex-end",
  },
  fontDarkGray: {
    color: `${theme.palette.brandGray.darker} !important`,
  },
  totalFooterContainer: {
    justifyContent: "flex-end",
    position: "absolute",
    bottom: "0",
  },
  totalFooter: {
    backgroundColor: theme.palette.common.white,
    justifyContent: "space-between",
    borderRadius: "1rem 1rem 0 0",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      borderRadius: "unset",
    },
  },
  // TODO: we should refactor all these css classes as most of them are duplicated
  planSelectedFooter: {
    backgroundColor: "none",
    border: "none",
    padding: "0px 24px 24px 24px",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      justifyContent: "flex-end",
      padding: "16px 24px",
      borderWidth: "0",
      borderStyle: "solid",
      borderColor: "rgba(0, 0, 0, 0.12)",
      borderTopWidth: "thin !important",
    },
  },
  planSelectedButtons: {
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "fit-content",
      flexDirection: "row-reverse !important",
    },
  },
}));
