import { observer } from "mobx-react-lite";
import { Outlet } from "react-router-dom";

import { Loader } from "@components/common";
import { VerificationHeader } from "@components/layout";
import { useStore } from "@stores";

import { useStyles } from "./VerificationWrapper.styles";

export const VerificationWrapper = observer(() => {
  const {
    sessionStore,
    profileStore: { tempUser },
  } = useStore();
  const updateBackground = !!tempUser.email || !!tempUser.phone;

  const classes = useStyles({ updateBackground });

  if (sessionStore.isLoadingEngagementData || sessionStore.isSessionExpiring) {
    return <Loader />;
  } else {
    return (
      <div className={classes.rootContainer}>
        <div className={classes.cardContainer}>
          <VerificationHeader />
          <Outlet />
        </div>
      </div>
    );
  }
});
