export interface AxiosErrorData {
  statusCode: number;
  message: string;
  error: string;
}

export type ClickSource = "header" | "footer" | "skipButton";

export const CONSENT_SELECTION = "cookie-consent";
export const CONSENT_SELECTION_ACC = "accepted";
export const CONSENT_SELECTION_DEC = "declined";

export const PIXEL_PARTNER_NAME = "Instant Debt Advisor";
