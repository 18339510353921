import { Box, useMediaQuery } from "@mui/material";
import { useContext } from "react";

import { Copyright } from "@components/common/Copyright";
import { TermsAndPolicy } from "@components/common/TermsAndPolicy";
import { useStore } from "@stores";
import { theme } from "@styles";

import { useStyles } from "./VerificationHeader.styles";
import { ThemeContext } from "../OverlayTheme";

export const VerificationHeader: React.FC = () => {
  const themeContext = useContext(ThemeContext);
  const overlay = themeContext.state.overlay;
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const { remoteConfigStore } = useStore();

  const classes = useStyles();

  return (
    <div className={`${classes.sidebarViewContainer} ${overlay ? "overlay" : ""}`}>
      <div className={classes.svgContainer}>
        <div
          className="svgElement"
          onClick={() => {
            window.location.replace(remoteConfigStore.redirectUrl);
          }}
          tabIndex={0}
          role="button"
        ></div>
      </div>
      <Box sx={{ flexGrow: 1 }} />
      {isDesktop && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <TermsAndPolicy />
          <div className={classes.copyright}>
            <Copyright />
          </div>
        </div>
      )}
    </div>
  );
};
