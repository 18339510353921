import { AxiosError } from "axios";
import { User } from "firebase/auth";
import { makeAutoObservable, reaction } from "mobx";

import { AxiosAgent } from "@services";
import {
  BaseEngagement,
  JourneyId,
  JourneyName,
  Profile,
  ProfileResponse,
  Servicer,
  TemporaryUser,
  UpdateProfile,
  VTPageTitles,
} from "@types";
import {
  convertEngagementToProfileObject,
  GTMDynamicEvent,
  GTMProfileCreated,
  GTMProgramType,
  GTMPartnerName,
  ProfileSubStatus,
  setLanguage,
  GTMInitiate,
  CTAModulesEnum,
  ProfileSubStatusEnum,
  GTMWorkflowVariant,
  isDateExpired,
} from "@utils";

import { LoadingStore } from "./LoadingStore.ts";
import { store } from "./store";
import { rollbar } from "../../rollbarConfig.ts";
import { AuthError } from "../utils/errors/AuthError.ts";

export class ProfileStore {
  profile?: ProfileResponse;
  loader: LoadingStore;
  tempUser: TemporaryUser = {};
  verificationMethodValue: string | null = null;
  userPhoneInput: string | undefined = undefined;
  userEmailInput: string | undefined = undefined;
  isEnrollmentComplete = false;
  hasPushEngagementData = false;

  private trustedFormUrl: string | undefined = undefined;

  constructor() {
    makeAutoObservable(this, {
      createProfile: false,
      updateUserProfileAPI: false,
      doesProfileExistInDatabase: false,
    });
    this.loader = new LoadingStore();
    reaction(
      () => this.profile?.journeys,
      () => {
        store.remoteConfigStore.getFeatureFlags();
        store.remoteConfigStore.getThresholds();
      },
    );
    reaction(
      () => this.profile?.id,
      (id) => {
        if (id) {
          store.chatStore.updateProfileId(id);
        }
      },
    );
    reaction(
      () => this.profile?.lastCompletedPage,
      (pageTitle) => {
        if (pageTitle) {
          store.chatStore.updateLastPage(pageTitle);
        }
      },
    );
    reaction(
      () => this.profile?.engagementId,
      (id) => {
        if (id && !this.hasPushEngagementData) {
          this.getGTMParamsFromEngagement(id);
          this.hasPushEngagementData = true;
        }
      },
    );
  }

  setIsEnrollmentComplete = (isEnrollmentComplete: boolean) => {
    this.isEnrollmentComplete = isEnrollmentComplete;
  };

  setTrustedFormUrl = (trustedFormUrl: string) => {
    this.trustedFormUrl = trustedFormUrl;
  };

  clearProfileStore = () => {
    this.profile = undefined;
    this.tempUser = {};
    this.verificationMethodValue = null;
  };

  setVerificationMethodValue = (value: string) => {
    this.verificationMethodValue = value;
  };

  setTempUser = (engagement: Partial<BaseEngagement>) => {
    this.tempUser = {
      phone: engagement.profile?.phone_mobile,
    };
    setLanguage(engagement.profile?.language ?? "en");
  };

  setUserPhoneInput = (phone: string) => {
    this.userPhoneInput = phone;
  };

  get username() {
    return `${this.profile?.firstName} ${this.profile?.lastName}`;
  }

  setProfile = (profile: ProfileResponse) => {
    this.profile = profile;
    setLanguage(profile.preferredLanguage);
  };

  setHasPushEngagementData = (hasPushEngagementData: boolean) => {
    this.hasPushEngagementData = hasPushEngagementData;
  };

  doesProfileExistInDatabase = async (id: string): Promise<ProfileResponse | undefined> => {
    try {
      return await AxiosAgent.Profile.getById(id);
    } catch (error) {
      return undefined;
    }
  };

  updateProfile = async () => {
    if (this.profile?.id) {
      const profile = await AxiosAgent.Profile.getById(this.profile.id);
      this.setProfile(profile);
    }
  };

  getUserProfileFromAPI = async (id: string): Promise<ProfileResponse | void> => {
    try {
      this.loader.updateState("LOADING");
      const profile = await AxiosAgent.Profile.getById(id);
      this.setProfile(profile);
      this.loader.updateState("SUCCESS");
    } catch (error: unknown) {
      this.loader?.updateState("ERROR");
      if (error instanceof AxiosError) {
        if (error.response?.status === 404) {
          throw new AuthError({
            name: "api/no-profile-found",
            message: "Profile does not exist.",
            cause: error,
          });
        }
      }
    }
  };

  updateUserProfileAPI = async (id: string, profileData: UpdateProfile): Promise<void> => {
    const profile = await AxiosAgent.Profile.update(id, profileData);
    this.setProfile(profile);
  };

  createProfile = async (profileObject: Profile): Promise<ProfileResponse> => {
    return await AxiosAgent.Profile.create(profileObject);
  };

  findOrCreateProfile = async (profileObject: Profile): Promise<ProfileResponse> => {
    return await AxiosAgent.Profile.findOrCreate(profileObject);
  };

  invokeSweeper = async (id: string) => {
    return await AxiosAgent.Profile.invokeSweeper(id);
  };

  createOrGetProfile = async (
    user: User,
    verificationValue: string = this.verificationMethodValue!,
    isOnSignInPage: boolean = false,
  ): Promise<void> => {
    try {
      const engagement = store.engagementStore.engagement;
      const servicer = store.engagementStore.servicer;
      this.loader.updateState("LOADING");
      if (engagement) {
        const profileObject = convertEngagementToProfileObject(engagement, verificationValue, user.uid, servicer);

        profileObject.trustedFormUrl = this.trustedFormUrl;
        const profile = await this.findOrCreateProfile(profileObject);
        GTMProfileCreated(engagement.partner_id ?? "Direct", engagement.experiment_name ?? "Direct");
        this.setProfile(profile);
      } else {
        const existingProfile = await this.doesProfileExistInDatabase(user.uid);

        if (existingProfile) {
          let profile = existingProfile;
          const isProfileTimedOut = profile.subStatus === ProfileSubStatusEnum.SYSTEM_TIMEOUT_BEFORE_CREDIT;

          if (isProfileTimedOut) {
            if (isOnSignInPage) {
              store.modalStore.openModal(CTAModulesEnum["call-for-help"]);
            }
          }

          const isProfileExpiredButNotTimedOut = !isProfileTimedOut && isDateExpired(existingProfile.expirationDate);

          if (isProfileExpiredButNotTimedOut) {
            if (isOnSignInPage) {
              store.modalStore.openModal(CTAModulesEnum["call-for-help"]);
            }

            const updatedProfile = await this.updateEnrollmentStatusTimedOut();
            if (updatedProfile) profile = updatedProfile;
          }

          this.setProfile(profile);
        }
      }
      this.loader.updateState("SUCCESS");
    } catch (error: unknown) {
      this.loader.updateState("ERROR");
      if (error instanceof AxiosError) {
        if (error.response?.status === 404) {
          throw new AuthError({
            name: "api/no-profile-found",
            message: "Profile does not exist.",
            cause: error,
          });
        } else if (error.response?.status === 409) {
          throw new AuthError({
            name: "api/profile-exists-with-a-different-auth-method",
            message: "Profile already exists. Please try with the previously used authentication method",
            cause: error,
          });
        } else {
          throw new AuthError({
            name: "api/internal-error",
            message: "Error - Internal error encountered.",
          });
        }
      }
    }
  };

  updateProfileExpirationDate = async (newExpirationDate: Date): Promise<void> => {
    try {
      const profile = await AxiosAgent.Profile.update(this.profile!.id!, {
        expirationDate: newExpirationDate,
      });
      this.setProfile(profile);
    } catch (error: unknown) {
      rollbar.debug("Error updating profile expiration date", error as Error);
    }
  };

  updateEnrollmentStatus = async (status: ProfileSubStatus) => {
    try {
      if (this.profile?.id) {
        const profile = await AxiosAgent.Profile.update(this.profile.id, {
          subStatus: status,
        });
        this.setProfile(profile);
        return profile;
      }
    } catch (error: unknown) {
      rollbar.debug("Error updating profile subStatus", error as Error);
    }
  };

  get timedOutEnrollmentStatus() {
    const { IDA_TIMED_OUT, IDA_ENROLLMENT_STARTED, SYSTEM_TIMEOUT_BEFORE_CREDIT } = ProfileSubStatusEnum;
    const subStatus = this.profile?.subStatus;

    if (subStatus) {
      if (subStatus === IDA_ENROLLMENT_STARTED) {
        return SYSTEM_TIMEOUT_BEFORE_CREDIT;
      }

      if (![IDA_TIMED_OUT, SYSTEM_TIMEOUT_BEFORE_CREDIT].includes(subStatus)) {
        return IDA_TIMED_OUT;
      }

      return subStatus;
    }
  }

  updateEnrollmentStatusTimedOut = async () => {
    if (this.timedOutEnrollmentStatus) {
      return this.updateEnrollmentStatus(this.timedOutEnrollmentStatus);
    }
  };

  updatePercentageCompleteAndLastPageCompleted = async (percentage: number, page: VTPageTitles) => {
    store.chatStore.updateLastPage(page);

    if (this.profile?.id) {
      const profile = await AxiosAgent.Profile.update(this.profile.id, {
        enrollmentPercentageComplete: percentage,
        lastCompletedPage: page,
      });
      this.setProfile(profile);
    }
  };

  getAllServicers = async () => {
    return AxiosAgent.Utils.getAllServicers();
  };

  updateServicer = async (servicer?: Servicer) => {
    if (this.profile?.id && servicer) {
      const profile = await AxiosAgent.Profile.update(this.profile.id, {
        servicer,
      });
      this.setProfile(profile);
    }
  };

  updateIsBudgetPageVisited = async () => {
    if (this.profile?.id && !this.profile.isBudgetPageVisited) {
      const profile = await AxiosAgent.Profile.update(this.profile.id, {
        isBudgetPageVisited: true,
      });
      GTMDynamicEvent("budget_page_viewed");
      this.setProfile(profile);
    }
  };

  // @TODO: Future enhancement - set ga_client_id, partner_name, experiment_name, lead_type in profile collection and remove this method to prevent making a get engagement request
  getGTMParamsFromEngagement = async (id: string) => {
    try {
      const { ga_client_id, partner_name, experiment_name, lead_type } = await AxiosAgent.Engagement.getById(id);
      const dataLayerPushOrLogError = (value: string | undefined, cb: (arg: string) => void, errorMsg: string) => {
        if (value) {
          cb(value);
        } else {
          rollbar.debug(errorMsg, new Error(errorMsg));
        }
      };
      dataLayerPushOrLogError(ga_client_id, GTMInitiate, "GA Client ID not found in engagement");
      dataLayerPushOrLogError(partner_name, GTMPartnerName, "Partner name not found in engagement");
      dataLayerPushOrLogError(experiment_name, GTMWorkflowVariant, "Workflow Variant not found in engagement");
      dataLayerPushOrLogError(lead_type, GTMProgramType, "Program type not found in engagement");
    } catch (error: unknown) {
      rollbar.debug("Error getting GA Client ID from engagement", error as Error);
    }
  };

  private getJourneyIdFromName(name: JourneyName) {
    const journeyMap = {
      [JourneyName.DEBT_MANAGEMENT]: JourneyId.DEBT_MANAGEMENT,
      [JourneyName.DEBT_SETTLEMENT]: JourneyId.DEBT_SETTLEMENT,
    };

    return journeyMap[name];
  }

  get journeyId() {
    if (this.profile?.journeys && this.profile?.engagementLeadType) {
      const leadType = this.profile.engagementLeadType;
      return this.getJourneyIdFromName(leadType);
    }
    // default debt-settlement
    return JourneyId.DEBT_SETTLEMENT;
  }

  get journey() {
    return this.profile?.journeys?.[this.journeyId];
  }

  get leadType() {
    return this.profile?.engagementLeadType;
  }

  get hasSelectedPlan() {
    const selectedPlan = this.journey?.selectedPlan;
    return !!selectedPlan && !!Object.keys(selectedPlan).length;
  }
}
