import isEmpty from "lodash/isEmpty";
import { autorun, makeAutoObservable } from "mobx";

import {
  BUDGET_FREQUENCY,
  BudgetItemData,
  BudgetItemTypeEnum,
  BudgetStatusEnum,
  BudgetType,
  BudgetTypeEnum,
  IBudgetItemData,
  BudgetDMPType,
  IBudgetDMPItemData,
  IBudgetDMPItemDataDto,
  IBudgetItemDataDto,
} from "@types";

const STATUS_STORAGE_NAME = "budgetToolStatuses";
const DEFAULT_BUDGET_STATUSES: BudgetType<BudgetStatusEnum> = {
  [BudgetTypeEnum.DEBT]: BudgetStatusEnum.PENDING,
  [BudgetTypeEnum.INCOME]: BudgetStatusEnum.PENDING,
  [BudgetTypeEnum.EXPENSE]: BudgetStatusEnum.PENDING,
};

export class BudgetStore {
  totalValues: BudgetType<number | null> = {
    [BudgetTypeEnum.DEBT]: null,
    [BudgetTypeEnum.INCOME]: null,
    [BudgetTypeEnum.EXPENSE]: null,
  };
  gaugeCategories: Array<BudgetTypeEnum | "total"> = ["total", ...Object.values(BudgetTypeEnum)];
  currGaugeCategoryIdx = 0;
  data: BudgetType<IBudgetItemData[]> = {
    [BudgetTypeEnum.DEBT]: [],
    [BudgetTypeEnum.INCOME]: [],
    [BudgetTypeEnum.EXPENSE]: [],
  };
  isBudgetEdit: boolean = false;
  isComplete: boolean = false;
  isEmptyData: boolean = false;
  itemData: IBudgetItemData = new BudgetItemData({});
  itemBudgetType: BudgetTypeEnum = BudgetTypeEnum.INCOME;
  statuses: BudgetType<BudgetStatusEnum> = DEFAULT_BUDGET_STATUSES;

  constructor() {
    makeAutoObservable(this);

    const storedStatuses = sessionStorage.getItem(STATUS_STORAGE_NAME);

    if (storedStatuses) {
      this.statuses = JSON.parse(storedStatuses);
    }

    autorun(() => {
      sessionStorage.setItem(STATUS_STORAGE_NAME, JSON.stringify(this.statuses));
      this.isEmptyData = Object.values(this.totalValues).every((v) => {
        return v === 0 || v === null;
      });
      this.isComplete = Object.values(this.statuses).every((status) => {
        return status === BudgetStatusEnum.COMPLETED;
      });
    });
  }

  setData = (data?: BudgetType<IBudgetItemData[]>) => {
    if (!isEmpty(data)) {
      if ("elements" in data) {
        for (const t of Object.values(BudgetTypeEnum)) {
          const filteredData = (data as unknown as BudgetDMPType).elements.filter((e: IBudgetDMPItemData) =>
            this.filterDMPCategory(e, t),
          );
          this.data[t] = filteredData.map((e: IBudgetDMPItemData) => this.transformDMPtoDSP(e));
          this.setTotalDMP(t, filteredData);
        }
      } else {
        for (const t of Object.values(BudgetTypeEnum)) {
          this.data[t] = data[t];
          this.setTotal(t, this.data[t]);
        }
      }
    }
  };

  filterDMPCategory = (item: IBudgetDMPItemData, category: BudgetTypeEnum): boolean => {
    return item.tags?.includes(category);
  };

  transformDMPtoDSP = (item: IBudgetDMPItemData): IBudgetItemData => {
    return {
      subCategory: item.subCategory,
      category: item.category,
      type: item.type === "Fixed" ? BudgetItemTypeEnum.REQUIRED : BudgetItemTypeEnum.OPTIONAL,
      id: item.name,
      name: item.subCategory,
      amount: item.amount,
      frequency: item.frequency === 12 ? BUDGET_FREQUENCY.MONTHLY : BUDGET_FREQUENCY.YEARLY,
    };
  };

  transformDSPtoDMP = (item: IBudgetItemDataDto, category: BudgetTypeEnum): IBudgetDMPItemDataDto => {
    return {
      subCategory: item.name,
      type: item.type === BudgetItemTypeEnum.REQUIRED ? "Fixed" : "Variable",
      category: item.category,
      name: item.id,
      amount: Number(item.amount),
      tags: [category],
      frequency: item.frequency === BUDGET_FREQUENCY.MONTHLY ? 12 : 1,
    };
  };

  addItem = (t: BudgetTypeEnum, item: IBudgetItemData) => {
    this.data[t].push(item);
  };

  removeItem = (t: BudgetTypeEnum, item: IBudgetItemData) => {
    if (item.id) {
      this.data[t] = this.data[t].filter(({ id }) => id !== item.id);
    } else {
      this.data[t] = this.data[t].filter(({ name, id }) => id || name !== item.name);
    }
  };

  setTotalDMP = (type: BudgetTypeEnum, items: IBudgetDMPItemData[]): void => {
    const total =
      items.reduce((total, item) => {
        // Repete the logic === BUDGET_FREQUENCY.MONTHLY ? 1 : 12;
        const denominator = item.frequency === 12 ? 1 : 12;
        return total + Number(Number(item.amount / denominator).toFixed(2));
      }, 0) ?? 0;
    this.totalValues[type] = total;
  };

  calcTotal = (items?: IBudgetItemData[]): number | null => {
    if (items) {
      if (items.every((i) => i.amount === null)) return null;
      return items.reduce((total, item) => {
        let { amount } = item;
        amount = typeof amount === "number" ? amount : 0;
        const denominator = item.frequency === BUDGET_FREQUENCY.MONTHLY ? 1 : 12;
        return total + Number(Number(amount / denominator).toFixed(2));
      }, 0);
    }
    return 0;
  };

  isRequiredEmpty = (t: BudgetTypeEnum) => {
    return this.data[t]
      .filter(({ type }) => type === BudgetItemTypeEnum.REQUIRED)
      .some(({ amount }) => amount === null);
  };

  setTotal(type: BudgetTypeEnum, items?: IBudgetItemData[]) {
    const total = this.calcTotal(items);
    this.totalValues[type] = total;
  }

  setIsBudgetEdit(isBudgetEdit: boolean) {
    this.isBudgetEdit = isBudgetEdit;
  }

  setItemBudgetType(t: BudgetTypeEnum) {
    this.itemBudgetType = t;
  }
  setItemData(d: IBudgetItemData) {
    this.itemData = d;
  }

  getTotal(type: BudgetTypeEnum) {
    return this.totalValues[type];
  }

  setStatus(type: BudgetTypeEnum, status: BudgetStatusEnum) {
    this.statuses[type] = status;
  }

  resetStatuses() {
    this.statuses = DEFAULT_BUDGET_STATUSES;
  }

  setCurrGaugeCategory(idx: number) {
    this.currGaugeCategoryIdx = idx;
  }

  get isBudgetVisited() {
    return Object.values(this.statuses).some((i) => i === BudgetStatusEnum.STARTED || i === BudgetStatusEnum.COMPLETED);
  }

  get availableCash() {
    const income = this.totalValues[BudgetTypeEnum.INCOME] || 0;
    const debt = this.totalValues[BudgetTypeEnum.DEBT] || 0;
    const expense = this.totalValues[BudgetTypeEnum.EXPENSE] || 0;

    return income - debt - expense;
  }
}
